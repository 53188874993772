import React, { useState,useEffect } from 'react';
import { Select,Spin } from 'antd';
import moment from "moment";
import { socketInfoDetails } from "../../../redux/action";
import { useSelector } from "react-redux";

const ExpiringProgramsAndLimits =({expiringPrograms}:any) => {
  const [ loading,setLoading]=useState(true)
  const socketData = useSelector(socketInfoDetails)
  const Data = expiringPrograms ? expiringPrograms : socketData?.payload?.socketInfo?.dashboard_data?.Expiring_programs
    const { Option } = Select;

const onChangeDropDown=(value:any)=> {
  if(value === "Next_3_months"){
    setDataSource(Data?.Next_3_months)
  }else if(value === "Next_2_months"){
    setDataSource(Data?.Next_2_months)
  } else if(value === "Next_1_month"){
    setDataSource(Data?.Next_1_month)
  }
  
}
    const [dataSource,setDataSource] = useState(Data? Data?.Next_1_month : [])
   
      useEffect(()=>{
setDataSource(Data?.Next_1_month)
setTimeout(()=>{
  setLoading(false)

},1000)
      },[])
    return(
        <div style={{ padding: "24px" }}>
          <h2>Expiring Programs/Limits</h2>
            <Select
    placeholder="Select a person"
    optionFilterProp="children"
    onChange={onChangeDropDown}
    style={{width:"35%",float:"right",marginTop:"-50px"}}
    defaultValue={"Next_1_month"}
  >
    <Option value="Next_1_month">Next 1 Month</Option>
    <Option value="Next_2_months">Next 2 Months</Option>
    <Option value="Next_3_months">Next 3 Months</Option>
  </Select>
  
    <div className='containerTable'>
        <div className="OuterDiv">
        <div className='HeadInnerDiv'>
                <h1 className='head'>Customer</h1>
                <h1 className='head'>Program</h1>
                <h1 className='head'> Limit Amt
                </h1>
                <h1 className='head'>Expiry Date</h1>
                </div>
        </div>
        {loading ? <Spin /> :
        <div className='OuterDiv' 
        style={{overflow:"scroll",height:"19vh",
        paddingBottom:"23px"
        ,justifyContent:"unset"
      }} >
        {dataSource ?
          dataSource?.map((record:any,index:number)=>{
            return(
              <div key={index} 
              className={'InnerDiv'} 
              style={{cursor:"default"}}
              >
                <h5 className='body'>  {record?.anchor_party__name}  </h5>
                <h5 className='body'>  {record?.program_type}  </h5>
                <h5 className='body'> {record?.max_limit_amount}
                 </h5>
                <h5 className='body' style={{whiteSpace:"nowrap"}}>  {record?.expiry_date !== null ? moment(record.expiry_date).format("DD-MM-YYYY"): "-"}  </h5>
                    </div>
            )
          }):
          Data?.length >0 ? 
          Data?.Next_1_month?.map((record:any,index:number)=>{
            return(
              <div key={index} 
              className={'InnerDiv'} 
              style={{cursor:"default"}}
              >
                <h5 className='body'>  {record?.party__name}  </h5>
                <h5 className='body'>  {record?.program_type}  </h5>
                <h5 className='body'> {record?.max_limit_amount} 
                 </h5>
                <h5 className='body' style={{whiteSpace:"nowrap"}}>  {record?.expiry_date !== null ? moment(record.expiry_date).format("DD-MM-YYYY"): "-"}  </h5>
                   
                    </div>
            )
          })
          :
          <div className="noDataText">No data</div>}
      </div>
}
      </div>
        </div>
    )
}

export default ExpiringProgramsAndLimits;