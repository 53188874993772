import React, { useEffect, useState } from 'react'
import { Card, DatePicker, Spin, message, Form } from 'antd';
import moment from 'moment';
import images from "../../assets/images";
import httpClient from '../../utils/config/core/httpClient';
import baseurl from '../../utils/config/url/base';
import { ResponseStatus } from '../../utils/enum/choices';
import Breadcrumbs from "../common/heading/breadcrumb";
import { FieldErrorMessages } from "../../utils/enum/messageChoices";

const CurrentDate = () => {
    const { DatePickerImg } = images;
    const [transactionData, setTransactionData] = useState([] as any)
    const [currentDate, setCurrentDate] = useState("")
    const [loading, setLoading] = useState(true)
    const [buttondisable, setButtonDisable] = useState(false);

    const disabledDate = (current: any) => {
        return current && current > moment().endOf("day");
    };

    useEffect(() => {
        httpClient
            .getInstance()
            .get(`${baseurl}api/transition/schedule/`)
            .then((resp: any) => {
                const originalDate = resp?.data?.data?.[0]?.system_date;
                const formattedDate = moment(originalDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
                if (resp.data?.data?.length > 0) {
                    setTransactionData(resp?.data?.data)
                    setCurrentDate(formattedDate)
                    // } else {
                    //     setCurrentDate(moment().format("DD-MM-YYYY"))
                }
                setTimeout(() => {
                    setLoading(false)

                }, 2000)
            })
    }, [])

    const submitDate = () => {
        setButtonDisable(true)
        if (transactionData.length === 0) {
            const body = {
                "party": localStorage.getItem("party_id"),
                "system_date": currentDate,
            }
            httpClient
                .getInstance()
                .post(`${baseurl}api/transition/schedule/`, body)
                .then((resp: any) => {
                    console.log("resp", resp)
                    if (resp.data.status === ResponseStatus.SUCCESS) {
                        message.success("System date is updated")
                    } else {
                        message.error(resp.data.status)
                    }
                    setButtonDisable(false)
                })
        } else {
            const body = {
                "party": localStorage.getItem("party_id"),
                "system_date": currentDate
            }
            httpClient
                .getInstance()
                .put(`${baseurl}api/transition/schedule/${transactionData?.[0]?.id}/`, body)
                .then((resp: any) => {
                    console.log("resp", resp)
                    if (resp.data.status === ResponseStatus.SUCCESS) {
                        message.success("System date is updated")
                    } else {
                        message.error(resp.data.status)
                    }
                    setButtonDisable(false)
                })
        }

    }
    const onChangeDate = (e: any) => {
        setCurrentDate(moment(e?._d).format("DD-MM-YYYY"))
    }
    const buttonStatusData = {
        default: buttondisable

    }
    const onClickExit = () => {
        console.log("exit")
    };
    const Data = {
        fromMenu: "Settings",
        data: {
            type: ""
        },
        recordType: ""
    }
    return loading ? <Spin /> : (
        <React.Fragment>
            <div className="fixedContainer">
                <Breadcrumbs
                    Data={Data}
                    onClickExit={onClickExit}
                    commentsValue={""}
                    flag="Current Date"
                    onClickAction={submitDate}
                    buttonStatusData={buttonStatusData}
                />
            </div>
            <div className="mainContentContainer">
                <Card className='CardContainer'>
                    <h4>System Date</h4>
                    {
                        <>
                            <div className="createNewAccount loginContainer">

                                <Form
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    autoComplete="off"
                                >
                                    <Form.Item>
                                        <p>The current system date is {currentDate}</p>
                                    </Form.Item>

                                    <Form.Item
                                        label={"Current Date"}
                                        name={"Current Date"}
                                        className="FormContainer"
                                        key={"Current Date"}
                                        rules={[
                                            {
                                                required: false,
                                                message: FieldErrorMessages.CUSID,
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format={"DD-MM-YYYY"}
                                            id="system_datePicker"
                                            disabledDate={disabledDate}
                                            suffixIcon={<img src={DatePickerImg} alt="pickericon" />}
                                            style={{
                                                lineHeight: "2.5",
                                                borderRadius: "8px",
                                                fontSize: "13px",
                                                padding: "5px 15px",
                                                width: "100%",
                                                height: "40px",
                                            }}
                                            placeholder={"DD-MM-YYYY"}
                                            className={"valueExpiryDate"}

                                            onChange={onChangeDate}
                                        /></Form.Item>
                                </Form>

                            </div>

                        </>

                    }
                </Card>
                {/* <Card className='CardContainer'>
                <Row
                        className="counterPartyCollapseDetails"
                    >
                        <p>The current system date is {currentDate}</p>
                        </Row>
                    <Row
                        className="counterPartyCollapseDetails"
                    >
                        <Col span={2}></Col>
                        <Col span={5}><p>Current Date</p></Col>
                        <Col span={5} className="counterPartyValue repaymentText">
                            <DatePicker
                                format={"DD-MM-YYYY"}
                                id="program_datePicker"
                                disabledDate={disabledDate}
                                suffixIcon={<img src={DatePickerImg} alt="pickericon" />}
                                style={{
                                    lineHeight: "2.5",
                                    borderRadius: "8px",
                                    fontSize: "13px",
                                    padding: "5px 15px",
                                    width: "100%",
                                    height: "40px",
                                }}
                                placeholder={"DD-MM-YYYY"}
                                className={"valueExpiryDate"}

                                onChange={onChangeDate}
                            />
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}><p></p></Col>
                        <Col span={5} className="counterPartyValue" style={{ alignItems: "baseline" }}>
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                </Card> */}
            </div>

        </React.Fragment>
    )
}
export default CurrentDate