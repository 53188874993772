import React, { useState, useEffect } from "react";
import { Row, Col, Select, Input, message, Card, Radio, DatePicker } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import httpClient from "../../../utils/config/core/httpClient";
import baseurl from "../../../utils/config/url/base";
import images from "../../../assets/images"
import { useSelector } from "react-redux";
import { allCurrency } from "../../../redux/action";
import { transition } from "../../api/finfloTransition";
import { ErrorMessage } from "../../../utils/enum/messageChoices";
import { Action, ResponseStatus, TransactionType } from "../../../utils/enum/choices";
import CurrencyField from "../function/currency";
import Breadcrumbs from "../heading/breadcrumb";
import moment from 'moment'
const { Option } = Select;
const FinanceRequestDetail = () => {
  const { state } = useLocation();
  const Navigator = useNavigate();

  const [invoice_detail] = useState(state as any);
  const { DropdownIcon, DatePickerImg } = images;
  const currency_data = useSelector(allCurrency);

  const [allCurrencyData, setallCurrencyData] = useState([]);
  const [RepaymentValue, setRepaymentValue] = useState("");
  const [OtherAmountValue, setOtherAmountValue] = useState("")
  const [PartyAccountDetails, setPartyAccountDetails] = useState([])
  const [PartyAccountCurrencyDetails, setPartyAccountCurrencyDetails] = useState("")
  const [repaymentId, setRepaymentId] = useState("");
  const [buttondisable, setButtonDisable] = useState(false);
  const [primaryButtonDisable, setPrimaryButtonDisable] = useState(false)
  const [secondaryButtonDisable, setSecondaryButtonDisable] = useState(false)
  const [repaymentDate, setRepaymentDate] = useState("")

  const onClickExit = () => {
    Navigator(`/PendingInvoices`)
  };
  const returnRoute = (data: any) => {
    if (data === true) {
      Navigator(`/PendingInvoices`)
    }
  }
  const onClickSubmitAction = () => {
    setButtonDisable(true)
    setPrimaryButtonDisable(true)
    if (RepaymentValue === "") {
      message.error(ErrorMessage.PCR)
      setButtonDisable(false)
      setPrimaryButtonDisable(false)
    } else {
      if (RepaymentValue === "Other Amount") {
        if (OtherAmountValue === "") {
          message.error(ErrorMessage.PFOAMT)
          setButtonDisable(false)
          setPrimaryButtonDisable(false)
        } else {
          const bodyData = {
            repayment_amount: parseFloat(OtherAmountValue).toFixed(2),
            repayment_account: repaymentId ? repaymentId : invoice_detail?.data?.repayment_account.id,
            repayment_date: repaymentDate
          }
          httpClient
            .getInstance()
            .put(`${baseurl}api/invoice/${invoice_detail.data.id}/`, bodyData)
            .then((resp: any) => {

              if (resp.data.status === ResponseStatus.SUCCESS) {
                const body = {
                  action: Action.SUBMIT,
                  type: TransactionType.TINVOICEREPAYMENTS,
                  t_id: invoice_detail.data.id
                }
                httpClient
                  .getInstance()
                  .post(`${baseurl}api/resource/action/status/`, body)
                  .then((resp: any) => {
                    if (resp.data.status === ResponseStatus.SUCCESS) {
                      message.success(resp?.data?.transition_status)
                      setButtonDisable(false)
                      setPrimaryButtonDisable(false)
                      // returnRoute(true)
                      Navigator(`/PendingInvoices`)
                      // transition(info, buttonKey === Action.DRAFT ? "SUBMIT" : buttonKey, resp.data.data.from_party, resp.data.data.to_party, info.fromMenu, returnRoute, buttonStatus)
                    } else {
                      message.error(resp.data.data)
                      setButtonDisable(false)
                      setPrimaryButtonDisable(false)
                    }
                  })
                // httpClient
                //   .getInstance()
                //   .get(`${baseurl}api/resource/action/status/?action=${Action.SUBMIT}&type=${TransactionType.TINVOICEREPAYMENTS}&t_id=${invoice_detail.data.id}`)
                //   .then((respo: any) => {
                //     if (respo.data.status === ResponseStatus.SUCCESS) {
                //       transition(invoice_detail, "Submit", respo.data.data.from_party, respo.data.data.to_party, "PendingInvoices", returnRoute, buttonStatus)
                //     } else {
                //       message.error(respo.data.data)
                //       setButtonDisable(false)
                //       setPrimaryButtonDisable(false)
                //     }
                //   })
              } else {
                setButtonDisable(false)
                setPrimaryButtonDisable(false)
              }
            })
        }

      } else {
        const bodyData = {
          repayment_amount: parseFloat(invoice_detail?.data?.outstanding_amount).toFixed(2),
          repayment_account: repaymentId ? repaymentId : invoice_detail?.data?.repayment_account?.id,
          repayment_date: repaymentDate
        }
        httpClient
          .getInstance()
          .put(`${baseurl}api/invoice/${invoice_detail.data.id}/`, bodyData)
          .then((resp: any) => {
            if (resp.data.status === ResponseStatus.SUCCESS) {
              const body = {
                action: Action.SUBMIT,
                type: TransactionType.TINVOICEREPAYMENTS,
                t_id: invoice_detail.data.id
              }
              httpClient
                .getInstance()
                .post(`${baseurl}api/resource/action/status/`, body)
                .then((resp: any) => {
                  if (resp.data.status === ResponseStatus.SUCCESS) {
                    message.success(resp?.data?.transition_status)
                    setButtonDisable(false)
                    setPrimaryButtonDisable(false)
                    Navigator(`/PendingInvoices`)
                    // returnRoute(true)
                    // transition(info, buttonKey === Action.DRAFT ? "SUBMIT" : buttonKey, resp.data.data.from_party, resp.data.data.to_party, info.fromMenu, returnRoute, buttonStatus)
                  } else {
                    message.error(resp.data.data)
                    setButtonDisable(false)
                    setPrimaryButtonDisable(false)
                  }
                })

              // httpClient
              //   .getInstance()
              //   .get(`${baseurl}api/resource/action/status/?action=${Action.SUBMIT}&type=${TransactionType.TINVOICEREPAYMENTS}&t_id=${invoice_detail.data.id}`)
              //   .then((resp: any) => {
              //     if (resp.data.status === ResponseStatus.SUCCESS) {
              //       transition(invoice_detail, "Submit", resp.data.data.from_party, resp.data.data.to_party, "PendingInvoices", returnRoute, buttonStatus)
              //     }
              //   })
            } else {
              console.log("")
              message.error("Invalid, Please check the repayments ")
            }
          })

      }
    }


  }
  console.log("repaymentId", repaymentId, "2", invoice_detail, "22", invoice_detail?.data?.repayment_account?.id)
  useEffect(() => {
    setPartyAccountCurrencyDetails(invoice_detail?.data?.repayment_account?.currency)
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        setallCurrencyData(resp.data.data);
      });
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/party/party-accounts/`)
      .then((resp: any) => {
        setPartyAccountDetails(resp.data.data)
      })
    setRepaymentDate(moment().format("DD-MM-YYYY"));
  }, []);
  const buttonStatus = (data: any) => {
    setButtonDisable(data)
    setPrimaryButtonDisable(data)
    setSecondaryButtonDisable(data)
  }
  const onChangeDate = (e: any) => {
    setRepaymentDate(moment(e?._d).format("DD-MM-YYYY"))
  }
  const buttonStatusData = {
    default: buttondisable,
    primary: primaryButtonDisable,
    secondary: secondaryButtonDisable

  }
  const Data = {
    fromMenu: "Pending Invoice",
    data: {
      type: ""
    },
    recordType: ""
  }
  const disabledDate = (current: any) => {
    return current && current > moment().endOf("day");
  };

  return (
    <React.Fragment>
      <div>

        <div className="fixedContainer">
          <Breadcrumbs
            Data={Data}
            onClickExit={onClickExit}
            commentsValue={""}
            flag="pendingInvoice"
            onClickAction={onClickSubmitAction}
            buttonStatusData={buttonStatusData}
          />
        </div>
        <div className="Card_Main_Container mainContentContainer">
          <Card className="CardContainer pendingDetailContainer">
            <h4>Pending Invoice Detail</h4>
            <div className="SummaryContainer">
              <Row
                className="counterPartyCollapseDetails"
                style={{ marginTop: "3%" }}
              >
                <Col span={2}></Col>
                <Col span={5}><p>Invoice No</p></Col>
                <Col span={5} className="counterPartyValue">
                  <p> {
                    invoice_detail?.data?.invoice_no
                  }</p>
                </Col>
              </Row>
              <Row
                className="counterPartyCollapseDetails"
              >
                <Col span={2}></Col>
                <Col span={5}><p>Due Date</p></Col>
                <Col span={5} className="counterPartyValue">
                  <p> {invoice_detail?.data?.due_date !== null ? moment(invoice_detail?.data?.due_date).format("DD-MM-YYYY") : "-"}</p>

                </Col>
                <Col span={1}></Col>
              </Row>
              <Row
                className="counterPartyCollapseDetails"
              >
                <Col span={2}></Col>
                <Col span={5}><p>Invoice Amount</p></Col>
                <Col span={5} className="counterPartyValue">
                  <p> {invoice_detail?.data?.amount ?
                    <CurrencyField currencyvalue={invoice_detail?.data?.invoice_currency} amount={invoice_detail?.data?.amount} />
                    : "-"}</p>
                </Col>
                <Col span={1}></Col>
                <Col span={1}></Col>
              </Row>
              <Row
                className="counterPartyCollapseDetails"
              >
                <Col span={2}></Col>
                <Col span={5}><p>Repayment Account</p></Col>
                <Col span={16}
                >
                  <Row gutter={24}>
                    <Col span={2} style={{ padding: "0 0 0 3px" }}>
                      <div style={{ fontSize: "14px", fontFamily: "Poppins", border: "1px solid #d9d9d9", height: "43px", width: "100%", borderRadius: "8px", color: "#000000", alignItems: "center", padding: "10px", textAlign: "center" }}>
                        {currency_data?.payload.currencyData.allCurrency &&
                          currency_data?.payload.currencyData.allCurrency.map((item: any) => {
                            if ((PartyAccountCurrencyDetails === item.id)
                            ) {
                              return (
                                <span>{item.description}</span>
                              );
                            }

                          })}
                      </div>
                    </Col>
                    <Col span={14}>
                      <Select
                        id="repayment_account"
                        showSearch
                        placeholder="Select"
                        optionFilterProp="children"
                        defaultValue={invoice_detail?.data?.repayment_account?.account_number}
                        style={{
                          fontSize: "14px", fontFamily: "Poppins",
                          width: "35%"
                        }}
                        suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                        onChange={
                          (e) => {
                            let repaymentCurrency = ""
                            PartyAccountDetails &&
                              PartyAccountDetails.map((item: any) => {
                                if (e === item.account_number) {
                                  repaymentCurrency = item.currency
                                  setPartyAccountCurrencyDetails(item.currency)
                                  setRepaymentId(item.id)
                                }

                              })
                            let fromCurrency = ""
                            currency_data?.payload.currencyData.allCurrency &&
                              currency_data?.payload.currencyData.allCurrency.map((item: any) => {

                                if (invoice_detail?.data?.finance_currency === item.id) {
                                  return (
                                    fromCurrency = item.description
                                  );
                                }

                              })
                            let tocurrency = ""
                            currency_data?.payload.currencyData.allCurrency &&
                              currency_data?.payload.currencyData.allCurrency.map((item: any) => {

                                if (repaymentCurrency === item.id) {
                                  return (
                                    tocurrency = item.description
                                  );
                                }

                              })

                            const bodyData = {
                              from_currency: fromCurrency,
                              to_currency: tocurrency,
                              from_amount: RepaymentValue === "Other Amount" ? parseFloat(OtherAmountValue) : parseFloat(invoice_detail?.data?.repay_total_amount)
                            }
                            httpClient
                              .getInstance()
                              .post(`${baseurl}api/resource/currency/conversion/`, bodyData)
                              .then((resp: any) => {
                                setOtherAmountValue(resp.data.data)
                              })
                          }
                        }

                      >
                        {PartyAccountDetails &&
                          PartyAccountDetails.map((item: any, index: number) => {
                            return (
                              <Option value={item.account_number} key={item.account_number} id={`account_number${index}`} style={{ fontSize: "14px", fontFamily: "Poppins" }}
                              >
                                <div >
                                  <span>
                                    {allCurrencyData &&
                                      allCurrencyData.map((items: any) => {
                                        let repaymentcurr = '' as any
                                        if (item.currency === items.id) {
                                          repaymentcurr = items.description
                                        }
                                        return repaymentcurr
                                      })}</span>  {" "}
                                  {item.account_number}
                                </div>
                              </Option>
                            );
                          })}
                      </Select>

                    </Col>
                  </Row>
                </Col>
                <Col span={1}></Col>
              </Row>
              <Row
                className="counterPartyCollapseDetails"
              >
                <Col span={2}></Col>
                <Col span={5}><p>Repay</p></Col>
                <Col span={15} className="counterPartyValue repaymentText">
                  <Radio.Group
                    onChange={(e) => {
                      setRepaymentValue(e.target.value)
                    }
                    }
                    id="radioButtonGroup"

                  >
                    <Row
                      className="counterPartyCollapseDetails"

                    >
                      <Radio value={"Repay Total Amount"} id="repayTotalAmount">
                        <p>  <div style={{ display: "flex" }}>Repay Total Amount - {" "}
                          <CurrencyField currencyvalue={invoice_detail?.data?.finance_currency} amount={parseFloat(invoice_detail?.data?.outstanding_amount ? invoice_detail?.data?.outstanding_amount : 0)} />
                        </div></p>
                      </Radio>

                    </Row>

                  </Radio.Group>
                  <Row>
                    {RepaymentValue === "Other Amount" ?
                      <Input style={{ width: "40%" }} id="otherAmount" onChange={(e: any) => setOtherAmountValue(e.target.value)} prefix={allCurrencyData &&
                        allCurrencyData.map((item: any) => {
                          if (invoice_detail?.data?.finance_currency === item.id) {
                            return (
                              <span>{item.description} </span>
                            );
                          }

                        })} /> : ""}
                  </Row>
                </Col>
                <Col span={1}></Col>

              </Row>
              <Row
                className="counterPartyCollapseDetails"
              >
                <Col span={2}></Col>
                <Col span={5}><p>Repayment Date</p></Col>
                <Col span={5} className="counterPartyValue repaymentText">
                  {/* <DatePicker/> */}
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    id="program_datePicker"
                    disabledDate={disabledDate}
                    suffixIcon={<img src={DatePickerImg} alt="pickericon" />}
                    style={{
                      lineHeight: "2.5",
                      borderRadius: "8px",
                      fontSize: "13px",
                      padding: "5px 15px",
                      width: "100%",
                      height: "40px",
                    }}
                    placeholder={repaymentDate ? repaymentDate : "DD-MM-YYYY"}
                    className={repaymentDate ? "valueExpiryDate" : ""}
                    onChange={onChangeDate}
                  />
                </Col>
                <Col span={1}></Col>
                <Col span={5}><p></p></Col>
                <Col span={5} className="counterPartyValue" style={{ alignItems: "baseline" }}>
                </Col>
                <Col span={1}></Col>
              </Row>

            </div>
          </Card>
        </div>

      </div>
    </React.Fragment>

  );
};
export default FinanceRequestDetail;
