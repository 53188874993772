import { Card } from "antd";
import "./draft.scss";
import TableComponent from "../common/table/Table";
import Heading from "../common/heading/heading";

const Draft = () => {

  return (
    <div className="tableContainer">
      <div className="fixedContainer">
        <Heading flag="draft" text="Draft" subText="" />
      </div>
      <div className="allContainer mainContentContainer">
        <Card className="CardContainer">
          <TableComponent
            fromMenu={"draft"}
            fromPage={"draft"}
            recordType={""}
          />
        </Card>
      </div>
    </div>
  );
};

export default Draft;
