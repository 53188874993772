
import { message } from "antd";

let link = ""
let socketurl: WebSocket | null = null
if (localStorage.getItem("token") !== null) {
    //link = `ws://c57vfsp3-8000.inc1.devtunnels.ms/ws/info/?token=${localStorage.getItem("token")}`
    //dev
    //link = `wss://finflo-beta-lpbb2.ondigitalocean.app/ws/info/?token=${localStorage.getItem("token")}`
    //test
    link = `wss://krediq-wtzdm.ondigitalocean.app/ws/info/?token=${localStorage.getItem("token")}`
    socketurl = new WebSocket(link)

}
else if (localStorage.getItem("token") === null && window.location.pathname !== "/") {
    message.error('Session Expired, Please login and try again!', 3)
    localStorage.clear();
}


export default socketurl;