import React from "react";
import TableComponent from "../table/Table";
interface IProps {
  fromMenu: any;
  fromMenuValue: any;
  recordType: string;
}
const AllContainer = (props: React.PropsWithChildren<IProps>) => {
  
  return (
    <div className="allContainer">
      <TableComponent
        fromPage={""}
        fromMenu={props.fromMenu}
        recordType={props.recordType}
      />
    </div>
  );
};
export default AllContainer;
