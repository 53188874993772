import { Card, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import httpClient from "../../utils/config/core/httpClient";
import baseurl from "../../utils/config/url/base";
import moment from "moment";
import { Type } from "../../utils/enum/choices";
import Breadcrumbs from "../common/heading/breadcrumb";
const EnquiryDetail = () => {
  const Navigator = useNavigate();
  const { state } = useLocation();
  const [enquiryData] = useState(state as any);
  const [dataSource, setDataSource] = useState([]);
  const [buttondisable, setButtonDisable] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const getCurrency = () => {
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        setCurrencyList(resp.data.data);
      });
  }
  useEffect(() => {
    getCurrency()
    httpClient
      .getInstance()
      .get(`${baseurl}api/message/workflow-history/?wf_item=${enquiryData.id}`)
      .then((resp: any) => {
        setDataSource(resp.data.data);
      });
  }, []);
  const onClickExit = () => {
    Navigator("/Enquiry");
  };
  
  const Data = {
    fromMenu: "Enquiry",
    data: {
        type: ""
    },
    recordType: ""
  }
  const buttonStatusData = {
    default: buttondisable
  }
  return (
    <React.Fragment>
       <div className="fixedContainer">
      <Breadcrumbs
      Data={Data}
      onClickExit={onClickExit}
      commentsValue={""}
      flag="Enquiry"
      onClickAction={onClickExit}
      buttonStatusData={buttonStatusData}
      />
      </div>
      <div className="Card_Main_Container mainContentContainer">
        <Card className="CardContainer">

          <Row gutter={24}>
            <Col span={6}>
              <div className="labelContainer" >Type</div>
            </Col>
            <Col span={6}>
              <p>{enquiryData.type}</p>
            </Col>
            <Col span={6}>
            <div className="labelContainer" >Status</div>
            </Col>
            <Col span={6}>
              <p>
                {enquiryData?.type === Type.INVOICEUPLOAD ? enquiryData?.interim_state
                  : enquiryData?.interim_state}
              </p>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
            <div className="labelContainer" >Program Name</div>
            </Col>
            <Col span={6}>
              <p>{enquiryData?.type ===Type.INVOICEUPLOAD ? enquiryData?.record_datas?.values?.[0]?.fields?.program_type : enquiryData?.type === Type.INVOICE ? enquiryData?.record_datas?.values?.[0]?.fields?.program_type : "-"} Program</p>
            </Col>
            <Col span={6}>
            <div className="labelContainer" >Amount</div>
            </Col>
            <Col span={6}>
              <p>
                {enquiryData?.type === Type.INVOICEUPLOAD ? enquiryData?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceCurrency :
                  enquiryData?.type === Type.INVOICE ?
                    currencyList.map((item: any) => {
                      let desc = ""
                      if (item.id === enquiryData?.record_datas?.values?.[0]?.fields?.invoice_currency) {
                        desc = item.description
                      }
                      return desc
                    })
                    : "-"}{" "}
                {enquiryData?.type === Type.INVOICEUPLOAD ? enquiryData?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.Invoiceamount :
                  enquiryData?.type === Type.INVOICE ? enquiryData?.record_datas?.values?.[0]?.fields?.amount : "-"}{" "}
              </p>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
            <div className="labelContainer" >Transaction ID</div>
            </Col>
            <Col span={6}>
              <p>{enquiryData.id}</p>
            </Col>
            <Col span={6}>
            <div className="labelContainer" >Due/Expire Date</div>
            </Col>
            <Col span={6}>
              <p>{enquiryData?.type === Type.INVOICEUPLOAD ? enquiryData?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.Duedate :
                enquiryData?.type === Type.INVOICE ? enquiryData?.record_datas?.values?.[0]?.fields?.due_date : "-"}</p>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
            <div className="labelContainer" >CounterParty</div>
            </Col>
            <Col span={6}>
              <p>{enquiryData?.seller_details ? enquiryData?.seller_details?.[0]?.name : "-"}</p>
            </Col>
          </Row>
          <div className='containerTable' style={{ marginTop: "5%" }}>
            <div className="OuterDiv">

              <div className='HeadInnerDiv'>
                <h1 className='head'>Created Date</h1>
                <h1 className='head'>To</h1>
                <h1 className='head'>From Status</h1>
                <h1 className='head'>To Status</h1>
                <h1 className='head'>User</h1>
                <h1 className='head'>Action</h1>
              </div>

            </div>
            <div className='OuterDiv'>
              {dataSource && dataSource.map((record: any, index: number) => {
                return (
                  <div key={index}
                    className={
                      'InnerDiv'} id={`enquiryDetail_table`} style={{ cursor: "default" }}>
                    <h5 className='body' id={`enquiryDetail_date`}>
                      <div>{moment(record?.record_datas?.values?.[0]?.fields?.created_date).format("DD-MM-YYYY")}</div>
                    </h5>
                    <h5 className='body' id={`enquiryDetail_to`}>
                      <div>
                        {record?.to_party_name ? record?.to_party_name:"-"}
                      </div>
                    </h5>
                    <h5 className='body' id={`enquiryDetail_fromStatus`}>
                      <div>
                        {record?.initial_state}
                      </div>
                    </h5>
                    <h5 className='body' id={`enquiryDetail_toStatus`}>
                      <div>
                        {record?.final_state}
                      </div>
                    </h5>
                    <h5 className='body' id={`enquiryDetail_user`}>
                      <div>
                        {record?.event_user_details ? record?.event_user_details :"-"}
                      </div>
                    </h5>
                    <h5 className='body' id={`enquiry_action`}>
                      <div>
                        {record.action}
                      </div>
                    </h5>

                  </div>
                )
              })}
            </div>
          </div>
        </Card>
      </div>

    </React.Fragment>
  );
};

export default EnquiryDetail;
