import { useEffect, useState,useRef } from "react";
import { Avatar, Button, Modal, message, Badge, Drawer, Card } from "antd";
import httpClient from "../../utils/config/core/httpClient";
import { useNavigate } from "react-router-dom";
import Images from "../../assets/images";
import "./layout.scss";
import baseurl from "../../utils/config/url/base";
import chatsocketurl from "../../utils/config/url/chatSocket";
import { useSelector,useDispatch } from "react-redux";
import { socketInfoDetails,selectedNotificationData } from "../../redux/action";
import useSound from 'use-sound';
const HeaderContainer = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const soundUrl = "https://static.openreplay.com/tracker-assist/notification.mp3"
  const { NotificationIcon, CloseIcon,krediq } = Images;
  const [loginDetail] = useState(localStorage.getItem("login_detail") as any);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profileData, setProfiledata] = useState({} as any);
  const [notificationCount, setNotificationCount] = useState(0 as any);
  const [openDrawer, setOpenDrawer] = useState(false as any);
  const [notificationInfo, setNotificationInfo] = useState([])
  const ws = useRef(null) as any;
  const socketData = useSelector(socketInfoDetails);
  const socketInfoData = socketData?.payload?.socketInfo
  const showModal = () => {
    setIsModalVisible(true);
  };
  const [play] = useSound(soundUrl);

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  // useEffect(() => {
  //   // Play the sound when the notification count changes
  //   if (socketInfoData?.message_data?.notification?.count > 0) {
  //     play();
  //   }
  // }, [socketInfoData?.message_data?.notification?.count, play]);

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    httpClient
      .getInstance()
      .post(`${baseurl}api-auth/logout/`)
      .then((resp: any) => {
        props.loading("true")
        setTimeout(() => {
          navigate("/");
          window.location.reload();
          message.success("Logout Successful")
          localStorage.clear();
        }, 1000)
        ws.current = chatsocketurl ;
        ws.current.close();
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);

  };

  useEffect(() => {
   httpClient
      .getInstance()
      .get(`${baseurl}api-auth/user/profile/`)
      .then((resp: any) => {
        setProfiledata(resp?.data?.data?.[0]);
      });
  }, []);
  

  const notificationData = () => {
    httpClient
      .getInstance()
      .get(`${baseurl}api/message/notification/`)
      .then((resp: any) => {
        setNotificationInfo(resp.data?.data)
        setNotificationCount(resp.data.message_count)
      })

  }
  const closeOneNotification = (data: any) => {
    console.log(data)
    const body = {
      model_type: "NT",
      id: data.wf_item_id
    }
    httpClient
      .getInstance()
      .put(`${baseurl}api/message/update/${data.wf_item_id}/`, body)
      .then((resp: any) => {
        console.log(resp)
      })
  }

  const clearAllNotification = () => {
    const body = {
      model_type: "NT-ALL",
    }
    httpClient
      .getInstance()
      .put(`${baseurl}api/message/update/${socketInfoData?.notification_data?.data?.[0]?.wf_item_id}`, body)
      .then((resp: any) => {
        console.log(resp)
        setOpenDrawer(false);
      })
  }
  const OnNotificationCardClick = (data:any) => {
    console.log("data",data,data?.base_data?.detail)
    if(data?.base_data?.subject === "Program Expiry Notice"){
    httpClient
    .getInstance()
    .get(`${baseurl}api/resource/workflowitem/?id=${data?.wf_item_id}`)
    .then((resp: any) => {
      navigate("/ManageScf")
      dispatch(selectedNotificationData(resp?.data?.data));
      setOpenDrawer(false);
    })
    }else{
      httpClient
      .getInstance()
      .get(`${baseurl}api/resource/workflowitem/?id=${data?.wf_item_id}`)
      .then((resp: any) => {
      navigate("/Inbox/NotificationDetail")
        dispatch(selectedNotificationData(resp?.data?.data));
        setOpenDrawer(false);
      })
    }
  }
  
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const modal = document.querySelector(".ant-modal-wrap");
      if (modal && !modal.contains(e.target as Node)) {
        setIsModalVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className="profileContainer" style={{ width: props.width }}>
      
      <div className="profileDetail">
      <>
        <Badge count={socketInfoData?.notification_data?.count} showZero className="notificationImg">
          <img
            src={NotificationIcon}
            alt="Notification"
            onClick={showDrawer}
          />
        </Badge>
        <Drawer title="" placement="right" onClose={onCloseDrawer} open={openDrawer} >
          <>
            <a className="clearAllNotification" onClick={clearAllNotification}>Clear all</a>
            {socketInfoData?.notification_data?.data?.map((item: any,index:any) => {
              return (
                <Card className="notificationCard" onClick={()=>OnNotificationCardClick(item)} key={index}>
                  <img src={CloseIcon} alt="CloseIcon" className="notificationCloseIcon" onClick={() => closeOneNotification(item)} />
                  <div className="noticationSubjectText"><span>{item?.base_data?.subject} </span></div>
                  <div className="noticationText"><span>{item?.base_data?.content}</span></div>
                  <div className="noticationTimeStamp"><span>{item?.created_at}</span></div>
                </Card>
              )
            })}
          </>
        </Drawer>
      </>
        <h3>{JSON.parse(loginDetail).party}</h3>
        <p>{JSON.parse(loginDetail).display_name}</p>
      </div>
      <p></p>
      <Button className="ProfileImg" onClick={showModal} id="profile_modal">
        <Avatar
          icon={
            <img src={krediq} alt="VenzoLogo" className="profileAvatar" />
          }
        />
      </Button>
      <Modal
      maskClosable={true}
        title={
          <div className="profileCard">
            <Button className="ProfileImg">
              <Avatar
                icon={
                  <img
                    src={krediq}
                    alt="VenzoLogo"
                    className="profileAvatar"
                  />
                }
              />
            </Button>
            <div className="profileDetail">
              <h3>{JSON.parse(loginDetail).party}</h3>
              <p>{JSON.parse(loginDetail).display_name}</p>
            </div>
          </div>
        }
        open={isModalVisible}
        okButtonProps={{
          type: "primary",
          size: "large",
          style: {
            fontSize: "14px",
            fontWeight: 500,
            color: "#4E6ACB",
            border: "1px solid #4E6ACB",
          },
          ghost: true,
          id: "SignOut"
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        okText={"Sign Out"}
        onCancel={handleCancel}
      >
        <h3>Manage Account</h3>

        <div>{profileData?.email}</div>
        <div>{profileData?.phone}</div>
      </Modal>
    </div>
  );
};
export default HeaderContainer;
