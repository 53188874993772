interface Iprops {
    flag:string,
    text:any,
    subText:any
}
const Heading = ({text,subText}:Iprops) => {
    return(
       <div>
             <div className="HeadingTxt">{text}
             {subText ? <span> {subText}</span>:null}
             </div>
        </div>
    )
}
export default Heading;