
//dev internal
// const baseurl = "https://c57vfsp3-8000.inc1.devtunnels.ms/"
//const baseurl ="https://finflo-beta-lpbb2.ondigitalocean.app/"

//dev
//const baseurl="https://finflo-beta-v1.onrender.com/"

//test automation team
// const baseurl = "https://finflo-test-v2-uikte.ondigitalocean.app/"

// Pre Production
// const baseurl = "https://krediq-pre-prod-backend.onrender.com/"

//prod
const baseurl = "https://krediq-wtzdm.ondigitalocean.app/"

export default baseurl;
