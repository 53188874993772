import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import httpClient from "../../../utils/config/core/httpClient";
import baseurl from "../../../utils/config/url/base";
import { allCurrency } from "../../../redux/action";
import { actionStatus } from "../../api/actionStatus";
import CurrencyField from "../function/currency";
import Breadcrumbs from "../heading/breadcrumb";
import { Action } from "../../../utils/enum/choices";
import moment from 'moment'
const FinanceRequestDetail = () => {
    const { state } = useLocation();
    const Navigator = useNavigate();
    const [buttondisable, setButtonDisable] = useState(false);
    const [primaryButtonDisable, setPrimaryButtonDisable] = useState(false)
    const [secondaryButtonDisable, setSecondaryButtonDisable] = useState(false)

    const currency_data = useSelector(allCurrency);
    const [invoice_detail] = useState(state as any);
    const [PartyAccountDetails, setPartyAccountDetails] = useState({
        currency: "",
        account_number: ""
    })

    const onClickExit = () => {
        Navigator(`/${invoice_detail.fromMenu}`)
    };

    useEffect(() => {
        httpClient
            .getInstance()
            .get(`${baseurl}api-auth/party/party-accounts/${invoice_detail?.data?.record_datas?.values[0]?.fields?.repayment_account}/`)
            .then((resp: any) => {
                setPartyAccountDetails(resp?.data?.data)
            })
    }, []);
    const returnRoute = (data: any) => {
        if (data === true) {
            Navigator(`/${invoice_detail.fromMenu}`)
        }
    }
    const onClickAction = (buttonKey: any) => {
        if (buttonKey === Action.RETURN) {
            setSecondaryButtonDisable(true)
        } else {
            setPrimaryButtonDisable(true)
        }
        setButtonDisable(true)
        console.log("entered onclick action")

        actionStatus(invoice_detail, buttonKey, returnRoute, buttonStatus)
    }
    const buttonStatus = (data: any) => {
        setButtonDisable(data)
        setPrimaryButtonDisable(data)
        setSecondaryButtonDisable(data)


    }
    const buttonStatusData = {
        default: buttondisable,
        primary: primaryButtonDisable,
        secondary: secondaryButtonDisable

    }
    return (
        <React.Fragment>
            <div className="fixedContainer">
                <Breadcrumbs
                    Data={invoice_detail}
                    onClickExit={onClickExit}
                    commentsValue={""}
                    flag="repayment"
                    onClickAction={onClickAction}
                    buttonStatusData={buttonStatusData}

                />
            </div>
            <div className="RepaymentDetailContainer mainContentContainer">

                <div className="Card_Main_Container">
                    <Card className="CardContainer">
                        <div className="programHeading">{invoice_detail?.data?.type === "Invoicerepayment" ? "Invoice Repayment" : "Finance Repayment"} </div>
                        <div className="SummaryContainer">
                            <Row className="counterPartyCollapseDetails" style={{ marginTop: "3%" }} >
                                <Col span={2}></Col>
                                <Col span={5}><p>Invoice No</p></Col>
                                <Col span={5} className="counterPartyValue">
                                    <p> {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_no}</p>
                                </Col>
                            </Row>
                            <Row className="counterPartyCollapseDetails" >
                                <Col span={2}></Col>
                                <Col span={5}> <p>  Finance Date</p> </Col>
                                <Col span={5} className="counterPartyValue">
                                    <p>  {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_date !== null ? moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_date).format("DD-MM-YYYY") : "-"}</p>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5}> <p> Due Date</p></Col>
                                <Col span={5} className="counterPartyValue">
                                    <p>  {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.due_date !== null ? moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.due_date).format("DD-MM-YYYY") : "-"}</p>
                                </Col>
                                <Col span={1}></Col>
                            </Row>
                            <Row className="counterPartyCollapseDetails" >
                                <Col span={2}></Col>
                                <Col span={5}> <p> Invoice Amount</p></Col>
                                <Col span={5} className="counterPartyValue">
                                    <p>  <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_currency} amount={invoice_detail?.data?.type === "Invoicerepayment" ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.amount : invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_amount} /></p>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5}> <p> Finance Amount</p></Col>
                                <Col span={5} className="counterPartyValue">
                                    <p>  <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_amount} /></p>
                                </Col>
                                <Col span={1}></Col>
                            </Row>

                            <Row className="counterPartyCollapseDetails" >
                                <Col span={2}></Col>
                                <Col span={5}> <p> {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate !== null ? "Interest Rate" : ""}</p></Col>
                                <Col span={5} className="counterPartyValue">
                                    <p>  {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate !== null ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate : ""}</p>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5}> <p> {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount !== null ? "Interest Amount" : ""}</p></Col>
                                <Col span={5} className="counterPartyValue">
                                    <p>  {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount !== null ?
                                        <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount} />
                                        : ""}</p></Col>
                                <Col span={1}></Col>
                            </Row>
                            <Row className="counterPartyCollapseDetails" >
                                <Col span={2}></Col>
                                <Col span={5} style={{ alignItems: "center", display: "flex" }}> <p> Repayment Amount</p></Col>
                                <Col span={5} className="counterPartyValue repaymentText">
                                    <p>  <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.repayment_amount} /></p>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5} style={{ alignItems: "center", display: "flex" }}> <p> Repayment Account</p></Col>
                                <Col span={5} className="counterPartyValue">
                                    <p>  <div>
                                        {currency_data?.payload?.currencyData?.allCurrency &&
                                            currency_data?.payload?.currencyData?.allCurrency.map((item: any) => {
                                                if (PartyAccountDetails.currency === item.id) {
                                                    return (
                                                        <span>{item.description} </span>
                                                    );
                                                }
                                            })
                                        } <span>{PartyAccountDetails.account_number}</span>
                                    </div></p>

                                </Col>
                                <Col span={1}></Col>
                            </Row>
                        </div>
                    </Card>
                </div>

            </div>
        </React.Fragment>

    );
};
export default FinanceRequestDetail;
