import React, { useEffect, useState } from "react";
import { Row, Col, Collapse, Button, Spin, Input, message } from "antd";
import "../manageScf.scss";
import { useNavigate } from "react-router-dom";
import images from "../../../assets/images";
import httpClient from "../../../utils/config/core/httpClient";
import baseurl from "../../../utils/config/url/base";
import { useDispatch, useSelector } from "react-redux";
import {
  program_Basicdetails,
  program_counterpartydetails,
  allInterestRateType,
} from "../../../redux/action";
import imageBaseurl from "../../../utils/config/url/image";
import { Currency } from "../../api/base";
import { Action, InterestDeduction, InterestPaidBy, ProgramType, ResponseStatus, TransactionType } from "../../../utils/enum/choices";
import moment from 'moment'
interface IProps {
  previousPage: any;
  nextpage: any;
  programData: any;
  CounterPartyData: any;
}

const Summary = (props: React.PropsWithChildren<IProps>) => {
  const dispatch = useDispatch();
  const [currencyData, setCurrencyData] = useState([]);
  const interest_Rate_Type_data = useSelector(allInterestRateType);
  const basicDetailsData = useSelector(program_Basicdetails);
  const basicDetailsDatas = basicDetailsData.payload.programBasicdetailsData
  const [counterPartyDetails, setCounterpartyDetails] = useState([] as any);
  const navigate = useNavigate();
  const [rateType, setRateType] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [SubmitDisableButton, setSubmitDisableButton] = useState(false);
  const [SaveDisableButton, setSaveDisableButton] = useState(false);
  const [commentsValue, setCommentsvalue] = useState(null as any)
  const { TextArea } = Input;
  const { CollapseCloseIcon, CollapseOpenIcon } = images;
  const { Panel } = Collapse;
  const [isLoading, setIsLoading] = useState(true);
  const [CurrencyValue, setCurrencyValue] = useState([])
  const [programFile, setProgramFile] = useState([]);
  const [repaymentDetail, setRepaymentDetail] = useState({
    currency: "",
    account_number: ""
  })
  
  const getCurrency = async () => {
    const data = await Currency()
    setCurrencyData(data)
  }
  const onClickAction = (buttonKey: string) => {
    setSubmitDisableButton(true)
    const loginlocalItems = localStorage.getItem("login_detail") as any;

    const formdata = new FormData();
    formdata.append('comments', commentsValue)
    setDisableButton(true)
    const id = basicDetailsDatas ? basicDetailsDatas.id : "";
    if (id) {
      const body = {
        action:buttonKey,
        type:"transaction.programs",
        t_id:id
    }
        httpClient
            .getInstance()
            .post(`${baseurl}api/resource/action/status/`,body)
            .then((resp: any) => {
                console.log("resp action",resp)
                console.log("resp action",resp?.data)
                
                if (resp.data.status === ResponseStatus.SUCCESS) {
                    message.success(resp?.data?.transition_status)
                    localStorage.removeItem("program_t_id")
                    localStorage.getItem("user") === "Bank" ?
                      navigate("/inbox")
                      :
                      navigate("/ManageScf")
                    dispatch(program_Basicdetails(null))
                    dispatch(program_counterpartydetails(null))
                    // transition(info, buttonKey === Action.DRAFT ? "SUBMIT" : buttonKey, resp.data.data.from_party, resp.data.data.to_party, info.fromMenu, returnRoute, buttonStatus)
                } else {
                    message.error(resp.data.data)
                    setDisableButton(false)
                    setSubmitDisableButton(false)
                }
            })

      // httpClient
      //   .getInstance()
      //   .get(`${baseurl}api/resource/action/status/?action=${buttonKey}&type=transaction.programs&t_id=${id}`)
      //   .then((resp: any) => {
      //     if (resp.data.status === ResponseStatus.SUCCESS) {
      //       const body = {
      //         type: TransactionType.TPROGRAM,
      //         action: buttonKey,
      //         t_id: id,
      //         from_party: resp.data.data.from_party,
      //         to_party: resp.data.data.to_party,
      //         party: JSON.parse(loginlocalItems).party_id
      //       }
      //       httpClient
      //         .getInstance()
      //         .post(`${baseurl}finflo/transition/`, body)
      //         .then((resp: any) => {
      //           localStorage.removeItem("program_t_id")
      //           localStorage.getItem("user") === "Bank" ?
      //             navigate("/inbox")
      //             :
      //             navigate("/ManageScf")
      //           message.success(resp.data.status)
      //           dispatch(program_Basicdetails(null))
      //           dispatch(program_counterpartydetails(null))

      //         }).catch(() => {
      //           setDisableButton(false)
      //           setSubmitDisableButton(false)


      //         })
      //     } else {
      //       message.error(resp.data.data)
      //       setDisableButton(false)
      //       setSubmitDisableButton(false)

      //     }
      //   }).catch(() => {
      //     setDisableButton(false)
      //     setSubmitDisableButton(false)

      //   })
    }
  };
  useEffect(() => {
    getCurrency();
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        setCurrencyValue(resp.data.data)

      });
    setTimeout(() => {
      const id = basicDetailsDatas ? basicDetailsDatas.id : "";
      if (id) {

        httpClient
          .getInstance()
          .get(`${baseurl}api/pairing/?pg_id=${id}`)
          .then((resp: any) => {
            setTimeout(() => {
              setIsLoading(false)
              setCounterpartyDetails(resp.data.data);
              // if (resp.data.data.length < 0) {
              //   httpClient
              //     .getInstance()
              //     .get(`${baseurl}api/resource/file/?pairing=${resp.data.data[0].id}`)
              //     .then((resp: any) => {
              //       setCounterPartyFile(resp.data.data)
              //     });
              // }
            }, 1000)

          });

        httpClient
          .getInstance()
          .get(`${baseurl}api/program/${id}/`)
          .then((resp: any) => {
            if (resp.data.data.attachments !== null) {
              setProgramFile(resp.data.data.attachments?.file)
            }
            setRepaymentDetail(resp.data.data.repayment_account)
          });
        interest_Rate_Type_data &&
          interest_Rate_Type_data.payload.interestRateType.allInterestRateType.map((rateType: any) => {
            if (basicDetailsDatas.record_datas) {
              if (
                rateType.id ===
                basicDetailsDatas.record_datas.model[0].interest_rate_type_id
              ) {
                setRateType(rateType.description);
              }
            }
          });
      }
    }, 5000)

  }, []);

  const onClickprevious = () => {
    setSaveDisableButton(true)
    navigate("/ManageScf");
  };


  return isLoading ? <Spin /> : (
    <div className="SummaryContainer">
      <Button className="actionButtonSaveContainer" htmlType="submit" disabled={counterPartyDetails.length === 0 ? true : disableButton ? disableButton : false} loading={SaveDisableButton} onClick={onClickprevious} id="summary_save" style={{ position: "fixed", zIndex: 99999 }}>
        Save</Button>
      {localStorage.getItem("user") === "Bank" ?
        <Button className="nextButtonContainer"
          onClick={() => onClickAction(Action.APPROVE)}
          disabled={
            basicDetailsDatas
              ? basicDetailsDatas.program_type === ProgramType.APF
                ? counterPartyDetails.length === 0
                  ? true
                  : false
                : false
              : false
          }
          loading={SubmitDisableButton} id="summary_submit">Submit</Button>
        :
        <Button className="nextButtonContainer" onClick={() => onClickAction(Action.SUBMIT)} disabled={
          basicDetailsDatas
            ? basicDetailsDatas.program_type === ProgramType.APF
              ? counterPartyDetails.length === 0
                ? true
                : false
              : false
            : false
        }
          loading={SubmitDisableButton} id="summary_submit">Submit</Button>
      }
      <h4>Basic Details</h4>
      <Row gutter={24}>
        <Col span={12}>
          <Row>
            <Col span={12}
              className="SummaryLabel"
            >
              Limit Amount
            </Col>
            <Col span={12}
              id="summaryLimitAmount">
              {
                currencyData.map((item: any) => {
                  if (item.id === basicDetailsDatas.limit_currency) {
                    return `${item.description} ${new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(basicDetailsDatas.max_limit_amount)}`
                  }
                })
              }
            </Col>
          </Row>
          <Row>
            <Col span={12} className="SummaryLabel">
              Expiry Date
            </Col>
            <Col span={12} className="SummaryDesc" id="summaryExpiryDate">
              {basicDetailsDatas ? moment(basicDetailsDatas.expiry_date).format("DD-MM-YYYY") : "-"}
            </Col>
          </Row>
          <Row>
            <Col span={12} className="SummaryLabel">
              Max Invoice Amount
            </Col>
            <Col span={12} className="SummaryDesc" id="summaryMaxAmount">
              {
                currencyData.map((item: any) => {
                  if (item.id === basicDetailsDatas.max_invoice_currency) {
                    return `${item.description} ${new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(basicDetailsDatas.max_invoice_amount)}`
                  }
                })
              }
            </Col>
          </Row>
          <Row>
            <Col span={12} className="SummaryLabel">
              Max Invoice PCT
            </Col>
            <Col span={12} className="SummaryDesc" id="summaryMaxPCT">
              {basicDetailsDatas
                ? basicDetailsDatas.max_invoice_percent
                : "-"}
            </Col>
          </Row>
          <Row>
            <Col span={12} className="SummaryLabel">
              Max Tenor
            </Col>
            <Col span={12} className="SummaryDesc" id="summaryMaxtenor">
              {basicDetailsDatas
                ? basicDetailsDatas.max_tenor
                : "-"}
            </Col>
          </Row>
          <Row>
            <Col span={12} className="SummaryLabel">
              Grace Period
            </Col>
            <Col span={12} className="SummaryDesc" id="summaryGracePeriod">
              {basicDetailsDatas ? basicDetailsDatas.grace_period : "-"}
            </Col>
          </Row>
          <Row>
            <Col span={12} className="SummaryLabel">
              Interest Type
            </Col>
            <Col span={12} className="SummaryDesc" id="summaryInterestType">
              {basicDetailsDatas ? basicDetailsDatas.interest_type === 1 ? "Fixed" : basicDetailsDatas.interest_type === 2 ? "Floating"
                : basicDetailsDatas.interest_type === "FIXED" ? "Fixed"
                  : basicDetailsDatas.interest_type === "FLOATING" ? "Floating"
                    : basicDetailsDatas.interest_type === "FLOATING" ? "Floating"

                      : "" : ""}
            </Col>
          </Row>
          {basicDetailsDatas && basicDetailsDatas.interest_type === "FIXED" ? (
            ""
          ) : (
            <Row>
              <Col span={12} className="SummaryLabel">
                Interest rate type
              </Col>
              <Col span={12} className="SummaryDesc" id="summaryInterestRateType">
                {basicDetailsDatas ?
                  basicDetailsDatas.interest_type === 1 ? "-"
                    : basicDetailsDatas.interest_type === "fixed" ? "-"
                      : basicDetailsDatas.interest_rate_type === 1 ? "Euribor"
                        : basicDetailsDatas.interest_rate_type === 2 ? "Libor"
                          : basicDetailsDatas.interest_rate_type === 3 ? "Sofr"
                            : basicDetailsDatas.interest_rate_type === 4 ? ""
                              : basicDetailsDatas.interest_rate_type === "EURIBOR" ? "Euribor"
                                : basicDetailsDatas.interest_rate_type === "LIBOR" ? "Libor"
                                  : basicDetailsDatas.interest_rate_type === "SOFR" ? "Sofr"
                                    : basicDetailsDatas.interest_rate_type === "NULL" ? "" :
                                      rateType : rateType
                }
              </Col>
            </Row>
          )}
          <Row>
            <Col span={12} className="SummaryLabel">
              Fixed rate / Margin
            </Col>
            <Col span={12} className="SummaryDesc" id="summaryMargin">
              {basicDetailsDatas ? basicDetailsDatas.margin : "-"}
            </Col>
          </Row>
          {props?.programData === ProgramType.APF || props?.programData?.program === ProgramType.APF || props?.programData?.program_type === ProgramType.APF ?
            <>
              <Row>
                <Col span={12} className="SummaryLabel">
                  Interest Paid By
                </Col>
                <Col span={12} className="SummaryDesc" id="summaryInterestPaidBy">
                  {basicDetailsDatas ? basicDetailsDatas.interest_paid_by === InterestPaidBy.OWNPARTY ? "Ownparty" : basicDetailsDatas?.interest_paid_by === InterestPaidBy.COUNTERPARTY ? "Counterparty" : basicDetailsDatas?.interest_paid_by : "-"}
                </Col>
              </Row>
              <Row>
                <Col span={12} className="SummaryLabel">
                  Overdue Interest Rate
                </Col>
                <Col span={12} className="SummaryDesc" id="summaryOverdueInterestRate">
                  {basicDetailsDatas ? basicDetailsDatas.overdue_interest_rate? basicDetailsDatas.overdue_interest_rate : "-" : "-"}

                </Col>
              </Row>
              <Row>
                <Col span={12} className="SummaryLabel">
                  Interest Deduction Stage
                </Col>
                <Col span={12} className="SummaryDesc" id="summaryInterestDeduction">
                  {basicDetailsDatas ?
                    basicDetailsDatas.interest_deduction === InterestDeduction.FINANCING ? "Financing" : basicDetailsDatas.interest_deduction === InterestDeduction.REPAYMENT ? "Repayment" : basicDetailsDatas.interest_deduction
                    : "-"}

                </Col>
              </Row>
              <Row>
                <Col span={12} className="SummaryLabel">
                  Repayment Account
                </Col>
                <Col span={12} className="SummaryDesc" id="summaryRepaymentAccount">
                  {
                    currencyData.map((item: any) => {
                      if (item.id === repaymentDetail.currency) {
                        return `${item.description} ${repaymentDetail.account_number}`
                      }
                    })
                  }
                </Col>
              </Row>
              <Row>
                <Col span={12} className="SummaryLabel">
                  Allow Invoice upload by buyer
                </Col>
                <Col span={12} className="SummaryDesc" id="summaryInvoiceUploadBy">
                  {basicDetailsDatas?.buyer_details?.[0]?.self_invoice_upload === false ? "No" : "Yes"}
                </Col>
              </Row>
              <Row>
                <Col span={12} className="SummaryLabel">
                  Auto Debit for Invoices not financed
                </Col>
                <Col span={12} className="SummaryDesc" id="summaryAutoDebitInvoice">
                  {basicDetailsDatas ? basicDetailsDatas.auto_debit_invoice === false ? "No" : "Yes" : "-"}

                </Col>
              </Row>
              <Row>
                <Col span={12} className="SummaryLabel">
                  Allow Rebate for Buyer
                </Col>
                <Col span={12} className="SummaryDesc" id="summaryRebateBuyer">
                  {basicDetailsDatas ? basicDetailsDatas.buyer_rebate === false ? "No" : "Yes" : "-"}

                </Col>
              </Row>
              {
                basicDetailsDatas.buyer_rebate_percent !== null ?
                  <Row>
                    <Col span={12} className="SummaryLabel">
                      Buyer Rebate PCT
                    </Col>
                    <Col span={12} className="SummaryDesc" id="summaryRebateBuyerPct">
                      {basicDetailsDatas ? basicDetailsDatas.buyer_rebate_percent : "-"}

                    </Col>
                  </Row> : ""}
              <Row>
                <Col span={12} className="SummaryLabel">
                  Backed by Insurance
                </Col>
                <Col span={12} className="SummaryDesc" id="summaryInsuranceBacked">
                  {basicDetailsDatas ? basicDetailsDatas.insurance_backed === false ? "No" : "Yes" : "-"}

                </Col>
              </Row>
              {
                basicDetailsDatas.fee !== null ?
                  <Row>
                    <Col span={12} className="SummaryLabel">
                      Fee
                    </Col>
                    <Col span={12} className="SummaryDesc" id="summaryFee">
                      {basicDetailsDatas ? basicDetailsDatas.fee : "-"}

                    </Col>
                  </Row> : ""
              }

            </>



            :
            props?.programData === ProgramType.RF || props?.programData?.program === ProgramType.RF || props?.programData?.program_type === ProgramType.RF ?
              <>
                <Row>
                  <Col span={12} className="SummaryLabel">
                    Repayment Account
                  </Col>
                  <Col span={12} className="SummaryDesc" id="summaryRepaymentAccount">
                    {
                      currencyData.map((item: any) => {
                        if (item.id === repaymentDetail.currency) {
                          return `${item.description} ${repaymentDetail.account_number}`
                        }
                      })
                    }
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className="SummaryLabel">
                    Overdue Interest Rate
                  </Col>
                  <Col span={12} className="SummaryDesc" id="summaryOverdueInterestRate">
                    {basicDetailsDatas ? basicDetailsDatas.overdue_interest_rate ? basicDetailsDatas.overdue_interest_rate : "-" : "-"}

                  </Col>
                </Row>
                <Row>
                  <Col span={12} className="SummaryLabel">
                    Interest Deduction Stage
                  </Col>
                  <Col span={12} className="SummaryDesc" id="summaryInterestDeduction">
                    {basicDetailsDatas ?
                      basicDetailsDatas.interest_deduction === InterestDeduction.FINANCING ? "Financing" : basicDetailsDatas.interest_deduction === InterestDeduction.REPAYMENT ? "Repayment" : basicDetailsDatas.interest_deduction
                      : "-"}

                  </Col>
                </Row>

                <Row>
                  <Col span={12} className="SummaryLabel">
                    Allow Finance Invoices
                  </Col>
                  <Col span={12} className="SummaryDesc" id="summaryAutoFinanceInvoice">
                    {basicDetailsDatas ? basicDetailsDatas.auto_finance : "-"}

                  </Col>
                </Row>
                <Row>
                  <Col span={12} className="SummaryLabel">
                    Backed by Insurance
                  </Col>
                  <Col span={12} className="SummaryDesc" id="summaryInsuranceBacked">
                    {basicDetailsDatas ? basicDetailsDatas.insurance_backed === false ? "No" : "Yes" : "-"}

                  </Col>
                </Row>
                {
                  basicDetailsDatas.fee !== null ?
                    <Row>
                      <Col span={12} className="SummaryLabel">
                        Fee
                      </Col>
                      <Col span={12} className="SummaryDesc" id="summaryFee">
                        {basicDetailsDatas ? basicDetailsDatas.fee : "-"}

                      </Col>
                    </Row>
                    : ""}

                <Row>
                  <Col span={12} className="SummaryLabel">
                    Refund Interest during Early Repayment
                  </Col>
                  <Col span={12} className="SummaryDesc" id="summaryInvoiceUploadBy">
                    -
                  </Col>
                </Row>
              </>
              : ""
          }
        </Col>
        <Col span={12}>
          <div className="UploadCardContainer" style={{ display: "flex" }} id="summaryAttachments">
            {(programFile.map((file: any, index: any) => {
              const splitedValue = file.file_path.split("/")
              const lastValue = splitedValue.pop()
              return (
                <div key={index} style={{ border: '1px dashed #006666', borderRadius: '5px', alignItems: 'center', width: '100px', height: '130px', position: 'relative', padding: 10, paddingRight: 5, marginRight: "10px" }}>
                  <img src={`${imageBaseurl}${file.file_path}`} style={{ objectFit: 'cover', width: '45px', height: '60px', position: 'absolute', top: 20 }} alt="file" />
                  <p style={{ position: 'absolute', bottom: 0, fontSize: 12, color: '#006666' }}>
                    <a href={`${imageBaseurl}${file.file_path}`} target="_blank" id="summaryImages"> {lastValue}</a>
                  </p>
                </div>
              )
            })
            )
            }
          </div>
        </Col>
      </Row>
      <div className="containerCollapse">
        <h4>Counterparty List</h4>
        <Row className="counterPartyHeaderDetails">
          <Col span={8} style={{ color: "#FFFFFF", fontSize: "14px", fontFamily: "Poppins" }} >Counterparty Name</Col>
          <Col span={8} style={{ color: "#FFFFFF", fontSize: "14px", fontFamily: "Poppins" }}>City</Col>
          <Col span={8} style={{ color: "#FFFFFF", fontSize: "14px", fontFamily: "Poppins" }}>Limit</Col>
        </Row>
        {counterPartyDetails.length === 0 ? (
          <div style={{ textAlign: "center", fontSize: "18px" }}>no data</div>
        ) : (
          counterPartyDetails &&
          counterPartyDetails.map((Data: any, index: number) => {
            return (

              <Collapse accordion className="counterPartyDetails" key={index}
                expandIcon={({ isActive }) => isActive ? <img src={CollapseCloseIcon} alt="collapseIcon" id={`summaryCollapseOpen${index}`} /> : <img src={CollapseOpenIcon} alt="collapseIcon" id={`summaryCollapseClose${index}`} />}
              >
                <Panel
                  header={
                    <Row style={{ borderRadius: 25 }}>
                      <Col span={8} id={`summarycounterpartyId${index}`} style={{ color: "#4E6ACB", fontSize: "14px", fontFamily: "Poppins" }}>{Data.counter_party_name}</Col>
                      <Col span={8} id={`summaryCity${index}`} style={{ color: "#4E6ACB", fontSize: "14px", fontFamily: "Poppins" }}>{Data.city}</Col>
                      <Col span={8} id={`summaryMaxLimitAmount${index}`} style={{ color: "#4E6ACB", fontSize: "14px", fontFamily: "Poppins" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Data.max_limit_amount)}</Col>
                    </Row>
                  }
                  key="1"
                >
                  <Row
                    className="counterPartyCollapseDetails"
                  >
                    <Col span={1}></Col>
                    <Col span={5}><p>Limit</p></Col>
                    <Col span={1}></Col>
                    <Col span={5} className="counterPartyValue" id={`summaryCP_LimitAmount${index}`}>
                      <p> {CurrencyValue.map((item: any) => {
                        return (
                          item.id === Data.limit_currency &&
                          item.description


                        )
                      })}
                        {" "}
                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Data.max_limit_amount)}</p>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={5}><p>Expiry Date</p></Col>
                    <Col span={1}></Col>
                    <Col span={5} className="counterPartyValue" id={`summaryCP_ExpiryDate${index}`}>
                      <p> {Data?.expiry_date}</p>
                    </Col>
                    <Col span={1}></Col>
                  </Row>
                  <Row
                    className="counterPartyCollapseDetails"
                  >
                    <Col span={1}></Col>
                    <Col span={5}><p>Max Invoice Amount</p></Col>
                    <Col span={1}></Col>
                    <Col span={5} className="counterPartyValue" id={`summaryCP_MaxInvoiceAmount${index}`}>
                      <p> {CurrencyValue.map((item: any) => {
                        return (
                          item.id === Data.max_invoice_currency &&
                          item.description
                        )
                      })}
                        {" "}
                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Data.max_invoice_amount)}</p>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={5}><p>Max Invoice PCT</p></Col>
                    <Col span={1}></Col>
                    <Col span={5} className="counterPartyValue" id={`summaryCP_MaxInvoicePCT${index}`}>
                      <p>{Data ? Data.max_invoice_percent : "-"}</p>
                    </Col>
                    <Col span={1}></Col>
                  </Row>
                  <Row
                    className="counterPartyCollapseDetails"
                  >
                    <Col span={1}></Col>
                    <Col span={5}><p>Grace Period</p></Col>
                    <Col span={1}></Col>
                    <Col span={5} className="counterPartyValue" id={`summaryCP_GracePeriod${index}`}>
                      <p>{Data.grace_period}</p>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={5}><p>Max Tenor</p></Col>
                    <Col span={1}></Col>
                    <Col span={5} className="counterPartyValue" id={`summaryCP_MaxTenor${index}`}>

                      <p> {Data ? Data.max_tenor : "-"}</p>
                    </Col>

                    <Col span={1}></Col>
                  </Row>
                  <Row
                    className="counterPartyCollapseDetails"
                  >
                    <Col span={1}></Col>
                    <Col span={5}><p>Interest Rate Type</p></Col>
                    <Col span={1}></Col>
                    <Col span={5} className="counterPartyValue" id={`summaryCP_InterestRateType${index}`}>

                      <p> {Data.interest_type === 1
                        ? "-"
                        : Data.interest_rate_type === 1 ? "Euribor"
                          : Data.interest_rate_type === 2 ? "Libor"
                            : Data.interest_rate_type === 3 ? "Sofr"

                              : "-"
                      }</p>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={5}><p>Interest Type</p></Col>
                    <Col span={1}></Col>
                    <Col span={5} className="counterPartyValue" id={`summaryCP_interestType${index}`}>

                      <p> {Data.interest_type === 1 ? "Fixed" : Data.interest_type === 2 ? "Floating" : "-"
                      }</p>
                    </Col>

                    <Col span={1}></Col>
                  </Row>
                  {props?.programData === ProgramType.APF || props?.programData?.program === ProgramType.APF || props?.programData?.program_type === ProgramType.APF
                    ?
                    <>
                      <Row className="counterPartyCollapseDetails">
                        <Col span={1}></Col>
                        <Col span={5}><p>Fixed Rate/ Margin</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_Margin${index}`}>
                          <p> {Data.margin}</p>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}><p>Interest Paid By</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_InterestPaidBy${index}`}>
                          <p>{Data.interest_paid_by === InterestPaidBy.OWNPARTY ? "Ownparty" : Data?.interest_paid_by === InterestPaidBy.COUNTERPARTY ? "Counterparty" : Data?.interest_paid_by ? Data?.interest_paid_by : "-"}</p>
                        </Col>
                      </Row>
                      <Row className="counterPartyCollapseDetails">
                        <Col span={1}></Col>
                        <Col span={5}><p>Overdue Interest Rate</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_OverdueInterestRate${index}`}>
                          <p> {Data.overdue_interest_rate ? Data.overdue_interest_rate :"-"}</p>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}><p>Interest Deduction Stage</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_InterestDeduction${index}`}>
                          <p>{Data.interest_paid_by === InterestPaidBy.OWNPARTY ? "Repayment" : Data.interest_deduction === InterestDeduction.FINANCING ? "Financing" : Data.interest_deduction === InterestDeduction.REPAYMENT ? "Repayment" : Data.interest_deduction}</p>
                        </Col>
                      </Row>
                      <Row className="counterPartyCollapseDetails">
                        <Col span={1}></Col>
                        <Col span={5}><p>Auto Debit for Invoices not Financed</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_AutoDebitInvoice${index}`}>
                          <p> {Data.auto_debit_invoice === false ? "No" : "Yes"}</p>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}><p>Allow Rebate for Buyer</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_BuyerRebate${index}`}>
                          <p>{Data.buyer_rebate === false ? "No" : "Yes"}</p>
                        </Col>
                      </Row>
                      <Row className="counterPartyCollapseDetails">
                        <Col span={1}></Col>
                        <Col span={5}><p>Backed by Insurance</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_InsuranceBacked${index}`}>
                          <p> {Data.insurance_backed === false ? "No" : "Yes"}</p>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}><p> Buyer Rebate PCT</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_BuyerRebatePct${index}`}>
                          <p> {Data.buyer_rebate_percent}</p>
                        </Col>
                      </Row>
                      {Data.fee !== null ?
                        <Row className="counterPartyCollapseDetails">
                          <Col span={1}></Col>
                          <Col span={5}><p>Fee</p></Col>
                          <Col span={1}></Col>
                          <Col span={5} className="counterPartyValue" id={`summaryCP_Fee${index}`}>
                            {Data.fee}
                          </Col>
                          <Col span={1}></Col>
                          <Col span={5}></Col>
                          <Col span={1}></Col>
                          <Col span={5} className="counterPartyValue" id={`summaryCP${index}`}>

                          </Col>
                        </Row> : ""}
                    </> :
                    <>
                      <Row className="counterPartyCollapseDetails">
                        <Col span={1}></Col>
                        <Col span={5}><p>Fixed Rate/ Margin</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_Margin${index}`}>
                          {Data.margin}
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}><p>Interest Deduction Stage</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_InterestDeduction${index}`}>
                          <p> {Data.interest_deduction === InterestDeduction.FINANCING ? "Financing" : Data.interest_deduction === InterestDeduction.REPAYMENT ? "Repayment" : Data.interest_deduction}</p>
                        </Col>
                      </Row>
                      <Row className="counterPartyCollapseDetails">
                        <Col span={1}></Col>
                        <Col span={5}><p>Overdue Interest Rate</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_OverdueInterestRate${index}`}>
                          <p>{Data.overdue_interest_rate ? Data.overdue_interest_rate : "-"}</p>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}><p>Backed by Insurance</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_InsuranceBacked${index}`}>
                          <p>{Data.insurance_backed === false ? "No" : "Yes"}</p>

                        </Col>
                      </Row>
                      <Row className="counterPartyCollapseDetails">
                        <Col span={1}></Col>
                        <Col span={5}><p>Fee</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_Fee${index}`}>
                          <p> {Data.fee}</p>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}><p>Auto Finance Invoices</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_AutoFinanceInvoice${index}`}>
                          <p> {Data.auto_finance ? Data.auto_finance === false ? "No" : "Yes" : "No"}</p>

                        </Col>
                      </Row>
                      <Row className="counterPartyCollapseDetails">
                        <Col span={1}></Col>
                        <Col span={5}><p>Refund Interest during Early Repayment</p></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP_RefundEarlyPay${index}`}>
                          <p>{Data.refund_int_early_repay === false ? "No" : "Yes"}</p>

                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}></Col>
                        <Col span={1}></Col>
                        <Col span={5} className="counterPartyValue" id={`summaryCP${index}`}>

                        </Col>
                      </Row>

                    </>
                  }
                  {Data.attachments.file.length > 0 ?
                    <Row className="counterPartyCollapseDetails">
                      <Col span={1}></Col>
                      <Col span={5}><p>Attachments</p></Col>
                    </Row> : ""}
                  <Row gutter={24} className="counterPartyCollapseDetails">
                    <Col span={1}></Col>
                    {Data.attachments.file.map((value: any) => {
                      const splitedValue = value.file_path.split("/")
                      const lastValue = splitedValue.pop()
                      return (
                        <Col span={4} key={lastValue}>
                          <div className="fileAttachmentsText" >
                            <a href={`${imageBaseurl}${value.file_path}`} target="_blank" id={`summaryCP_images${index}`}>{lastValue}</a>
                          </div>
                        </Col>
                      )
                    })
                    }
                  </Row>
                </Panel>
              </Collapse>
            );
          })
        )}
      </div>
      <div className="SummaryContainer">
        <div className="SummaryLabel">Comment Section</div>
        <TextArea style={{ width: "50%", margin: "0px 10px", backgroundColor: "#FAFAFA" }} onChange={(e: any) => setCommentsvalue(e.target.value)} id="SummaryComment" />
      </div>
    </div>
  );
};
export default Summary;
