import { useState } from "react";
import { useNavigate } from "react-router-dom";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import { Button, message } from "antd";
import { actionStatus } from "../../../api/actionStatus";
import { transition } from "../../../api/finfloTransition";
import { ErrorMessage } from "../../../../utils/enum/messageChoices";
import { Action, InterimState, ResponseStatus, Type } from "../../../../utils/enum/choices";
const ButtonContainer = ({ Data, commentsValue }: any) => {
    const Navigator = useNavigate();
    const [primaryButtonDisable, setPrimaryButtonDisable] = useState(false)
    const [secondaryButtonDisable, setSecondaryButtonDisable] = useState(false)
    const [buttondisable, setButtonDisable] = useState(false);


    const returnRoute = (data: any) => {
        if (data === true) {
            Navigator(`/${Data.fromMenu}`)
        }
    }
    const buttonStatus = (data: any) => {
        setButtonDisable(data)
        setPrimaryButtonDisable(data)
        setSecondaryButtonDisable(data)


    }

    const onClickAction = (buttonKey: string) => {
        setButtonDisable(true)
        if (buttonKey === "REJECT" || buttonKey === "RETURN") {
            setSecondaryButtonDisable(true)

        } else {
            setPrimaryButtonDisable(true)
        }
        const formdata = new FormData();
        formdata.append('comments', commentsValue)
        const loginlocalItems = localStorage.getItem("login_detail") as any;
        if (buttonKey === Action.REQFINANCE) {
            actionStatus(Data, buttonKey, returnRoute, buttonStatus);
        } else if (Data.data.type === Type.INVOICEUPLOAD) {
            if ((buttonKey === Action.REJECT && commentsValue === "") || (buttonKey === Action.REJECT && commentsValue === null) || (buttonKey === Action.RETURN && commentsValue === "") || (buttonKey === Action.RETURN && commentsValue === null)) {
                message.error(ErrorMessage.PFC)
                setSecondaryButtonDisable(false)
                setButtonDisable(false)
            } else if (buttonKey !== Action.RETURN) {
                postMessageComments(buttonKey)


            } else {
                if(commentsValue !== null) {
                    const commentsBody = {
                        t_id: Data.data.work_model.t_id,
                        type: Data.data.model_type,
                        comments: commentsValue
                    }
                    httpClient
                        .getInstance()
                        .post(`${baseurl}api/message/comments/`, commentsBody)
                        .then((resp: any) => {
                            if (resp.data.status === ResponseStatus.SUCCESS) {
                                transition(Data, buttonKey, JSON.parse(loginlocalItems).party_id, JSON.parse(loginlocalItems).party_id, Data.fromMenu, returnRoute, buttonStatus)
                            } else {
                                message.error(resp.data.data)
                                setSecondaryButtonDisable(false)
                                setPrimaryButtonDisable(false)
                                setButtonDisable(false)
                            }
                        })
                }else{
                    transition(Data, buttonKey, JSON.parse(loginlocalItems).party_id, JSON.parse(loginlocalItems).party_id, Data.fromMenu, returnRoute, buttonStatus)
                }   
            }
        } else {
            if ((buttonKey === Action.REJECT && commentsValue === "") || (buttonKey === Action.REJECT && commentsValue === null) || (buttonKey === Action.RETURN && commentsValue === "") || (buttonKey === Action.RETURN && commentsValue === null)) {
                message.error(ErrorMessage.PFC)
                setSecondaryButtonDisable(false)
                setButtonDisable(false)
            } else if (buttonKey !== Action.RETURN) {
                postMessageComments(buttonKey)
            } else {
                transition(Data, buttonKey, JSON.parse(loginlocalItems).party_id, JSON.parse(loginlocalItems).party_id, Data.fromMenu, returnRoute, buttonStatus)
            }
        }
    }
    const postMessageComments = (buttonKey: string) => {
        if(commentsValue !== null) {
            const commentsBody = {
                t_id: Data.data.work_model.t_id,
                type: Data.data.model_type,
                comments: commentsValue
            }
            httpClient
                .getInstance()
                .post(`${baseurl}api/message/comments/`, commentsBody)
                .then((resp: any) => {
                    if (resp.data.status === ResponseStatus.SUCCESS) {
                        actionStatus(Data, buttonKey, returnRoute, buttonStatus);
                    }
                })
        }else{
            actionStatus(Data, buttonKey, returnRoute, buttonStatus);
        }
    }
    return (
        <div className="Button_Container">
            {Data.fromMenu === "sent" || Data.fromMenu === "sent_awap" || Data.fromMenu === "draft" || Data.data.interim_state === InterimState.COMPLETED || Data.data.interim_state === InterimState.PARTIALLYSETTLED? "" :
                Data.data.interim_state === InterimState.AWAITINGBUYERA ?
                    <Button className="ExitButtonContainer"
                        onClick={() => onClickAction(Action.REJECT)}
                        disabled={buttondisable}
                        loading={secondaryButtonDisable}
                        id={`${Data?.fromMenu}_${Data?.recordType}_invoice_reject`}>
                        Reject
                    </Button>
                    : Data.data.next_available_transitions && Data.data.next_available_transitions.values.length > 0 ?
                        <Button className="ExitButtonContainer"
                            onClick={() => onClickAction(Action.RETURN)}
                            disabled={buttondisable}
                            loading={secondaryButtonDisable}
                            id={`${Data?.fromMenu}_${Data?.recordType}_invoice_return`}>
                            Return
                        </Button>
                        : ""}

            {Data.fromMenu === "sent" || Data.fromMenu === "sent_awap" || Data.data.interim_state === InterimState.COMPLETED || Data.data.interim_state === InterimState.PARTIALLYSETTLED || Data.data.interim_state === InterimState.SETTLED || Data.data.interim_state === InterimState.FINANCED ? "" :
                Data.data.interim_state === InterimState.ABYBUYER ?
                    <Button
                        style={{width:"max-Content"}}
                        className="SaveButtonContainer"
                        disabled={buttondisable}
                        loading={primaryButtonDisable}
                        onClick={() => onClickAction(Action.REQFINANCE)}
                        id={`${Data?.fromMenu}_${Data?.recordType}_invoice_requestFinance`} >
                        Request for Finance
                    </Button>
                    : Data.data.interim_state === InterimState.AWAITINGBUYERA ?
                        <Button
                            className="SaveButtonContainer"
                            disabled={buttondisable}
                            loading={primaryButtonDisable}
                            onClick={() => onClickAction(Action.APPROVE)}
                            id={`${Data?.fromMenu}_${Data?.recordType}_invoice_approve`} >
                            Approve
                        </Button>
                        : Data.data.action === Action.RETURN || Data.data.action === Action.DRAFT ||
                            (Data.data.next_available_transitions && Data.data.next_available_transitions.values.length > 0) ?
                            <Button
                                className="SaveButtonContainer"
                                disabled={buttondisable}
                                loading={primaryButtonDisable}
                                onClick={() => onClickAction(Data.data.action)}
                                id={`${Data?.fromMenu}_${Data?.recordType}_invoice_release`}>
                                Release
                            </Button>
                            : ""}


        </div>
    )
}
export default ButtonContainer

