import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Card, Modal, Spin, message } from "antd";
import "./../new.scss";
import httpClient from "../../../utils/config/core/httpClient";
import baseurl from "../../../utils/config/url/base";
import { Currency } from "../../api/base";
import moment from "moment"
import { Action, ResponseStatus } from "../../../utils/enum/choices";
import Heading from "../../common/heading/heading";
import { TransactionType } from "../../../utils/enum/choices";
const Invoice = () => {
  const Navigator = useNavigate();
  const [invoiceData, setInvoiceData] = useState([] as any)
  const [currencyData, setCurrencyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Datas, setDatas] = useState({} as any);
  const [downloadPopup,setDownloadPopup] =useState(true as any)
  const [DisplayModal, setDisplayModal] = useState(false)

  const handleOk = () => {
    const body = {
      auto_finance: true
    }
    httpClient
      .getInstance()
      .put(`${baseurl}api/pairing/${Datas.id}/`, body)
      .then(() => {
        localStorage.setItem("autofinance", "true")
        Navigator("/Manual", { state: Datas });
      })
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    const body = {
      auto_finance: false
    }
    httpClient
      .getInstance()
      .put(`${baseurl}api/pairing/${Datas.id}/`, body)
      .then(() => {
        localStorage.setItem("autofinance", "false")

        Navigator("/Manual", { state: Datas });
      })
    setIsModalOpen(false);
  };
  const onClickManual = (data: any) => {

    httpClient
      .getInstance()
      .get(`${baseurl}api/transition/permission/?model_type=${TransactionType.TINVOICEUPLOADS}&action=${Action.SUBMIT}`)
      .then((resp: any) => {
        if (resp.data.status === ResponseStatus?.SUCCESS) {
          httpClient
            .getInstance()
            .get(`${baseurl}api/pairing/?pg_id=${data?.program_id ? data?.program_id : data?.id}&data_type=INVOICE`)
            .then((resp: any) => {
              if (resp.data.data.length > 0) {
                if (data?.auto_finance === null) {
                  if (data?.party_details?.APF_seller === true) {
                    if (data?.party_details?.self_invoice_upload === false) {
                      setIsModalOpen(true)
                      setDatas(data)
                    } else {
                      Navigator("/Manual", { state: data });

                    }
                  } else {
                    Navigator("/Manual", { state: data });
                  }
                } else {
                  if (data?.auto_finance === true) {
                    localStorage.setItem("autofinance", "true")
                  } else if (data?.auto_finance === false) {
                    localStorage.setItem("autofinance", "false")
                  }
                  Navigator("/Manual", { state: data });
                }

              } else {
                message.error("There is no record to raise Invoice", 3)
              }
            })
        } else {
          message.error(resp.data.data)
        }
      })



  };

  const onOkClick = (data: any) => {
    Navigator("/Upload", { state: data })
  }

  const getCurrency = async () => {
    const data = await Currency()
    setCurrencyData(data)
  }
  const handleDownload = () => {
    window.open(`${baseurl}api/resource/download/?model_type=${invoiceData?.[0]?.work_model?.model_type}&t_id=${invoiceData?.[0]?.work_model?.t_id}&party_id=${localStorage.getItem("party_id")}`)
  }
  const showModal = (data: any) => {
    httpClient
      .getInstance()
      .get(`${baseurl}api/transition/permission/?model_type=${TransactionType.TINVOICEUPLOADS}&action=${Action.SUBMIT}`)
      .then((resp: any) => {
        if (resp.data.status === ResponseStatus?.SUCCESS) {
                        
          Modal.info({
            title: '',
            content: (
              <div>
                <div onClick={handleDownload}>Click Here </div>
                <p>to download the template(.csv) for Bulk upload of Invoices.</p>
                <div style={{ display: "flex" }}>
                  <div className="importantMark">!</div>
                  <p style={{ paddingLeft: "10px" }}>The upload file should have the same column names as in the sample upload template.</p>

                </div>

              </div>
            ),
            onOk() { onOkClick(data) },
          maskClosable: true 
          });

        } else {
          message.error(resp.data.data)

        }
      })
  };

  useEffect(() => {
    getCurrency();
    httpClient
      .getInstance()
      .get(`${baseurl}api/resource/menu-data/${localStorage.getItem('user') === 'Bank' ? `?data=${localStorage.getItem('invoice_customerID_Bank')}` : ''}`)
      .then((resp: any) => {
        if (resp.data.status === ResponseStatus.SUCCESS) {
          setIsLoading(false)
          setInvoiceData(resp.data.data)
        } else {
          setIsLoading(false)
        }

      }).catch(() => {
        setIsLoading(false)
      })
  }, [])
  return isLoading ? <Spin /> : (
    <React.Fragment>
      <div className="fixedContainer">
        <Heading flag="invoice" text="Invoice" subText="" />
      </div>
      <div className="manageScfContainer mainContentContainer">
        <Modal
          maskClosable={true}
          open={isModalOpen}
          title="" onOk={handleOk} onCancel={handleCancel}
          cancelButtonProps={{ type: "primary", id: "autoFinanceNo", size: "small", style: { fontSize: "12px", fontWeight: 500, borderColor: "#4E6ACB" }, ghost: true }}
          okButtonProps={{ size: "small", id: "autoFinanceYes", style: { fontSize: "12px", fontWeight: 500, backgroundColor: "#4E6ACB" } }}
          okText={"Yes"}
          cancelText="No"
        >
          <p>Raise Finance request automatically for approved invoices?</p>

        </Modal>
        {invoiceData.length > 0 ?
          <Row gutter={24}>
            {invoiceData.map((data: any, index: number) => {
              return (
                <Col span={8} key={index}>
                  <Card
                    key={index}
                    style={{
                      position: 'relative', height:
                        "maxContent"
                      , marginBottom: "6%"
                    }}
                    id={`invoiceCard${index + 1}`}
                  >
                    <h1 id={`${data.buyer_details?.[0]?.name}${index + 1}`} style={{ whiteSpace: "unset" }}>{data.buyer_details?.[0]?.name} - {data.program_type}
                    </h1>
                    <p>
                      {
                        currencyData.map((item: any) => {
                          let description = ""
                          if (item.id === data.limit_currency) {
                            description = `${item.description}`
                          }
                          return description
                        })
                      }
                      <span> {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.max_limit_amount)}</span></p>
                    <div className="invoiceCard">Available Limit - {
                      currencyData.map((item: any) => {
                        let description = ""
                        if (item.id === data.limit_currency) {
                          description = `${item.description}`
                        }
                        return description
                      })
                    }
                      <span id={`Invoice_availableLimit${index}`}> {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.available_limit)}</span></div>
                    <div className="invoiceCard" id={`Invoice_expiryDate${index}`} >Valid Till - {moment(data.expiry_date).format("DD-MM-YYYY")}</div>
                    <Button className="GreenButtonContainer" onClick={() => onClickManual(data)} id="Invoice_manualUploadButton">Manual</Button>
                    <Button className="GreenButtonContainer"
                      onClick={() => { showModal(data); localStorage.setItem('party_id', data?.party)}} id="Invoice_bulkUploadButton" style={{ width: "135px" }}
                    >Bulk Upload</Button>
                  </Card>
                </Col>
              );
            })}
          </Row>
          : "No Invoice Found"}
      </div>
    </React.Fragment>
  );
};
export default Invoice;
