import { Card, Row, Col, Tooltip } from "antd";
import moment from 'moment'
import { useNavigate } from "react-router";
import images from "../../../assets/images";
import { socketInfoDetails } from "../../../redux/action";
import { useSelector } from "react-redux";
const Program = ({ programData }: any) => {
  const navigate = useNavigate();
  const socketData = useSelector(socketInfoDetails)
  const programInfo = programData ? programData : socketData?.payload?.socketInfo?.dashboard_data?.program
  const { BlackRightArrow } = images;
  const routeToProgram = (key: any) => {
    if (key === "apf") {
      navigate("/ProgramDetailView", { state: { data: programData?.APF, programType: "apf" } })
    } else {
      navigate("/ProgramDetailView", { state: { data: programData?.RF, programType: "rf" } })
    }
  }
  return (
    <div className="dashboardProgramCard">
      <h2>Program </h2>
      {
        (((programInfo?.RF !== null) && (programInfo?.APF === null)) || ((programInfo?.RF?.length > 0) && (programInfo?.APF?.length === 0))) ?
          <>
            <Row gutter={24} style={{ marginBottom: "30px" }}>
              <Col span={24} style={{ marginBottom: "30px" }}>
                <Card className="programContainer" >
                  <div className="programRowContainer">
                    <h1>Receivable Finance ( {programInfo?.RF?.length} )</h1>
                  </div>
                  <div style={{ float: "right", marginTop: "-40px", fontSize: "18px", cursor: "pointer" }} key={programInfo?.RF?.length} onClick={() => routeToProgram("rf")}>More <span><img src={BlackRightArrow} alt="rightarrow" className="dashboardRightArrow" /></span></div>
                  <div className='dashboardContainerTable '>
                    <div className="OuterDiv">
                      <div className='HeadInnerDiv'>
                        <h1 className='head'>{'Max. Limit Amt'}</h1>
                        <h1 className='head'>{'Available Limit'}</h1>
                        <h1 className='head'>{'Expiry Date'}</h1>
                      </div>
                    </div>
                    {programInfo?.RF?.slice(0, 3).map((item: any, index: number) => {
                      return <Tooltip title={
                        <div key={index}>
                          <p>Max.Invoice Amount : {item?.max_invoice_currency__description} {item.max_invoice_amount}</p>
                          <p>Max.Invoice PCT : {item.max_invoice_percent}</p>
                          <p>Max Tenor : {item.max_tenor}</p>
                          <p>Grace Period : {item.grace_period}</p>
                          <p>Interest Rate (%): {item.interest_type__description === "FIXED" ? item.margin : item.interest_rate_type__description + " + " + item.margin}</p>

                        </div>
                      } >
                        <div className='OuterDiv' key={index}>
                          <div className="InnerDiv" style={{ cursor: "default" }}>
                            <h5 className='body'>
                              {item?.limit_currency__description}  {item?.program_max_limit_amount}
                            </h5>
                            <h5 className='body'>
                              {item?.limit_currency__description} {item?.program_available_limit}
                            </h5>
                            <h5 className='body'>
                              {moment(item?.program_expiry_date).format("DD-MM-YYYY")}
                            </h5>
                          </div>
                        </div>
                      </Tooltip>
                    })}

                  </div>
                </Card>

              </Col>

            </Row>
          </>
          : (((programInfo?.APF !== null) && (programInfo?.RF === null)) || ((programInfo?.APF?.length > 0) && (programInfo?.RF?.length === 0))) ?
            <>
              <Row gutter={24} style={{ marginBottom: "30px" }}>
                <Col span={24} style={{ marginBottom: "30px" }}>
                  <Card className="programContainer" >
                    <div className="programRowContainer">
                      <h1>Approved Payable Finance ( {programInfo?.APF?.length} )</h1>
                    </div>
                    <div style={{ float: "right", marginTop: "-40px", fontSize: "18px", cursor: "pointer" }} onClick={() => routeToProgram("apf")}>More<span><img src={BlackRightArrow} alt="rightarrow" className="dashboardRightArrow" /></span></div>
                    <div className='dashboardContainerTable '>
                      <div className="OuterDiv">
                        <div className='HeadInnerDiv'>
                          <h1 className='head'>{'Max. Limit Amt'}</h1>
                          <h1 className='head'>{'Available Limit'}</h1>
                          <h1 className='head'>{'Expiry Date'}</h1>
                        </div>
                      </div>
                      {programInfo?.APF?.slice(0, 3).map((item: any, index: number) => {
                        return <Tooltip title={
                          <div key={index}>
                            <p>Max.Invoice Amount : {item?.max_invoice_currency__description} {item.max_invoice_amount}</p>
                            <p>Max.Invoice PCT : {item.max_invoice_percent}</p>
                            <p>Max Tenor : {item.max_tenor}</p>
                            <p>Grace Period : {item.grace_period}</p>
                            <p>Interest Rate (%): {item.interest_type__description === "FIXED" ? item.margin : item.interest_rate_type__description + " + " + item.margin}</p>
                          </div>
                        } >
                          <div className='OuterDiv' key={index}>
                            <div className="InnerDiv" style={{ cursor: "default" }}>
                              <h5 className='body'>
                                {item?.limit_currency__description}  {item?.program_max_limit_amount}
                              </h5>
                              <h5 className='body'>
                                {item?.limit_currency__description} {item?.program_available_limit}
                              </h5>
                              <h5 className='body'>
                                {moment(item?.program_expiry_date).format("DD-MM-YYYY")}
                              </h5>
                            </div>
                          </div>
                        </Tooltip>
                      })}
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
            :
            programInfo?.APF !== undefined && programInfo?.RF !== undefined ?
              <>
                <Row gutter={24}>
                  <Col span={24} >
                    <Card className="programContainer" >
                      <div className="programRowContainer">
                        <h1>Approved Payable Finance ( {programInfo?.APF?.length} )</h1>
                      </div>
                      <div style={{ float: "right", marginTop: "-48px", fontSize: "18px", cursor: "pointer" }} onClick={() => routeToProgram("apf")}>More<span><img src={BlackRightArrow} alt="rightarrow" className="dashboardRightArrow" /></span></div>
                      <div className='dashboardContainerTable '>
                        <div className="OuterDiv">
                          <div className='HeadInnerDiv'>
                            <h1 className='head'>{'Max. Limit Amt'}</h1>
                            <h1 className='head'>{'Available Limit'}</h1>
                            <h1 className='head'>{'Expiry Date'}</h1>
                          </div>
                        </div>
                        {programInfo?.APF?.slice(0, 1).map((item: any, index: number) => {
                          return <Tooltip title={
                            <div key={index}>
                              <p>Max.Invoice Amount : {item?.max_invoice_currency__description} {item.max_invoice_amount}</p>
                              <p>Max.Invoice PCT : {item.max_invoice_percent}</p>
                              <p>Max Tenor : {item.max_tenor}</p>
                              <p>Grace Period : {item.grace_period}</p>
                              <p>Interest Rate (%): {item.interest_type__description === "FIXED" ? item.margin : item.interest_rate_type__description + " + " + item.margin}</p>
                            </div>
                          } >
                            <div className='OuterDiv' key={index}>
                              <div className="InnerDiv" style={{ cursor: "default" }}>
                                <h5 className='body'>
                                  {item?.limit_currency__description}  {item?.program_max_limit_amount}
                                </h5>
                                <h5 className='body'>
                                  {item?.limit_currency__description} {item?.program_available_limit}
                                </h5>
                                <h5 className='body'>
                                  {moment(item?.program_expiry_date).format("DD-MM-YYYY")}
                                </h5>
                              </div>
                            </div>
                          </Tooltip>
                        })}
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: "30px" }}>
                  <Col span={24} style={{ marginBottom: "30px" }}>
                    <Card className="programContainer" >
                      <div className="programRowContainer">
                        <h1>Receivable Finance ( {programInfo?.RF?.length} )</h1>
                      </div>
                      <div style={{ float: "right", marginTop: "-48px", fontSize: "18px", cursor: "pointer" }} onClick={() => routeToProgram("rf")}>More <span><img src={BlackRightArrow} alt="rightarrow" className="dashboardRightArrow" /></span></div>
                      <div className='dashboardContainerTable '>
                        <div className="OuterDiv">
                          <div className='HeadInnerDiv'>
                            <h1 className='head'>{'Max. Limit Amt'}</h1>
                            <h1 className='head'>{'Available Limit'}</h1>
                            <h1 className='head'>{'Expiry Date'}</h1>
                          </div>
                        </div>
                        {programInfo?.RF?.slice(0, 1).map((item: any, index: number) => {
                          return <Tooltip title={
                            <div key={index}>
                              <p>Max.Invoice Amount : {item?.max_invoice_currency__description} {item.max_invoice_amount}</p>
                              <p>Max.Invoice PCT : {item.max_invoice_percent}</p>
                              <p>Max Tenor : {item.max_tenor}</p>
                              <p>Grace Period : {item.grace_period}</p>
                              <p>Interest Rate (%): {item.interest_type__description === "FIXED" ? item.margin : item.interest_rate_type__description + " + " + item.margin}</p>

                            </div>
                          } >
                            <div className='OuterDiv' key={index}>
                              <div className="InnerDiv" style={{ cursor: "default" }}>
                                <h5 className='body'>
                                  {item?.limit_currency__description}  {item?.program_max_limit_amount}
                                </h5>
                                <h5 className='body'>
                                  {item?.limit_currency__description} {item?.program_available_limit}
                                </h5>
                                <h5 className='body'>
                                  {moment(item?.program_expiry_date).format("DD-MM-YYYY")}
                                </h5>
                              </div>
                            </div>
                          </Tooltip>
                        })}

                      </div>
                    </Card>

                  </Col>

                </Row>
              </>
              : <div className="noDataText">No data</div>
      }
    </div>
  )
}
export default Program;
