import { useState, useEffect } from "react";
import { Input, Select, DatePicker, Checkbox, InputNumber, message } from "antd";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import moment from "moment";
import { UploadImage } from "../../../common/UploadImage/manualInvoiceUpload";
import images from "../../../../assets/images";
import { useNavigate } from "react-router";
import { ResponseStatus } from "../../../../utils/enum/choices";

const Header_RF_Seller = [{
  Name: "Buyer Name", InvNo: "Inv No", InvDate: "Inv Date", InvAmt: "Inv Amount", DueDate: "Due Date", FinanceAmount: "Finance Amount", RepaymentCurrency: "repaymentCurrency", RepaymentAccount: "repayment Account", AutoFinance: "Auto Finance",
  attached: "Attached"
}]
const RfBuyer = ({ invoice_detail, getRfBuyerdataSource, getrfBuyercounterPartyList, FinanceAmountErr, setFileLists,commentsData }: any) => {
  const Navigate = useNavigate()

  const { TextArea } = Input;
  const { Option } = Select;
  const { DeleteIcon, DatePickerImg, DropdownIcon } = images
  const [counterpartyId, setCounterpartyId] = useState([] as any);
  const [MaxInvoicePercent, setMaxInvoicePercent] = useState("");
  const [counterPartyList, setCounterPartyList] = useState([] as any);
  const [buyerName, setBuyerName] = useState([] as any);

  const [currencyList, SetCurrencyList] = useState([]);
  const [PartyAccountDetails, setPartyAccountDetails] = useState([] as any);
  const [FinanceAmountError, setFinanceAmountError] = useState(false)
  const [FinanceAmountErrorMessage, setFinanceAmountErrorMessage] = useState("")
  const [rfBuyerdataSource, setrfBuyerDataSource] = useState([
    {
      BuyerID: "" as any,
      BuyerName: buyerName as any,
      InvoiceNo: "" as any,
      InvoiceDate: "" as any,
      InvoiceCurrency: "" as any,
      InvoiceAmount: "" as any,
      DueDate: "" as any,
      FinanceAmount: 0 as any,
      FinancingCurrency: "" as any,
      RepaymentAccount: "" as any,
      RepaymentCurrency: 0 as any,
      counterparty_id: counterpartyId as any,
      RepaymentID: 0 as any,
      auto_finance: "" as any,
      ProgramID: invoice_detail?.id
    }])
  getRfBuyerdataSource(rfBuyerdataSource)
  getrfBuyercounterPartyList(counterPartyList)

  const selectedFiles = (value: any) => {
    setFileLists(value)
  }
  const getPartyAccounts = () => {
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/party/party-accounts/`)
      .then((resp: any) => {
        setPartyAccountDetails(resp.data.data)
      })
      .catch(() => {
        Navigate("/Notfound")
      })
  }
  useEffect(() => {
    getPartyAccounts();
    let id = ""
    if (invoice_detail?.fromMenu === "draft") {
      id = invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.ProgramID
    } else {
      id = invoice_detail.id
    }
    httpClient
      .getInstance()
      .get(`${baseurl}api/pairing/?pg_id=${id}&data_type=INVOICE`)
      .then((resp: any) => {
        setCounterPartyList(resp.data.data);
        const partyName = [] as any;
        resp.data.data.map((items: any) => {
          return partyName.push(items.counter_party_name);
        });
        setBuyerName(partyName);

        setCounterPartyList(resp.data.data);
        const sellerIds = [] as any;
        resp.data.data.map((items: any) => {
          return sellerIds.push(items.counterparty_uid);
        });

        setrfBuyerDataSource([
          {
            BuyerID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.BuyerID,
            BuyerName: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.BuyerName,
            InvoiceNo: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceNo,
            InvoiceDate: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceDate,
            InvoiceCurrency: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceCurrency,
            InvoiceAmount: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceAmount,
            DueDate: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.DueDate,
            FinanceAmount: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.FinanceAmount,
            FinancingCurrency: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.FinancingCurrency,
            RepaymentAccount: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.RepaymentAccount,
            RepaymentCurrency: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.RepaymentCurrency,
            counterparty_id: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.counterparty_id,
            RepaymentID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.RepaymentID,
            auto_finance: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.auto_finance,
            ProgramID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.ProgramID
          }])
        setMaxInvoicePercent(resp.data.data?.[0]?.max_invoice_percent)
      })
      .catch(() => {
        Navigate("/Notfound")
      })



    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        SetCurrencyList(resp.data.data);
      })
      .catch(() => {
        Navigate("/Notfound")
      })

  }, [])
  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };
  const disabledInvoiceDate = (current: any) => {
    return current && current > moment().endOf("day");
  }

  const deleteinvoice = () => {
    httpClient
      .getInstance()
      .delete(`${baseurl}api/invoice/upload/${invoice_detail?.data?.work_model?.t_id}/`)
      .then((resp: any) => {
        if (resp.data.status === ResponseStatus.SUCCESS) {
          Navigate("/Draft")
          message.success(resp.data.data)
        } else {
          message.error(resp.data.status)
        }
      })
      .catch(() => {
        Navigate("/Notfound")
      })
  }
  return (
    <div>
      <div className='containerTable'>
        <div className="OuterDiv">
          {
            Header_RF_Seller && Header_RF_Seller.map((item: any, index: any) => {
              return (
                <div key={index} className='HeadInnerDiv'>
                  <h1 className='head' style={{ width: "12%" }}>{item.Name}</h1>
                  <h1 className='head' style={{ width: "10%" }}>{item.InvNo}</h1>
                  <h1 className='head' style={{ width: "12%" }}>{item.InvDate}</h1>
                  <h1 className='head' style={{ width: "20%" }}>{item.InvAmt}</h1>
                  <h1 className='head' style={{ width: "12%" }}>{item.DueDate}</h1>
                  <h1 className='head' style={{ width: "20%" }}>{item.FinanceAmount}</h1>
                  <h1 className='head' style={{ width: "4%" }}>{item.AutoFinance}</h1>
                  <h1 className='head' style={{ width: "6%" }}>{item.attached}</h1>
                </div>
              )
            })

          }
        </div>
        <div className='OuterDiv'>

          {
            rfBuyerdataSource && rfBuyerdataSource.map((record: any, index: number) => {
              return (
                <div key={index} className='InnerDiv' >
                  <h5 className='body' style={{ width: "12%", fontSize: "10px", margin: "8px 2px" }}>
                    {buyerName.length !== 1 ?
                      <Select
                        optionFilterProp="children"
                        defaultValue={record.BuyerName}
                        value={record.BuyerName}
                        style={{
                          width: 100, padding: "4px"
                        }}
                        suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}

                        onSelect={(event: string) => {
                          const array = [...rfBuyerdataSource];
                          array[index].BuyerName = event;
                          setrfBuyerDataSource(array);
                          const names: any[] = [];
                          let values: any;
                          counterPartyList.map((data: any) => {
                            const array = rfBuyerdataSource;
                            if (event === data.counter_party_name) {
                              return (
                                (values = {
                                  id: data.id,
                                  name: data.counter_party,
                                }),
                                names.push(values),
                                setCounterpartyId(data.counter_party),
                                setMaxInvoicePercent(data.max_invoice_percent),
                                (array[index].BuyerID = data.id),
                                (array[index].counterparty_id = data.counter_party),
                                setrfBuyerDataSource(array)
                              );
                            }
                            return true;
                          });
                        }}
                      >
                        {buyerName.map((partyList: any, index: any) => {
                          return (
                            <Option value={partyList} key={partyList} id={`invRfBuyer_partyName${index}`}>
                              {partyList}
                            </Option>
                          );
                        })}
                      </Select>
                      : <div id={`invRfBuyer_partyName${index}`}>
                        {counterPartyList[0].counter_party_name}</div>}
                  </h5>

                  <h5 className='body' style={{ width: "10%", margin: "8px 2px" }}>
                    <div >
                      <Input
                        className="InputContainer"

                        onChange={(event: any) => {
                          const array = [...rfBuyerdataSource];
                          array[index].InvoiceNo = event.target.value;
                          if (buyerName.length === 1) {
                            setCounterpartyId(counterPartyList?.[0]?.counter_party)
                            setMaxInvoicePercent(counterPartyList?.[0]?.max_invoice_percent)
                            array[index].BuyerID = counterPartyList?.[0]?.id
                            array[index].BuyerName = counterPartyList?.[0]?.counter_party_name
                            array[index].counterparty_id = counterPartyList?.[0]?.counter_party
                          }
                          setrfBuyerDataSource(array);
                        }}
                        defaultValue={rfBuyerdataSource[index].InvoiceNo}
                        value={rfBuyerdataSource[index].InvoiceNo}
                        id={`invRfBuyer_InvoiceNumber${index}`}
                        style={{ padding: "4px" }}
                      />

                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "12%", margin: "8px 2px" }}>

                    <DatePicker
                      format="DD-MM-YYYY"
                      suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}
                      placeholder={record.InvoiceDate}
                      onChange={(event: any) => {
                        const array = [...rfBuyerdataSource];
                        array[index].InvoiceDate = moment(event._d).format("DD-MM-YYYY");
                        setrfBuyerDataSource(array);
                      }}
                      style={{ padding: "4px" }}
                      className={record.InvoiceDate ? "valueExpiryDate InputContainer" : "InputContainer"}
                      disabledDate={disabledInvoiceDate}
                      id={`invRfBuyer_InvoiceDate${index}`}
                    />

                  </h5>

                  <h5 className='body' style={{ width: "20%", margin: "8px 2px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                      >
                        <Select
                          showSearch
                          style={{ width: 64, padding: "4px" }}
                          onSelect={(event: string) => {
                            const array = [...rfBuyerdataSource];
                            array[index].InvoiceCurrency = event;
                            array[index].FinancingCurrency = event;
                            setrfBuyerDataSource(array);
                          }}
                          defaultValue={record.InvoiceCurrency}
                          value={record.InvoiceCurrency}
                          suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                          id={`invRfBuyer_InvoiceCurrency${index}`}
                        >
                          {currencyList &&
                            currencyList.map((item: any) => {
                              return (
                                <Option
                                  value={item.description}
                                  id={item.description}
                                  key={item.description}
                                >
                                  {item.description}
                                </Option>
                              );
                            })}
                        </Select>
                      </span>
                      <InputNumber
                        className="InputContainer"
                        disabled={!record.BuyerName}
                        defaultValue={record.InvoiceAmount}
                        value={record.InvoiceAmount}
                        onChange={(event: any) => {
                          const array = [...rfBuyerdataSource];
                          array[index].InvoiceAmount = parseInt(event);
                          array[index].FinanceAmount = (parseInt(event) * (parseInt(MaxInvoicePercent) / 100)).toFixed(2)
                          setrfBuyerDataSource(array)
                        }}
                        formatter={(value, e) => {
                          if (value === "" || isNaN(parseFloat(value))) {
                            return "";
                          }
                          return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                            : value
                        }}
                        id={`invRfBuyer_InvoiceAmount${index}`}
                        style={{ padding: "5px 4px" }}
                      />
                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "12%", margin: "8px 2px" }}>
                    <div>
                      <DatePicker
                        disabledDate={disabledDate}
                        format="DD-MM-YYYY"
                        suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}
                        placeholder={record.DueDate}
                        onChange={(event: any) => {
                          const array = [...rfBuyerdataSource];
                          array[index].DueDate = moment(event._d).format("DD-MM-YYYY");
                          array[index].RepaymentAccount = PartyAccountDetails?.[0]?.account_number;
                          PartyAccountDetails &&
                            PartyAccountDetails.map((item: any) => {
                              return PartyAccountDetails?.[0]?.account_number === item.account_number ?
                                currencyList &&
                                currencyList.map((currency: any) => {
                                  return currency.id === item.currency ?
                                    (array[index].RepaymentCurrency = currency.description,
                                      array[index].RepaymentID = item.id) : null
                                }) : null
                            })

                          setrfBuyerDataSource(array);
                        }}
                        style={{ padding: "4px" }}
                        className={record.DueDate ? "valueExpiryDate InputContainer" : "InputContainer"}
                        id={`invRfBuyer_DueDate${index}`}
                      />
                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "20%", margin: "8px 2px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span id={`invRfBuyer_FinanceCurrency${index}`} style={{
                        marginRight: "3px", border: "1px solid #d9d9d9", width: "28%", height: " 45px", borderRadius: "10px",
                        padding: "9px",
                        backgroundColor: "#FFFFFF"
                      }}>
                        {record.InvoiceCurrency}

                      </span>
                      <div style={{ display: "block" }}>
                        <InputNumber
                          className="InputContainer"
                          style={{ width: "100%", padding: "5px 4px" }}
                          id={`invRfBuyer_FinanceAmount${index}`}
                          defaultValue={record.FinanceAmount}
                          value={record.FinanceAmount}
                          disabled={!record.InvoiceAmount}
                          onChange={(event: any) => {
                            const arr = [...rfBuyerdataSource];
                            const data = (arr[index].InvoiceAmount * (parseInt(MaxInvoicePercent) / 100)).toFixed(2)
                            if (event > data) {
                              setFinanceAmountError(true)
                              setFinanceAmountErrorMessage(data)
                              FinanceAmountErr(true)
                            } else {
                              setFinanceAmountError(false)
                              setFinanceAmountErrorMessage('')
                              FinanceAmountErr(false)
                            }
                            arr[index].FinanceAmount = parseInt(event)
                            setrfBuyerDataSource(arr);

                          }}
                          formatter={(value, e) => {
                            if (value === "" || isNaN(parseFloat(value))) {
                              return "";
                            }
                            return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                              : value
                          }}
                        />
                      </div>

                    </div>
                    {FinanceAmountError ? <span style={{ color: "red" }}>Finance Amount should not be greater than {FinanceAmountErrorMessage}</span> : null}

                  </h5>
                  <h5 className='body' style={{ width: "4%", margin: "8px 2px" }}>
                    <div>
                      <Checkbox onChange={(event) => {
                        const array = [...rfBuyerdataSource];
                        array[index].auto_finance = event.target.checked;
                        setrfBuyerDataSource(array);
                      }
                      } id={`invRfBuyer_AutoFinance${index}`}
                        defaultChecked={localStorage.getItem("autofinance") === "true" ? true : false}
                      />
                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "6%", margin: "8px 2px" }} id={`invRfBuyer_attachments${index}`}>
                    <UploadImage selectedFiles={selectedFiles} attachmentFiles={[]} />
                  </h5>
                  <h5 >
                    <img src={DeleteIcon} alt="deleteIcon" id="invRfBuyer_delete" onClick={deleteinvoice}
                    />
                  </h5>
                </div>
              )
            })
          }
        </div>
      </div>

      <div className="SummaryContainer" style={{ marginTop: "5%" }}>
        <div className="SummaryLabel">Comments</div>
        <TextArea
          style={{ width: "50%", margin: "0px 10px" }}
          onChange={(e: any) => commentsData(e.target.value)} id="comments"
        />
      </div>

    </div>
  )
}
export default RfBuyer