import React, { useEffect, useState } from "react";
import { Spin, Card, Form, AutoComplete, message } from "antd";
import httpClient from "../../../utils/config/core/httpClient";
import baseurl from "../../../utils/config/url/base";
import { useNavigate } from "react-router-dom";
import { FieldErrorMessages } from "../../../utils/enum/messageChoices";
import Breadcrumbs from "../../common/heading/breadcrumb";
import { useForm } from "antd/es/form/Form";

const Repayment = () => {
    const [Loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [customerId, setCustomerId] = useState("")
    const [customerIdList, setCustomerIdList] = useState([]);
    const pathname = window.location.pathname
    const Navigator = useNavigate();
    const [form] = useForm();

    useEffect(() => {
        form.setFieldValue('customer_id', '')
    }, [pathname, form])

    const onFinish = () => {
        if (customerId !== "") {
            setButtonLoading(true)
            if (pathname === '/InvoiceCustomer') {
                localStorage.setItem('invoice_customerID_Bank', customerId);
                Navigator('/New');
            } else {
                httpClient
                    .getInstance()
                    .get(`${baseurl}api/finance-request/?data=${customerId}`)
                    .then(() => {
                        setButtonLoading(false)
                        setLoading(false)
                        localStorage.setItem("repayment_customerID", customerId)
                        Navigator("/Repayment")
                    })
                    .catch(() => {
                        setLoading(false);
                        Navigator("/Notfound")
                    })
            }
        } else {
            message.warning('Please enter Customer ID to continue')
        }
    }
    const Data = {
        fromMenu: pathname === "/InvoiceCustomer" ? "Invoice" : "Repayment",
        data: {
            type: ""
        },
        recordType: ""
    }
    const onClickExit = () => {
        // console.log("")
    }
    const buttonStatusData = {
        default: buttonLoading
    }

    const onChange = (e: any) => {
        httpClient
            .getInstance()
            .get(`${baseurl}api-auth/party/?data=${e}`)
            .then((resp) => {
                setCustomerIdList(resp.data.data)
            })
        setCustomerId(e);
    }

    const onSelect = (selectedText: string) => {
        const splitSelectedText = selectedText?.split(" - ");
        setCustomerId(splitSelectedText[0]?.trim());
    };

    return Loading ? <Spin /> : (
        <React.Fragment>
            <div className="fixedContainer">
                <Breadcrumbs
                    Data={Data}
                    onClickExit={onClickExit}
                    commentsValue={""}
                    flag="repaymentcustomer"
                    onClickAction={onFinish}
                    buttonStatusData={buttonStatusData}
                />
            </div>
            <div className="manageScfContainer mainContentContainer" style={{ marginTop: "12px", border: "none" }}>
                <Card>
                    <h4>{pathname === "/InvoiceCustomer" ? "Invoice" : "Repayment"}</h4>
                    <>
                        <div className="createNewAccount loginContainer">
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                autoComplete="off"
                                form={form}
                            >
                                <Form.Item
                                    label={"Customer ID"}
                                    name={"customer_id"}
                                    className="FormContainer"
                                    key={"customer_id"}
                                    rules={[
                                        {
                                            required: false,
                                            message: FieldErrorMessages.CUSID,
                                        },
                                    ]}
                                >
                                    <AutoComplete
                                        id="repaymentCustomer"
                                        dataSource={
                                            customerIdList &&
                                            customerIdList
                                                .filter(
                                                    (value: any) =>
                                                        value.customer_id !== null && value.name !== null
                                                )
                                                .map((value: any) => (
                                                    <AutoComplete.Option
                                                        key={value.customer_id}
                                                        value={value.customer_id}
                                                    >
                                                        {value.customer_id} - {value.name}
                                                    </AutoComplete.Option>
                                                ))
                                        }
                                        style={{ width: "100%", top: "unset", bottom: "unset" }}
                                        onSelect={onSelect}
                                        onSearch={onChange}
                                        onChange={onChange}
                                        placeholder="CustomerID"
                                        defaultValue={customerId}
                                        value={customerId}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </>
                </Card>
            </div>

        </React.Fragment>
    )
}
export default Repayment;