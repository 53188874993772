import ManageScf from "../manageScf/manageScf";
import ApprovedPayable from "../manageScf/program/programTab";
import ManageUsers from "../manageUsers/manageUser";
import NewAccount from "../new/createNewAccount";
import Invoice from "../new/invoice/invoice";
import Manual from "../new/invoice/manual/manual";
import UploadFile from "../new/invoice/bulk/uploadInvoice";
import TabContainer from "../common/tab/tab";
import Dashboard from "../dashboard/dashboard";
import Draft from "../draft/draft";
import Settings from "../settings/settings";
import ModifyPage from "../enquiry/enquiry";
import EnquiryDetail from "../enquiry/enquiryDetail";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../utils/page/404";
import OnboardingTabs from "../onboarding/onboardingTabs";
import ProgramDetail from "../common/detail/program/programDetail";
import BulkUploadDetail from "../new/invoice/bulk/uploadTab";
import UploadCsv from "../new/invoice/bulk/uploadInvoice";
import UploadDetail from "../common/detail/invoice/invoiceDetail";
import FinanceRequestDetail from "../common/detail/financeRequestDetail";
import OnboardingDetail from "../common/detail/onboardingDetail";
import HistoryList from "../common/list/history";
import RepaymentList from "../new/repayment/repayment";
import RepaymentDetail from "../common/detail/repaymentDetail";
import FinanceRepaymentDetail from "../common/detail/financeRepaymentDetail";
import ProgramDetailView from "../dashboard/graphs/programDetail";
import UserAuthorization from "../settings/settings";
import RepaymentCustomer from "../new/repayment/repaymentCustomer";
import PendingInvoicesList from "../pendingInvoices/pendingInvoices";
import PendingInvoiceDetail from "../common/detail/pendingInvoiceDetail";
import BulkuploadEdit from "../new/invoice/bulk/editBulkUpload";
import BulkuploadTab from "../new/invoice/bulk/uploadTab";
import NotFound from "../../utils/page/500";
import SystemDate from "../settings/systemDate";
import NotificationDetail from "../common/detail/notificationDetail";
const Sidebar = (width: any) => {
  return (
    <div style={{ width: width }}>
      {
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/Notfound" element={<NotFound />} />


          <Route
            path="/CounterPartyOnboarding"
            element={<OnboardingTabs />}
          />
          <Route path="/Inbox" element={<TabContainer fromMenu="inbox" recordType="ALL" />} />
          <Route path="/Sent" element={<TabContainer fromMenu="sent" recordType="ALL" />} />
          <Route path="/sent/CounterpartyDetail" element={<OnboardingDetail />} />
          <Route path="/inbox/CounterpartyDetail" element={<OnboardingDetail />} />


          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Inbox" element={<TabContainer fromMenu="inbox" recordType="ALL" />} />
          <Route path="/Sent" element={<TabContainer fromMenu="sent" recordType="ALL" />} />
          <Route path="/Manual" element={<Manual />} />
          <Route path="/UploadFile" element={<UploadFile />} />
          <Route path="/New" element={<Invoice />} />
          <Route path="/Invoice" element={<Invoice />} />
          <Route path="/NewAccount" element={<NewAccount />} />
          <Route path="/ManageScfNew" element={<NewAccount />} />
          <Route path="/ManageScf" element={<ManageScf />} />
          <Route path="/ManageUsers" element={<ManageUsers />} />
          <Route path="/ApprovedPayableFinancing" element={<ApprovedPayable />} />
          <Route path="/ReceivableFinancing" element={<ApprovedPayable />} />
          <Route path="/Inbox/ProgramModify" element={<ApprovedPayable />} />
          <Route path="/Draft" element={<Draft />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/Enquiry" element={<ModifyPage />} />
          <Route path="/SubmittedForSign" element={<TabContainer fromMenu="sent_awap" recordType="ALL" />} />
          <Route path="/sent_awap" element={<TabContainer fromMenu="sent_awap" />} />
          <Route path="/EnquiryDetail" element={<EnquiryDetail />} />
          <Route path="/Inbox/ProgramDetail" element={<ProgramDetail />} />
          <Route path="/sent_awap/ProgramDetail" element={<ProgramDetail />} />
          <Route path="/sent/ProgramDetail" element={<ProgramDetail />} />

          <Route path="/Inbox/UploadDetail" element={<UploadDetail />} />
          <Route path="/draft/ProgramDetail" element={<ProgramDetail />} />
          <Route path="/Draft/UploadDetail" element={<UploadDetail />} />
          <Route path="/Draft/Manual" element={<Manual />} />
          <Route path="/Draft/Invoice" element={<BulkuploadEdit />} />
          <Route path="/Draft/InvoiceDetail" element={<UploadDetail />} />
          <Route path="/Sent/InvoiceDetail" element={<UploadDetail />} />
          <Route path="/Inbox/InvoiceDetail" element={<UploadDetail />} />
          <Route path="/Inbox/InvoiceRepaymentDetail" element={<FinanceRepaymentDetail />} />
          <Route path="/Inbox/FinanceRepaymentDetail" element={<FinanceRepaymentDetail />} />
          <Route path="/sent/FinanceRepaymentDetail" element={<FinanceRepaymentDetail />} />
          <Route path="/sent_awap/FinanceRepaymentDetail" element={<FinanceRepaymentDetail />} />
          <Route path="/sent/InvoiceRepaymentDetail" element={<FinanceRepaymentDetail />} />
          <Route path="sent_awap/InvoiceRepaymentDetail" element={<FinanceRepaymentDetail />} />
          <Route path="/sent_awap/InvoiceDetail" element={<UploadDetail />} />
          <Route path="/sent_awap/FinanceRequestDetail" element={<FinanceRequestDetail />} />
          <Route path="/draft/FinanceRequestDetail" element={<FinanceRequestDetail />} />
          <Route path="/Inbox/FinanceRequestDetail" element={<FinanceRequestDetail />} />
          <Route path="/Sent/FinanceRequestDetail" element={<FinanceRequestDetail />} />

          <Route path="/Upload" element={<UploadCsv />} />
          <Route path="/UploadDetail" element={<BulkUploadDetail />} />
          <Route path="/inbox/CounterpartyDetail" element={<OnboardingDetail />} />
          <Route path="/sent/CounterpartyDetail" element={<OnboardingDetail />} />
          <Route path="/sent_awap/CounterpartyDetail" element={<OnboardingDetail />} />
          <Route path="/Inbox/History" element={<HistoryList />} />
          <Route path="/sent/History" element={<HistoryList />} />
          <Route path="/Repayment" element={<RepaymentList />} />
          <Route path="/RepaymentDetail" element={<RepaymentDetail />} />
          <Route path="/ProgramDetailView" element={<ProgramDetailView />} />
          <Route path="/UserAuthorization" element={<UserAuthorization />} />
          <Route path="/RepaymentCustomer" element={<RepaymentCustomer />} />
          <Route path="/InvoiceCustomer" element={<RepaymentCustomer />} />
          <Route path="/PendingInvoices" element={<PendingInvoicesList />} />
          <Route path="/PendingInvoiceDetail" element={<PendingInvoiceDetail />} />
          <Route path="/Draft/BulkUploadDetail" element={<BulkuploadTab />} />
          <Route path="/SystemDate" element={<SystemDate/>}/>
          <Route path="/Inbox/NotificationDetail" element={<NotificationDetail/>} />
        </Routes>
      }

    </div>
  );
};
export default Sidebar;