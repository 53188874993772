import { Tabs, Card, Spin } from "antd";
import React, { useEffect, useState, useRef } from "react";
import AllContainer from "../list/all";
import { TabPaneFunc } from "./tabPane";
import "./tabPane.scss";
import Heading from "../heading/heading";
import { socketInfoDetails, pageSizeDetails, socketConnected } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import * as pako from 'pako';
import socketurl from "../../../utils/config/url/socket";

interface IProps {
  fromMenu: string;
  recordType?: string;
}
const ApprovedPayable = (props: React.PropsWithChildren<IProps>) => {

  const [tabList, setTabList] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false)
  const [Loading, setLoading] = useState(false)
  const ws = useRef(null) as any;
  const loginData = localStorage.getItem("login_detail") || "";
  const logindata = JSON.parse(loginData);
  const dispatch = useDispatch();
  const [isPaused, setPause] = useState(false);
  const socketConnection = useSelector(socketConnected)

  const pageSizeDatas = useSelector(pageSizeDetails);
  const PageSizeCount = pageSizeDatas?.payload?.pageSizeData
  const [changeTabValue, setChangeTabValue] = useState("ALL" as string);
  const [tabDatas, setTabDatas] = useState("ALL")
  const [activeTabKey, setActiveTabKey] = useState("ALL");

  const callback = (key: string) => {
    setIsLoading(true)
    let tabData = "ALL"
    if (key === "Invoice" && props.fromMenu === "sent" && logindata.party_type === "BANK") {
      setChangeTabValue("FINANCE REQUEST")
      tabData = "FINANCE REQUEST"
    } else if (key === "Invoice") {
      setChangeTabValue("INVOICE")
      tabData = "INVOICE"
    } else if (key === "Program") {
      setChangeTabValue("PROGRAM")
      tabData = "PROGRAM"
    } else if (key === "Awaiting Sign") {
      setChangeTabValue("AW_SIGN")
      tabData = "AW_SIGN"
    } else if (key === "Onboarding") {
      setChangeTabValue("COUNTERPARTY ONBOARDING")
      tabData = "COUNTERPARTY ONBOARDING"
    } else if (key === "Finance request") {
      setChangeTabValue("FINANCE REQUEST")
      tabData = "FINANCE REQUEST"
    } else if (key === "ALL" || key === "All") {
      setChangeTabValue("ALL")
      tabData = "ALL"

    }

    setTabDatas(tabData)


    ws.current = socketurl;
    const data = {
      "party_id": logindata.party_id,
      "user_id": logindata.user_id,
      "record_type":
      {
        "inbox":
        {
          type: props.fromMenu === "inbox" ? tabData : "ALL",
          data: props.fromMenu === "inbox" ? true : false
        },
        "sent":
        {
          type: props.fromMenu === "sent" ? tabData : "ALL",
          data: props.fromMenu === "sent" ? true : false
        },
        "sent_aw": {
          type: props.fromMenu === "sent_awap" ? tabData : "ALL",
          data: props.fromMenu === "sent_awap" ? true : false
        },
        "enquiry": {
          "data": false,
          "filter": {
            "model_type": null,
            "program_type": null,
            "transaction_date": null,
            "due_date": null,
            "currency": null,
            "counterparty": null,
            "amount": null
          },
          "search": null
        },
        "draft": props.fromMenu === "draft" ? true : false
      },
      "data_type": "MESSAGE",
      "page_size": PageSizeCount,
      "page_number": 1

    }

    if (socketConnection?.payload?.socketConnectData === "connected") {
      ws.current.send(JSON.stringify(data));
      ws.current.onmessage = (e: any) => {
        const message = JSON.parse(e.data);
        const compressedDataArray = message

        const compressedData = new Uint8Array(compressedDataArray);
        const decompressedData = pako.inflate(compressedData, { to: 'string' });
        const decompressedMessageData = JSON.parse(decompressedData);
        dispatch(
          socketInfoDetails(decompressedMessageData)
        )
      }

    } else {
      ws.current.onopen = () => {
        ws.current.send(JSON.stringify(data));
        ws.current.onmessage = (e: any) => {
          const message = JSON.parse(e.data);
          const compressedDataArray = message

          const compressedData = new Uint8Array(compressedDataArray);
          const decompressedData = pako.inflate(compressedData, { to: 'string' });
          const decompressedMessageData = JSON.parse(decompressedData);
          dispatch(
            socketInfoDetails(decompressedMessageData)
          )
        }
      }

    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)

  };

  useEffect(() => {
    setIsLoading(true)
    setLoading(true)
    ws.current = socketurl;
    setChangeTabValue("ALL")
    setTabDatas("ALL")
    const data = {
      "party_id": logindata.party_id,
      "user_id": logindata.user_id,
      "record_type":
      {
        "inbox":
        {
          type: "ALL",
          data: props.fromMenu === "inbox" ? true : false
        },
        "sent":
        {
          type: "ALL",
          data: props.fromMenu === "sent" ? true : false
        },
        "sent_aw": {
          type: "ALL",
          data: props.fromMenu === "sent_awap" ? true : false
        },
        "enquiry": {
          "data": false,
          "filter": {
            "model_type": null,
            "program_type": null,
            "transaction_date": null,
            "due_date": null,
            "currency": null,
            "counterparty": null,
            "amount": null
          },
          "search": null
        },
        "draft": props.fromMenu === "draft" ? true : false
      },
      "data_type": "MESSAGE",
      "page_size": PageSizeCount,
      "page_number": 1

    }

    if (socketConnection?.payload?.socketConnectData === "connected") {

      ws.current.send(JSON.stringify(data));
      ws.current.onmessage = (e: any) => {
        const message = JSON.parse(e.data);
        const compressedDataArray = message

        const compressedData = new Uint8Array(compressedDataArray);
        const decompressedData = pako.inflate(compressedData, { to: 'string' });
        const decompressedMessageData = JSON.parse(decompressedData);
        dispatch(
          socketInfoDetails(decompressedMessageData)
        )
      }
    } else {
      ws.current.onopen = () => {
        dispatch(
          socketConnected('connected')
        )
        ws.current.send(JSON.stringify(data));
        ws.current.onmessage = (e: any) => {
          const message = JSON.parse(e.data);
          const compressedDataArray = message

          const compressedData = new Uint8Array(compressedDataArray);
          const decompressedData = pako.inflate(compressedData, { to: 'string' });
          const decompressedMessageData = JSON.parse(decompressedData);
          dispatch(
            socketInfoDetails(decompressedMessageData)
          )
        }
      }

    }
    window.addEventListener('beforeunload', () => {
      console.log("websocket disconnected  closed")
      dispatch(
        socketConnected('disconnected')
      )
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        console.log("websocket disconnected  closed")
        ws.current.close();
        dispatch(
          socketConnected('disconnected')
        )
      }
    });
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    setTimeout(() => {
      setIsLoading(false)

    }, 1500)
  }, [props.fromMenu])
  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e: any) => {
      setIsLoading(true)
      if (isPaused) return;
      const message = JSON.parse(e.data);
      const compressedDataArray = message

      const compressedData = new Uint8Array(compressedDataArray);
      const decompressedData = pako.inflate(compressedData, { to: 'string' });
      const decompressedMessageData = JSON.parse(decompressedData);
      dispatch(
        socketInfoDetails(decompressedMessageData)
      )
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)

    };
  }, [isPaused]);
  useEffect(() => {
    const data = TabPaneFunc(props)
    setTabList(data);
    setTimeout(() => {
      setIsLoading(false)
    }, 5)
  }, [props])

  return (
    <React.Fragment>
      <div className="fixedContainer">
        <Heading flag={props.fromMenu === "sent_awap" ? "Sent for Sign" : props.fromMenu} text={props.fromMenu === "sent_awap" ? "Sent for Sign" : props.fromMenu} subText="" />
      </div>
      <div className="TabContainer mainContentContainer"
      >
        <Card className="tableContainer" >
          {Loading ? <Spin /> :
            <Tabs
              defaultActiveKey={activeTabKey}
              onChange={callback}
              id={`${props.fromMenu}_tabs`}
              items={tabList.map((value: any, index: number) => (

                props.fromMenu !== "inbox" && value.Name === "Awaiting Sign" ? "" :
                  {
                    key: value.Name,
                    label: value.Name,
                    id: value.Name,
                    children: isLoading ? <Spin /> : <AllContainer
                      fromMenu={props.fromMenu}
                      fromMenuValue={props.fromMenu}
                      recordType={
                        changeTabValue
                      }
                    />
                  }))}
            />
          }
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ApprovedPayable;
