import { useState } from "react";
import Main from "./main"
import images from "../../assets/images";
import { chatSocketList } from "../../redux/action"
import { useSelector } from "react-redux";
import "./chatapp.scss";

const Base = () => {
  const { ChatIcon } = images;
  const socketList = useSelector(chatSocketList);
  const [openchat, setopenchat] = useState(false);

  const onclickOpenChat = () => {
    setopenchat(!openchat);
  }
  const chatbotClose = (data: any) => {
    setopenchat(data)   
  }

  return (
    <>
      {openchat ?
        <Main chatbotOpen={chatbotClose} />
        :
        <div
          className={"chatContainer"}
          onClick={onclickOpenChat}>
          <span>
            <img src={ChatIcon} alt="chat" />
          </span>
          <div className="TotalCount">{socketList?.payload?.chatList?.unread_message ? socketList?.payload?.chatList?.unread_message : 0}</div>
        </div>
      }
    </>
  )
}
export default Base;
