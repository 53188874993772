import { useState, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import images from "../../../assets/images";
import { program_Basicdetails } from "../../../redux/action";
import { useNavigate } from "react-router-dom";
import imageBaseurl from "../../../utils/config/url/image";
import httpClient from "../../../utils/config/core/httpClient";
import baseurl from "../../../utils/config/url/base";
import { Action, InterimState, Type, ProgramType, ResponseStatus } from "../../../utils/enum/choices";
import CurrencyField from "../function/currency";
import { Checkbox, Pagination, Spin, Button, message } from "antd";
import socketurl from "../../../utils/config/url/socket";
import { socketInfoDetails, pageSizeDetails, socketConnected, selectedAwaitingItems } from "../../../redux/action";
import * as pako from 'pako';


interface Iprops {
  fromPage: string;
  fromMenu: string;
  recordType: string | any
}

const TableComponent = (props: Iprops) => {
  const { FileAttachIcon, AwaitingIcon, DeleteIcon } = images;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ws = useRef(null) as any;
  const [isPaused, setPause] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);
  const [selectedIds, setSelectedIds] = useState([] as any);
  const [selectedData, setSelectedData] = useState([] as any)
  const [selectData, setSelect] = useState([] as any)
  const [tid, setTid] = useState([] as any)
  const [selectedItems, setSelectedItems] = useState([] as any);

  const socketData = useSelector(socketInfoDetails, shallowEqual)
  const socketInfoData = socketData?.payload?.socketInfo
  const pageSizeDatas = useSelector(pageSizeDetails);
  const PageSizeCount = pageSizeDatas?.payload?.pageSizeData
  const socketConnection = useSelector(socketConnected)
  const [loading, setLoading] = useState(false)
  const HeadersData = [{
    Type: 'Type', Date: 'Date', Status: 'Status', From: 'From', Program: 'Program', Amount: 'Amount', Due: 'Due Date', Attached: 'Attachments'
  }]

  const OnClickDetails = async (e: any, data: any) => {
    await
      e.stopPropagation();
    e.preventDefault();
    if (props.fromMenu === "inbox" || props.fromMenu === "draft" || props.fromMenu === "sent") {

      const body = {
        is_read: false,
        model_type: props.fromMenu === "inbox" || props.fromMenu === "draft" ? "WF" : "WE",
        id: data.id
      }
      // httpClient
      //   .getInstance()
      //   .put(`${baseurl}api/message/update/${data.id}/`, body)
      //   .then((resp: any) => {
      //     console.log(resp)
      //   })
    }
    if (data.type === Type.PROGRAM) {
      if (props.fromMenu === "draft") {
        if (data?.record_datas?.values?.[0]?.fields.program_type === ProgramType.APF) {
          navigate(`/ApprovedPayableFinancing`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType, programData: data?.record_datas?.values?.[0]?.fields?.program_type } })
          dispatch(program_Basicdetails(data?.record_datas?.values?.[0]?.fields))
          localStorage.setItem("party__id", data?.record_datas?.values?.[0]?.fields?.party)

        } else if (data?.record_datas?.values?.[0]?.fields.program_type === ProgramType.RF) {
          navigate(`/ReceivableFinancing`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType, programData: data?.record_datas?.values?.[0]?.fields?.program_type } })
          dispatch(program_Basicdetails(data?.record_datas?.values?.[0]?.fields))
          localStorage.setItem("party__id", data?.record_datas?.values?.[0]?.fields?.party)
        } else {
          navigate(`/${props.fromMenu}/ProgramDetail`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })
        }


      } else {
        navigate(`/${props.fromMenu}/ProgramDetail`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })

      }

    } else if (data.type === Type.INVOICEUPLOAD) {
      if (props.fromMenu === "draft") {
        if (data?.record_datas?.values?.[0]?.fields?.uploaded_by === "RF_SELLER" && data?.record_datas?.values?.[0]?.fields?.is_manual === false) {
          navigate("/Draft/Invoice", { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })

        } else {
          navigate("/Draft/Manual", { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })

        }


      } else {
        navigate("/Inbox/UploadDetail", { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })

      }
    } else if (
      data.interim_state === "Finance requested" && data.type === Type.INVOICE ||
      data.type === Type.FINANCERQ || data.type === Type.FINANCEREQ
    ) {
      navigate(`/${props.fromMenu}/FinanceRequestDetail`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })

    } else if (data.type === Type.FINANCEREPAYMENT) {
      navigate(`/${props.fromMenu}/FinanceRepaymentDetail`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })

    } else if (data.type === Type.INVOICE) {
      if (localStorage.getItem("user") === "Bank") {
        if (data.action === Action.REQFINANCE || data.action === Action.FINANCED) {
          navigate(`/${props.fromMenu}/FinanceRequestDetail`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })
        }
      } else {
        navigate(`/${props.fromMenu}/InvoiceDetail`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })

      }


    } else if (data.type === Type.COUNTERPARTY) {
      navigate(`/${props.fromMenu}/CounterpartyDetail`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })

    } else if (data.type === Type.INVOICEREPAYMENT) {
      navigate(`/${props.fromMenu}/InvoiceRepaymentDetail`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })

    } else if (data.type === Type.BULKUPLOAD) {
      navigate(`/${props.fromMenu}/BulkUploadDetail`, { state: { fromMenu: props.fromMenu, data: data, recordType: props.recordType } })
    }
  }

  const loginlocalItems = localStorage.getItem("login_detail") as any;
  const loginData = localStorage.getItem("login_detail") || "";
  const logindata = JSON.parse(loginData);

  const datasource = props.fromMenu === "inbox" ? socketInfoData?.message_data?.inbox?.data
    : props.fromMenu === "sent" ? socketInfoData?.message_data?.sent?.data
      : props.fromMenu === "sent_awap" ? socketInfoData?.message_data?.sent_awap?.data
        : props.fromMenu === "draft" ? socketInfoData?.message_data?.draft?.data
          : props.fromMenu === "InboxHistory" ? props?.recordType
            : []

  console.log(datasource, 'ds')

  const paginationTotalCount = props.fromMenu === "inbox" ? socketInfoData?.message_data?.inbox?.overall_count
    : props.fromMenu === "sent" ? socketInfoData?.message_data?.sent?.overall_count
      : props.fromMenu === "sent_awap" ? socketInfoData?.message_data?.sent_awap?.overall_count
        : props.fromMenu === "draft" ? socketInfoData?.message_data?.draft?.overall_count
          : datasource.length

  const onChange = (e: any) => {
    setLoading(true);
    dispatch(socketInfoDetails(null))
    const updatedSelectedItems = [
      ...selectedItems,
      ...selectData.map((record: any) => record.id),
    ];
    setSelectedItems(updatedSelectedItems);

    setpageNumber(e)
    ws.current = socketurl;

    const data = {
      "party_id": logindata.party_id,
      "user_id": logindata.user_id,
      "record_type":
      {
        "inbox":
        {
          type: props.fromMenu === "inbox" ? props.recordType : "ALL",
          data: props.fromMenu === "inbox" ? true : false
        },
        "sent":
        {
          type: props.fromMenu === "sent" ? props.recordType : "ALL",
          data: props.fromMenu === "sent" ? true : false
        },
        "sent_aw": {
          type: props.fromMenu === "sent_awap" ? props.recordType : "ALL",
          data: props.fromMenu === "sent_awap" ? true : false
        },
        "enquiry": {
          "data": false,
          "filter": {
            "model_type": null,
            "program_type": null,
            "transaction_date": null,
            "due_date": null,
            "currency": null,
            "counterparty": null,
            "amount": null
          },
          "search": null
        },
        "draft": props.fromMenu === "draft" ? true : false

      },
      "data_type": "MESSAGE",
      "page_size": PageSizeCount,
      "page_number": e
    }
    if (socketConnection?.payload?.socketConnectData === "connected") {

      ws.current.send(JSON.stringify(data));

      ws.current.onmessage = (e: any) => {
        const message = JSON.parse(e.data);
        const compressedDataArray = message

        const compressedData = new Uint8Array(compressedDataArray);
        const decompressedData = pako.inflate(compressedData, { to: 'string' });
        const decompressedMessageData = JSON.parse(decompressedData);
        console.log(decompressedMessageData, 'DMD')
        dispatch(
          socketInfoDetails(decompressedMessageData)
        )
        setLoading(false);
      }
    } else {
      ws.current.onopen = () => {
        dispatch(
          socketConnected('connected')
        )
        ws.current.send(JSON.stringify(data));

        ws.current.onmessage = (e: any) => {
          const message = JSON.parse(e.data);
          const compressedDataArray = message

          const compressedData = new Uint8Array(compressedDataArray);
          const decompressedData = pako.inflate(compressedData, { to: 'string' });
          const decompressedMessageData = JSON.parse(decompressedData);
          dispatch(
            socketInfoDetails(decompressedMessageData)
          )
          console.log(decompressedMessageData, 'DMD')
          setLoading(false);
        }
      }

    }
    // setLoading(true)

    window.addEventListener('beforeunload', () => {
      dispatch(
        socketConnected('disconnected')
      )
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        console.log("websocket disconnected  closed")
        ws.current.close();
        dispatch(
          socketConnected('disconnected')
        )
      }
    });
  }

  const updateGlobalSelectedItems = (updatedSelectedItems: any) => {
    console.log("e update ", updatedSelectedItems)
  };

  useEffect(() => {
    setLoading(true)
    ws.current = socketurl;
    if (props.fromMenu === "draft") {
      const data = {
        "party_id": logindata.party_id,
        "user_id": logindata.user_id,
        "record_type":
        {
          "inbox":
          {
            type: "ALL",
            data: false
          },
          "sent":
          {
            type: "ALL",
            data: false
          },
          "sent_aw": {
            type: "ALL",
            data: false
          },
          "enquiry": {
            "data": false,
            "filter": {
              "model_type": null,
              "program_type": null,
              "transaction_date": null,
              "due_date": null,
              "currency": null,
              "counterparty": null,
              "amount": null
            },
            "search": null
          },
          "draft": props.fromMenu === "draft" ? true : false

        },
        "data_type": "MESSAGE",
        "page_size": PageSizeCount,
        "page_number": 1

      }
      if (socketConnection?.payload?.socketConnectData === "connected") {

        ws.current.send(JSON.stringify(data));

        ws.current.onmessage = (e: any) => {
          const message = JSON.parse(e.data);
          const compressedDataArray = message

          const compressedData = new Uint8Array(compressedDataArray);
          const decompressedData = pako.inflate(compressedData, { to: 'string' });
          const decompressedMessageData = JSON.parse(decompressedData);
          dispatch(
            socketInfoDetails(decompressedMessageData)
          )

        }
      } else {
        ws.current.onopen = () => {
          ws.current.send(JSON.stringify(data));

          ws.current.onmessage = (e: any) => {
            const message = JSON.parse(e.data);
            const compressedDataArray = message

            const compressedData = new Uint8Array(compressedDataArray);
            const decompressedData = pako.inflate(compressedData, { to: 'string' });
            const decompressedMessageData = JSON.parse(decompressedData);
            dispatch(
              socketInfoDetails(decompressedMessageData)
            )

          }
        }
      }

    }

  }, [props.fromMenu])

  useEffect(() => {
    if (!ws.current) return;
    setLoading(true)
    ws.current.onmessage = (e: any) => {
      if (isPaused) return;
      const message = JSON.parse(e.data);
      const compressedDataArray = message

      const compressedData = new Uint8Array(compressedDataArray);
      const decompressedData = pako.inflate(compressedData, { to: 'string' });
      const decompressedMessageData = JSON.parse(decompressedData);
      console.log("decompressedData", decompressedMessageData);
      dispatch(
        socketInfoDetails(decompressedMessageData)
      )
      setTimeout(() => {
        setLoading(false)
      }, 2500)
    };
  }, [isPaused]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoading(false);
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const handleCheckboxChange = (e: any, recordId: any) => {
    const isSelected = selectedIds.includes(recordId?.work_model?.t_id);
    const { work_model, model_type, action } = recordId;
    setSelect((prevIds: any) =>
      isSelected
        ? prevIds.filter((selectedRecord: any) => selectedRecord.id !== recordId?.work_model?.t_id)
        : [...prevIds, { work_model, model_type, action }]
    );

    setSelectedIds((prevIds: any) =>
      isSelected
        ? prevIds.filter((id: any) => id !== recordId?.work_model?.t_id)
        : [...prevIds, recordId?.work_model?.t_id]
    );
    setSelectedData((prevIds: any) =>
      isSelected
        ? prevIds.filter((id: any) => id !== recordId?.work_model?.t_id)
        : [...prevIds, recordId?.work_model?.t_id, recordId.model_type, recordId.action]
    );
    updateGlobalSelectedItems(selectData);
    dispatch(selectedAwaitingItems(selectData));

  };
  const HeaderCheckboxChange = (e: any) => {
    console.log("e", e.target.checked, "selectedIds", selectedIds)
    // setSelectedIds([])
    // const isChecked = e.target.checked;

    // if (isChecked) {
    //   // If header checkbox is checked, select all records
    //   const allIds = datasource.map((record: any) => record.work_model?.t_id);
    //   setSelectedIds(allIds);
    //   setSelectedData(
    //     datasource.map((record: any) => ({
    //       id: record.work_model?.t_id,
    //       model_type: record.model_type,
    //       action: record.action,
    //     }))
    //   );
    // } else {
    //   // If header checkbox is unchecked, deselect all records
    //   setSelectedIds([]);
    //   setSelectedData([]);
    // }

    if (e.target.checked === true) {
      datasource.map((record: any) => {
        const isSelected = selectedIds.includes(record.work_model?.t_id);
        console.log("e isSelected", isSelected, record.work_model?.t_id, "selectedIds.includes(record.work_model?.t_id",)

        const { work_model, model_type, action } = record;

        setSelect((prevIds: any) =>
          isSelected
            ? prevIds.filter((selectedRecord: any) => selectedRecord.id !== record.work_model?.t_id)
            : [...prevIds, { work_model, model_type, action }]
        );
        // setSelectedIds((prevIds: any) =>
        // console.log("e prevIds",prevIds))
        // setSelectedIds(datasource.map((record: any) => record.work_model?.t_id));
        setSelectedIds((prevIds: any) =>
          isSelected
            ? prevIds.filter((id: any) => id !== record.work_model?.t_id)
            : [...prevIds, record.work_model?.t_id]
        );

        setSelectedData((prevIds: any) =>
          isSelected
            ? prevIds.filter((id: any) => id !== record.work_model?.t_id)
            : [...prevIds, record.work_model?.t_id, record.model_type, record.action]
        );

      })

    } else {
      setSelectedIds([])
      setSelect([]);
      setSelectedData([]);
    }
  }
  //working
  const onclickRelease = (key: string) => {
    const originalArray = selectData
    const transformedArray = originalArray.reduce((acc: any, item: any) => {
      if (key === "Return") {
        const existingItem = acc.find((accItem: any) => accItem.model_type === item.model_type);

        if (existingItem) {
          existingItem.t_ids?.push(item?.work_model?.t_id);
        } else {
          acc.push({
            action: "RETURN",
            model_type: item.model_type,
            t_ids: [item?.work_model?.t_id]
          });
        }
      } else {
        const existingItem = acc.find((accItem: any) => accItem.action === item.action && accItem.model_type === item.model_type);

        if (existingItem) {
          existingItem.t_ids?.push(item?.work_model?.t_id);
        } else {
          acc.push({
            action: item.action,
            model_type: item.model_type,
            t_ids: [item?.work_model?.t_id]
          });
        }
      }


      return acc;
    }, []);
    const body = {
      data: transformedArray
    }
    console.log("e body", body)
    httpClient
      .getInstance()
      .post(`${baseurl}api/resource/transition/`, body)
      .then((resp: any) => {
        if (resp.data.data === ResponseStatus?.FAILURE) {
          datasource.map((value: any) => {
            resp.data.errors.map((item: any) => {
              setTid(item.t_ids)
              item.t_ids.find((data: any) => {
                if (data === value.id) {
                  message.error("You dont have sufficient authorization to perform this action")
                }
              })
            })
          })
        } else {
          message.success(resp.data.data)
          setSelect([])
        }
      })

  }
  //working


  const areAllRecordsSelectedOnPage = () => {
    const visibleRecordIds = datasource?.map((record: any) => record.work_model?.t_id);
    console.log("e visibleRecordIds", visibleRecordIds, selectedIds, "0", visibleRecordIds && visibleRecordIds.length > 0 && visibleRecordIds.every((id: any) => selectedIds.includes(id)))
    // return visibleRecordIds.every((id: any) => selectedIds.includes(id));
    return (
      visibleRecordIds && visibleRecordIds.length > 0 && visibleRecordIds.every((id: any) => selectedIds.includes(id))
    );
  };

  const deleteRecord = (e: any, item: any) => {
    e.stopPropagation()
    httpClient
      .getInstance()
      .delete(`${baseurl}api/transition/delete/?model_type=${item?.work_model?.model_type}&t_id=${item?.work_model?.t_id}`)
      .then((resp: any) => {
        console.log("resp", resp)
        if (resp.data.status === ResponseStatus?.SUCCESS) {
          message.success(resp.data.data)
        } else {
          message.error(resp.data.data)
        }
      })
  }
  return (

    <div>
      {selectedIds.length > 0 ? props?.fromMenu === "inbox" && props?.recordType === "AW_SIGN" &&
        <div style={{ float: "right", marginTop: "-63px", display: "flex" }}>
          <Button className="actionButtonSaveContainer" onClick={() => onclickRelease("Return")} style={{ marginRight: "10px" }} id="bulkReturn">Bulk Return</Button>
          <Button className="actionButtonSaveContainer" onClick={() => onclickRelease("Release")} id="bulkRelease" >Bulk Release</Button>
        </div> : null}
      <div className='containerTable'>
        <div className="OuterDiv">
          {HeadersData && HeadersData.map((head: any, indx: number) => {
            return (
              <div key={indx} className='HeadInnerDiv'>
                {props?.fromMenu === "inbox" && props?.recordType === "AW_SIGN" ? <h1 className='head' style={{ width: "2%" }}> <Checkbox
                  onChange={HeaderCheckboxChange}
                  checked={

                    areAllRecordsSelectedOnPage()}
                  id={`${props?.fromMenu}_${props?.recordType}_headerCheckbox${indx}${pageNumber}`}
                /></h1> : null}
                <h1 className='head'>{head.Type}</h1>
                <h1 className='head' style={{ width: "8%" }}>{head.Date}</h1>
                <h1 className='head' style={{ width: "23%", textAlign: "center" }}
                >{head.Status}</h1>
                <h1 className='head'>{props?.fromMenu === "sent" || props?.fromMenu === "sent_awap" ? "To" : head.From}</h1>
                <h1 className='head' style={{ width: "7%", marginLeft: "0" }}>{head.Program}</h1>
                <h1 className='head'>{head.Amount}</h1>
                <h1 className='head' style={{ width: "11%", marginLeft: "0" }}>{head.Due}</h1>
              </div>
            )
          })}
        </div>
        {loading || datasource === null || datasource === undefined ? <Spin /> : <div className='BodyOuterDiv'>
          {datasource?.map((record: any, index: number) => {
            return (
              <div key={index}
                className={((props?.fromMenu === "inbox" || props?.fromMenu === "draft") && record.is_read === true) ? "unreadInnerDiv" : 'InnerDiv'}
                onClick={(e: any) => {
                  props?.fromPage === "InboxHistory" ? console.log("history") : OnClickDetails(e, record);
                }}
                style={{
                  border: props?.fromMenu === "inbox" && props?.recordType === "AW_SIGN" && tid.includes(record.id) ? "1px solid red" : "none"
                }}
                id={`${props?.fromMenu}_${props?.recordType}_table${index}${pageNumber}`}>
                {props?.fromMenu === "inbox" && props?.recordType === "AW_SIGN" ? <h5 className='body' style={{ width: "2%" }}>
                  <Checkbox
                    onChange={(e: any) => handleCheckboxChange(e, record)}
                    checked={selectedIds.includes(record.work_model?.t_id)}
                    onClick={event => event.stopPropagation()}
                    id={`${props?.fromMenu}_${props?.recordType}_checkbox${index}${pageNumber}`}
                  />
                </h5>
                  : null}
                <h5 className='body' id={`${props?.fromMenu}_${props?.recordType}_type${index}${pageNumber}`}>
                  {props?.fromMenu === "inbox" &&
                    record?.next_available_transitions &&
                    record?.next_available_transitions?.values.length > 0 ? (
                    <div>
                      <img src={AwaitingIcon} alt="AwaitingIcon" style={{ marginRight: "5px" }} />
                      Awaiting
                    </div>
                  ) : record.type === Type.INVOICEUPLOAD ? (
                    "Invoiceupload"
                  ) :
                    record.type === Type.UPLOAD ? "Invoice"
                      :
                      (record.type === Type.COUNTERPARTY && localStorage.getItem("user") === "Customer") ? ("Onboarding"
                      ) : (
                        record.type === Type.FINANCEREQ ? "Invoice" : record.type
                      )}
                </h5>
                <h5 className='body' id={`${props?.fromMenu}_${props?.recordType}_date${index}${pageNumber}`} style={{ width: "8%" }}>
                  <div>{record.type === Type.FINANCEREQ ? moment(record?.record_datas?.values?.[0]?.fields?.invoice_date).format("DD-MM-YYYY") : moment(record?.record_datas?.values?.[0]?.fields?.created_date).format("DD-MM-YYYY")}</div>
                </h5>
                <h5 className='body' style={{ width: "23%" }}
                  id={`${props?.fromMenu}_${props?.recordType}_status${index}${pageNumber}`}>
                  <div
                    className={"ApprovedStatus"}
                  >
                    {record.action === Action.RETURN ? "RETURNED" : (localStorage.getItem("user") === "Bank" && record.interim_state === InterimState.STB) ? "Await Onboarding"
                      : (record.type === Type.INVOICE && props?.fromMenu === "inbox" && record.interim_state === InterimState.AWAITINGBUYERA) ? "Awaiting approval"
                        : record.interim_state}
                  </div>
                </h5>
                <h5 className='body' id={`${props?.fromMenu}_${props?.recordType}_from/to${index}${pageNumber}`}>
                  <div>
                    {props?.fromMenu === "sent_awap" && props?.fromPage === "InboxHistory"
                      ? record.to_party
                      : props?.fromMenu === "sent_awap"
                        ? record.current_to_party_name
                        : props?.fromMenu === "sent" ?
                          record.to_party === "financing_module" ? record.to_party_name :
                            record.to_party_name ? record.to_party_name
                              : record.current_to_party_name
                          : props?.fromMenu === "draft" ?
                            JSON.parse(loginlocalItems).party
                            : props?.fromMenu === "inbox"
                              ?
                              record.from_party === "financing_module" ?
                                record.current_from_party_name :
                                props?.fromPage === "InboxHistory"
                                  ? record.from_party_name
                                    ? record.from_party_name
                                    : record.current_from_party_name
                                  : record.current_from_party_name
                              : props?.fromMenu === "InboxHistory"
                                ? props?.fromPage === "InboxHistory"
                                  ? record.from_party_name ? record.from_party_name
                                    : record.current_from_party_name ? record.current_from_party_name : "-" : "-"

                                : "-"
                    }
                  </div>
                </h5>
                <h5 className='body' id={`${props?.fromMenu}_${props?.recordType}_programName${index}${pageNumber}`} style={{ width: "7%" }}>
                  <div>
                    {record.record_datas?.values?.[0]?.fields?.program_type === ProgramType.RF ? "RF" : record.record_datas?.values?.[0]?.fields?.program_type === ProgramType.APF ? "APF" : record.type === Type.COUNTERPARTY ? "APF" : "-"}

                  </div>

                </h5>
                <h5 className='body' id={`${props?.fromMenu}_${props?.recordType}_amount${index}${pageNumber}`}>
                  {record
                    ? record.interim_state === InterimState.PARTIALREPAYMENTREQ || record.interim_state === InterimState.PARTIALLYSETTLED ?
                      <CurrencyField currencyvalue={record?.record_datas?.values?.[0]?.fields?.finance_currency} amount={record?.record_datas?.values?.[0]?.fields?.finance_amount} />

                      : record.type === Type.COUNTERPARTY
                        ? record?.pairings?.[0]?.max_invoice_currency_id ?
                          <CurrencyField currencyvalue={record.pairings?.[0]?.max_invoice_currency_id} amount={record.pairings?.[0]?.max_limit_amount} />
                          : "-"
                        : record.type === Type.INVOICEUPLOAD
                          ?
                          <CurrencyField currencyvalue={record.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceCurrency} amount={record.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceAmount} />


                          : record.type === Type.PROGRAM
                            ? record.record_datas
                              ? record.record_datas?.values
                                ? record.record_datas?.values?.[0]?.fields?.limit_currency ?
                                  <CurrencyField currencyvalue={record.record_datas.values?.[0]?.fields?.limit_currency} amount={record.record_datas.values?.[0]?.fields?.max_limit_amount} />
                                  : ""
                                :
                                <CurrencyField currencyvalue={record.record_datas.limit_currency} amount={record.record_datas.max_limit_amount} />
                              : "-"
                            : record.type === Type.INVOICE ?
                              <CurrencyField currencyvalue={record.record_datas?.values?.[0]?.fields?.invoice_currency} amount={record.record_datas?.values?.[0]?.fields?.amount} />
                              :
                              record.type === Type.FINANCERQ || record.type === Type.FINANCEREQ ?
                                <CurrencyField currencyvalue={record.record_datas?.values?.[0]?.fields?.finance_currency} amount={record.record_datas?.values?.[0]?.fields?.finance_amount} />
                                : record.type === Type.FINANCEREPAYMENT || record.type === Type.INVOICEREPAYMENT ?
                                  <CurrencyField currencyvalue={record.record_datas?.values?.[0]?.fields?.invoice_currency} amount={record.record_datas?.values?.[0]?.fields?.repayment_amount} />
                                  : "-"
                    : ""
                  }
                </h5>
                <h5 className='body' id={`${props?.fromMenu}_${props?.recordType}_dueDate${index}${pageNumber}`} style={{ display: "flex", whiteSpace: "nowrap", width: "11%" }}>
                  <div style={{ paddingRight: "28px" }}>
                    {record
                      ? record.type === Type.COUNTERPARTY
                        ? moment(record.pairings?.[0]?.expiry_date).format("DD-MM-YYYY")
                        : record.type === Type.INVOICEUPLOAD
                          ? record.record_datas?.values?.[0]?.fields?.invoices?.[0]?.DueDate
                          : record.type === Type.PROGRAM
                            ? record.record_datas
                              ? props?.fromPage === "InboxHistory"
                                ?
                                moment(record.record_datas?.values?.[0]?.fields?.expiry_date
                                ).format("DD-MM-YYYY")
                                :
                                moment(
                                  record.record_datas?.values?.[0]?.fields?.expiry_date
                                ).format("DD-MM-YYYY")
                              : "-"
                            : record.type === Type.INVOICE || record.type === Type.FINANCERQ || record.type === Type.FINANCEREQ || record.type === Type.FINANCEREPAYMENT || record.type === Type.INVOICEREPAYMENT
                              ? moment(record.record_datas?.values?.[0]?.fields?.due_date).format("DD-MM-YYYY")

                              // :
                              // record.type === Type.FINANCERQ || record.type === Type.FINANCEREQ ?
                              //   moment(record.record_datas?.values?.[0]?.fields?.due_date).format("DD-MM-YYYY")
                              // :
                              // record.type === Type.FINANCEREPAYMENT || record.type === Type.INVOICEREPAYMENT ?
                              //   moment(record.record_datas?.values?.[0]?.fields?.due_date).format("DD-MM-YYYY")

                              : "-"
                      : ""}
                  </div>
                  <span > {record.type === Type.PROGRAM ?
                    record?.attachments?.program_attachments?.[0] ?
                      <a href={`${imageBaseurl}${record?.attachments?.program_attachments?.[0].file_path}`} target="_blank" style={{ color: '#006666' }} > <img src={FileAttachIcon} alt="fileAttach" id={`${props?.fromMenu}_${props?.recordType}_attachments${index}`} /> </a>
                      : "" :
                    record.type === Type.INVOICEUPLOAD ?

                      record?.attachments?.file?.[0] ?
                        <a href={`${imageBaseurl}${record?.attachments?.file?.[0].file_path}`} target="_blank" style={{ color: '#006666' }}> <img src={FileAttachIcon} alt="fileAttach" id={`${props?.fromMenu}_${props?.recordType}_attachments${index}`} /> </a>
                        : ""
                      : ""
                  }</span>
                </h5>
                {props?.fromMenu === "draft" ?
                  <h5>
                    <img src={DeleteIcon} alt="deleteIcon" onClick={(e) => deleteRecord(e, record)} />
                  </h5>
                  : ""}
              </div>
            )

          })}
        </div>
        }
      </div>
      {props?.fromPage === "InboxHistory" ? "" : datasource?.length > 0 ?
        <Pagination
          pageSize={PageSizeCount}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          defaultCurrent={pageNumber}
          current={pageNumber}
          total={paginationTotalCount}
          responsive={true}
          onChange={onChange}
        />
        : null}

    </div>
  );
};
export default TableComponent;