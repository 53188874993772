import { useState, useEffect } from "react";
import { Input, Select, DatePicker, Checkbox, InputNumber,Row,Col } from "antd";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import moment from "moment";
import { UploadImage } from "../../../common/UploadImage/manualInvoiceUpload";
import images from "../../../../assets/images";
import { useNavigate } from "react-router";
import { ResponseStatus } from "../../../../utils/enum/choices";

const Header_APF_Seller = [{
  Name: "Buyer Name", InvNo: "Inv No", InvDate: "Inv Date", InvAmt: "Inv Amount", DueDate: "Due Date", FinanceAmount: "Finance Amount", FinanceCurrency: "Financing Currency", SettlementCurrency: "Settlement Currency", autoFinance: "Auto Finance", attached: "Attached"
}]
const ApfSeller = ({ invoice_detail, getApfSellerdataSource, getApfSellercounterPartyList,setFileLists, commentsData }: any) => {
  const { TextArea } = Input;
  const Navigate = useNavigate()

  const { Option } = Select;
  const { DeleteIcon, DatePickerImg, DropdownIcon,ErrorImage } = images
  const [buyerId, setBuyerId] = useState("");
  const [programUser, setProgramUser] = useState([] as any);
  const [counterpartyId, setCounterpartyId] = useState([] as any);
  const [MaxInvoicePercent, setMaxInvoicePercent] = useState("");
  const [counterPartyList, setCounterPartyList] = useState([] as any);
  const [buyerName, setBuyerName] = useState([] as any);
  const [currencyList, SetCurrencyList] = useState([]);
  const [FinanceAmountError, setFinanceAmountError] = useState("")

  const [apfSellerdataSource, setApfSellerDataSource] = useState([
    {
      BuyerID: buyerId,
      BuyerName: "" as any,
      InvoiceNo: "" as any,
      InvoiceDate: "" as any,
      InvoiceCurrency: "" as any,
      InvoiceAmount: "" as any,
      DueDate: "" as any,
      FinancingCurrency: "" as any,
      SettlementCurrency: "" as any,
      program_user: programUser as any,
      counterparty_id: counterpartyId as any,
      auto_finance: localStorage.getItem("autofinance") === "true" ? true : false as any,
      FinanceAmount: 0 as any,
      ProgramID: invoice_detail?.program_id
    },
  ]);
  getApfSellerdataSource(apfSellerdataSource)
  getApfSellercounterPartyList(counterPartyList)

  const apfSellerAppendData = () => {
    const newInput = {
      BuyerID: buyerId,
      BuyerName: "",
      InvoiceNo: "",
      InvoiceDate: "",
      InvoiceAmount: 0,
      InvoiceCurrency: "",
      DueDate: 0,
      FinancingCurrency: 0,
      SettlementCurrency: 0,
      program_user: programUser,
      counterparty_id: counterpartyId,
      auto_finance: localStorage.getItem("autofinance") === "true" ? true : false as any,
      FinanceAmount: 0,
      ProgramID: invoice_detail?.program_id
    } as any;
    setApfSellerDataSource([...apfSellerdataSource, newInput]);

  }
  const selectedFiles = (value: any) => {
    setFileLists(value)
  }
  useEffect(() => {
    httpClient
      .getInstance()
      .get(`${baseurl}api/pairing/?pg_id=${invoice_detail.program_id}&data_type=INVOICE`)
      .then((resp: any) => {
        setCounterPartyList(resp.data.data);
        const partyName = [] as any;
        resp.data.data.map((items: any) => {
          return partyName.push(items.buyer_name);
        });
        setBuyerName(partyName);

        setCounterPartyList(resp.data.data);
      })
      .catch(() => {
        Navigate("/Notfound")
      })

    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        SetCurrencyList(resp.data.data);
      })
      .catch(() => {
        Navigate("/Notfound")
      })

  }, [])
  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };
  const disabledInvoiceDate = (current: any) => {
    return current && current > moment().endOf("day");
  }

  // useEffect(() => {
  //   TotalFinanceAmountCalculation()
  //   PartyFinanceAmountCalculation()
  // }, [apfSellerdataSource])


  const totalFinanceAmount = apfSellerdataSource.reduce((accumulator, data) => {
    // Use the unary plus (+) to convert string to number if FinanceAmount is in string format
    const financeAmount = +data?.FinanceAmount || 0;

    // Add the financeAmount to the accumulator
    return accumulator + financeAmount;
  }, 0);

  console.log("totalFinanceAmount",totalFinanceAmount)
  const TotalFinanceAmountCalculation = () => {
    // apfBuyerdataSource.map((data: any) => {
    // console.log("finance amt total",data?.FinanceAmount,"totalFinanceAmount",totalFinanceAmount,totalFinanceAmount.toFixed(2))
    // })
    httpClient
      .getInstance()
      .get(`${baseurl}api/program/${invoice_detail?.program_id}/?finance_amount=${totalFinanceAmount.toFixed(2)}`)
      .then((resp: any) => {
        console.log("resp", resp)
        if (resp.data.status === ResponseStatus?.FAILURE) {
          setFinanceAmountError(resp.data.data)
        } else {
          setFinanceAmountError("")
        }
      })
  }

  const counterPartyTotals: Record<string, number> = {};
  let finamt: any = {};
  counterPartyList.forEach((counterPartyItem: any) => {
    // Initialize total amount for the counterparty
    console.log("counterPartyItem", counterPartyItem)
    console.log("counterPartyItem", counterPartyItem?.id)
    counterPartyTotals[counterPartyItem?.counterparty_uid] = 0;
    finamt = 0;

    apfSellerdataSource.forEach((data: any) => {
      if (counterPartyItem?.counterparty_uid === data?.SellerID) {
        // Use the unary plus (+) to convert string to number if FinanceAmount is in string format
        const financeAmount = +data?.FinanceAmount || 0;

        // Add financeAmount to the total for the counterparty
        counterPartyTotals[counterPartyItem?.counterparty_uid] += financeAmount;
        finamt += financeAmount;
        console.log("Counterparty Totals: 22", counterPartyTotals, "finamt", finamt, "counterPartyTotals", counterPartyTotals?.cp1s);

        // httpClient
        // .getInstance()
        // .get(`${baseurl}api/pairing/${counterPartyItem?.id}/?finance_amount=${totalFinanceAmount.toFixed(2)}`)
        // .then((resp:any)=>{
        //   console.log("resp",resp)
        //   if(resp.data.status === ResponseStatus?.FAILURE){
        //     setFinanceAmountError(resp.data.data)
        //   }else{
        //     setFinanceAmountError("")
        //   }
        // })


      }
    });
    console.log("Counterparty Totals: 11", counterPartyTotals, "finamt", finamt);


  });
  console.log("Counterparty Totals:", counterPartyTotals, "finamt", finamt);

  const transformedData = Object.entries(counterPartyTotals).map(([key, value]) => ({
    key: key,
    value: value
  }));
  

  const PartyFinanceAmountCalculation = () => {
    console.log("invoice_detail party", invoice_detail, "apfBuyerdataSource", apfSellerdataSource, "counterPartyList", counterPartyList)


    // counterPartyList.map((item: any) => {
    //   apfSellerdataSource.map((data: any) => {
    //     if (item?.counterparty_uid === data?.SellerID) {
    //       console.log("invoice_detail party map", data?.FinanceAmount)
    //     }

    //   })
    // })
    counterPartyList.map((partyitem: any) => {
      apfSellerdataSource.map((data: any) => {
console.log("partyitem",partyitem)
        console.log("data",data?.SellerID === counterPartyTotals[0])

        transformedData.map((item:any)=>{
          console.log("counterPartyTotals",data?.SellerID === item.key)
          console.log("data",data)
              if(data?.SellerID === item.key && partyitem.counterparty_uid === item.key){
                console.log("if value",item.value)
                httpClient
                .getInstance()
                .get(`${baseurl}api/pairing/${partyitem?.id}/?finance_amount=${item.value.toFixed(2)}`)
                .then((resp: any) => {
                  console.log("resp", resp)
                  if (resp.data.status === ResponseStatus?.FAILURE) {
                    setFinanceAmountError(resp.data.data)
                  } else {
                    setFinanceAmountError("")
                  }
                })
              }
            })

        // const amt = []
        // if (item?.counterparty_uid === data?.SellerID) {
        //   console.log("invoice_detail party map", data?.FinanceAmount)
        //   amt.push(data?.FinanceAmount)
        // }
        // console.log("amt",amt)
       

      })
    })

    TotalFinanceAmountCalculation()

  }

  const invoiceNumberApiCall = (data: any) => {
    
    const apiEndpoint = `${baseurl}api/invoice/upload/?invoice_no=${data?.InvoiceNo}&party=${data?.BuyerName}&program_type=${invoice_detail?.program_type}`;
    const body = {
      counter_party:data?.BuyerName,
      program_type:invoice_detail?.program_type,
    invoice_no:data?.InvoiceNo
    }
    httpClient
      .getInstance()
      .post(`${baseurl}api/invoice/upload/check/`,body)
      .then((resp: any) => {
        if (resp?.data?.status === ResponseStatus?.FAILURE) {
          // Update error state for the specific row
          setApfSellerDataSource((prevData) =>
            prevData.map((item) =>
              item.InvoiceNo === data.InvoiceNo ? { ...item, error: resp?.data?.data } : item
            )
          );
          console.log("error3")
        } else {
          console.log("error4")
          // Clear the error state for the specific row if the API call is successful
          setApfSellerDataSource((prevData) =>
            prevData.map((item) =>
              item.InvoiceNo === data.InvoiceNo ? { ...item, error: '' } : item
            )
          );
        }
      });
  };

  return (
    <div>
{
          FinanceAmountError ?

            <Row gutter={24} style={{ width: "80%", backgroundColor: "#FDF1F1", borderLeft: "5px solid #E43E3E", padding: "10px", margin: "0" }}>
              <Col span={2} style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                <img src={ErrorImage} alt="errorImage" width="35px" />
              </Col>
              <Col span={20}>
                {/* <div style={{ color: "black", fontSize: "16px", fontFamily: "Poppins", fontWeight: 500, paddingBottom: "10px" }}> Automatic Finance Rejection: Please review error details</div> */}

                {FinanceAmountError}
              </Col>
            </Row>
            : ""}

      <div className='containerTable'>
        <div className="OuterDiv">
          {

            Header_APF_Seller && Header_APF_Seller.map((item: any, index: any) => {
              return (
                <div key={index} className='HeadInnerDiv'>
                  <h1 className='head' style={{ width: "10%" }}>{item.Name}</h1>
                  <h1 className='head' style={{ width: "10%" }}>{item.InvNo}</h1>
                  <h1 className='head' style={{ width: "10%" }}>{item.InvDate}</h1>
                  <h1 className='head' style={{ width: "20%" }}>{item.InvAmt}</h1>
                  <h1 className='head' style={{ width: "10%" }}>{item.DueDate}</h1>
                  <h1 className='head' style={{ width: "20%" }}>{item.FinanceAmount}</h1>
                  <h1 className='head' style={{ width: "10%" }}>{item.autoFinance}</h1>
                  <h1 className='head' style={{ width: "6%" }}>{item.attached}</h1>
                </div>
              )
            })

          }
        </div>
        <div className='OuterDiv'>
          {
            apfSellerdataSource && apfSellerdataSource.map((record: any, index: number) => {
              return (
                <div key={index} className='InnerDiv' >
                  <h5 className='body' style={{ width: "10%" }} id={`invApfSeller_buyerId${index}`}>
                    {buyerName.length !== 1 ?
                      <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue=""
                        style={{
                          width: 100,
                        }}
                        suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}

                        onSelect={(event: string) => {
                          const array = [...apfSellerdataSource];
                          array[index].BuyerName = event;
                          setApfSellerDataSource(array);
                          const names: any[] = [];
                          let values: any;
                          counterPartyList.map((data: any) => {
                            const array = apfSellerdataSource;
                            if (event === data.buyer_name) {
                              return (
                                (values = {
                                  id: data.id,
                                  name: data.buyer_name,
                                }),
                                names.push(values),
                                setBuyerId(data.id),
                                setProgramUser(data.program_user),
                                setCounterpartyId(data.counterparty_id),
                                setMaxInvoicePercent(data.max_invoice_percent),
                                (array[index].BuyerID = data.id),
                                (array[index].BuyerName = data.buyer_name),
                                (array[index].program_user = data.program_user),
                                (array[index].counterparty_id = data.counterparty_id),
                                setApfSellerDataSource(array)
                              );
                            }
                            return true;
                          });
                        }}
                      >
                        {buyerName.map((partyList: any, index: any) => {
                          return (
                            <Option value={partyList} key={partyList} id={`invApfSeller_${partyList}${index}`}>
                              {partyList}
                            </Option>
                          );
                        })}
                      </Select>
                      : <div id={`invApfSeller_${counterPartyList?.[0]?.buyer_name}${index}`}>
                        {counterPartyList[0].buyer_name}</div>}
                  </h5>
                  <h5 className='body' style={{ width: "10%" }}>
                    <div >
                      <Input
                        id={`invApfSeller_InvoiceNumber${index}`}
                        className="InputContainer"
                        onChange={(event: any) => {
                          const array = apfSellerdataSource;
                          array[index].InvoiceNo = event.target.value;
                          if (buyerName.length === 1) {
                            array[index].BuyerID = counterPartyList?.[0]?.id;
                            array[index].BuyerName = counterPartyList?.[0]?.buyer_name;
                            array[index].program_user = counterPartyList?.[0]?.program_user;
                            array[index].counterparty_id = counterPartyList?.[0]?.counterparty_id;
                            setMaxInvoicePercent(counterPartyList?.[0]?.max_invoice_percent)

                          }
                          setApfSellerDataSource(array);
                        }}
                        onBlur={()=> invoiceNumberApiCall(record)}

                      />
                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "10%" }}>

                    <DatePicker
                      id={`invApfSeller_InvoiceDate${index}`}
                      format="DD-MM-YYYY"
                      suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}

                      onChange={(event: any) => {
                        const array = apfSellerdataSource;
                        array[index].InvoiceDate = moment(event._d).format("DD-MM-YYYY");
                        setApfSellerDataSource(array);
                      }}
                      style={{ width: 100 }}
                      className="InputContainer"
                      disabledDate={disabledInvoiceDate}
                    />

                  </h5>

                  <h5 className='body' style={{ width: "20%" }} id={`invApfSeller_Invoice${index}`}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ paddingRight: "10px" }}>
                        <Select
                          showSearch
                          style={{ width: 100 }}
                          onSelect={(event: string) => {
                            const array = [...apfSellerdataSource];
                            array[index].InvoiceCurrency = event;
                            array[index].FinancingCurrency = event;
                            setApfSellerDataSource(array);
                          }}
                          suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}

                          id={`invApfSeller_InvoiceCurrency${index}`}
                        >
                          {currencyList &&
                            currencyList.map((item: any) => {
                              return (
                                <Option
                                  value={item.description}
                                  id={item.description}
                                  key={item.description}
                                >
                                  {item.description}
                                </Option>
                              );
                            })}
                        </Select>
                      </span>

                      <InputNumber
                        className="InputContainer"
                        disabled={!record.BuyerName}
                        id={`invApfSeller_InvoiceAmount${index}`}
                        onChange={(event: any) => {
                          const array = [...apfSellerdataSource];
                          array[index].InvoiceAmount = parseInt(event);
                          setApfSellerDataSource(array);
                          array[index].FinanceAmount = parseInt(event) * (parseInt(MaxInvoicePercent) / 100)

                        }}
                        formatter={(value, e) => {
                          if (value === "" || isNaN(parseFloat(value))) {
                            return "";
                          }
                          return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                            : value
                        }}
                        onBlur={PartyFinanceAmountCalculation}
                      />
                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "10%" }}>
                    <div>
                      <DatePicker
                        disabledDate={disabledDate}
                        format="DD-MM-YYYY"
                        suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}
                        id={`invApfSeller_DueDate${index}`}
                        onChange={(event: any) => {
                          const array = apfSellerdataSource;
                          array[index].DueDate = moment(event._d).format("DD-MM-YYYY");
                          setApfSellerDataSource(array);
                        }}
                        style={{ width: 100 }}
                        className="InputContainer"
                      />
                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "20%" }} id={`invApfSeller_Finance${index}`}>
                    <div style={{ display: "flex" }}>
                      <span id={`invApfSeller_FinanceCurrency${index}`} style={{ marginRight: "10px", border: "1px solid #d9d9d9", width: "50%", height: " 45px", borderRadius: "10px", padding: "9px", backgroundColor: "#FFFFFF" }}>
                        {record.InvoiceCurrency}
                      </span>
                      <div style={{ display: "block" }}>
                        <InputNumber
                          className="InputContainer"
                          style={{ width: "80%" }}
                          id={`invApfSeller_FinanceAmount${index}`}
                          defaultValue={record.FinanceAmount}
                          value={(record.FinanceAmount).toFixed(2)}
                          disabled={!record.InvoiceAmount}
                          onChange={(event: any) => {
                            const arr = [...apfSellerdataSource];
                            arr[index].FinanceAmount = parseInt(event)
                            setApfSellerDataSource(arr);
                          }}
                          formatter={(value, e) => {
                            if (value === "" || isNaN(parseFloat(value))) {
                              return "";
                            }
                            return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                              : value
                          }}
                          onBlur={PartyFinanceAmountCalculation}
                        />
                      </div>
                    </div>
                  </h5>

                  <h5 className='body' style={{ width: "10%" }}>
                    <div>
                      <Checkbox id={`invApfSeller_AutoFinance${index}`} onChange={(event) => {
                        const array = apfSellerdataSource;
                        array[index].auto_finance = event.target.checked;
                        setApfSellerDataSource(array);
                      }
                      }
                        defaultChecked={localStorage.getItem("autofinance") === "true" ? true : false}
                      />
                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "6%" }} id={`invApfSeller_attachments${index}`}>
                    <UploadImage selectedFiles={selectedFiles} attachmentFiles={[]} />
                  </h5>
                  <h5 >
                    <img src={DeleteIcon} alt="deleteIcon" id={`invApfSeller_delete${index}`}
                    />
                  </h5>
                </div>
              )
            })


          }
        </div>

      </div>
      <div
        onClick={apfSellerAppendData}
        style={{
          position: "absolute",
          right: "5%",
          padding: "0px 8px",
          backgroundColor: "#FFB800",
          color: "#FFFFFF",
          marginTop: "10px"
        }}
        id="addInvoice"
      >
        +
      </div>
      <div className="SummaryContainer" style={{ marginTop: "5%" }}>
        <div className="SummaryLabel">Comments</div>
        <TextArea
          style={{ width: "50%", margin: "0px 10px" }}
          onChange={(e: any) => commentsData(e.target.value)}
          id={`inv_comments`}
        />
      </div>

    </div>
  )
}
export default ApfSeller