import { useState, useEffect, useRef } from "react";
import { Tag, Select, Spin, Input, DatePicker, Card, Pagination, Button, message, Modal, Form } from "antd";
import httpClient from "../../utils/config/core/httpClient";
import baseurl from "../../utils/config/url/base";
import "./enquiry.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import images from "../../assets/images";
import { ResponseStatus, Type } from "../../utils/enum/choices";
import Heading from "../common/heading/heading";
import * as pako from 'pako';
import socketurl from "../../utils/config/url/socket";
import { socketInfoDetails, pageSizeDetails, socketConnected } from "../../redux/action";

const Enquiry = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { ScrollLeftArrow, RightArrow, Search } = images
  const [currencyList, setCurrencyList] = useState([]);
  const Navigator = useNavigate();
  const dispatch = useDispatch();
  const socketConnection = useSelector(socketConnected)
  const containerRef: any = useRef(null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filterTags, setFilterTags]: any = useState([]);
  const [TableData, setTableData] = useState([])
  const pageSizeDatas = useSelector(pageSizeDetails);
  const PageSizeCount = pageSizeDatas?.payload?.pageSizeData
  const [pageNumber, setpageNumber] = useState(1)
  const [loading, setLoading] = useState(true)
  const ws = useRef(null) as any;
  const [isPaused, setPause] = useState(false);
  const [pageCount, setPagecount] = useState(0)
  const [dataSource, setDataSource] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const [searchWord, setSearchWord] = useState<string | any>(null);
  const loginData = localStorage.getItem("login_detail") || "";
  const logindata = JSON.parse(loginData);

  const [invoiceNo, setInvoiceNo] = useState("" as any);
  const [TypefilteredData, setTypefilteredData] = useState(null)
  const [ProgramTypefilteredData, setProgramTypefilteredData] = useState(null)
  const [CurrencyfilteredData, setCurrencyfilteredData] = useState(null)
  const [CounterpartyfilteredData, setCounterpartyfilteredData] = useState(null)
  const [TransactionDatefilteredData, setTransactionDatefilteredData] = useState(null as any)
  const [DueDatefilteredData, setDueDatefilteredData] = useState(null as any)
  const [FinanceDueDateFileteredData, setFinanceDueDateFilteredData] = useState(null as any);
  const [fromAmountfilteredData, setFromAmountfilteredData] = useState(null as any)
  const [toAmountfilteredData, setToAmountfilteredData] = useState(null as any);
  const [amountfilterData, setAmountfilterData] = useState({ from: null, to: null } as any)
  const [clearTransactionDate, setClearTransactionDate] = useState([] as any);
  const [clearDueDate, setClearDueDate] = useState([] as any);
  const [clearFinanceDueDate, setClearFinanceDueDate] = useState([] as any);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 250;
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 250;
    }
  };

  useEffect(() => {
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        setCurrencyList(resp.data.data);
      });
    getEnquiry()
    setIsLoading(false)
  }, []);

  const forMap = (tag: any) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e: any) => {
          e.preventDefault();
          handleCloseTag(tag);
        }}
        className="tagStyle"
      >
        <span style={{ fontWeight: 'bold' }}>{tag.label}</span>: {tag?.value}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block', padding: 4 }}>
        {tagElem}
      </span>
    );
  };

  const tagChild = filterTags.map(forMap);

  const tagCondition = tagChild?.length >= 3;

  const handleCloseTag = (removedTag: any) => {
    const filteredTags = filterTags?.filter((tag: any) => tag.label !== removedTag.label);
    console.log(filteredTags, 'FT')
    setFilterTags(filteredTags)
    switch (removedTag?.label) {
      case 'Program':
        setProgramTypefilteredData(null);
        SocketFilter('filterPro', null);
        break;
      case 'Type':
        setTypefilteredData(null);
        SocketFilter('filterType', null);
        break;
      case 'Currency':
        setCurrencyfilteredData(null);
        SocketFilter('filterCur', null);
        break;
      case 'Counterparty':
        setCounterpartyfilteredData(null);
        SocketFilter('filterCP', null);
        break;
      case 'Amount (From)':
        setAmountfilterData({ from: 0, to: toAmountfilteredData });
        setFromAmountfilteredData(0);
        SocketFilter('filterAF', 0);
        break;
      case 'Amount (To)':
        setAmountfilterData({ from: fromAmountfilteredData, to: 0 });
        setToAmountfilteredData(0);
        SocketFilter('filterTF', 0);
        break;
      case 'Transaction Date':
        setTransactionDatefilteredData(null);
        setClearTransactionDate([]);
        SocketFilter('filterTD', null);
        break;
      case 'Due Date/Expire':
        setDueDatefilteredData(null);
        setClearDueDate([]);
        SocketFilter('filterDD', null);
        break;
      case 'Finance Due Date':
        setFinanceDueDateFilteredData(null);
        setClearFinanceDueDate([]);
        SocketFilter('filterFDD', null);
        break;
      case 'Invoice No':
        setInvoiceNo(null);
        SocketFilter('filterIN', null);
        break;
      default:
      // setFilterTags([])
    }
    console.log(removedTag, 'RT')
    // const filteredTags = filterTags?.filter((tag: any) => tag !== removedTag);
  }

  const getEnquiry = () => {
    const body = {
      page_number: pageNumber,
      page_size: 5,
      filter: {
        "model_type": null,
        "program_type": null,
        "transaction_date": null,
        "due_date": null,
        "currency": null,
        "counterparty": null,
        "amount": null,
        "finance_due_date": null,
        "invoice_no": null,
      },
      search: null
    }
    httpClient
      .getInstance()
      .post(`${baseurl}api/message/enquiry/`, body)
      // &filter={model_type:null,program_type:null,transaction_date:null,due_date:null,currency:null,counterparty:null,amount:null}`)
      .then((resp: any) => {
        // console.log("enquiry resp", resp?.data)
        if (resp.data.status === ResponseStatus?.SUCCESS) {

          setDataSource(resp.data?.response?.filter)
          setTableData(resp?.data?.response?.data)
          setPagecount(resp?.data?.response?.count)
        }
        else {
          message.error(resp.data.data)
        }
        setLoading(false)
      })
  }

  // useEffect(() => {
  //   ws.current = socketurl;
  //   const data = {
  //     "party_id": logindata.party_id,
  //     "user_id": logindata.user_id,
  //     "record_type":
  //     {
  //       "inbox":
  //       {
  //         type: "ALL",
  //         data: false
  //       },
  //       "sent":
  //       {
  //         type: "ALL",
  //         data: false
  //       },
  //       "sent_aw": {
  //         type: "ALL",
  //         data: false
  //       },
  //       "enquiry": {
  //         "data": true,
  // "filter": {
  //   "model_type": null,
  //   "program_type": null,
  //   "transaction_date": null,
  //   "due_date": null,
  //   "currency": null,
  //   "counterparty": null,
  //   "amount": null
  // },
  // "search": null
  //       },


  //       "draft": false,
  //       "history": false

  //     },
  //     "data_type": "MESSAGE",
  //     "page_size": 5,
  //     "page_number": pageNumber

  //   }

  //   if (socketConnection?.payload?.socketConnectData === "connected") {
  //     ws.current.send(JSON.stringify(data));
  //     ws.current.onmessage = (e: any) => {
  //       const message = JSON.parse(e.data);
  //       const compressedDataArray = message

  //       const compressedData = new Uint8Array(compressedDataArray);
  //       const decompressedData = pako.inflate(compressedData, { to: 'string' });
  //       const decompressedMessageData = JSON.parse(decompressedData);
  //       dispatch(
  //         socketInfoDetails(decompressedMessageData)
  //       )
  //       setDataSource(decompressedMessageData?.message_data?.enquiry)
  //       setTableData(decompressedMessageData?.message_data?.enquiry?.data)
  //       setPagecount(decompressedMessageData?.message_data?.enquiry?.overall_count)

  //       setTimeout(() => {
  //         setIsLoading(false)
  //         setLoading(false)
  //       }, 2000)

  //     }

  //   } else {
  //     ws.current.onopen = () => {
  //       ws.current.send(JSON.stringify(data));
  //       ws.current.onmessage = (e: any) => {
  //         const message = JSON.parse(e.data);
  //         const compressedDataArray = message

  //         const compressedData = new Uint8Array(compressedDataArray);
  //         const decompressedData = pako.inflate(compressedData, { to: 'string' });
  //         const decompressedMessageData = JSON.parse(decompressedData);
  //         dispatch(
  //           socketInfoDetails(decompressedMessageData)
  //         )
  //         setDataSource(decompressedMessageData?.message_data?.enquiry)
  //         setTableData(decompressedMessageData?.message_data?.enquiry?.data)
  //         setPagecount(decompressedMessageData?.message_data?.enquiry?.overall_count)

  //         setTimeout(() => {
  //           setIsLoading(false)
  //           setLoading(false)
  //         }, 2000)

  //       }
  //     }
  //   }

  // }, []);

  const enquiryDetail = (data: any) => {
    Navigator("/EnquiryDetail", { state: data });
  };

  const SocketClearFilter = (type: string, value: any) => {
    const body = {
      page_number: pageNumber,
      page_size: 5,
      filter: {
        "model_type": type === "type" ? value === undefined || value === null ? null : TypefilteredData : TypefilteredData,
        "program_type": type === "program" ? value === undefined || value === null ? null : ProgramTypefilteredData : ProgramTypefilteredData,
        "transaction_date": type === "transactionDate" ? value === undefined || value === null ? null : TransactionDatefilteredData : TransactionDatefilteredData,
        "due_date": type === "dueDate" ? value === undefined || value === null ? null : DueDatefilteredData : DueDatefilteredData,
        "currency": type === "currency" ? value === undefined || value === null ? null : CurrencyfilteredData : CurrencyfilteredData,
        "counterparty": type === "counterparty" ? value === undefined || value === null ? null : CounterpartyfilteredData : CounterpartyfilteredData,
        "amount": type === "amount" ? value === undefined || value === null || value === "" ? null : amountfilterData : amountfilterData
      },
      search: type === "search" ? value === "" || value === null ? null : value ? value : searchWord ? searchWord === "" ? null : searchWord : searchWord : searchWord,


    }

    httpClient
      .getInstance()
      .post(`${baseurl}api/message/enquiry/`, body)
      .then((resp: any) => {
        console.log("enquiry resp", resp?.data);
        if (resp.data.status === ResponseStatus?.SUCCESS) {

          setDataSource(resp.data?.response?.filter)
          setTableData(resp?.data?.response?.data)
          setPagecount(resp?.data?.response?.count)
          setLoading(false);
        } else {
          message.error(resp.data.data)
        }
      })
      .catch((error: any) => {
        console.error('Error:', error);
      });

    // ws.current = socketurl;
    // const data = {
    //   "party_id": logindata.party_id,
    //   "user_id": logindata.user_id,
    //   "record_type":
    //   {
    //     "inbox":
    //     {
    //       type: "ALL",
    //       data: false
    //     },
    //     "sent":
    //     {
    //       type: "ALL",
    //       data: false
    //     },
    //     "sent_aw": {
    //       type: "ALL",
    //       data: false
    //     },
    //     "enquiry": {
    //       "data": true,
    //       "filter": {
    //         "model_type": type === "type" ? value === undefined || value === null ? null : TypefilteredData : TypefilteredData,
    //         "program_type": type === "program" ? value === undefined || value === null ? null : ProgramTypefilteredData : ProgramTypefilteredData,
    //         "transaction_date": type === "transactionDate" ? value === undefined || value === null ? null : TransactionDatefilteredData : TransactionDatefilteredData,
    //         "due_date": type === "dueDate" ? value === undefined || value === null ? null : DueDatefilteredData : DueDatefilteredData,
    //         "currency": type === "currency" ? value === undefined || value === null ? null : CurrencyfilteredData : CurrencyfilteredData,
    //         "counterparty": type === "counterparty" ? value === undefined || value === null ? null : CounterpartyfilteredData : CounterpartyfilteredData,
    //         "amount": type === "amount" ? value === undefined || value === null || value === "" ? null : AmountfilteredData : AmountfilteredData
    //       },
    //       "search": type === "search" ? value === "" || value === null ? null : value ? value : searchWord ? searchWord === "" ? null : searchWord : searchWord : searchWord,

    //     },


    //     "draft": false,
    //     "history": false

    //   },
    //   "data_type": "MESSAGE",
    //   "page_size": 5,
    //   "page_number": pageNumber

    // }
    // if (socketConnection?.payload?.socketConnectData === "connected") {
    //   ws.current.send(JSON.stringify(data));
    //   ws.current.onmessage = (e: any) => {
    //     const message = JSON.parse(e.data);
    //     const compressedDataArray = message

    //     const compressedData = new Uint8Array(compressedDataArray);
    //     const decompressedData = pako.inflate(compressedData, { to: 'string' });
    //     const decompressedMessageData = JSON.parse(decompressedData);
    //     dispatch(
    //       socketInfoDetails(decompressedMessageData)
    //     )
    //     setDataSource(decompressedMessageData?.message_data?.enquiry)
    //     setTableData(decompressedMessageData?.message_data?.enquiry?.data)
    //     setPagecount(decompressedMessageData?.message_data?.enquiry?.overall_count)

    //     setTimeout(() => {
    //       setIsLoading(false)
    //       setLoading(false)
    //     }, 2000)

    //   }

    // } else {
    //   ws.current.onopen = () => {
    //     ws.current.send(JSON.stringify(data));
    //     ws.current.onmessage = (e: any) => {
    //       const message = JSON.parse(e.data);
    //       const compressedDataArray = message

    //       const compressedData = new Uint8Array(compressedDataArray);
    //       const decompressedData = pako.inflate(compressedData, { to: 'string' });
    //       const decompressedMessageData = JSON.parse(decompressedData);
    //       dispatch(
    //         socketInfoDetails(decompressedMessageData)
    //       )
    //       setDataSource(decompressedMessageData?.message_data?.enquiry)
    //       setTableData(decompressedMessageData?.message_data?.enquiry?.data)
    //       setPagecount(decompressedMessageData?.message_data?.enquiry?.overall_count)

    //       setTimeout(() => {
    //         setIsLoading(false)
    //         setLoading(false)
    //       }, 2000)

    //     }
    //   }
    // }
  }
  const SocketFilter = (type: string, value: any) => {
    // const body ={
    //   "model_type": type === "type" ? value : TypefilteredData,
    //   "program_type": type === "program" ? value : ProgramTypefilteredData,
    //   "transaction_date": type === "transactionDate" ? value : TransactionDatefilteredData,
    //   "due_date": type === "dueDate" ? value : DueDatefilteredData,
    //   "currency": type === "currency" ? value : CurrencyfilteredData,
    //   "counterparty": type === "counterparty" ? value : CounterpartyfilteredData,
    //   "amount": type === "amount" ? value : AmountfilteredData
    // } as any
    // const formdata = new FormData();

    //     // formdata.append('filter', JSON.parse(body)
    //     for (const key in body) {
    //       formdata.append(key, body[key]);
    //     }

    // httpClient
    //   .getInstance()
    //   .get(`${baseurl}api/message/enquiry/?page_number=${pageNumber}&page_size=5&filter={"model_type":${type === "type" ? value : TypefilteredData},"program_type":${type === "program" ? value : ProgramTypefilteredData},"transaction_date": ${type === "transactionDate" ? value : TransactionDatefilteredData},"due_date": ${type === "dueDate" ? value : DueDatefilteredData},"currency": ${type === "currency" ? value : CurrencyfilteredData},"counterparty": ${type === "counterparty" ? value : CounterpartyfilteredData},"amount": ${type === "amount" ? value : AmountfilteredData}&search=${type === "search" ? value === "" || searchWord === "" ? null : value ? value : searchWord : searchWord}`,formdata)
    //   // &filter={model_type:null,program_type:null,transaction_date:null,due_date:null,currency:null,counterparty:null,amount:null}`)
    //   .then((resp: any) => {
    //     console.log("enquiry resp", resp?.data)
    //     setDataSource(resp.data?.filter)
    //     setTableData(resp?.data?.data)
    //     setLoading(false)
    //   })
    let fromamt = { from: fromAmountfilteredData, to: toAmountfilteredData } as any
    console.log("de1",fromAmountfilteredData,toAmountfilteredData)
    if((amountfilterData.from === null && amountfilterData.to === null) || (fromAmountfilteredData === null && toAmountfilteredData === null)){
      fromamt = null
    }else if ((type === "filterAF" && value === 0 && toAmountfilteredData !== 0) || (type === "filterAF" && value === null && toAmountfilteredData !== 0)) {
      fromamt = { from: 0, to: toAmountfilteredData }
    } else if ((type === "filterTF" && value === 0 && fromAmountfilteredData !== 0) || (type === "filterTF" && value === null && fromAmountfilteredData !== 0)) {
      fromamt = { from: fromAmountfilteredData, to: 0 }
    } else {
      fromamt = null
    }
    const body = {
      page_number: 1,
      page_size: 5,
      filter: {
        "model_type": type === "filterType" ? null : TypefilteredData,
        "program_type": type === "filterPro" ? null : ProgramTypefilteredData,
        "transaction_date": type === "filterTD" ? null : TransactionDatefilteredData,
        "due_date": type === "filterDD" ? null : DueDatefilteredData,
        "currency": type === "filterCur" ? null : CurrencyfilteredData,
        "counterparty": type === "filterCP" ? null : CounterpartyfilteredData,
        "finance_due_date": type === "filterFDD" ? null : FinanceDueDateFileteredData,
        "invoice_no": type === "filterIN" ? null : invoiceNo,
        // "amount": (type === "filterAF" || type === "filterTF" || (amountfilterData.from === null && amountfilterData.to === null)) ? null : amountfilterData
        "amount": fromamt
      },
      search: type === "search" && (value === "" || searchWord === "") ? null : value || searchWord,
    }

    httpClient
      .getInstance()
      .post(`${baseurl}api/message/enquiry/`, body)
      .then((resp: any) => {
        console.log("enquiry resp", resp?.data);
        if (resp.data.status === ResponseStatus?.SUCCESS) {

          setDataSource(resp.data?.response?.filter)
          setTableData(resp?.data?.response?.data)
          setPagecount(resp?.data?.response?.count)
          setLoading(false);
        } else {
          message.error(resp.data.data)
        }
      })
      .catch((error: any) => {
        console.error('Error:', error);
      });
    // ws.current = socketurl;
    // const data = {
    //   "party_id": logindata.party_id,
    //   "user_id": logindata.user_id,
    //   "record_type":
    //   {
    //     "inbox":
    //     {
    //       type: "ALL",
    //       data: false
    //     },
    //     "sent":
    //     {
    //       type: "ALL",
    //       data: false
    //     },
    //     "sent_aw": {
    //       type: "ALL",
    //       data: false
    //     },
    //     "enquiry": {
    //       "data": true,
    // "filter": {
    //   "model_type": type === "type" ? value : TypefilteredData,
    //   "program_type": type === "program" ? value : ProgramTypefilteredData,
    //   "transaction_date": type === "transactionDate" ? value : TransactionDatefilteredData,
    //   "due_date": type === "dueDate" ? value : DueDatefilteredData,
    //   "currency": type === "currency" ? value : CurrencyfilteredData,
    //   "counterparty": type === "counterparty" ? value : CounterpartyfilteredData,
    //   "amount": type === "amount" ? value : AmountfilteredData
    // },
    // "search": type === "search" ? value === "" || searchWord === "" ? null : value ? value : searchWord : searchWord,

    //     },


    //     "draft": false,
    //     "history": false

    //   },
    //   "data_type": "MESSAGE",
    //   "page_size": 5,
    //   "page_number": pageNumber

    // }
    // if (socketConnection?.payload?.socketConnectData === "connected") {
    //   ws.current.send(JSON.stringify(data));
    //   ws.current.onmessage = (e: any) => {
    //     const message = JSON.parse(e.data);
    //     const compressedDataArray = message

    //     const compressedData = new Uint8Array(compressedDataArray);
    //     const decompressedData = pako.inflate(compressedData, { to: 'string' });
    //     const decompressedMessageData = JSON.parse(decompressedData);
    //     dispatch(
    //       socketInfoDetails(decompressedMessageData)
    //     )
    //     setDataSource(decompressedMessageData?.message_data?.enquiry)
    //     setTableData(decompressedMessageData?.message_data?.enquiry?.data)
    //     setPagecount(decompressedMessageData?.message_data?.enquiry?.overall_count)

    //     setTimeout(() => {
    //       setIsLoading(false)
    //       setLoading(false)
    //     }, 2000)

    //   }

    // } else {
    //   ws.current.onopen = () => {
    //     ws.current.send(JSON.stringify(data));
    //     ws.current.onmessage = (e: any) => {
    //       const message = JSON.parse(e.data);
    //       const compressedDataArray = message

    //       const compressedData = new Uint8Array(compressedDataArray);
    //       const decompressedData = pako.inflate(compressedData, { to: 'string' });
    //       const decompressedMessageData = JSON.parse(decompressedData);
    //       dispatch(
    //         socketInfoDetails(decompressedMessageData)
    //       )
    //       setDataSource(decompressedMessageData?.message_data?.enquiry)
    //       setTableData(decompressedMessageData?.message_data?.enquiry?.data)
    //       setPagecount(decompressedMessageData?.message_data?.enquiry?.overall_count)

    //       setTimeout(() => {
    //         setIsLoading(false)
    //         setLoading(false)
    //       }, 2000)

    //     }
    //   }
    // }
  }
  // const typeFilter = (e: any) => {
  //   if (e == "") {
  //     SocketClearFilter("type", null)
  //     setTypefilteredData(null)
  //   } else {
  //     setTypefilteredData(e)
  //     SocketFilter("type", e)
  //   }
  // }

  const typeFilter = (e: any) => {
    try {
      const inputValue = e;
      const inputLabel = 'Type';
      console.log(inputValue, 'IV')
      if (InputEvent === undefined) {
        console.log('HItted here')
        SocketClearFilter("type", null);
        setTypefilteredData(null);
        handleCloseTag({ label: inputLabel, value: '' })
      } else {
        // Check if the label already exists in filterTags
        const existingTagIndex = filterTags.findIndex((tag: any) => tag.label === inputLabel);

        if (existingTagIndex !== -1) {
          // If the label already exists, update the value for that label
          const updatedTags = [...filterTags];
          updatedTags[existingTagIndex] = { label: inputLabel, value: inputValue };
          setFilterTags(updatedTags);
        } else {
          // If the label doesn't exist, add a new tag
          setFilterTags([...filterTags, { label: inputLabel, value: inputValue }]);
        }

        setTypefilteredData(inputValue);
        // SocketFilter("type", inputValue);
      }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error in Currency:", error);
    }
  };

  // const currencyFilter = (e: any) => {
  //   if (e == "") {
  //     SocketClearFilter("currency", null)
  //     setCurrencyfilteredData(null)
  //   } else {
  //     console.log(e, 'e')
  //     setCurrencyfilteredData(e)
  //     SocketFilter("currency", e)
  //   }
  // }

  const currencyFilter = (e: any) => {
    try {
      const inputValue = e;
      const inputLabel = 'Currency';
      if (inputValue == "") {
        SocketClearFilter("currency", null);
        setCurrencyfilteredData(null);
      } else {
        // Check if the label already exists in filterTags
        const existingTagIndex = filterTags.findIndex((tag: any) => tag.label === inputLabel);

        if (existingTagIndex !== -1) {
          // If the label already exists, update the value for that label
          const updatedTags = [...filterTags];
          updatedTags[existingTagIndex] = { label: inputLabel, value: inputValue };
          setFilterTags(updatedTags);
        } else {
          // If the label doesn't exist, add a new tag
          setFilterTags([...filterTags, { label: inputLabel, value: inputValue }]);
        }

        setCurrencyfilteredData(inputValue);
        // SocketFilter("currency", inputValue);
      }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error in Currency:", error);
    }
  };

  // const transactionDateFilter = (e: any) => {
  //   if (e?._d === undefined) {
  //     SocketClearFilter("transactionDate", null)
  //     setTransactionDatefilteredData(null)
  //   } else {
  //     setTransactionDatefilteredData(moment(e?._d).format("DD-MM-YYYY"))
  //     SocketFilter("transactionDate", moment(e?._d).format("DD-MM-YYYY"))
  //   }
  // }

  const transactionDateFilter = (e: any) => {
    try {
      if (e?.length > 0) {
        const newDue = e.map((el: any, idx: number) => (`${moment(el).format('DD/MM/YYYY')}${idx === 1 ? '' : ' - '}`))
        const existingTransactionDateIndex = filterTags.findIndex((tag: any) => tag.label === 'Transaction Date');

        setTransactionDatefilteredData({ from: moment(e[0]).format('YYYY-MM-DD'), to: moment(e[1]).format('YYYY-MM-DD') })

        if (existingTransactionDateIndex !== -1) {
          const updatedFilterTags = [...filterTags];
          updatedFilterTags[existingTransactionDateIndex].value = newDue;
          setFilterTags(updatedFilterTags);
          setClearTransactionDate(e)
        } else {
          setFilterTags([...filterTags, { label: 'Transaction Date', value: newDue }]);
        }
      } else {
        handleCloseTag({ label: 'Transaction Date', value: '' })
      }
      // const inputValue = e;
      // const inputLabel = "Transaction";

      // if (inputValue === "") {
      //   SocketClearFilter("transactionDate", null);
      //   setTransactionDatefilteredData({from: null, to: null});
      // } else {
      //   // Check if the label already exists in filterTags
      //   const existingTagIndex = filterTags.findIndex(tag => tag.label === inputLabel);

      //   if (existingTagIndex !== -1) {
      //     // If the label already exists, update the value for that label
      //     const updatedTags = [...filterTags];
      //     updatedTags[existingTagIndex] = { label: inputLabel, value: inputValue };
      //     setFilterTags(updatedTags);
      //   } else {
      //     // If the label doesn't exist, add a new tag
      //     setFilterTags([...filterTags, { label: inputLabel, value: inputValue }]);
      //   }

      //   if(e?.length > 0){
      //     // setTransactionDatefilteredData({from: moment(e[0]).format("DD-MM-YYYY"), to: moment(e[1]).format("DD-MM-YYYY")})
      //     // SocketFilter("transactionDate", moment(e?._d).format("DD-MM-YYYY"))
      //   }
      // }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error in transaction date:", error);
    }
  };


  // const dueDateFilter = (e: any) => {
  //   if (e?._d === undefined) {
  //     SocketClearFilter("dueDate", null)
  //     setDueDatefilteredData(null)
  //   } else {
  //     setDueDatefilteredData(moment(e?._d).format("DD-MM-YYYY"))
  //     SocketFilter("dueDate", moment(e?._d).format("DD-MM-YYYY"))
  //   }
  // }

  const dueDateFilter = (e: any) => {
    try {
      if (e?.length > 0) {
        const newDue = e.map((el: any, idx: number) => (`${moment(el).format('DD/MM/YYYY')}${idx === 1 ? '' : ' - '}`))
        const existingDueDateIndex = filterTags.findIndex((tag: any) => tag.label === 'Due Date/Expire');

        setDueDatefilteredData({ from: moment(e[0]).format('YYYY-MM-DD'), to: moment(e[1]).format('YYYY-MM-DD') })

        if (existingDueDateIndex !== -1) {
          const updatedFilterTags = [...filterTags];
          updatedFilterTags[existingDueDateIndex].value = newDue;
          setFilterTags(updatedFilterTags);
          setClearDueDate(e);
        } else {
          setFilterTags([...filterTags, { label: 'Due Date/Expire', value: newDue }]);
        }
      }
      // const inputValue = e;
      // const inputLabel = 'Due Date\\Expire';
      // if (inputValue === "") {
      //   SocketClearFilter("dueDate", null);
      //   setDueDatefilteredData(null);
      // } else {
      //   // Check if the label already exists in filterTags
      //   const existingTagIndex = filterTags.findIndex(tag => tag.label === inputLabel);

      //   if (existingTagIndex !== -1) {
      //     // If the label already exists, update the value for that label
      //     const updatedTags = [...filterTags];
      //     updatedTags[existingTagIndex] = { label: inputLabel, value: {from: moment(e[0]).format('DD-MM-YYYY'), to: moment(e[1]).format('DD-MM-YYYY')}};
      //     setFilterTags(updatedTags);
      //   } else {
      //     // If the label doesn't exist, add a new tag
      //     setFilterTags([...filterTags, { label: inputLabel, value: {from: moment(e[0]).format('DD-MM-YYYY'), to: moment(e[1]).format('DD-MM-YYYY')} }]);
      //   }
      //   // setDueDatefilteredData(inputValue);
      //   // SocketFilter("dueDate", inputValue);
      // }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error in due date:", error);
    }
  };

  const financeDueDateFilter = (e: any) => {
    try {
      if (e?.length > 0) {
        const newDue = e.map((el: any, idx: number) => (`${moment(el).format('DD/MM/YYYY')}${idx === 1 ? '' : ' - '}`))
        const existingFinanceDueDateIndex = filterTags.findIndex((tag: any) => tag.label === 'Finance Due Date');

        setFinanceDueDateFilteredData({ from: moment(e[0]).format('YYYY-MM-DD'), to: moment(e[1]).format('YYYY-MM-DD') })

        if (existingFinanceDueDateIndex !== -1) {
          const updatedFilterTags = [...filterTags];
          updatedFilterTags[existingFinanceDueDateIndex].value = newDue;
          setFilterTags(updatedFilterTags);
          setClearFinanceDueDate(e);
        } else {
          setFilterTags([...filterTags, { label: 'Finance Due Date', value: newDue }]);
        }
      }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error in due date:", error);
    }
  };

  // const fromAmountFilter = (e: any) => {
  //   if (e.target.value == "") {
  //     SocketClearFilter("amount", null)
  //     setFromAmountfilteredData(null)
  //   } else {
  //     setFilterTags([...filterTags, { label: e.target.placeholder, value: e.target.value }]);
  //     setFromAmountfilteredData(e.target.value)
  //     SocketFilter("amount", e.target.value)
  //   }
  // }

  const invoiceNoFilter = (e: any) => {
    try {
      const inputValue = e.target.value;
      const inputLabel = e.target.placeholder;

      if (inputValue === "") {
        SocketClearFilter("invoiceNo", null);
        setFromAmountfilteredData(null);
        handleCloseTag('Invoice No');
      } else {
        // Check if the label already exists in filterTags
        const existingTagIndex = filterTags.findIndex((tag: any) => tag.label === inputLabel);

        if (existingTagIndex !== -1) {
          // If the label already exists, update the value for that label
          const updatedTags = [...filterTags];
          updatedTags[existingTagIndex] = { label: inputLabel, value: inputValue };
          setFilterTags(updatedTags);
        } else {
          // If the label doesn't exist, add a new tag
          setFilterTags([...filterTags, { label: inputLabel, value: inputValue }]);
        }

        setInvoiceNo(e.target.value);
        // SocketFilter("amount", inputValue);
      }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error in Invoice No:", error);
    }
  };


  const fromAmountFilter = (e: any) => {
    try {
      const inputValue = e.target.value;
      const inputLabel = `Amount (${e.target.placeholder})`;

      if (inputValue === "") {
        SocketClearFilter("amount", null);
        setFromAmountfilteredData(null);
        handleCloseTag('Amount (From)');
      } else {
        // Check if the label already exists in filterTags
        const existingTagIndex = filterTags.findIndex((tag: any) => tag.label === inputLabel);

        if (existingTagIndex !== -1) {
          // If the label already exists, update the value for that label
          const updatedTags = [...filterTags];
          updatedTags[existingTagIndex] = { label: inputLabel, value: inputValue };
          setFilterTags(updatedTags);
        } else {
          // If the label doesn't exist, add a new tag
          setFilterTags([...filterTags, { label: inputLabel, value: inputValue }]);
        }

        setAmountfilterData((prevState: any) => ({ ...prevState, from: Number(inputValue) }))
        setFromAmountfilteredData(e.target.value);
        // SocketFilter("amount", inputValue);
      }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error in fromAmountFilter:", error);
    }
  };

  const toAmountFilter = (e: any) => {
    try {
      const inputValue = e.target.value;
      const inputLabel = `Amount (${e.target.placeholder})`;

      if (inputValue === "") {
        SocketClearFilter("amount", null);
        setToAmountfilteredData(null);
        handleCloseTag('Amount (To)');
      } else {
        // Check if the label already exists in filterTags
        const existingTagIndex = filterTags.findIndex((tag: any) => tag.label === inputLabel);

        if (existingTagIndex !== -1) {
          // If the label already exists, update the value for that label
          const updatedTags = [...filterTags];
          updatedTags[existingTagIndex] = { label: inputLabel, value: inputValue };
          setFilterTags(updatedTags);
        } else {
          // If the label doesn't exist, add a new tag
          setFilterTags([...filterTags, { label: inputLabel, value: inputValue }]);
        }

        setAmountfilterData((prevState: any) => ({ ...prevState, to: Number(inputValue) }))
        setToAmountfilteredData(e.target.value);
        // SocketFilter("amount", inputValue);
      }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error in toAmountFilter:", error);
    }
  };

  // const toAmountFilter = (e: any) => {
  //  if (e.target.value == "") {
  //     SocketClearFilter("amount", null)
  //     setFromAmountfilteredData(null)
  //   } else {
  //     setFromAmountfilteredData(e.target.value)
  //     SocketFilter("amount", e.target.value)
  //   }
  // }

  // const counterpartyFilter = (e: any) => {
  //   if (e == "") {
  //     SocketClearFilter("counterparty", null)
  //     setCounterpartyfilteredData(null)
  //   } else {
  //     setCounterpartyfilteredData(e)
  //     SocketFilter("counterparty", e)
  //   }
  // }

  const counterpartyFilter = (e: any) => {
    try {
      const inputValue = e;
      const inputLabel = "Counterparty";

      if (inputValue === "") {
        SocketClearFilter("counterparty", null);
        setCounterpartyfilteredData(null);
      } else {
        // Check if the label already exists in filterTags
        const existingTagIndex = filterTags.findIndex((tag: any) => tag.label === inputLabel);

        if (existingTagIndex !== -1) {
          // If the label already exists, update the value for that label
          const updatedTags = [...filterTags];
          updatedTags[existingTagIndex] = { label: inputLabel, value: inputValue };
          setFilterTags(updatedTags);
        } else {
          // If the label doesn't exist, add a new tag
          setFilterTags([...filterTags, { label: inputLabel, value: inputValue }]);
        }

        setCounterpartyfilteredData(inputValue);
        // SocketFilter("amount", inputValue);
      }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error in toAmountFilter:", error);
    }
  };

  // const programFilter = (e: any) => {

  //   if (e == "") {
  //     SocketClearFilter("program", null)
  //     setProgramTypefilteredData(null)
  //   } else {
  //     setProgramTypefilteredData(e)
  //     SocketFilter("program", e)
  //   }

  //   setTimeout(() => {
  //     setLoading(false)

  //   }, 2000)
  // }

  const programFilter = (e: any) => {
    try {
      const inputValue = e;
      const inputLabel = "Program";

      if (inputValue === "") {
        SocketClearFilter("program", null);
        setProgramTypefilteredData(null);
      } else {
        // Check if the label already exists in filterTags
        const existingTagIndex = filterTags.findIndex((tag: any) => tag.label === inputLabel);

        if (existingTagIndex !== -1) {
          // If the label already exists, update the value for that label
          const updatedTags = [...filterTags];
          updatedTags[existingTagIndex] = { label: inputLabel, value: inputValue };
          setFilterTags(updatedTags);
        } else {
          // If the label doesn't exist, add a new tag
          setFilterTags([...filterTags, { label: inputLabel, value: inputValue }]);
        }

        setProgramTypefilteredData(inputValue);
        // SocketFilter("program", inputValue);
      }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error in toAmountFilter:", error);
    }
  };

  const onChange = (e: any) => {
    setpageNumber(e)
    setLoading(true)
    const body = {
      page_number: e,
      page_size: 5,
      filter: {
        "model_type": TypefilteredData ? TypefilteredData : null,
        "program_type": ProgramTypefilteredData ? ProgramTypefilteredData : null,
        "transaction_date": TransactionDatefilteredData ? TransactionDatefilteredData : null,
        "due_date": DueDatefilteredData ? DueDatefilteredData : null,
        "currency": CurrencyfilteredData ? CurrencyfilteredData : null,
        "counterparty": CounterpartyfilteredData ? CounterpartyfilteredData : null,
        "amount": amountfilterData ? amountfilterData : null,
        "finance_due_date": FinanceDueDateFileteredData ? FinanceDueDateFileteredData : null,
        "invoice_no": invoiceNo ? invoiceNo : null,
      },
      search: searchWord !== "" ? searchWord : null
    }

    httpClient
      .getInstance()
      .post(`${baseurl}api/message/enquiry/`, body)
      .then((resp: any) => {
        console.log("enquiry resp", resp?.data);
        if (resp.data.status === ResponseStatus?.SUCCESS) {

          setDataSource(resp.data?.response?.filter)
          setTableData(resp?.data?.response?.data)
          setPagecount(resp?.data?.response?.count)
          setLoading(false);
        } else {
          message.error(resp.data.data)
        }
      })
      .catch((error: any) => {
        console.error('Error:', error);
      });

    // ws.current = socketurl;

    // const data = {
    //   "party_id": logindata.party_id,
    //   "user_id": logindata.user_id,
    //   "record_type":
    //   {
    //     "inbox":
    //     {
    //       type: "ALL",
    //       data: false
    //     },
    //     "sent":
    //     {
    //       type: "ALL",
    //       data: false
    //     },
    //     "sent_aw": {
    //       type: "ALL",
    //       data: false
    //     },
    //     "enquiry": {
    //       "data": true,
    //       "filter": {
    //         "model_type": TypefilteredData ? TypefilteredData : null,
    //         "program_type": ProgramTypefilteredData ? ProgramTypefilteredData : null,
    //         "transaction_date": TransactionDatefilteredData ? TransactionDatefilteredData : null,
    //         "due_date": DueDatefilteredData ? DueDatefilteredData : null,
    //         "currency": CurrencyfilteredData ? CurrencyfilteredData : null,
    //         "counterparty": CounterpartyfilteredData ? CounterpartyfilteredData : null,
    //         "amount": AmountfilteredData ? AmountfilteredData : null
    //       },
    //       "search": searchWord !== "" ? searchWord : null
    //     },
    //     "draft": false,
    //     "history": false

    //   },
    //   "data_type": "MESSAGE",
    //   "page_size": PageSizeCount,
    //   "page_number": e

    // }

    // if (socketConnection?.payload?.socketConnectData === "connected") {
    //   ws.current.send(JSON.stringify(data));

    //   ws.current.onmessage = (e: any) => {
    //     const message = JSON.parse(e.data);
    //     const compressedDataArray = message

    //     const compressedData = new Uint8Array(compressedDataArray);
    //     const decompressedData = pako.inflate(compressedData, { to: 'string' });
    //     const decompressedMessageData = JSON.parse(decompressedData);
    //     setTableData(decompressedMessageData?.message_data?.enquiry?.data)
    //     setPagecount(decompressedMessageData?.message_data?.enquiry?.overall_count)

    //     dispatch(
    //       socketInfoDetails(decompressedMessageData)
    //     )


    //   }
    // } else {
    //   ws.current.onopen = () => {
    //     ws.current.send(JSON.stringify(data));

    //     ws.current.onmessage = (e: any) => {
    //       const message = JSON.parse(e.data);
    //       const compressedDataArray = message

    //       const compressedData = new Uint8Array(compressedDataArray);
    //       const decompressedData = pako.inflate(compressedData, { to: 'string' });
    //       const decompressedMessageData = JSON.parse(decompressedData);
    //       setTableData(decompressedMessageData?.message_data?.enquiry?.data)
    //       setPagecount(decompressedMessageData?.message_data?.enquiry?.overall_count)
    //       dispatch(
    //         socketInfoDetails(decompressedMessageData)
    //       )

    //     }
    //   }

    // }
    setTimeout(() => {
      setLoading(false);

    }, 1000)
  }

  const clearAll = () => {
    setIsLoading(true)
    setTypefilteredData(null);
    setProgramTypefilteredData(null);
    setCurrencyfilteredData(null);
    setCounterpartyfilteredData(null);
    setTransactionDatefilteredData(null);
    setClearTransactionDate([]);
    setClearDueDate([]);
    setDueDatefilteredData(null);
    setFromAmountfilteredData(null);
    setSearchWord(null);
    setAmountfilterData({ from: null, to: null });
    setLoading(true);
    setInvoiceNo(null);
    setFilterTags([])

    // ws.current = socketurl;

    // const data = {
    //   "party_id": logindata.party_id,
    //   "user_id": logindata.user_id,
    //   "record_type":
    //   {
    //     "inbox":
    //     {
    //       type: "ALL",
    //       data: false
    //     },
    //     "sent":
    //     {
    //       type: "ALL",
    //       data: false
    //     },
    //     "sent_aw": {
    //       type: "ALL",
    //       data: false
    //     },
    //     "enquiry": {
    //       "data": true,
    //       "filter": {
    //         "model_type": null,
    //         "program_type": null,
    //         "transaction_date": null,
    //         "due_date": null,
    //         "currency": null,
    //         "counterparty": null,
    //         "amount": null
    //       },
    //       "search": null
    //     },
    //     "draft": false,
    //     "history": false

    //   },
    //   "data_type": "MESSAGE",
    //   "page_size": PageSizeCount,
    //   "page_number": pageNumber

    // }

    // if (socketConnection?.payload?.socketConnectData === "connected") {
    //   ws.current.send(JSON.stringify(data));

    //   ws.current.onmessage = (e: any) => {
    //     const message = JSON.parse(e.data);
    //     const compressedDataArray = message

    //     const compressedData = new Uint8Array(compressedDataArray);
    //     const decompressedData = pako.inflate(compressedData, { to: 'string' });
    //     const decompressedMessageData = JSON.parse(decompressedData);
    //     setTableData(decompressedMessageData?.message_data?.enquiry?.data)
    //     setPagecount(decompressedMessageData?.message_data?.enquiry?.overall_count)

    //     dispatch(
    //       socketInfoDetails(decompressedMessageData)
    //     )


    //   }
    // } else {
    //   ws.current.onopen = () => {
    //     ws.current.send(JSON.stringify(data));

    //     ws.current.onmessage = (e: any) => {
    //       const message = JSON.parse(e.data);
    //       const compressedDataArray = message

    //       const compressedData = new Uint8Array(compressedDataArray);
    //       const decompressedData = pako.inflate(compressedData, { to: 'string' });
    //       const decompressedMessageData = JSON.parse(decompressedData);
    //       setTableData(decompressedMessageData?.message_data?.enquiry?.data)
    //       setPagecount(decompressedMessageData?.message_data?.enquiry?.overall_count)

    //       dispatch(
    //         socketInfoDetails(decompressedMessageData)
    //       )

    //     }
    //   }

    // }
    setTimeout(() => {
      setLoading(false)
      setIsLoading(false)
    }, 500)
  }
  return isLoading ? <Spin /> : (
    <div className="ProgramMainContainer">
      <div>
        <div className="headerStyle" style={{ display: 'flex' }}>
          <div style={{ paddingLeft: 10, marginTop: 10 }}>
            <Heading flag="Enquiry" text="Enquiry" subText="" />
          </div>
          <Input
            className=""
            placeholder="Search"
            prefix={<img src={Search} alt="search" style={{ padding: 4 }}
            // onClick={() =>
            //     SocketFilter("search", searchWord)
            //   } 
            />}
            style={{ width: "20%", height: '35px', borderRadius: "5px", margin: "10px" }}
            onChange={(event) => {
              if (event.target.value === "") {
                setSearchWord(null)
                SocketClearFilter("search", null)
              } else {
                setSearchWord(event.target.value)
                SocketFilter("search", event.target.value)
              }
            }
            }
          // onKeyDown={(event) => {
          //   if (event.key === 'Enter') {
          //     event.preventDefault();
          //     SocketFilter("search", searchWord)
          //   }
          // }}
          />
        </div>

        <div className="mainContentTableContainer">
          <Modal style={{ top: 100 }} open={isModalOpen}
            onCancel={() => { setIsModalOpen(false); clearAll() }} className="modalStyle" footer={false}>
            <Form className="modalContainer" layout="vertical">
              <Form.Item label="Type">
                <Select
                  showSearch
                  placeholder="Type"
                  optionFilterProp="children"
                  className="dropDownContainer"
                  onChange={typeFilter}
                  value={TypefilteredData}
                  allowClear={true}
                  onClear={() => {
                    handleCloseTag({ label: 'Type', value: '' })
                  }}
                >
                  {
                    dataSource?.type?.map((item: any, index: number) => {
                      return <Option value={item} id={item} key={index}>
                        {item === "Financerequest" ? "Invoice" : item}
                      </Option>
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item label="Program">


                <Select
                  showSearch
                  placeholder="Program"
                  optionFilterProp="children"
                  className="dropDownContainer"
                  onChange={programFilter}
                  value={ProgramTypefilteredData}
                  allowClear={true}
                  onClear={() => {
                    handleCloseTag({ label: 'Program', value: "" })
                  }}
                >
                  {
                    dataSource?.program_type?.map((item: any, index: number) => {
                      return <Option value={item} id={item} key={index}>
                        {item}
                      </Option>
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item label="Counterparty">
                <Select
                  showSearch
                  placeholder="Counterparty"
                  optionFilterProp="children"
                  className="dropDownContainer"
                  onChange={counterpartyFilter}
                  value={CounterpartyfilteredData}
                  autoClearSearchValue={true}
                  allowClear={true}
                  onClear={() => {
                    handleCloseTag({ label: 'Counterparty', value: '' })
                  }}
                >
                  {
                    dataSource?.counterparty?.map((item: any, index: number) => {
                      return <Option value={item} id={item} key={index}>
                        {item}
                      </Option>

                    })}

                </Select>
              </Form.Item>
              <Form.Item label="Currency">
                <Select
                  showSearch
                  placeholder="Currency"
                  optionFilterProp="children"
                  className="dropDownContainer"
                  onChange={currencyFilter}
                  value={CurrencyfilteredData}
                  autoClearSearchValue={true}
                  allowClear={true}
                  onClear={() => {
                    handleCloseTag({ label: 'Currency', value: '' })
                  }}
                >
                  {
                    dataSource?.currency?.map((item: any, index: number) => {
                      return <Option value={item} id={item} key={index}>
                        {item}
                      </Option>

                    })}
                </Select>
              </Form.Item>
              <div>
                <p>Amount</p>
                <div className="amountFilter">
                  <Form.Item label="">
                    <Input placeholder="From" type="number" className="inputContainer"
                      allowClear={true}
                      onChange={fromAmountFilter} value={fromAmountfilteredData} />
                  </Form.Item>
                  <Form.Item label="">
                    <Input placeholder="To" type="number" className="inputContainer"
                      allowClear={true}
                      onChange={toAmountFilter} value={toAmountfilteredData}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item label="Transaction Date">
                <RangePicker
                  format={"DD/MM/YYYY"}
                  className="datepickerContainer"
                  value={clearTransactionDate}
                  allowClear={true}
                  onCalendarChange={transactionDateFilter}
                />
              </Form.Item>
              <Form.Item label="Due/Expire Date" style={{ marginLeft: 0 }}>
                <RangePicker
                  format={"DD/MM/YYYY"}
                  className="datepickerContainer"
                  onCalendarChange={dueDateFilter}
                  value={clearDueDate}
                  allowClear={true}
                />
              </Form.Item>
              <Form.Item label="Finance Due Date" style={{ marginLeft: 0 }}>
                <RangePicker
                  format={"DD/MM/YYYY"}
                  className="datepickerContainer"
                  onCalendarChange={financeDueDateFilter}
                  value={clearFinanceDueDate}
                  allowClear={true}
                />
              </Form.Item>
              <Form.Item label="Invoice No.">
                <Input placeholder="Invoice No" type="text" className="inputContainer"
                  allowClear={true}
                  onChange={invoiceNoFilter}
                  value={invoiceNo}
                />
              </Form.Item>
              <Form.Item style={{
                marginTop: 35
              }}>
                <Button className="filterButtonStyle" color={'red'} onClick={() => { setIsModalOpen(false); clearAll(); }}>Cancel</Button>
                <Button type="primary" className="filterButtonStyle" style={{ marginLeft: 14 }} onClick={() => { setIsModalOpen(false); SocketClearFilter("search", searchWord) }}>Apply Filter</Button>
              </Form.Item>
            </Form>

          </Modal>
          <div className="tagFilterStyle">
            {tagCondition ? <span style={{ cursor: 'pointer' }} onClick={() => { scrollLeft() }}><img src={ScrollLeftArrow} alt="left arrow" /></span> : <></>}
            <div style={{ padding: 4, marginTop: 18, overflowX: 'scroll', whiteSpace: 'nowrap', width: '85%' }} ref={containerRef}>
              {tagChild}
            </div>
            {tagCondition ? <span style={{ cursor: 'pointer' }} onClick={() => { scrollRight() }}><img src={RightArrow} alt="left arrow" /></span> : <></>}
            <Button onClick={() => { setIsModalOpen(true) }} className="enquiryFilterButton">Filter</Button>
          </div>
          <div className="Card_Main_Container" style={{ paddingTop: "4vh" }}>
            <Card className="CardContainer">
              <>

                <div className='containerTable'>
                  <div className="OuterDiv">

                    <div className='HeadInnerDiv'>
                      <h1 className='head'>Date</h1>
                      <h1 className='head'>Type</h1>
                      {/* <h1 className='head'>Transaction</h1> */}
                      <h1 className='head'>Program</h1>
                      <h1 className='head'>Counterparty</h1>
                      <h1 className='head'>Status</h1>
                      <h1 className='head' >Amount</h1>
                      <h1 className='head'>Duedate</h1>
                    </div>

                  </div>
                  <div className='OuterDiv'>
                    {loading ? <Spin /> : TableData && TableData?.map((record: any, index: number) => {
                      return (
                        <div key={index}
                          className={
                            'InnerDiv'} onClick={() => {
                              enquiryDetail(record);
                            }} id={`enquiry_table`}>
                          <h5 className='body' id={`enquiry_date`}>
                            <div>{moment(record?.record_datas?.values?.[0]?.fields?.created_date).format("DD-MM-YYYY")}</div>

                          </h5>
                          <h5 className='body' id={`enquiry_type`}>
                            <div>
                              {
                                record.type === Type.UPLOAD
                                  ? "Invoice" : record.type === Type.FINANCEREQ ? "Invoice"
                                    : record.type}
                            </div>
                          </h5>
                          {/* <h5 className='body' id={`enquiry_transactionId`}>
                            <div> {record ? record.id : ""}</div>
                          </h5> */}
                          <h5 className='body' id={`enquiry_programType`}>
                            <div>
                              {record ? (record?.base_data?.[0]?.program_type) : ""}</div>
                          </h5>
                          <h5 className='body' id={`enquiry_counterparty`}>
                            <div>{record ? record?.base_data?.[0]?.PartyName : "-"}</div>

                          </h5>
                          <h5 className='body' id={`enquiry_status`}>
                            <div>
                              {record.interim_state}
                            </div>
                          </h5>
                          <h5 className='body' id={`enquiry_amount`}
                          >
                            <div>
                              {

                                record ?
                                  record?.base_data?.[0]?.InvoiceCurr
                                  // currencyList.map((item: any) => {
                                  //   let desc = ""
                                  //   if (item.id === record?.base_data?.[0]?.InvoiceCurr) {
                                  //     desc = item.description
                                  //   }
                                  //   return desc
                                  // })
                                  // record?.type === Type.INVOICEUPLOAD
                                  //   ? record?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceCurrency
                                  //   : record?.type === Type.INVOICE ?
                                  //     currencyList.map((item: any) => {
                                  //       let desc = ""
                                  //       if (item.id === record?.record_datas?.values?.[0]?.fields?.invoice_currency) {
                                  //         desc = item.description
                                  //       }
                                  //       return desc
                                  //     })

                                  //     : record?.type === Type.PROGRAM ?
                                  //       currencyList.map((item: any) => {
                                  //         let desc = ""
                                  //         if (item.id === record?.record_datas?.values?.[0]?.fields?.max_invoice_currency) {
                                  //           desc = item.description
                                  //         }
                                  //         return desc
                                  //       })
                                  //       : record?.type === Type.FINANCEREQ ?
                                  //         currencyList.map((item: any) => {
                                  //           let desc = ""
                                  //           if (item.id === record?.record_datas?.values?.[0]?.fields?.invoice_currency) {
                                  //             desc = item.description
                                  //           }
                                  //           return desc
                                  //         })
                                  //         : "-"
                                  : "-"
                              } {" "}
                              <span>

                                {record ? record?.base_data?.[0]?.Amount : "-"}
                                {/* ? record?.type === Type.INVOICEUPLOAD
                                    ? record?.record_datas?.values?.[0]?.fields?.invoices
                                      ? record?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceAmount
                                      : "-"
                                    : record.type === Type.PROGRAM
                                      ? record?.record_datas?.values?.[0]?.fields?.max_invoice_amount

                                      : record.type === Type.INVOICE
                                        ? record?.record_datas?.values?.[0]?.fields?.amount
                                        : record?.type === Type.FINANCEREQ
                                          ? record?.record_datas?.values?.[0]?.fields?.invoice_amount
                                          : "-"
                                  : "-"} */}

                              </span>
                            </div>
                          </h5>
                          <h5 className='body' id={`enquiry_duedate`}>
                            <div>
                              <div>
                                {record ? record?.base_data?.[0]?.Date !== null ? moment(record?.base_data?.[0]?.Date).format("DD-MM-YYYY") : "-" : "-"}
                                {/* {record ? record.type === Type.INVOICE ? moment(record?.record_datas?.values?.[0]?.fields?.due_date).format("DD-MM-YYYY") : record.type === Type.INVOICEUPLOAD ? record?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.DueDate : record.type === Type.PROGRAM ? moment(record?.record_datas?.values?.[0]?.fields?.expiry_date).format("DD-MM-YYYY") : record.type === Type.FINANCEREQ ? moment(record?.record_datas?.values?.[0]?.fields?.due_date).format("DD-MM-YYYY") : "-" : "-"} */}

                              </div>
                            </div>
                          </h5>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </>
            </Card>
          </div>
        </div>
      </div>
      <Pagination
        pageSize={PageSizeCount}
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        defaultCurrent={pageNumber}
        current={pageNumber}
        total={pageCount}
        onChange={onChange}
      />
    </div>
  );
};
export default Enquiry;
