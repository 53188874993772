import axios, { AxiosInstance } from "axios";
import { message } from "antd";

const httpClient = {
  getInstance(): AxiosInstance {
    const instance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
    });

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (
          error.response &&
          (error.response.status === 403)
        ) {
          message.error('Session Expired, Please login and try again!', 3)

        } else if (
          error.response &&
          (error.response.status === 401)
        ) {
          message.error('Session Expired, Please login and try again!', 3)
          if (error.response.data.detail === "Invalid token.") {
            localStorage.clear();
            window.location.href = "/";
            window.location.reload();
          }
        }
        return Promise.reject(error);
      }
    );
    return instance;
  },
};

export default httpClient;
