
import React, { useState, useEffect } from "react";
import { Input, Row, Col, Button, message, Popconfirm, Spin } from "antd";
import images from "../../../../assets/images";
import "../../new.scss";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import { useNavigate } from "react-router";
import moment from 'moment';
import {UploadImage} from "../../../common/UploadImage/manualInvoiceUpload";
const { FileAttachIcon, DeleteIcon } = images;


const IncompleteFiles = (props: any) => {
  const Navigate = useNavigate()

  const [completedInvoices, setCompletedInvoices] = useState([]);
  const [buyerIdErrors, setBuyerIdErrors] = useState(Array(completedInvoices.length).fill(false));
  const [loading, setLoadings] = useState(true);
  const [buyerIdError, setBuyerIdError] = useState(false as any);
  const [fileList, setFileList] = useState([] as any);

  useEffect(() => {
    IncompleteNo();
  }, []);

  const IncompleteNo = () => {
    setLoadings(true)
    httpClient
      .getInstance()
      .get(`${baseurl}api/invoice/upload/?type=NO`)
      .then((resp: any) => {
        setCompletedInvoices(resp.data.data)
        props.incompleteData(resp.data.data)
        if (resp.data.data.length === 0) {
          props.tabValue("2")
        }
        setLoadings(false);
        resp.data.data.map((record: any, index: number) => {
          setBuyerIdErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors[index] = true;
            return newErrors;
          });
        })
      })
      .catch(() => {
        Navigate("/Notfound")
        setLoadings(false);
      })
  }

  const Header_APF_Buyer = [{
    Id: "Seller Id", Name: "Seller Name", InvNo: "Inv No", InvDate: "Inv Date", InvAmt: "Inv Amount", DueDate: "Due Date", FinanceCurrency: "Financing Currency", SettlementCurrency: "Settlement Currency", autoFinance: "Auto Finance", attached: "Attached"
  }]
  const Header_APF_Seller = [{
    Id: "Buyer Id", Name: "Buyer Name", InvNo: "Inv No", InvDate: "Inv Date", InvAmt: "Inv Amount", DueDate: "Due Date", FinanceCurrency: "Financing Currency", SettlementCurrency: "Settlement Currency", autoFinance: "Auto Finance", attached: "Attached"
  }]
  const deleteRecord = (record: any) => {
    setLoadings(true);
    httpClient
      .getInstance()
      .delete(`${baseurl}api/invoice/upload/${record.id}/`)
      .then(() => {
        setLoadings(false);
        IncompleteNo();
      }).catch(() => {
        setLoadings(false);
        Navigate("/Notfound")

      })
  }
  const ClearAllData = () => {
    httpClient
      .getInstance()
      .delete(`${baseurl}api/invoice/upload/delete/?type=NO`)
      .then((resp: any) => {
        setLoadings(false);
        message.success(resp.data.data)
        IncompleteNo();
        props.tabValue("2")
      }).catch(() => {
        setLoadings(false)
        Navigate("/Notfound")

      })
  }
  const selectedFiles = (value: any,record:any) => {
    console.log("value",value,"record",record)
    setFileList(value)
    const formdata = new FormData();
    fileList.map((item: any) => {
      formdata.append(`files`, item[0]);
      formdata.append(`comments`, "bulkUploadfile");
    });
    formdata.append("invoice_upload", record?.id);
    if(record?.attachments?.file?.length >0){
      httpClient
      .getInstance()
      .put(`${baseurl}api/resource/file/${record?.attachments?.file?.[0]?.id}/`, formdata)
      // .then((response: any) => {
      // })
      .catch(() => {
        Navigate("/Notfound")
      })
    }else{
  
      httpClient
        .getInstance()
        .post(`${baseurl}api/resource/file/`, formdata)
        // .then((response: any) => {
        // })
        .catch(() => {
          Navigate("/Notfound")
        })
    }
   
  }
  return loading ? <Spin /> : (
    <React.Fragment>
      <div className="ManualContainer" style={{ marginTop: "0" }}>
        <Button className="clearAllButton ExitButtonContainer" onClick={ClearAllData}>Clear All</Button>

        <div className='containerTable'>
          <div className="OuterDiv">
            {
              props?.upload_detail?.party_details?.APF_buyer === true || props?.upload_detail?.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "APF_BUYER" ?
                Header_APF_Buyer.map((head: any, index: number) => {
                  return (
                    <div key={index} className='HeadInnerDiv'>
                      <h1 className='head' style={{ width: "10%" }}>{head.Id}</h1>
                      <h1 className='head' style={{ width: "10%" }}>{head.Name}</h1>
                      <h1 className='head' style={{ width: "10%" }}>{head.InvNo}</h1>
                      <h1 className='head' style={{ width: "10%" }}>{head.InvDate}</h1>
                      <h1 className='head' style={{ width: "20%" }}>{head.InvAmt}</h1>
                      <h1 className='head' style={{ width: "10%" }}>{head.DueDate}</h1>
                      <h1 className='head' style={{ width: "8%" }}>{head.FinanceCurrency}</h1>
                      <h1 className='head' style={{ width: "8%" }}>{head.SettlementCurrency}</h1>
                      <h1 className='head' style={{ width: "6%" }}>{head.attached}</h1>
                    </div>
                  )
                }) :
                Header_APF_Seller.map((head: any, index: number) => {
                  return (
                    <div key={index} className='HeadInnerDiv'>
                      <h1 className='head' style={{ width: "10%" }}>{head.Id}</h1>
                      <h1 className='head' style={{ width: "10%" }}>{head.Name}</h1>
                      <h1 className='head' style={{ width: "12%" }}>{head.InvNo}</h1>
                      <h1 className='head' style={{ width: "10%" }}>{head.InvDate}</h1>
                      <h1 className='head' style={{ width: "15%", textAlign: "center" }}>{head.InvAmt}</h1>
                      <h1 className='head' style={{ width: "10%" }}>{head.DueDate}</h1>
                      <h1 className='head' style={{ width: "8%" }}>{head.FinanceCurrency}</h1>
                      <h1 className='head' style={{ width: "8%" }}>{head.SettlementCurrency}</h1>
                      <h1 className='head' style={{ width: "6%" }}>{head.attached}</h1>
                    </div>
                  )
                })}

          </div>
          <div className='OuterDiv'>
            {props?.upload_detail?.party_details?.APF_buyer === true || props?.upload_detail?.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "APF_BUYER" ?
              completedInvoices && completedInvoices.map((record: any, index: number) => {
                return (
                  <div key={index} className='InnerDiv' style={{ alignItems: "unset" }}>

                    <h5 className='body' style={{ width: "10%" }}>
                      <Input
                        onChange={(e) => {
                          const arr = completedInvoices as any;
                          arr[index].invoices[0].SellerID = e.target.value
                          setCompletedInvoices(arr)
                          setBuyerIdError(true)

                          props.incompleteData(completedInvoices)
                        }}
                        defaultValue={record?.invoices?.[0]?.SellerID}
                        style={{ borderRadius: "5px", height: "45px" }}
                      />
                      {buyerIdError !== true ? record?.errors?.length > 0 ?
                        record?.errors?.map((err: any, index: number) => {
                          return <div className="errorMsg" key={index}> {err.SellerID}</div>
                        })
                        : "" : ""}
                    </h5>
                    <h5 className='body' style={{ width: "10%" }}>
                      <Input
                        onChange={(e) => {
                          const arr = completedInvoices as any;
                          arr[index].invoices[0].SellerName = e.target.value
                          setCompletedInvoices(arr)
                          props.incompleteData(completedInvoices)
                        }}
                        defaultValue={record?.invoices?.[0]?.SellerName}
                        style={{ borderRadius: "5px", height: "45px" }}
                      />

                      {record?.errors?.length > 0 ?
                        record?.errors?.map((err: any, index: number) => {
                          const value = buyerIdError === index
                          return value === true ? "" : <div className="errorMsg" key={index}> {err.SellerName}</div>
                        })
                        : ""}
                    </h5>
                    <h5 className='body' style={{ width: "10%" }}>
                      <div>
                        <Input
                          onChange={(e) => {
                            console.log("e.target.value",e.target.value,completedInvoices)
                            const arr = [completedInvoices] as any;
                            // arr[index].invoices[0].InvoiceNo = e.target.value
                            // setCompletedInvoices(arr)

                            // props.incompleteData(completedInvoices)
                            if (arr[index]?.invoices?.[0]) { 
                              arr[index].invoices[0].InvoiceNo = e.target.value;
                              setCompletedInvoices(arr);
                              props.incompleteData(completedInvoices);
                            }
                          }}
                          defaultValue={record?.invoices?.[0]?.InvoiceNo}
                          style={{ borderRadius: "5px", height: "45px" }}
                        />
                        {record?.errors?.length > 0 ?
                          record?.errors?.map((err: any, index: number) => {
                            return <div className="errorMsg" key={index}> {err.InvoiceNo}</div>
                          })
                          : ""}
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "10%" }}>
                      <div>
                        <Input
                          onChange={(e) => {
                            const arr = completedInvoices as any;
                            arr[index].invoices[0].InvoiceDate = e.target.value
                            setCompletedInvoices(arr)

                            props.incompleteData(completedInvoices)

                          }}
                          defaultValue={record ? record.invoices.length > 0 ? record?.invoices?.[0]?.InvoiceDate : "" : ""}
                          // defaultValue={record ? record.invoices.length > 0 ? moment(record?.invoices?.[0]?.InvoiceDate).format("DD-MM-YYYY") : "" : ""}
                          style={{ borderRadius: "5px", height: "45px" }}
                        />
                        {record?.errors?.length > 0 ?
                          record?.errors?.map((err: any, index: number) => {
                            return <div className="errorMsg" key={index}> {err.InvoiceDate}</div>
                          })
                          : ""}
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "20%" }}>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Input
                            onChange={(e) => {
                              const arr = completedInvoices as any;
                              arr[index].invoices[0].InvoiceCurrency = e.target.value
                              setCompletedInvoices(arr)

                              props.incompleteData(completedInvoices)

                            }}
                            defaultValue={`${record?.invoices?.[0]?.InvoiceCurrency} `}
                            style={{ borderRadius: "5px", height: "45px" }}
                          />
                        </Col>
                        <Col span={12}>
                          <Input
                            onChange={(e) => {
                              const arr = completedInvoices as any;
                              arr[index].invoices[0].InvoiceAmount = e.target.value
                              setCompletedInvoices(arr)

                            }}
                            defaultValue={`${record?.invoices?.[0]?.InvoiceAmount}`}
                            style={{ borderRadius: "5px", height: "45px" }}
                          />
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col span={12}>
                          {record?.errors?.length > 0 ?
                            record?.errors?.map((err: any, index: number) => {
                              return <div className="errorMsg" key={index}> {err.InvoiceCurrency}</div>
                            })
                            : ""}
                        </Col>
                        <Col span={12}>
                          {record?.errors?.length > 0 ?
                            record?.errors?.map((err: any, index: number) => {
                              return <div className="errorMsg" key={index}> {err.InvoiceAmount}</div>
                            })
                            : ""}
                        </Col>
                      </Row>


                    </h5>
                    <h5 className='body' style={{ width: "10%" }}>
                      <div>
                        <Input
                          onChange={(e) => {
                            const arr = completedInvoices as any;
                            arr[index].invoices[0].DueDate = e.target.value
                            setCompletedInvoices(arr)

                            props.incompleteData(completedInvoices)
                            if (e.target.value) {
                              setBuyerIdError(index)

                            } else {
                              setBuyerIdError(index)
                            }
                          }}
                          defaultValue={record ? record.invoices.length > 0 ? record.invoices[0].DueDate : "" : ""}
                          // defaultValue={record ? record.invoices.length > 0 ? moment(record.invoices[0].DueDate).format("DD-MM-YYYY") : "" : ""}
                          style={{ borderRadius: "5px", height: "45px" }}
                        />
                        {record?.errors?.length > 0 ?
                          record?.errors?.map((err: any) => {
                            return completedInvoices.map((item: any, index: any) => {
                              return index === buyerIdError ? <div key={index}></div>
                                :
                                <div className="errorMsg" key={index}> {err.DueDate}</div>
                            })
                          })
                          : ""}
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "8%" }}>
                      <div>
                        <Input
                          onChange={(e) => {
                            const arr = completedInvoices as any;
                            arr[index].invoices[0].FinancingCurrency = e.target.value
                            setCompletedInvoices(arr)

                          }}
                          defaultValue={record ? record.invoices.length > 0 ? record.invoices[0].FinancingCurrency : "" : ""}
                          style={{ borderRadius: "5px", height: "45px" }}
                        />
                        {record?.errors?.length > 0 ?
                          record?.errors?.map((err: any, index: number) => {
                            return <div className="errorMsg" key={index}> {err.FinancingCurrency}</div>
                          })
                          : ""}

                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "8%" }}>
                      <div>
                        <Input
                          onChange={(e) => {
                            const arr = completedInvoices as any;
                            arr[index].invoices[0].SettlementCurrency = e.target.value
                            setCompletedInvoices(arr)

                            props.incompleteData(completedInvoices)

                          }}
                          defaultValue={record ? record.invoices.length > 0 ? record.invoices[0].SettlementCurrency : "" : ""} style={{ borderRadius: "5px", height: "45px" }}
                        />
                        {record?.errors?.length > 0 ?
                          record?.errors?.map((err: any, index: number) => {
                            return <div className="errorMsg" key={index}>{err.SettlementCurrency}</div>
                          })
                          : ""}
                      </div>

                    </h5>
                    <h5 className='body' style={{ width: "3%", display: "flex",marginTop:0 }}>
                    <UploadImage selectedFiles={(value:any)=>selectedFiles(value, record)} attachmentFiles={[]} />
                    {/* <img src={FileAttachIcon} alt="fileAttach" onChange={()=>selectedFiles}/> */}

                    </h5>
                    <h5 className='body' style={{ width: "3%", display: "flex" }}>
                      {/* <img src={FileAttachIcon} alt="fileAttach" /> */}
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => deleteRecord(record)}
                      >
                        <img src={DeleteIcon} alt="delete" style={{ marginLeft: "20px" }} />
                      </Popconfirm>
                    </h5>
                  </div>
                )
              }) :
              completedInvoices ? completedInvoices.map((record: any, index: number) => {
                const currentBuyerIdError = buyerIdErrors[index];

                return (
                  <div key={index} className='InnerDiv'>
                    <div style={{ width: "10%" }}>
                      <h5 className='body' style={{ width: "90%" }}>
                        <Input

                          onChange={(e) => {
                            const arr: any = [...completedInvoices];
                            arr[index].invoices[0].BuyerID = e.target.value;
                            setCompletedInvoices(arr);

                            setBuyerIdErrors((prevErrors) => {
                              const newErrors = [...prevErrors];
                              newErrors[index] = e.target.value ? false : true;
                              return newErrors;
                            });

                            props.incompleteData(completedInvoices);
                          }}
                          defaultValue={record?.invoices?.[0]?.BuyerID}
                        />
                      </h5>
                      {currentBuyerIdError && record?.errors?.length > 0 ?
                        record?.errors?.map((err: any, errIndex: number) => (
                          <div className="errorMsg" key={errIndex}>{err.BuyerID}</div>
                        ))
                        : ""}
                    </div>


                    <h5 className='body' style={{ width: "10%" }}>
                      <Input
                        onChange={(e) => {
                          const arr = completedInvoices as any;
                          arr[index].invoices[0].BuyerName = e.target.value
                          setCompletedInvoices(arr)

                          props.incompleteData(completedInvoices)
                        }}
                        defaultValue={record?.invoices?.[0]?.BuyerName}
                      />

                      {record?.errors?.length > 0 ?
                        record?.errors?.map((err: any, index: number) => {
                          const value = buyerIdError === index
                          return value === true ? "" : <div className="errorMsg" key={index}> {err.BuyerName}</div>
                        })
                        : ""}
                    </h5>
                    <h5 className='body' style={{ width: "12%" }}>
                      <div>
                        <Input
                          onChange={(e) => {
                            const arr = completedInvoices as any;
                            arr[index].invoices[0].InvoiceNo = e.target.value
                            setCompletedInvoices(arr)

                            props.incompleteData(completedInvoices)

                          }}
                          defaultValue={record?.invoices?.[0]?.InvoiceNo}
                        />
                        {record?.errors?.length > 0 ?
                          record?.errors?.map((err: any, index: number) => {
                            return <div className="errorMsg" key={index}> {err.InvoiceNo}</div>
                          })
                          : ""}
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "10%" }}>
                      <div>
                        <Input
                          onChange={(e) => {
                            const arr = completedInvoices as any;
                            arr[index].invoices[0].InvoiceDate = e.target.value
                            setCompletedInvoices(arr)

                            props.incompleteData(completedInvoices)

                          }}
                          defaultValue={record?.invoices?.[0]?.InvoiceDate}
                        />
                        {record?.errors?.length > 0 ?
                          record?.errors?.map((err: any, index: number) => {
                            return <div className="errorMsg" key={index}> {err.InvoiceDate}</div>
                          })
                          : ""}
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "15%" }}>
                      <Row gutter={24}>
                        <Col span={7} style={{ paddingRight: "2px" }}>
                          <Input
                            onChange={(e) => {
                              const arr = completedInvoices as any;
                              arr[index].invoices[0].InvoiceCurrency = e.target.value
                              setCompletedInvoices(arr)

                              props.incompleteData(completedInvoices)

                            }}
                            defaultValue={`${record?.invoices?.[0]?.InvoiceCurrency} `}
                          />
                        </Col>
                        <Col span={17} style={{ paddingLeft: "2px" }}>
                          <Input
                            onChange={(e) => {
                              const arr = completedInvoices as any;
                              arr[index].invoices[0].InvoiceAmount = e.target.value
                              setCompletedInvoices(arr)

                            }}
                            defaultValue={`${record?.invoices?.[0]?.InvoiceAmount}`}
                          />
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col span={12}>
                          {record?.errors?.length > 0 ?
                            record?.errors?.map((err: any, index: number) => {
                              return <div className="errorMsg" key={index}> {err.InvoiceCurrency}</div>
                            })
                            : ""}
                        </Col>
                        <Col span={12}>
                          {record?.errors?.length > 0 ?
                            record?.errors?.map((err: any, index: number) => {
                              return <div className="errorMsg" key={index}> {err.InvoiceAmount}</div>
                            })
                            : ""}
                        </Col>
                      </Row>


                    </h5>
                    <h5 className='body' style={{ width: "10%" }}>
                      <div>
                        <Input
                          onChange={(e) => {
                            const arr = completedInvoices as any;
                            arr[index].invoices[0].DueDate = e.target.value
                            setCompletedInvoices(arr)

                            props.incompleteData(completedInvoices)
                            if (e.target.value) {
                              setBuyerIdError(index)
                            } else {
                              setBuyerIdError(false)

                            }
                          }}
                          defaultValue={record ? record.invoices.length > 0 ? record.invoices[0].DueDate : "" : ""}
                        />
                        {record?.errors?.length > 0 ?
                          record?.errors?.map((err: any) => {
                            return completedInvoices.map((item: any, index: any) => {
                              return index === buyerIdError ? <div></div> :
                                <div className="errorMsg" key={index}> {err.DueDate}</div>
                            })
                          })
                          : ""}
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "8%" }}>
                      <div>
                        <Input
                          onChange={(e) => {
                            const arr = completedInvoices as any;
                            arr[index].invoices[0].FinancingCurrency = e.target.value
                            setCompletedInvoices(arr)

                          }}
                          defaultValue={record ? record.invoices.length > 0 ? record.invoices[0].FinancingCurrency : "" : ""}
                        />
                        {record?.errors?.length > 0 ?
                          record?.errors?.map((err: any, index: number) => {
                            return <div className="errorMsg" key={index}> {err.FinancingCurrency}</div>
                          })
                          : ""}
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "8%" }}>
                      <div>
                        <Input
                          onChange={(e) => {
                            const arr = completedInvoices as any;
                            arr[index].invoices[0].SettlementCurrency = e.target.value
                            setCompletedInvoices(arr)
                            props.incompleteData(completedInvoices)

                          }}
                          defaultValue={record ? record.invoices.length > 0 ? record.invoices[0].SettlementCurrency : "" : ""}
                        />
                        {record?.errors?.length > 0 ?
                          record?.errors?.map((err: any, index: number) => {
                            return <div className="errorMsg" key={index}>{err.SettlementCurrency}</div>
                          })
                          : ""}
                      </div>

                    </h5>
                    <h5 className='body' style={{ width: "3%", display: "flex",marginTop:0  }}>
                    <UploadImage selectedFiles={(value:any)=>selectedFiles(value, record)} attachmentFiles={[]} />
                    {/* <img src={FileAttachIcon} alt="fileAttach" onClick={()=>selectedFiles}/> */}

                    </h5>
                    <h5 className='body' style={{ width: "3%", display: "flex" }}>
                      {/* <img src={FileAttachIcon} alt="fileAttach" onClick={()=>selectedFiles}/> */}
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => deleteRecord(record)}
                      >
                        <img src={DeleteIcon} alt="delete" style={{ marginLeft: "20px" }} />
                      </Popconfirm>
                    </h5>
                  </div>
                )
              }) : []}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}
export default IncompleteFiles;