import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis,Tooltip } from "recharts";
import { socketInfoDetails } from "../../../redux/action";
import { useSelector } from "react-redux";

const CustomizedAxisTick = (props:any)=>{
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`} >
      <text x={31} y={0} dy={7} textAnchor="end" fill="#666" transform="rotate(25)" style={{marginLeft:"10px",fontSize:"12px"}}>
        {moment(payload.value).format("DD/MM")}        
      </text>
    </g>
  )
}
const CustomizedYAxisTick = (props:any)=>{
  const { x, y, payload } = props;
  console.log("payload",payload,"x",x,"y",y)
  if (payload.value >= 1000 && payload.value < 1000000) return ((payload.value / 1000) + 'K')
  else if (payload.value >= 1000000) return ((payload.value / 1000000) + 'M');
  else return  <g transform={`translate(${x},${y})`} >
  <text textAnchor="end" fill="#666" style={{marginLeft:"10px",fontSize:"12px"}}>
    {payload.value}      
  </text>
</g>;

  // return (
  //   <g transform={`translate(${x},${y})`} >
  //     <text textAnchor="end" fill="#666" style={{marginLeft:"10px",fontSize:"12px"}}>
  //       {payload.value}      
  //     </text>
  //   </g>
  // )

}

export default function FundsExpected({ fundsExpected }: any) {
  const socketData = useSelector(socketInfoDetails)
  const Data = fundsExpected ? fundsExpected : socketData?.payload?.socketInfo?.dashboard_data?.Funds_Expected
  const dateFormat = (tickItem: any) => {
    return moment(tickItem).format("DD/MM")
  }
  return (
    <div style={{ padding: "24px" }}>
      <div>
        <h2>Funds Expected</h2>
      </div>
      {Data?.data?.length > 0 ?
      <BarChart width={500} height={290} data={Data?.data} style={{ strokeWidth: 1 }}
        margin={{ top: 5, right: 30, left: 20, bottom: 5  }} >
        <XAxis dataKey="due_date"
          label={{ value: 'Date', position: 'insideBottom', offset: 0, fontFamily: "Poppins", padding: '10px' }} axisLine={{ stroke: '#E3E3E3' }} tickFormatter={dateFormat} tick={<CustomizedAxisTick/>}/>
        <YAxis scale="auto"
          tickFormatter={(tick) => {
            if (tick >= 1000 && tick < 1000000) return ((tick / 1000) + 'K')
            else if (tick >= 1000000) return ((tick / 1000000) + 'M');
            else return tick;
          }} dx={-5 }
          axisLine={{ stroke: '#E3E3E3' }} label={{ value: 'Amount (INR)', angle: -90, position: 'insideLeft', fontFamily: "Poppins" }} tickCount={6} 
          // tick={<CustomizedYAxisTick/>} 
          />
        <Bar dataKey="amount" fill="#ff8368" barSize={15} />
        <Tooltip cursor={{fill: 'transparent'}} />
      </BarChart>
      :<div style={{margin:"auto",fontSize:"20px",textAlign:"center",alignContent:"center",marginTop:"20%"}}>No Data</div>}
    </div>
  );
}
