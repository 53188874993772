
export const InterestDeduction = [
        {
            description: "Financing",
            id: "FINANCING"
        },
        {
            description: "Repayment",
            id: "REPAYMENT"
        },
    ]
   


export const InterestPaidByData = [
        {
            description: "Own Party",
            id: "OWNPARTY"
        },
        {
            description: "Counter Party",
            id: "COUNTERPARTY"
        },
    ]