import { useState } from "react";
import images from "../../../assets/images";

export const UploadImage = (props) => {
  const { FileAttachIcon } = images
  const [selectedFile, setSelectedFile] = useState([]);

  const changeHandler = (e) => {
    const files = e.target.files;
    if (files.length) {
      const file = Array.prototype.slice.call(files);
      const uploaded = [...selectedFile];
      const uploadedDetails = [...selectedFile];
      setSelectedFile(uploaded, uploaded.push(file));
      props.selectedFiles(uploadedDetails, uploadedDetails.push(file));
    }
  };

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
        }}
      >
        <label
          htmlFor="filePicker"
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            margin: "auto",
            cursor: "pointer"
          }}
        >
          <img src={FileAttachIcon} alt="fileAttach" />

        </label>
      </div>

      <input
        id="filePicker"
        type="file"
        style={{ border: "1px solid #000000", visibility: "hidden", marginTop: "-10px" }}
        name="file"
        onChange={changeHandler}
      />

    </>
  );
};
