import React, { useState, useEffect } from "react";
import { Input, Select, DatePicker, InputNumber, message, Card } from "antd";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import moment from "moment";
import { UploadImage } from "../../../common/UploadImage/manualInvoiceUpload";
import images from "../../../../assets/images";
import { useNavigate, useLocation } from "react-router";
import { Action, ResponseStatus, TransactionType } from "../../../../utils/enum/choices";
import Breadcrumbs from "../../../common/heading/breadcrumb";
import { transition } from "../../../api/finfloTransition";
const Header_RF_Seller = [{
  Id: "Buyer Id", Name: "Buyer Name", InvNo: "Inv No", InvDate: "Inv Date", InvAmt: "Inv Amount", DueDate: "Due Date", FinanceCurrency: "Financing Currency", SettlementCurrency: "Settlement Currency", autoFinance: "Auto Finance", attached: "Attached",
}]
const RfBuyer = () => {
  const Navigate = useNavigate()
  const { state } = useLocation();

  const { TextArea } = Input;
  const { Option } = Select;
  const { DatePickerImg, DropdownIcon } = images
  const [counterpartyId, setCounterpartyId] = useState([] as any);
  const [commentsValue, setCommentsvalue] = useState(null as any);
  const [MaxInvoicePercent, setMaxInvoicePercent] = useState("");
  const [counterPartyList, setCounterPartyList] = useState([] as any);
  const [buyerName, setBuyerName] = useState([] as any);

  const [currencyList, SetCurrencyList] = useState([]);
  const [fileList, setFileList] = useState([] as any);
  const [PartyAccountDetails, setPartyAccountDetails] = useState([] as any);
  const [FinanceAmountError, setFinanceAmountError] = useState(false)
  const [FinanceAmountErrorMessage, setFinanceAmountErrorMessage] = useState("")
  const invoice_detail = state as any
  const [buyerIdError, setBuyerIdError] = useState(false)
  const [rfBuyerdataSource, setrfBuyerDataSource] = useState([
    {
      BuyerID: "" as any,
      BuyerName: buyerName as any,
      InvoiceNo: "" as any,
      InvoiceDate: "" as any,
      InvoiceCurrency: "" as any,
      InvoiceAmount: "" as any,
      DueDate: "" as any,
      FinanceAmount: 0 as any,
      FinancingCurrency: "" as any,
      RepaymentAccount: "" as any,
      RepaymentCurrency: 0 as any,
      counterparty_id: counterpartyId as any,
      RepaymentID: 0 as any,
      auto_finance: "" as any,
      ProgramID: invoice_detail?.id
    }] as any)

  const selectedFiles = (value: any) => {
    setFileList(value)
  }
  const getPartyAccounts = () => {
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/party/party-accounts/`)
      .then((resp: any) => {
        setPartyAccountDetails(resp.data.data)
      })
      .catch(() => {
        Navigate("/Notfound")
      })
  }
  useEffect(() => {
    getPartyAccounts();
    setrfBuyerDataSource(
      [
        {
          BuyerID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.BuyerID,
          BuyerName: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.BuyerName,
          InvoiceNo: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceNo,
          InvoiceDate: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceDate,
          InvoiceCurrency: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceCurrency,
          InvoiceAmount: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceAmount,
          DueDate: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.DueDate,
          FinanceAmount: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.FinanceAmount,
          FinancingCurrency: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.FinancingCurrency,
          RepaymentAccount: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.RepaymentAccount,
          RepaymentCurrency: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.RepaymentCurrency,
          counterparty_id: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.counterparty_id,
          RepaymentID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.RepaymentID,
          auto_finance: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.auto_finance,
          ProgramID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.ProgramID
        }]
    )

    if (invoice_detail?.data?.record_datas?.values?.[0]?.fields?.errors?.[0]?.[0]?.BuyerID) {
      setBuyerIdError(true)
    }

    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        SetCurrencyList(resp.data.data);
      })
      .catch(() => {
        Navigate("/Notfound")
      })

  }, [])
  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };
  const disabledInvoiceDate = (current: any) => {
    return current && current > moment().endOf("day");
  }

  const data = {
    fromMenu: "Invoice",
    data: {
      type: "bulk upload Edit"
    },
    recordType: ""
  }
  const onClickExit = () => {
    Navigate("/Draft");
  };

  const returnRoute = (data: any) => {
    if (data === true) {
      Navigate("/Draft");
    }
  }
  const buttonStatus = (data: any) => {
    console.log("program button", data)
  }
  const onClickSubmit = (buttonkey: string) => {
    console.log("invoice_detail rf submit", buttonkey, invoice_detail?.data?.record_datas?.values?.[0]?.fields?.is_finished === false, "rfBuyerdataSource", rfBuyerdataSource)
    console.log("invoice_detail rf fileList", fileList)
    if (buttonkey === "SUBMIT" && invoice_detail?.data?.record_datas?.values?.[0]?.fields?.is_finished === true) {
      if (fileList.length >0) {
        const formdata = new FormData();
        fileList.map((item: any) => {
          formdata.append(`files`, item[0]);
          formdata.append(`comments`, "bulkUploadfile");
        });
        formdata.append("invoice_upload", invoice_detail?.data?.work_model?.t_id);

        httpClient
          .getInstance()
          .post(`${baseurl}api/resource/file/`, formdata)

          .catch(() => {
            Navigate("/Notfound")
          })
      }


      console.log("sub")
      const body = {
        action:Action.SUBMIT,
        type:TransactionType.TINVOICEUPLOADS,
        t_id:invoice_detail?.data?.work_model?.t_id
    }
        httpClient
            .getInstance()
            .post(`${baseurl}api/resource/action/status/`,body)
            .then((resp: any) => {
                console.log("resp action",resp)
                console.log("resp action",resp?.data)
                
                if (resp.data.status === ResponseStatus.SUCCESS) {
                    message.success(resp?.data?.transition_status)
                    buttonStatus(false)
                    Navigate("/Draft");
                    // returnRoute(true)
                    // transition(info, buttonKey === Action.DRAFT ? "SUBMIT" : buttonKey, resp.data.data.from_party, resp.data.data.to_party, info.fromMenu, returnRoute, buttonStatus)
                } else {
                    message.error(resp.data.data)
                    buttonStatus(false)
                }
            })

      // httpClient
      //   .getInstance()
      //   .get(`${baseurl}api/resource/action/status/?action=${Action.SUBMIT}&type=${TransactionType.TINVOICEUPLOADS}&t_id=${invoice_detail?.data?.work_model?.t_id}`)
      //   .then((response: any) => {
      //     if (response.data.status === ResponseStatus.SUCCESS) {
      //       transition(response.data.data, "Submit", response.data.data.from_party, response.data.data.to_party, "New", returnRoute, buttonStatus)

      //     } else {
      //       message.error(response.data.data)
      //     }
      //   })
      //   .catch(() => {
      //     Navigate("/Notfound")
      //   })

    } else if (buttonkey === "delete" && invoice_detail?.data?.record_datas?.values?.[0]?.fields?.is_finished === true) {
      httpClient
        .getInstance()
        .delete(`${baseurl}api/invoice/upload/${invoice_detail?.data?.work_model?.t_id}/`)
        .then((resp: any) => {
          if (resp.data.status === ResponseStatus.SUCCESS) {
            Navigate("/Draft")
            message.success(resp.data.data)
          } else {
            message.error(resp.data.status)
          }
        })
        .catch(() => {
          Navigate("/Notfound")
        })
    } else if (buttonkey === "SUBMIT" && invoice_detail?.data?.record_datas?.values?.[0]?.fields?.is_finished === false) {

      const body = {
        invoices: [{
          BuyerID: rfBuyerdataSource?.[0]?.BuyerID,
          BuyerName: rfBuyerdataSource?.[0]?.BuyerName,
          InvoiceNo: rfBuyerdataSource?.[0]?.InvoiceNo,
          InvoiceDate: rfBuyerdataSource?.[0]?.InvoiceDate,
          InvoiceCurrency: rfBuyerdataSource?.[0]?.InvoiceCurrency,
          InvoiceAmount: rfBuyerdataSource?.[0]?.InvoiceAmount,
          DueDate: rfBuyerdataSource?.[0]?.DueDate,
          FinancingCurrency: rfBuyerdataSource?.[0]?.FinancingCurrency,
          SettlementCurrency: rfBuyerdataSource?.[0]?.InvoiceCurrency,
          ProgramID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.ProgramID,
        }]
      }
      console.log("invoice_detail rf body", body, "o", invoice_detail?.data?.record_datas?.values?.[0]?.fields, "0", invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices, "6", invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.ProgramID)
      return httpClient
        .getInstance()
        .put(`${baseurl}api/invoice/upload/${invoice_detail?.data?.work_model?.t_id}/`, body)
        .then((resp) => {
          console.log("invoice_detail rf resp", resp.data.data, resp.data.data?.is_finished, resp.data.data?.[0]?.is_finished)
          if (resp.data.status === ResponseStatus.SUCCESS) {
            if (fileList.length >0) {
              const formdata = new FormData();
              fileList.map((item: any) => {
                formdata.append(`files`, item[0]);
                formdata.append(`comments`, "bulkUploadfile");
              });
              formdata.append("invoice_upload", invoice_detail?.data?.work_model?.t_id);

              httpClient
                .getInstance()
                .post(`${baseurl}api/resource/file/`, formdata)
                .catch(() => {
                  Navigate("/Notfound")
                })
            }
            if (resp.data?.data?.is_finished === true) {
              message.success("Invoice Uploaded Successfully")

              const body = {
                action:Action.SUBMIT,
                type:TransactionType.TINVOICEUPLOADS,
                t_id:invoice_detail?.data?.work_model?.t_id
            }
                httpClient
                    .getInstance()
                    .post(`${baseurl}api/resource/action/status/`,body)
                    .then((resp: any) => {
                        
                        if (resp.data.status === ResponseStatus.SUCCESS) {
                            message.success(resp?.data?.transition_status)
                            buttonStatus(false)
                            Navigate("/Draft");
                            // returnRoute(true)
                        } else {
                            message.error(resp.data.data)
                            buttonStatus(false)
                        }
                    })

              // httpClient
              //   .getInstance()
              //   .get(`${baseurl}api/resource/action/status/?action=${Action.SUBMIT}&type=${TransactionType.TINVOICEUPLOADS}&t_id=${invoice_detail?.data?.work_model?.t_id}`)
              //   .then((response: any) => {
              //     if (response.data.status === ResponseStatus.SUCCESS) {
              //       transition(response.data.data, "Submit", response.data.data.from_party, response.data.data.to_party, "New", returnRoute, buttonStatus)

              //     } else {
              //       message.error(response.data.data)
              //     }
              //   })
              //   .catch(() => {
              //     Navigate("/Notfound")
              //   })
            } else {
              if (resp.data?.data?.errors?.[0]?.[0]?.BuyerID) {
                setBuyerIdError(true)
              }
            }

          }
        })
        .catch(() => {
          Navigate("/Notfound")
        })
    }
    else if (buttonkey === "delete" && invoice_detail?.data?.record_datas?.values?.[0]?.fields?.is_finished === false) {
      httpClient
        .getInstance()
        .delete(`${baseurl}api/invoice/upload/${invoice_detail?.data?.work_model?.t_id}/`)
        .then((resp: any) => {
          if (resp.data.status === ResponseStatus.SUCCESS) {
            Navigate("/Draft")
            message.success(resp.data.data)
          } else {
            message.error(resp.data.status)
          }
        })
        .catch(() => {
          Navigate("/Notfound")
        })
    }

  }
  return (
    <React.Fragment>
      <div className="fixedContainer">
        <Breadcrumbs Data={data}
          onClickExit={onClickExit}
          commentsValue={commentsValue}
          flag="bulkUploadEdit"
          onClickAction={onClickSubmit}
          buttonStatusData={""}
        />
      </div>

      <div className="mainContentContainer">
        <Card className="CardContainer">
          <div className='containerTable '>
            <div className="OuterDiv">
              {
                Header_RF_Seller && Header_RF_Seller.map((item: any, index: any) => {
                  return (
                    <div key={index} className='HeadInnerDiv'>
                      <h1 className='head' style={{ width: "12%" }}>{item.Id}</h1>
                      <h1 className='head' style={{ width: "12%" }}>{item.Name}</h1>
                      <h1 className='head' style={{ width: "10%" }}>{item.InvNo}</h1>
                      <h1 className='head' style={{ width: "12%" }}>{item.InvDate}</h1>
                      <h1 className='head' style={{ width: "20%" }}>{item.InvAmt}</h1>
                      <h1 className='head' style={{ width: "12%" }}>{item.DueDate}</h1>
                      <h1 className='head' style={{ width: "10%" }}>{item.FinanceCurrency}</h1>
                      <h1 className='head' style={{ width: "10%" }}>{item.SettlementCurrency}</h1>
                      <h1 className='head' style={{ width: "6%" }}>{item.attached}</h1>
                    </div>
                  )
                })

              }
            </div>
            <div className='OuterDiv'>
              {console.log("invoice_detail rf rf", rfBuyerdataSource)}
              {
                invoice_detail?.data?.record_datas?.values?.[0]?.fields?.is_finished === false ?
                  rfBuyerdataSource && rfBuyerdataSource.map((record: any, index: number) => {
                    console.log("invoice_detail rf record", record, buyerName)
                    return (
                      <div key={index} className='InnerDiv' >
                        <h5 className='body' style={{ width: "12%" }}>
                          <Input
                            className="InputContainer"

                            onChange={(event: any) => {
                              console.log("event", event.target.value)
                              const array = [...rfBuyerdataSource];
                              array[index].BuyerID = event.target.value;
                              setBuyerIdError(false)
                              setrfBuyerDataSource(array);
                            }}
                            defaultValue={rfBuyerdataSource[index].BuyerID}
                            value={rfBuyerdataSource[index].BuyerID}
                            id={`invRfBuyer_InvoiceNumber${index}`}
                            style={{ padding: "4px", height: "43px", borderRadius: "10px" }}
                          />
                          {console.log("invoice_detail rf erroes", invoice_detail?.data?.record_datas?.values?.[0]?.fields?.errors?.[0]?.[0]?.BuyerID, "0", invoice_detail?.data?.record_datas?.values?.[0]?.fields?.errors?.[0]?.[0])}
                          {buyerIdError === false ? null : <div className="errorMsg"> {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.errors?.[0]?.[0]?.BuyerID ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.errors?.[0]?.[0]?.BuyerID : null}</div>}
                        </h5>
                        <h5 className='body' style={{ width: "12%", fontSize: "10px", margin: "8px 2px" }}>
                          <Input
                            onChange={(e) => {
                              const arr = [...rfBuyerdataSource];
                              arr[index].BuyerName = e.target.value
                              setrfBuyerDataSource(arr)
                            }}
                            defaultValue={rfBuyerdataSource[index].BuyerName}
                            value={rfBuyerdataSource[index].BuyerName}
                            style={{ height: "43px", borderRadius: "10px" }}
                          />

                        </h5>

                        <h5 className='body' style={{ width: "10%", margin: "8px 2px" }}>
                          {console.log("counterPartyList", counterPartyList)}
                          <div >
                            <Input
                              className="InputContainer"

                              onChange={(event: any) => {
                                console.log("event", event.target.value)
                                const array = [...rfBuyerdataSource];
                                array[index].InvoiceNo = event.target.value;
                                if (buyerName.length === 1) {
                                  setCounterpartyId(counterPartyList?.[0]?.counter_party)
                                  setMaxInvoicePercent(counterPartyList?.[0]?.max_invoice_percent)
                                  array[index].BuyerID = counterPartyList?.[0]?.id
                                  array[index].BuyerName = counterPartyList?.[0]?.counter_party_name
                                  array[index].counterparty_id = counterPartyList?.[0]?.counter_party
                                }
                                setrfBuyerDataSource(array);
                              }}
                              defaultValue={rfBuyerdataSource[index].InvoiceNo}
                              value={rfBuyerdataSource[index].InvoiceNo}
                              id={`invRfBuyer_InvoiceNumber${index}`}
                              style={{ padding: "4px", height: "43px", borderRadius: "10px" }}
                            />

                          </div>
                        </h5>
                        <h5 className='body' style={{ width: "12%", margin: "8px 2px" }}>

                          <DatePicker
                            format="DD-MM-YYYY"
                            suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}
                            placeholder={record.InvoiceDate}
                            onChange={(event: any) => {
                              const array = [...rfBuyerdataSource];
                              array[index].InvoiceDate = moment(event._d).format("DD-MM-YYYY");
                              setrfBuyerDataSource(array);
                            }}
                            style={{ padding: "4px", height: "43px", borderRadius: "10px" }}
                            className={record.InvoiceDate ? "valueExpiryDate InputContainer" : "InputContainer"}
                            disabledDate={disabledInvoiceDate}
                            id={`invRfBuyer_InvoiceDate${index}`}
                          />

                        </h5>

                        <h5 className='body' style={{ width: "20%", margin: "8px 2px" }}>
                          <div
                          >
                            <span
                            >
                              <Select
                                showSearch
                                style={{ width: 64, padding: "4px", height: "43px", borderRadius: "10px" }}
                                onSelect={(event: string) => {
                                  const array = [...rfBuyerdataSource];
                                  array[index].InvoiceCurrency = event;
                                  array[index].FinancingCurrency = event;
                                  setrfBuyerDataSource(array);
                                }}
                                defaultValue={record.InvoiceCurrency}
                                value={record.InvoiceCurrency}
                                suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                                id={`invRfBuyer_InvoiceCurrency${index}`}
                              >
                                {currencyList &&
                                  currencyList.map((item: any) => {
                                    return (
                                      <Option
                                        value={item.description}
                                        id={item.description}
                                        key={item.description}
                                      >
                                        {item.description}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </span>
                            {console.log("MaxInvoicePercent", MaxInvoicePercent)}
                            <InputNumber
                              className="InputContainer"
                              disabled={!record.BuyerName}
                              defaultValue={record.InvoiceAmount}
                              value={record.InvoiceAmount}
                              onChange={(event: any) => {
                                const array = [...rfBuyerdataSource];
                                array[index].InvoiceAmount = parseInt(event);
                                array[index].FinanceAmount = (parseInt(event) * (parseInt(MaxInvoicePercent) / 100)).toFixed(2)
                                console.log("event event", (parseInt(event) * (parseInt(MaxInvoicePercent) / 100)).toFixed(2))
                                setrfBuyerDataSource(array)
                              }}
                              formatter={(value, e) => {
                                console.log("e limit", e)
                                if (value === "" || isNaN(parseFloat(value))) {
                                  return "";
                                }
                                return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                                  : value
                              }}
                              id={`invRfBuyer_InvoiceAmount${index}`}
                              style={{ padding: "5px 4px", height: "43px", borderRadius: "10px" }}
                            />
                          </div>
                        </h5>
                        <h5 className='body' style={{ width: "12%", margin: "8px 2px" }}>
                          <div>
                            <DatePicker
                              disabledDate={disabledDate}
                              format="DD-MM-YYYY"
                              suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}
                              placeholder={record.DueDate}
                              onChange={(event: any) => {
                                const array = [...rfBuyerdataSource];
                                array[index].DueDate = moment(event._d).format("DD-MM-YYYY");
                                array[index].RepaymentAccount = PartyAccountDetails?.[0]?.account_number;
                                PartyAccountDetails &&
                                  PartyAccountDetails.map((item: any) => {
                                    return PartyAccountDetails?.[0]?.account_number === item.account_number ?
                                      currencyList &&
                                      currencyList.map((currency: any) => {
                                        return currency.id === item.currency ?
                                          (array[index].RepaymentCurrency = currency.description,
                                            array[index].RepaymentID = item.id) : null
                                      }) : null
                                  })

                                setrfBuyerDataSource(array);
                              }}
                              style={{ padding: "4px", height: "43px", borderRadius: "10px" }}
                              className={record.DueDate ? "valueExpiryDate InputContainer" : "InputContainer"}
                              id={`invRfBuyer_DueDate${index}`}
                            />
                          </div>
                        </h5>
                        <h5 className='body' style={{ width: "10%", margin: "8px 2px", height: "43px", borderRadius: "10px" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <span id={`invRfBuyer_FinanceCurrency${index}`} style={{
                              marginRight: "3px", border: "1px solid #d9d9d9", width: "48%", height: " 45px", borderRadius: "10px",
                              padding: "9px",
                              backgroundColor: "#FFFFFF"
                            }}>
                              {record.InvoiceCurrency}

                            </span>


                          </div>
                          {FinanceAmountError ? <span style={{ color: "red" }}>Finance Amount should not be greater than {FinanceAmountErrorMessage}</span> : null}

                        </h5>
                        <h5 className='body' style={{ width: "10%", margin: "8px 2px" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <span id={`invRfBuyer_FinanceCurrency${index}`} style={{
                              marginRight: "3px", border: "1px solid #d9d9d9", width: "48%", height: " 45px", borderRadius: "10px",
                              padding: "9px",
                              backgroundColor: "#FFFFFF"
                            }}>
                              {record.InvoiceCurrency}

                            </span>
                          </div>
                        </h5>

                        <h5 className='body' style={{ width: "6%", margin: "8px 2px" }} id={`invRfBuyer_attachments${index}`}>
                          <UploadImage selectedFiles={selectedFiles} attachmentFiles={[]} />
                        </h5>
                      </div>
                    )
                  })
                  :
                  rfBuyerdataSource && rfBuyerdataSource.map((record: any, index: number) => {
                    console.log("invoice_detail rf record", record, buyerName)
                    return (
                      <div key={index} className='InnerDiv' >
                        <h5 className='body'>
                          {record?.BuyerID}
                        </h5>
                        <h5 className='body' style={{ width: "12%", margin: "8px 2px" }}>
                          {record.BuyerName}
                        </h5>

                        <h5 className='body' style={{ width: "10%", margin: "8px 2px" }}>
                          {record?.InvoiceNo}
                        </h5>
                        <h5 className='body' style={{ width: "12%", margin: "8px 2px" }}>

                          {record.InvoiceDate}

                        </h5>

                        <h5 className='body' style={{ width: "20%", margin: "8px 2px" }}>
                          {record.InvoiceCurrency + " " + record.InvoiceAmount}
                        </h5>
                        <h5 className='body' style={{ width: "12%", margin: "8px 2px" }}>
                          {record.DueDate}
                        </h5>
                        <h5 className='body' style={{ width: "10%", margin: "8px 2px" }}>
                          {record.FinancingCurrency}

                        </h5>
                        <h5 className='body' style={{ width: "10%", margin: "8px 2px" }}>
                          {record.SettlementCurrency ? record.SettlementCurrency : record.FinancingCurrency}
                        </h5>
                        <h5 className='body' style={{ width: "6%", margin: "8px 2px" }} id={`invRfBuyer_attachments${index}`}>
                          <UploadImage selectedFiles={selectedFiles} attachmentFiles={[]} />
                        </h5>
                      </div>
                    )
                  })
              }
            </div>
          </div>
          <div className="SummaryContainer" style={{ marginTop: "5%" }}>
            <div className="SummaryLabel">Comments</div>
            <TextArea
              style={{ width: "50%", margin: "0px 10px" }}
              onChange={(e: any) => setCommentsvalue(e.target.value)} id="comments"
            />
          </div>
        </Card>
      </div>
    </React.Fragment>
  )
}
export default RfBuyer