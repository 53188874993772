
import { useState, useEffect } from "react";
import { Spin, Popconfirm } from "antd";
import images from "../../../../assets/images";
import "../../new.scss";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import {UploadImage} from "../../../common/UploadImage/manualInvoiceUpload"
const { DeleteIcon, FileAttachIcon } = images;

const CompleteFiles = (props: any) => {
  const [completedInvoices, setCompletedInvoices] = useState(
    []);
  const [loading, setLoadings] = useState(true)
  const [fileList, setFileList] = useState([] as any);

  const HeadersData = props?.upload_detail?.party_details?.APF_buyer === true || props?.upload_detail?.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "APF_BUYER"  ?
    [{
      Type: 'Seller Id', Date: 'Seller Name', Status: 'Inv No', From: 'Inv Date', dueDate: "Due Date", Program: 'Invoice Amount', Amount: 'Financing Currency', Due: 'Settlement Currency', Attached: 'Attached'
    }]
    : [{
      Type: 'Buyer Id', Date: 'Buyer Name', Status: 'Inv No', From: 'Inv Date', dueDate: "Due Date", Program: 'Invoice Amount', Amount: 'Financing Currency', Due: 'Settlement Currency', Attached: 'Attached'
    }]
  useEffect(() => {
    completedRecords();
  }, [])

  const completedRecords = () => {
    httpClient
      .getInstance()
      .get(`${baseurl}api/invoice/upload/?type=YES`)
      .then((resp: any) => {
        setLoadings(false);
        setCompletedInvoices(resp.data.data)
        props.completedData(resp.data.data)
      })
  }

  const handleDelete = (data: any) => {
    setLoadings(true);
    httpClient
      .getInstance()
      .delete(`${baseurl}api/invoice/upload/${data.id}/`)
      .then(() => {
        setLoadings(false);
        completedRecords();
      }).catch(() => {
        setLoadings(false);

      })
  }
  const selectedFiles = (value: any,record:any) => {
    console.log("value",value,"record",record)
    setFileList(value)
    const formdata = new FormData();
    fileList.map((item: any) => {
      formdata.append(`files`, item[0]);
      formdata.append(`comments`, "bulkUploadfile");
    });
    formdata.append("invoice_upload", record?.id);
    if(record?.attachments?.file?.length >0){
      httpClient
      .getInstance()
      .put(`${baseurl}api/resource/file/${record?.attachments?.file?.[0]?.id}/`, formdata)
      // .then((response: any) => {
      // })
    }else{
  
      httpClient
        .getInstance()
        .post(`${baseurl}api/resource/file/`, formdata)
        
    }
   
  }
  return loading ? <Spin /> : (
    <div className="ManualContainer" style={{ marginTop: "0" }}>
      <div className='containerTable'>
        <div className="OuterDiv">
          {HeadersData.map((head: any, index: number) => {
            return (
              <div key={index} className='HeadInnerDiv'>
                <h1 className='head' style={{ width: "11%" }}>{head.Type}</h1>
                <h1 className='head' style={{ width: "10%" }}>{head.Date}</h1>
                <h1 className='head' style={{ width: "10%" }}>{head.Status}</h1>
                <h1 className='head' style={{ width: "12%" }}>{head.From}</h1>
                <h1 className='head' style={{ width: "15%" }}>{head.Program}</h1>
                <h1 className='head' style={{ width: "10%" }}>{head.dueDate}</h1>
                <h1 className='head' style={{ width: "10%" }}>{head.Amount}</h1>
                <h1 className='head' style={{ width: "10%" }}>{head.Due}</h1>
                <h1 className='head' style={{ width: "6%" }}>{head.Attached}</h1>
              </div>
            )
          })}
        </div>
        <div className='BodyOuterDiv'>
          {completedInvoices && completedInvoices.map((record: any, index: number) => {
            return (
              <div key={index} className='InnerDiv'>
                <h5 className='body' style={{ width: "11%" }}>
                  {props?.upload_detail?.party_details?.APF_buyer === true || props?.upload_detail?.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "APF_BUYER" ? record?.invoices?.[0]?.SellerID : record?.invoices?.[0]?.BuyerID}
                </h5>
                <h5 className='body' style={{ width: "10%" }}>
                  {props?.upload_detail?.party_details?.APF_buyer === true || props?.upload_detail?.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "APF_BUYER" ? record?.invoices?.[0]?.SellerName : record?.invoices?.[0]?.BuyerName}
                </h5>
                <h5 className='body' style={{ width: "10%" }}>
                  {record?.invoices?.[0]?.InvoiceNo}
                </h5>
                <h5 className='body' style={{ width: "12%" }}>
                  <div>
                    {record?.invoices?.[0]?.InvoiceDate}
                  </div>
                </h5>
                <h5 className='body' style={{ width: "15%" }}>
                  <span>{record?.invoices?.[0]?.InvoiceCurrency} </span>
                  {record?.invoices?.[0]?.InvoiceAmount}
                </h5>
                <h5 className='body' style={{ width: "10%" }}>
                  <div>
                    {record?.invoices?.[0]?.DueDate}
                  </div>
                </h5>
                <h5 className='body' style={{ width: "10%" }}>
                  <div>
                    {record?.invoices?.[0]?.FinancingCurrency}
                  </div>
                </h5>
                <h5 className='body' style={{ width: "10%" }}>
                  {record?.invoices?.[0]?.SettlementCurrency}

                </h5>
                <h5 className='body' style={{ width: "3%", display: "flex",marginTop:0  }}>
                    <UploadImage selectedFiles={(value:any)=>selectedFiles(value, record)} attachmentFiles={[]} />
</h5>
                <h5 className='body' style={{ width: "3%", display: "flex" }}>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => handleDelete(record)}
                  >
                    <img src={DeleteIcon} alt="delete" />
                  </Popconfirm>
                </h5>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
export default CompleteFiles;




