import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import "./common.scss";
import axios from "axios";
import baseurl from "../../utils/config/url/base";
import { ResponseStatus } from "../../utils/enum/choices"
interface IProps {
  inputValues: any;
  buttonValue: string;
  onFinish: any;
  flag: string;
  disableButton: boolean
  logindetails: any
}
const Label = (props: React.PropsWithChildren<IProps>) => {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const onFinish = (values: any) => {
    props.onFinish(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo)
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    const body = {
      data: props.logindetails.toLowerCase(),
      //env:"DEV"
      env: "TEST"
    };

    axios
      .post(`${baseurl}api-auth/otp/`, body)
      .then((resp: any) => {
        if (resp.data.status === ResponseStatus.FAILURE) {
          resp.data.detail ? message.error(resp.data.detail) : message.error(resp.data.data)
        } else {
          message.success("Resend OTP sent successfully", 3)
          setSeconds(30);
          setMinutes(1);
        }
      }).catch((err: any) => {
        console.log(err)
      })

  }

  return (
    <div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        {props.inputValues &&
          props.inputValues.map((data: any) => {
            return (
              <Form.Item
                label={data.inputLabel}
                name={data.keyword}
                className="FormContainer"
                key={data.keyword}
                rules={[
                  {
                    required: data.required,
                    pattern: data.pattern,
                    message: data.errorMessage,
                  },
                ]}
              >
                <Input placeholder={data.placeholder} id={data.id} autoFocus={props?.flag === "Login" || data.keyword === "otp" ? true : false} />
              </Form.Item>
            );
          })}

        {props.flag === "Otp" ? (
          <>
            <div className="resendOtpContainer">
              {seconds > 0 || minutes > 0 ? (
                <p>
                  Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <div className="resendOtpContainer">Don't receive the OTP ?  <Button type="default" disabled={seconds > 0 || minutes > 0} onClick={resendOTP}>Resend OTP</Button></div>
              )}
            </div>

          </>
        ) : ("")}
        {props.flag === "CreateNewAccount" ? (
          <div className="buttonGroup">
            <Form.Item>
              <Button type="default" className="ExitBtnLabel">
                Exit
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="default" htmlType="submit" className="ExitBtnLabel" >
                {props.buttonValue}
              </Button>
            </Form.Item>
          </div>
        ) : (
          <Form.Item>
            <Button type="primary" htmlType="submit" className="loginBtnLabel" disabled={props.disableButton} loading={props.disableButton} id="loginSubmit">
              {props.buttonValue}
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
};
export default Label;
