import { useState, useEffect, useRef } from "react";
import { Select, Input, Card, Tooltip, message } from "antd";
import sendIcon from "../../assets/images/send.png";
import chatBaseurl from "../../utils/config/url/chat";
import baseurl from "../../utils/config/url/base";
import httpClient from "../../utils/config/core/httpClient";
import Conversation from "./conversation"
import { chatSocketList } from "../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import chatsocketurl from "../../utils/config/url/chatSocket";
const Chat = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [bankUserChat, setBankUserChat] = useState([])
  const [buyerUserChat, setBuyerUserChat] = useState([])
  const [partyUserChat, setPartyUserChat] = useState([]);
  const [selectedMainUsers, setSelectedMainUsers] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [xpathData, setXpathData] = useState([]);
  const [DomainData, setDomainData] = useState({} as any);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectMembers, setSelectedMembers] = useState([]);
  const [messageValue, setMessageValue] = useState("");
  const [displayMsgCard, setDisplayMsgCard] = useState(false);
  const [showConversation, setShowConversation] = useState(false)
  const [configId, setConfigId] = useState("" as string);
  const loginData = localStorage.getItem("login_detail") || "";
  const logindata = JSON.parse(loginData);
  const loginUser = localStorage.getItem("login_email") || ""
  const updatedMsg = useRef([]);
  const scrollto = useRef(null) as any;
  const chatListData = useSelector(chatSocketList);
  console.log("chat chatListData", chatListData?.payload?.chatList)
  useEffect(() => {

    getDomainConfig()

  }, [])
  const getDomainConfig = () => {
    let currentDomain = "" as string;
    if (window.location.href === "http://localhost:3000/ReceivableFinancing" || window.location.href === "http://localhost:3000/ApprovedPayableFinancing" || window.location.href === "http://localhost:3000/Manual"
      || window.location.href === "https://krediq-dev.netlify.app/ReceivableFinancing" || window.location.href === "https://krediq-dev.netlify.app/ApprovedPayableFinancing" || window.location.href === "https://krediq-dev.netlify.app/Manual"
      || window.location.href === "https://krediq-beta.netlify.app/ReceivableFinancing" || window.location.href === "https://krediq-beta.netlify.app/ApprovedPayableFinancing" || window.location.href === "https://krediq-beta.netlify.app/Manual"
      || window.location.href === "https://krediq-test.netlify.app/ReceivableFinancing" || window.location.href === "https://krediq-beta.netlify.app/ApprovedPayableFinancing" || window.location.href === "https://krediq-beta.netlify.app/Manual"
    ) {
      currentDomain = window.location.href
    } else {
      currentDomain = "http://localhost:3000/Misc" || "https://krediq-dev.netlify.app/Misc" || "https://krediq-test.netlify.app/Misc" || "https://krediq-beta.netlify.app/Misc"
    }


    httpClient
      .getInstance()
      .get(`https${chatBaseurl}configuration/get?domain_url=${currentDomain}`)
      .then((response: any) => {
        setDomainData(response.data.data?.[0])

        getChatlist();

        const xpathDatas = [] as any
        response.data.data?.[0].xpath?.map((item: any) => {
          console.log("xpath label", item)
          const xpathValue = getElementByXpath(item.xpath)
          const input = xpathValue as HTMLInputElement | null;
          if (input != null) {
            console.log("input", input)
            xpathDatas.push({ label: item.label, xpath: input.value })
          }
          console.log("xpathDatas", xpathDatas)
          setXpathData(xpathDatas)
        })
      })
      .catch(() => {
        message.error("Invalid domain link")
      })
  }
  function getElementByXpath(path: any) {
    return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
  }
  const getChatlist = () => {
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/user/chat/`)
      .then((resp: any) => {
        setBankUserChat(resp.data.data?.[0]?.chat_users?.bank_user);
        setBuyerUserChat(resp.data.data?.[0]?.chat_users?.buyer_user);
        setPartyUserChat(resp.data.data?.[0]?.chat_users?.counterparty_user);

      })
  }
  const onSelectUsersList = (e: any) => {
    setSelectedUsers(e)
    setDisplayMsgCard(true);
  }

  const onSelectMainUser = (e: any) => {
    setSelectedMainUsers(e)
    console.log("onSelectMainUser", e)
    bankUserChat && bankUserChat?.map((item: any) => {
      return e === item.bank_name ? setUsersList(item.users) : null
    })
    buyerUserChat && buyerUserChat?.map((item: any) => {
      if (e === item.party_name) {
        setUsersList(item.users)
      }
    })
    partyUserChat && partyUserChat?.map((item: any) => {
      if (e === item.party_name) {
        setUsersList(item.users)
      }
    })
  }
  const onClickCardList = (e: any) => {
    console.log("e card", e)
    setShowConversation(true)
    setSelectedMembers(e.members);
    setSelectedMainUsers(e.party);
    setConfigId(e.config_id)

  }
  const messageSending = () => {
    console.log("DomainData send from chatlist", DomainData)
    const ws = chatsocketurl
    const user = [loginUser] as any
    const arrayData = selectedUsers
    const concatData = arrayData.concat(user)
    let json = [] as any
    if (selectMembers.length > 0) {
      json = selectMembers;
    } else {
      json = concatData;
    }

    const data = {

      "type": "SEND",
      "config_id": DomainData.id,
      "members": json,
      "subject": "",
      "party": [selectedMainUsers, logindata.party],
      "message": [
        {
          "text": updatedMsg.current,
          "sender": loginUser,
          "is_read": [logindata?.email]
        }
      ]
    }
    console.log("body", data)
    ws.send(JSON.stringify(data));
    setMessageValue("")
    ws.onmessage = (e: any) => {
      console.log("return max max enetred onmessage", e.data)
      const message = JSON.parse(e.data);

      console.log("enetred onmessage e msg message", message, message?.data?.[0])
      dispatch(chatSocketList(message));

    };

  }
  const onchangeMessage = (e: any) => {
    setMessageValue(e.target.value)
    updatedMsg.current = e.target.value
  }
  const closeConversation = (value: any) => {
    console.log("value enetered clo")
    setShowConversation(value)
  }
  useEffect(() => {
    if (scrollto.current) {
      scrollto.current.scrollTop = scrollto.current.scrollHeight;
    }
    setDisplayMsgCard(true);
  }, [chatListData]);
  return (
    <>
      {console.log("showConversation", showConversation)}
      {showConversation ?

        <Conversation
          configId={configId}
          subjectsData={xpathData}
          selectedUsers={selectedUsers}
          selectedMainUsers={selectedMainUsers}
          selectDataMembers={selectMembers}
          closeConversation={closeConversation}
        />
        :
        <>
          <h3 style={{ padding: "10px" }}>chat list</h3>
          <div style={{ display: "", marginBottom: "10px", padding: "10px", overflow: "scroll" }}>
            <Select placeholder="Select party" style={{ width: "95%" }} onChange={onSelectMainUser} >
              {bankUserChat && bankUserChat?.map((item: any) => {
                return (
                  <Option value={item.bank_name} key={item.bank_name}>
                    {item.bank_name}
                  </Option>
                )
              }
              )}
              {buyerUserChat?.map((item: any) => {
                return (
                  <Option value={item ? item.party_name : ""} key={item ? item.party_name : ""}>
                    {item ? item.party_name : ""}
                  </Option>
                )
              }
              )}
              {partyUserChat?.map((item: any) =>
                <Option value={item.party_name} key={item.party_name}>
                  {item.party_name}
                </Option>

              )}
            </Select>
            <Select placeholder="Select members" style={{ width: "95%" }} onChange={onSelectUsersList} mode="multiple" >
              {usersList?.map((item: any) => {
                return (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                )
              })}
            </Select>
          </div>
          {xpathData?.map((item: any) => {
            return (
              <div style={{ margin: "0 25px" }}>
                {item.label} {" "} {item.xpath}
              </div>
            )
          })}
          {console.log("layout ConvoChatList data", chatListData?.payload?.chatList)}
          <div style={{
            overflow: "scroll", transition: "all 0.5s linear",
            height: '210px',
            scrollBehavior: "smooth"
          }} ref={scrollto}>
            {chatListData?.payload?.chatList?.data ? chatListData?.payload?.chatList?.data?.map((item: any) => {
              return (
                <Card onClick={() => onClickCardList(item)} style={{ position: "relative", margin: "15px 20px" }}>
                  <div className="TotalunreadMsgs">{item.unread_msgs}</div>
                  <div>
                    {item.party.length > 0 ? item.party?.[0] + ", " + item.party?.[1] : item.party} <span>{item.members.length > 2 ? "- Group" : ""}</span>
                  </div>
                  {item.members[0]}, <span>...+{item.members.length - 1}</span>
                  <Tooltip title={item.members} >
                    <span style={{ position: "absolute", bottom: 10, right: 10, border: "1px solid grey", borderRadius: "50px", width: "25px", height: "25px", padding: "2px 10px", textAlign: "center" }}>i</span>
                  </Tooltip>
                </Card>
              )
            }) :
              chatListData?.payload?.chatList?.chat_data.map((item: any) => {
                return (
                  <Card onClick={() => onClickCardList(item)} style={{ position: "relative", margin: "15px 20px" }}>
                    <div className="TotalunreadMsgs">{item.unread_msgs}</div>
                    <div>
                      {item.party.length > 0 ? item.party?.[0] + ", " + item.party?.[1] : item.party} <span>{item.members.length > 2 ? "- Group" : ""}</span>
                    </div>
                    {item.members[0]}, <span>...+{item.members.length - 1}</span>
                    <Tooltip title={item.members} >
                      <span style={{ position: "absolute", bottom: 10, right: 10, border: "1px solid grey", borderRadius: "50px", width: "25px", height: "25px", padding: "2px 10px", textAlign: "center" }}>i</span>
                    </Tooltip>
                  </Card>
                )
              })
            }
          </div>
          {displayMsgCard ?
            <div className={"input"}>
              <Input type="text" className={"msg_send"}
                id="msg_send"
                placeholder="Start a Message" onChange={onchangeMessage} value={messageValue} />
              <div className={"send"}
                id="reply"
                onClick={messageSending} >
                <img src={sendIcon} alt="sendIcon" />
              </div>
            </div>
            : ""}
        </>
      }
    </>
  )
}
export default Chat;