import React, { useState } from 'react';
import { Card, Row, Col } from "antd";
import { useNavigate, useLocation } from "react-router";
import moment from 'moment';
import Breadcrumbs from '../../common/heading/breadcrumb';
const Program = () => {
    const { state } = useLocation();
    const navigate = useNavigate()
    const [programData] = useState(state as any);
    const onClickExit = () => {
        navigate("/Dashboard")
    }
    const Data = {
        fromMenu: "Dashboard",
        data: {
            type: "Program"
        },
        recordType: ""
    }
    return (
        <React.Fragment>
            <div className="fixedContainer">
                <Breadcrumbs
                    Data={Data}
                    onClickExit={onClickExit}
                    commentsValue={""}
                    flag="Dashboard"
                    onClickAction={""}
                    buttonStatusData={""}
                />
            </div>
            <Card style={{ margin: "0vh 24px 24px" }} className='mainContentContainer'>
                <div>
                    <h2>{programData?.programType === "apf" ? `Approved Payable Finance ( ${programData?.data?.length} )` : `Receivable Finance ( ${programData?.data?.length} )`} </h2>
                    {

                        <Row gutter={24} style={{ marginBottom: "30px" }}>

                            <Col span={24} style={{ marginBottom: "30px" }}>
                                <div className='containerTable '>
                                    <div className="OuterDiv">

                                        <div className='HeadInnerDiv'>
                                            <h1 className='head'>{'Buyer'}</h1>
                                            <h1 className='head'>{'Max. Limit Amt'}</h1>
                                            <h1 className='head'>{'Available Limit'}</h1>
                                            <h1 className='head'>{'Expiry Date'}</h1>
                                            <h1 className='head'>{'Max.Invoice Amt'}</h1>
                                            <h1 className='head'>{'Max.Invoice PCT'}</h1>
                                            <h1 className='head'>{'Max Tenor'}</h1>
                                            <h1 className='head'>{'Interest Rate (%)'}</h1>
                                        </div>

                                    </div>
                                    {programData?.data?.map((item: any) => {
                                        return (
                                            <div className='OuterDiv'>
                                                <div className="InnerDiv" style={{ cursor: "default" }}>
                                                    <h5 className='body'>
                                                        {item?.buyer_name}
                                                    </h5>
                                                    <h5 className='body'>
                                                        {item?.limit_currency__description}  {item?.program_max_limit_amount}
                                                    </h5>
                                                    <h5 className='body'>
                                                        {item?.limit_currency__description} {item?.final_available_limit_amount}
                                                    </h5>
                                                    <h5 className='body'>
                                                        {moment(item?.program_expiry_date).format("DD-MM-YYYY")}
                                                    </h5>
                                                    <h5 className='body'>
                                                        {item?.max_invoice_currency__description} {item.max_invoice_amount}
                                                    </h5>
                                                    <h5 className='body'>
                                                        {item?.max_invoice_percent}
                                                    </h5>
                                                    <h5 className='body'>
                                                        {item?.max_tenor}
                                                    </h5>
                                                    <h5 className='body'>
                                                        {item?.interest_type__description === "FIXED" ? item.margin : item.interest_rate_type__description + " + " + item.margin}
                                                    </h5>
                                                </div>

                                            </div>
                                        )

                                    })}
                                </div>
                            </Col>
                        </Row>
                    }
                </div>
            </Card>
        </React.Fragment>
    )
}
export default Program;
