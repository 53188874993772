import React, { useState } from "react";
import { Button, message, Card, Modal, Checkbox } from "antd";
import UploadCSV from "./uploadfile";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import { useNavigate, useLocation } from "react-router-dom";
import { ErrorMessage } from "../../../../utils/enum/messageChoices";
import { ProgramType, ResponseStatus } from "../../../../utils/enum/choices";
import Breadcrumbs from "../../../common/heading/breadcrumb";
const UploadInvoice = () => {
    const Navigator = useNavigate();
    const { state } = useLocation();
    const [upload_detail] = useState(state as any);
    const [fileList, setFileList] = useState([]);
    const [uploadBtnLoader, setUploadBtnLoader] = useState(false)
    const selectedFiles = (value: any) => {
        setFileList(value)
    }
    const csvModal = () => {
        if (upload_detail.party_details.self_invoice_upload === false && upload_detail?.program_type !== ProgramType?.RF) {
            Modal.info({
                title: '',
                className: "manualBtn",
                content: (
                    <div>
                        <p><Checkbox
                        />  Raise Finance request automatically for approved invoices?</p>
                    </div>
                ),
                onOk() { csvUpload() },
            });
        } else {
            if (fileList.length === 0) {
                message.error(ErrorMessage.PAF)
            } else {
                csvUpload()
            }

        }
    }
    const csvUpload = () => {
        httpClient
            .getInstance()
            .delete(`${baseurl}api/invoice/upload/delete/`)
            .then((resp: any) => {
                console.log("delete resp", resp)
            })

        setUploadBtnLoader(true)
        setTimeout(() => {
            const docs = [...fileList] as any;
            docs[0] = fileList;
            if (fileList.length > 0) {
                const formdata = new FormData();
                fileList.map((item: any) => {
                    formdata.append(`data`, item[0]);
                    formdata.append(`program_id`, upload_detail?.program_id);
                });
                httpClient
                    .getInstance()
                    .post(`${baseurl}api/invoice/upload/bulk/`, formdata)
                    .then((resp: any) => {
                        if (resp.data.status === ResponseStatus.SUCCESS) {
                            message.success(resp.data.data)
                            setTimeout(() => {
                                setUploadBtnLoader(false)
                                Navigator("/UploadDetail", { state: upload_detail })
                            }, 2000)
                        } else {
                            message.error(resp.data.data)
                            setUploadBtnLoader(false)
                        }
                    })
                    .catch(() => {
                        message.error(ErrorMessage.FCA, 3)
                        setUploadBtnLoader(false)
                        Navigator("/Notfound")

                    })
            } else {
                setUploadBtnLoader(false)
                message.error(ErrorMessage.PAF)
            }
            setFileList(docs);
        }, 1000)
    }

    const onClickExit = () => {
        Navigator("/New")
    }
    const data = {
        fromMenu: "Invoice",
        data: {
            type: "Bulk upload"
        },
        recordType: ""
    }
    const buttonStatusData = {
        uploadBtnLoader: uploadBtnLoader
    };
    const handleDownload = () => {
        window.open(`${baseurl}api/resource/download/?model_type=${upload_detail?.work_model?.model_type}&t_id=${upload_detail?.work_model?.t_id}&party_id=${localStorage.getItem("party_id")}`)
    }
    return (
        <React.Fragment>
            <div className="fixedContainer">
                <Breadcrumbs
                    Data={data}
                    onClickExit={onClickExit}
                    commentsValue={""}
                    flag="BulkUpload"
                    onClickAction={csvModal}
                    buttonStatusData={buttonStatusData}
                />
            </div>
            <div className="approvedFinanceContainer mainContentContainer">
                <Card className="CardContainer">
                    <h3>Upload Files</h3>
                    <Button className="TemplateButtonContainer"
                        onClick={handleDownload
                        } >
                        Download Template
                    </Button>
                    <UploadCSV selectedFiles={selectedFiles} attachmentFiles={[]} />
                </Card>
            </div>
        </React.Fragment>

    );
};

export default UploadInvoice;
