import { Tabs, Card } from "antd";
import Transaction from "./transactions/tab";
import Users from "./users/users";
import "./settings.scss"
import Heading from "../common/heading/heading";
const Settings = () => {
    const items = [
        {
            label: "Transaction",
            id:"settings-tab-Transactions",
            key: "settings-tab-Transactions",
            children: <Transaction />
        },
        {
            label: "Users",
            id:"settings-tab-Users",
            key: "settings-tab-Users",
            children: <Users />
        }
    ]
    return (
        <div className="settingsMainContainer">
            <div className="fixedContainer">
                <Heading flag="Settings" text="Settings" subText="" />
            </div>
            <div className="mainContentContainer">
                <Card className="CardContainer">
                    <Tabs defaultActiveKey="1" type="card" className="settingsTabContainer" items={items} />

                </Card>
            </div>
        </div>
    )
}
export default Settings