import React, { useState, useEffect } from "react";
import { Row, Col, Input, Card } from "antd";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { InterimState, Type } from "../../../../utils/enum/choices";
import CurrencyField from "../../function/currency";
import Breadcrumbs from "../../heading/breadcrumb";
import { allCurrency } from "../../../../redux/action";
import httpClient from "../../../../utils/config/core/httpClient";
import imageBaseurl from "../../../../utils/config/url/image";
import baseurl from "../../../../utils/config/url/base";
const FinanceRequestDetail = () => {
  const { state } = useLocation();
  const Navigator = useNavigate();
  const [invoice_detail] = useState(state as any);
  const [commentsValue, setCommentsvalue] = useState(null as any);
  const currency_data = useSelector(allCurrency);
  const [PartyAccountDetails, setPartyAccountDetails] = useState({
    currency: "",
    account_number: ""
  })
  const { TextArea } = Input;
  useEffect(() => {
    if (invoice_detail?.data?.record_datas?.values?.[0]?.fields?.repayment_amount !== "0.00") {
      httpClient
        .getInstance()
        .get(`${baseurl}api-auth/party/party-accounts/${invoice_detail?.data?.record_datas?.values?.[0]?.fields?.repayment_account}/`)
        .then((resp: any) => {
          setPartyAccountDetails(resp.data.data)
        })
    }
  }, []);

  const UploadDetail = invoice_detail?.data?.type === Type.INVOICEUPLOAD ?
    [

      {
        label1: "Buyer Name",
        value1: invoice_detail
          ? invoice_detail?.data?.buyer_details?.[0]?.name
          : "-",
        id1: "buyerName",
        label2: "Seller Name",
        value2: invoice_detail
          ? invoice_detail?.data?.seller_details?.[0]?.name : "-",
        id2: "sellerName"
      },
      {
        label1: "Invoice Number",
        value1: invoice_detail
          ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceNo
          : "",
        id1: "invoiceNumber",
        label2: " ",
        value2: " ",
        id2: " "
      },

      {
        label1: "Invoice Date",
        value1: invoice_detail
          ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceDate
          : "",
        id1: "invoiceDate",
        label2: "Invoice Amount",
        value2:
          `${invoice_detail
            ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceCurrency : "-"}${" "}${new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(invoice_detail.data.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceAmount)}`,
        id2: "invoiceAmount"
      },

      {
        label1: "Due Date",
        value1: invoice_detail
          ?
          invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.DueDate
          : "",
        id1: "dueDate",
        label2: "Finance Amount",
        value2:
          `${invoice_detail
            ? invoice_detail?.data.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceCurrency : "-"}${" "}${new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(invoice_detail.data.record_datas?.values?.[0]?.fields?.invoices?.[0]?.FinanceAmount)}`,
        id2: "financeAmount"
      },

      {
        label1: "Status",
        value1: invoice_detail ? invoice_detail?.data?.interim_state : "-",
        id1: "financeStatus"
      },

    ] :
    invoice_detail?.data?.type === Type.INVOICE && invoice_detail?.data?.final_state === InterimState.SETTLED ?
      [
        {

          label1: "Buyer Name",
          value1: invoice_detail
            ? invoice_detail?.data?.buyer_details ? invoice_detail?.data?.buyer_details?.[0]?.name : invoice_detail?.data?.current_to_party_name
            : "-",
          id1: "buyerName",

          label2: "Seller Name",
          value2: invoice_detail
            ? invoice_detail?.data?.seller_details?.[0]?.name : "-",
          id2: "sellerName"
        },

        {
          label1: "Invoice Number",
          value1: invoice_detail
            ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_no
            : "",
          id1: "invoiceNumber",
          label2: " ",
          value2: " ",
          id2: " "
        },
        {
          label1: "Invoice Date",
          value1:
            moment(
              invoice_detail
                ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_date
                : ""
            ).format("DD-MM-YYYY"),
          id1: "invoiceDate",
          label2: "Invoice Amount",
          value2: <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.amount} />,
          id2: "invoiceAmount"
        },

        {
          label1: "Due Date",
          value1: invoice_detail
            ? moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.due_date).format("DD-MM-YYYY")
            : "",
          id1: "dueDate",
          label2: "Finance Amount",
          value2: <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_amount} />,
          id2: "financeAmount"
        },
        ...(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.repayment_amount !== "0.00") ?
          [
            {
              label1: "Repayment Account",
              value1: currency_data?.payload?.currencyData?.allCurrency &&
                currency_data?.payload?.currencyData?.allCurrency.map((item: any) => {
                  if (PartyAccountDetails.currency === item.id) {
                    return (
                      item.description + " " + PartyAccountDetails.account_number
                    );
                  }
                })

              ,
              id1: "repaymentAccount",
              label2: "Interest Rate",
              value2: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate !== null ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate : "",
              id2: "interestRate",
            }] : [],

        ...(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate !== "0.00") ? [
          {

            label1: "Interest Amount",
            value1: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount !== null ?
              <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount} />
              : "",
            id1: "interestAmount",
            label2: "Status",
            value2: invoice_detail ? ((invoice_detail?.data?.type === Type.INVOICE && invoice_detail?.fromMenu === "inbox" && invoice_detail?.data?.interim_state === InterimState.AWAITINGBUYERA)) ? "Awaiting_approval" : invoice_detail?.data?.interim_state : "-",
            id2: "financeStatus"
          }] : [],
      ]
      :

      [
        {

          label1: "Buyer Name",
          value1: invoice_detail
            ? invoice_detail?.data?.buyer_details ? invoice_detail?.data?.buyer_details?.[0]?.name : invoice_detail?.data?.current_to_party_name
            : "-",
          id1: "buyerName",

          label2: "Seller Name",
          value2: invoice_detail
            ? invoice_detail?.data?.seller_details?.[0]?.name : "-",
          id2: "sellerName"
        },

        {
          label1: "Invoice Number",
          value1: invoice_detail
            ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_no
            : "",
          id1: "invoiceNumber",
          label2: " ",
          value2: " ",
          id2: " "
        },
        {
          label1: "Invoice Date",
          value1:
            moment(
              invoice_detail
                ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_date
                : ""
            ).format("DD-MM-YYYY"),
          id1: "invoiceDate",
          label2: "Invoice Amount",
          value2: <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.amount} />,
          id2: "invoiceAmount"
        },

        {
          label1: "Due Date",
          value1: invoice_detail
            ? moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.due_date).format("DD-MM-YYYY")
            : "",
          id1: "dueDate",
          label2: "Finance Amount",
          value2: <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_amount} />,
          id2: "financeAmount"
        },
        ...(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.repayment_amount !== "0.00") ?
          [
            {
              label1: "Repayment Amount",
              value1: <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.repayment_amount} />,
              id1: "repaymentAmount",
              label2: "Repayment Account",
              value2: currency_data?.payload?.currencyData?.allCurrency &&
                currency_data?.payload?.currencyData?.allCurrency.map((item: any) => {
                  if (PartyAccountDetails.currency === item.id) {
                    return (
                      item.description + " " + PartyAccountDetails.account_number
                    );
                  }
                })

              ,
              id2: "repaymentAccount"
            }] : [],

        ...(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate !== "0.00") ? [
          {
            label1: "Interest Rate",
            value1: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate !== null ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate : "",
            id1: "interestRate",
            label2: "Interest Amount",
            value2: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount !== null ?
              <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount} />
              : "",
            id2: "interestAmount"
          }] : [],
        {
          label1: "Status",
          value1: invoice_detail ? ((invoice_detail?.data?.type === Type.INVOICE && invoice_detail?.fromMenu === "inbox" && invoice_detail?.data?.interim_state === InterimState.AWAITINGBUYERA)) ? "Awaiting_approval" : invoice_detail?.data?.interim_state : "-",
          id1: "financeStatus",
          label2: "Finance Due Date",
          value2: invoice_detail
            ? moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_due_date).format("DD-MM-YYYY")
            : "",
          id2: 'financeDueDate',
        },
      ]

  console.log(invoice_detail, 'ivd')

  const onClickExit = () => {
    Navigator(`/${invoice_detail.fromMenu}`)
  };
  const buttonStatusData = {}
  return (
    <React.Fragment>
      <div className="fixedContainer">
        <Breadcrumbs
          Data={invoice_detail}
          onClickExit={onClickExit}
          commentsValue={commentsValue}
          flag="invoice"
          onClickAction={onClickExit}
          buttonStatusData={buttonStatusData}

        />

      </div>
      <div className="Card_Main_Container mainContentContainer" >
        <Card className="CardContainer" style={{ marginTop: "1%" }}>
          <h4>{invoice_detail.data.type === Type.INVOICEUPLOAD ? "Upload " : "Invoice "}Detail</h4>
          <div className="SummaryContainer" style={{ marginTop: "4%" }}>
            {UploadDetail.map((data: any) => {
              return (
                <Row
                >

                  <Col span={12} >

                    <Row gutter={24} className="counterPartyCollapseDetails" style={{ padding: '0 0 10px 0', alignItems: "baseline" }}>
                      <Col span={3}></Col>
                      <Col span={10} style={{ color: "#4E6ACB", fontSize: "14px", fontFamily: "Poppins" }}>{data.label1}</Col>
                      <Col span={10} className="counterPartyValue" id={data.id1}>
                        <p>{data.value1}</p>
                      </Col>
                    </Row>


                  </Col>
                  <Col span={12}
                  >
                    <Row gutter={24} className="counterPartyCollapseDetails" style={{ padding: '0 0 10px 0', alignItems: "baseline" }}>
                      <Col span={3}></Col>
                      <Col span={10} style={{ color: "#4E6ACB", fontSize: "14px", fontFamily: "Poppins" }}>{data.label2}</Col>
                      <Col span={10} className="counterPartyValue" id={data.id2}>
                        <p>{data.value2}</p>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              )
            })}

            {invoice_detail?.data?.attachments?.file?.length > 0 ?
              <Row gutter={24} className="counterPartyCollapseDetails" >
                <Col span={1}></Col>
                <Col span={5} style={{ paddingLeft: "40px" }}><p>Attachments</p></Col>
              </Row> : ""}
            <Row gutter={24} className="counterPartyCollapseDetails">
              <Col span={1}></Col>

              {invoice_detail?.data?.attachments?.file?.map((value: any, index: number) => {
                const splitedValue = value.file_path.split("/")
                const lastValue = splitedValue.pop()
                return (
                  <Col span={4} key={lastValue} style={{ paddingLeft: "40px" }}>
                    <div className="fileAttachmentsText" style={{ width: "max-content" }}>
                      <a href={`${imageBaseurl}${value.file_path}`} target="_blank" id={`invoice_images${index}`}>{lastValue}</a>
                    </div>
                  </Col>
                )
              })
              }
            </Row>

            <Row gutter={24} className="counterPartyCollapseDetails" style={{ paddingTop: "10px" }}>

              <Col span={1} > </Col>
              <Col span={5}>
                <div style={{ color: "#4E6ACB", fontSize: "14px", fontFamily: "Poppins", paddingLeft: "36px" }}>
                  Comments
                </div>
              </Col>
              <Col span={16} style={{ padding: 0 }}>
                <TextArea style={{ width: "50%", margin: "0px 0px" }} onChange={(e: any) => setCommentsvalue(e.target.value)} id="comments" />
              </Col>
            </Row>
          </div>

        </Card>
      </div>

    </React.Fragment>

  );
};
export default FinanceRequestDetail;
