import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Checkbox, Dropdown, Spin } from "antd";
import FinanceMaturing from "./graphs/financeMaturing";
import FinanceAnalysis from "./graphs/financeAnalysis";
import "./dashboard.scss";
import TopBorrowers from "./graphs/topBorrowers";
import LimitUtilization from "./graphs/limitUtilization";
import ExpiringProgramsAndLimits from "./graphs/expiringPrograms&Limits";
import MonthlyTurnover from "./graphs/monthlyTurnover";
import FundAnalysis from "./graphs/fundAnalysis";
import images from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import ProgramCard from "./graphs/program";
import FundsExpected from "./graphs/fundsExpected";
import { PartyType } from "../../utils/enum/choices";
import { socketInfoDetails, socketConnected } from "../../redux/action";
import socketurl from "../../utils/config/url/socket";
import * as pako from 'pako';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import type { MenuProps } from 'antd';
// import Socket from "../common/socket/socket";
const ItemType = "CARD";


const DraggableCard = ({ item, index, moveCard, onClickClose, data }: any) => {
  const ref = useRef(null);
  const socketData = useSelector(socketInfoDetails)
  const socketInfoData = socketData?.payload?.socketInfo
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000)
  }, [])

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem: any) => {
      if (draggedItem.index !== index) {
        moveCard(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { type: ItemType, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    localStorage.getItem("user") === "Bank" ?
      <Card
        ref={ref}
        className={`cardContainer ${isDragging ? "dragging" : ""}`}
        style={{
          height: "410px",
          marginBottom: "20px",
          borderRadius: "25px",
          width: "95%",
          display: "inline-block",
          marginRight: "2%",
        }}
      >
        <span onClick={() => onClickClose(item)}>
          <img src={images.crossIcon} alt="closeIcon" className="closeIcon" />
        </span>
        {loading ? <Spin /> : item === "Top Borrowers" ? (
          <TopBorrowers topBorrowersData={socketInfoData?.dashboard_data?.top_borrowers} />
        ) : item === "Expiring Programs" ? (
          <ExpiringProgramsAndLimits expiringPrograms={socketInfoData?.dashboard_data?.Expiring_programs} />
        ) : item === "Monthly Turnover/Revenue" ? (
          <MonthlyTurnover monthlyTurnoverRevenue={socketInfoData?.dashboard_data?.Monthly_turnover_and_revenue} />
        ) : item === "Limit Utilization" ? (
          <LimitUtilization limitUtilizationData={socketInfoData?.dashboard_data?.limit_utilization} />
        ) : item === "Finance Ageing Analysis" ? (
          <FinanceAnalysis financeAgeing={socketInfoData?.dashboard_data?.Finance_ageing_analysis} />
        ) : item === "Cost of Funds Analysis" ? (
          <div>
            <h2 style={{ padding: "24px" }}>Cost of Funds Analysis</h2>
            <FundAnalysis />
          </div>
        ) : (
          ""
        )}
      </Card>
      :
      // (socketInfoData?.status_data?.[0].RF_seller === true && socketInfoData?.status_data?.[0].APF_buyer === true) || 
      socketInfoData?.status_data?.[0].RF_seller === true ?
        <Card
          ref={ref}
          className={`cardContainer ${isDragging ? "dragging" : ""}`}
          style={{
            height: "410px",
            marginBottom: "20px",
            borderRadius: "25px",
            width: "95%",
            display: "inline-block",
            marginRight: "2%",
          }}
        >
          <span onClick={() => onClickClose(item)}>
            <img src={images.crossIcon} alt="closeIcon" className="closeIcon" />
          </span>
          {/* // programData !== null &&  */}
          {loading ? <Spin /> : item === "Program" ? (
            <ProgramCard programData={socketInfoData?.dashboard_data?.program} />
          ) : item === "Funds Expected" ? (
            <FundsExpected fundsExpected={socketInfoData?.dashboard_data?.Funds_Expected} />
          )
            : item === "Finance Details" ? (
              <>
                <h2>Finance Details </h2>
                <Row gutter={24} style={{ marginBottom: "30px" }}>
                  <Col span={12}>
                    <Card style={{ backgroundColor: "#E6F5B3" }}>
                      <h1>Financed</h1>
                      <p>INR {socketInfoData?.dashboard_data?.finance_details ? socketInfoData?.dashboard_data?.finance_details?.FINANCED !== null ? socketInfoData?.dashboard_data?.finance_details?.FINANCED : 0 : socketInfoData?.dashboard_data?.finance_details ? socketInfoData?.dashboard_data?.finance_details?.FINANCED !== null ? socketInfoData?.dashboard_data?.finance_details?.FINANCED : 0 : 0}</p>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card style={{ backgroundColor: "#F5E8B3" }}>
                      <h1>Settled</h1>
                      <p>INR {socketInfoData?.dashboard_data?.finance_details ? socketInfoData?.dashboard_data?.finance_details?.SETTLED !== null ? socketInfoData?.dashboard_data?.finance_details?.SETTLED : 0 : socketInfoData?.dashboard_data?.finance_details ? socketInfoData?.dashboard_data?.finance_details?.SETTLED !== null ? socketInfoData?.dashboard_data?.finance_details?.SETTLED : 0 : 0}</p>
                    </Card>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Card style={{ backgroundColor: "#DDECFF" }}>
                      <h1>OverDue</h1>
                      <p>INR {socketInfoData?.dashboard_data?.finance_details ? socketInfoData?.dashboard_data?.finance_details?.OVERDUE !== null ? socketInfoData?.dashboard_data?.finance_details?.OVERDUE : 0 : socketInfoData?.dashboard_data?.finance_details ? socketInfoData?.dashboard_data?.finance_details?.OVERDUE !== null ? socketInfoData?.dashboard_data?.finance_details?.OVERDUE : 0 : 0}</p>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card style={{ backgroundColor: "#F5BBB3" }}>
                      <h1>Rejected</h1>
                      <p>INR {socketInfoData?.dashboard_data?.finance_details ? socketInfoData?.dashboard_data?.finance_details?.REJECTED !== null ? socketInfoData?.dashboard_data?.finance_details?.REJECTED : 0 : socketInfoData?.dashboard_data?.finance_details ? socketInfoData?.dashboard_data?.finance_details?.REJECTED !== null ? socketInfoData?.dashboard_data?.finance_details?.REJECTED : 0 : 0}</p>
                    </Card>
                  </Col>
                </Row>
              </>
            )
              : item === "Finances Maturing" ? (
                <FinanceMaturing financeMaturingDetails={socketInfoData?.dashboard_data?.finance_maturing} />
              )
                : (
                  ""
                )}
        </Card>
        :
        socketInfoData?.status_data?.[0].APF_seller === true ?
          <Card
            ref={ref}
            className={`cardContainer ${isDragging ? "dragging" : ""}`}
            style={{
              height: "410px",
              marginBottom: "20px",
              borderRadius: "25px",
              width: "95%",
              display: "inline-block",
              marginRight: "2%",
            }}
          >
            <span onClick={() => onClickClose(item)}>
              <img src={images.crossIcon} alt="closeIcon" className="closeIcon" />
            </span>
            {loading ? <Spin /> :
              socketInfoData?.dashboard_data?.program !== null &&
                item === "Program" ?
                <ProgramCard programData={socketInfoData?.dashboard_data?.program} />
                : item === "Funds Expected" ?
                  <FundsExpected fundsExpected={socketInfoData?.dashboard_data?.Funds_Expected} />

                  : item === "Invoice Details" ? (
                    <>
                      <h2>Invoice Details </h2>
                      {console.log("de")}
                      <Row gutter={24} style={{ marginBottom: "30px" }}>
                        <Col span={12}>
                          <Card style={{ backgroundColor: "#E6F5B3" }}>
                            <h1>Awaiting Buyer Approval</h1>
                            <p> {socketInfoData?.dashboard_data?.invoice_details?.AWAITING_BUYER_APPROVAL ? socketInfoData?.dashboard_data?.invoice_details?.AWAITING_BUYER_APPROVAL : socketInfoData?.dashboard_data?.invoice_details ? socketInfoData?.dashboard_data?.invoice_details?.AWAITING_BUYER_APPROVAL : 0}</p>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card style={{ backgroundColor: "#F5E8B3" }}>
                            <h1>Approved by Buyer</h1>
                            <p> {socketInfoData?.dashboard_data?.invoice_details?.APPROVED_BY_BUYER ? socketInfoData?.dashboard_data?.invoice_details?.APPROVED_BY_BUYER : socketInfoData?.dashboard_data?.invoice_details ? socketInfoData?.dashboard_data?.invoice_details?.APPROVED_BY_BUYER : 0}</p>
                          </Card>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Card style={{ backgroundColor: "#DDECFF" }}>
                            <h1>Finance Requested</h1>
                            <p> {socketInfoData?.dashboard_data?.invoice_details?.FINANCE_REQUESTED ? socketInfoData?.dashboard_data?.invoice_details?.FINANCE_REQUESTED : socketInfoData?.dashboard_data?.invoice_details ? socketInfoData?.dashboard_data?.invoice_details?.FINANCE_REQUESTED : 0}</p>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card style={{ backgroundColor: "#F5BBB3" }}>
                            <h1>Financed</h1>
                            <p> {socketInfoData?.dashboard_data?.invoice_details?.FINANCED ? socketInfoData?.dashboard_data?.invoice_details?.FINANCED : socketInfoData?.dashboard_data?.invoice_details ? socketInfoData?.dashboard_data?.invoice_details?.FINANCED : 0}</p>
                          </Card>
                        </Col>
                      </Row>
                    </>)
                    : item === "Finances Maturing" ? (
                      <FinanceMaturing financeMaturingDetails={socketInfoData?.dashboard_data?.finance_maturing} />
                    ) : (
                      ""
                    )}
          </Card>
          :
          socketInfoData?.status_data?.[0].APF_buyer === true ?
            <Card
              ref={ref}
              className={`cardContainer ${isDragging ? "dragging" : ""}`}
              style={{
                height: "410px",
                marginBottom: "20px",
                borderRadius: "25px",
                width: "95%",
                display: "inline-block",
                marginRight: "2%",
              }}
            >
              <span onClick={() => onClickClose(item)}>
                <img src={images.crossIcon} alt="closeIcon" className="closeIcon" />
              </span>
              {loading ? <Spin /> :
                // programData !== null && 
                item === "Finances Maturing" && socketInfoData?.status_data?.[0].party_type === PartyType.CUSTOMER ? (
                  <FinanceMaturing financeMaturingDetails={socketInfoData?.dashboard_data?.finance_maturing} />
                ) : (
                  ""
                )}
            </Card>
            : null
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const socketData = useSelector(socketInfoDetails)
  const socketInfoData = socketData?.payload?.socketInfo
  const socketConnection = useSelector(socketConnected)
  const [programData, setProgramData] = useState({} as any)
  const [loading, setLoading] = useState(true);
  const [isPaused, setPause] = useState(false);
  const ws = useRef(null) as any;
  const loginData = localStorage.getItem("login_detail") || "";
  const logindata = JSON.parse(loginData);
  useEffect(() => {
    let isMounted = true;
    setProgramData(socketInfoData?.dashboard_data?.program);
  
    setTimeout(() => {
      if (isMounted) {
        setLoading(false);
      }
    }, 3000);
    return () => {
      isMounted = false;
    };
  }, [socketInfoData?.dashboard_data?.program]); 

  // const forceUpdate = useForceUpdate();
  // const CheckboxGroup = Checkbox.Group;
  const { FilterIcon } = images;
  console.log("socketInfoData", socketInfoData)
  const defaultCheckedList =
    localStorage.getItem("user") === "Bank" ?
      [
        "Top Borrowers",
        "Limit Utilization",
        "Expiring Programs",
        "Finance Ageing Analysis",
        "Monthly Turnover/Revenue",
        "Cost of Funds Analysis",
      ] :
      socketInfoData?.status_data?.[0].RF_seller === true ?
        [
          "Program",
          "Finance Details",
          "Finances Maturing",
          "Funds Expected",
        ]
        :
        socketInfoData?.status_data?.[0].APF_seller === true ?
          [
            "Program",
            "Invoice Details",
            "Finances Maturing",
            "Funds Expected",
          ]
          :
          socketInfoData?.status_data?.[0].APF_buyer === true ?
            ["Finances Maturing"] : []

  // const [openModal, setOpenModal] = useState(false);
  const [specificData, setSpecificData] = useState("");
  const [checkedList, setCheckedList] = React.useState(defaultCheckedList);

  // const onClickFilter = () => {
  //   setOpenModal(true);
  // };
  const moveCard = (fromIndex: any, toIndex: any) => {
    const updatedList = [...checkedList];
    const [movedItem] = updatedList.splice(fromIndex, 1);
    updatedList.splice(toIndex, 0, movedItem);
    setCheckedList(updatedList);
  };

  const onClickClose = (data: any) => {
    setSpecificData(data);
    const array = checkedList;
    const index = array.indexOf(data);
    if (index > -1) {
      array.splice(index, 1);
    }
    setCheckedList(array)
  };

  const onChange = (list: any) => {
    const array = checkedList;
    array.splice(array.indexOf(specificData), 1);
    setCheckedList(list);
  };
  useEffect(() => {
    setLoading(true)
    // Socket();
    // console.log("loadingss",loadingss)
    ws.current = socketurl;
    const data = {
      "party_id": logindata.party_id,
      "user_id": logindata.user_id,
      "record_type":
      {
        "inbox":
        {
          type: "ALL",
          data: false
        },
        "sent":
        {
          type: "ALL",
          data: false
        },
        "sent_aw": {
          type: "ALL",
          data: false
        },
        "enquiry": {
          "data": false,
          "filter": {
            "model_type": null,
            "program_type": null,
            "transaction_date": null,
            "due_date": null,
            "currency": null,
            "counterparty": null,
            "amount": null
          },
          "search": null
        },
        "draft": false

      },
      "data_type": "DASHBOARD",
      "page_size": 10,
      "page_number": 1

    }
    if (socketConnection?.payload?.socketConnectData === "connected") {

      ws.current.send(JSON.stringify(data));
      ws.current.onmessage = (e: any) => {
        const message = JSON.parse(e.data);
        const compressedDataArray = message

        const compressedData = new Uint8Array(compressedDataArray);
        const decompressedData = pako.inflate(compressedData, { to: 'string' });
        const decompressedMessageData = JSON.parse(decompressedData);
        setProgramData(decompressedMessageData?.dashboard_data?.program);
        dispatch(
          socketInfoDetails(decompressedMessageData)
        )

        setTimeout(() => {
          setLoading(false);
        }, 2000)

      }
    } else {
      ws.current.onopen = () => {
        dispatch(
          socketConnected('connected')
        )
        ws.current.send(JSON.stringify(data));
        ws.current.onmessage = (e: any) => {
          const message = JSON.parse(e.data);
          const compressedDataArray = message

          const compressedData = new Uint8Array(compressedDataArray);
          const decompressedData = pako.inflate(compressedData, { to: 'string' });
          const decompressedMessageData = JSON.parse(decompressedData);

          setProgramData(decompressedMessageData?.dashboard_data?.program);
          dispatch(
            socketInfoDetails(decompressedMessageData)
          )

          setTimeout(() => {
            setLoading(false);
          }, 2000)

        }
      }

    }
    window.addEventListener('beforeunload', () => {
      console.log("websocket disconnected  closed")
      dispatch(
        socketConnected('disconnected')
      )
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        console.log("websocket disconnected  closed")
        ws.current.close();
        dispatch(
          socketConnected('disconnected')
        )
      }
    });

  }, []);

  useEffect(() => {
    setLoading(true)
    if (!ws.current) return;

    ws.current.onmessage = (e: any) => {
      if (isPaused) return;
      const message = JSON.parse(e.data);

      const compressedDataArray = message

      const compressedData = new Uint8Array(compressedDataArray);
      const decompressedData = pako.inflate(compressedData, { to: 'string' });
      const decompressedMessageData = JSON.parse(decompressedData);

      setProgramData(decompressedMessageData?.dashboard_data?.program);

      dispatch(
        socketInfoDetails(decompressedMessageData)
      )
      setTimeout(() => {
        setLoading(false);
      }, 2000)
    };
  }, [isPaused]);

  const items: MenuProps['items'] = localStorage.getItem("user") === "Bank" ? [
    {
      key: '1',
      label: (
        <Checkbox.Group
          style={{ width: "100%", display: "grid" }}
          onChange={onChange}
          defaultValue={checkedList}
          value={checkedList}
        >
          <Checkbox value="Top Borrowers" style={{ marginLeft: "8px" }}>Top Borrowers</Checkbox>
          <Checkbox value="Limit Utilization">Limit Utilization</Checkbox>
          <Checkbox value="Expiring Programs"> Expiring Programs/Limits </Checkbox>
          <Checkbox value="Finance Ageing Analysis">  Finance Ageing Analysis </Checkbox>
          <Checkbox value="Monthly Turnover/Revenue">  Monthly Turnover/Revenue </Checkbox>
          <Checkbox value="Cost of Funds Analysis"> Cost of Funds Analysis </Checkbox>
        </Checkbox.Group>
      ),
    }
  ] :
    socketInfoData?.status_data?.[0]?.RF_seller === true ?
      [
        {
          key: 1,
          label: (
            <Checkbox.Group
              style={{ width: "100%", display: "grid" }}
              onChange={onChange}
              defaultValue={checkedList}
              value={checkedList}
            >
              {programData !== null ? <Checkbox value="Program" style={{ marginLeft: "8px" }}>Program</Checkbox> : null}
              <Checkbox value="Finance Details">Finance Details</Checkbox>
              {socketInfoData?.status_data?.[0]?.party_type === PartyType.CUSTOMER ? <Checkbox value="Finances Maturing">Finances Maturing</Checkbox> : null}
              <Checkbox value="Funds Expected">Funds Expected</Checkbox>
            </Checkbox.Group>
          )
        }
      ] : socketInfoData?.status_data?.[0].APF_seller === true ?
        [
          {
            key: 1,
            label: (
              <Checkbox.Group
                style={{ width: "100%", display: "grid" }}
                onChange={onChange}
                defaultValue={checkedList}
                value={checkedList}
              >
                {programData !== null ? <Checkbox value="Program" style={{ marginLeft: "8px" }}>Program</Checkbox> : null}
                <Checkbox value="Invoice Details">Invoice Details</Checkbox>
                <Checkbox value="Funds Expected">Funds Expected</Checkbox>
                {socketInfoData?.status_data?.[0].party_type === PartyType.CUSTOMER ? <Checkbox value="Finances Maturing">Finances Maturing</Checkbox> : null}

              </Checkbox.Group>
            )
          }
        ]
        :
        [
          {
            key: 1,
            label: (
              <Checkbox.Group
                style={{ width: "100%", display: "grid" }}
                onChange={onChange}
                defaultValue={checkedList}
                value={checkedList}
              >
                {socketInfoData?.status_data?.[0].party_type === PartyType.CUSTOMER ? <Checkbox value="Finances Maturing">Finances Maturing</Checkbox> : null}
              </Checkbox.Group>
            )
          }
        ]

  return loading ? <Spin /> : (
    (
      <div>
        <div className="fixedContainer">
          <Row>
            <Col span={22}>
              <div className="HeadingTxt"> Dashboard</div>
            </Col>
            <Col span={2}>
              <div
                className="filterContainer"
              // onClick={onClickFilter}
              >

                <Dropdown menu={{ items }}>
                  <img src={FilterIcon} alt="filter" />
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>

        <div className="dashboardContainer mainContentContainer" style={{ overflow: "hidden", width: "100%" }}>
          
          <DndProvider backend={HTML5Backend}>
            <div className="DragCrad">
              {checkedList.map((item, index) => (
                <DraggableCard
                  key={item}
                  item={item}
                  index={index}
                  moveCard={moveCard}
                  onClickClose={onClickClose
                  }
                  data={socketInfoData?.dashboard_data}
                />
              ))}
            </div>
          </DndProvider>


        </div>
      </div>
    )
  );
};
export default Dashboard;