import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Button, message } from "antd";
import OnboardingParty from "./onboardingParty";
import AccountDetails from "./existingCustomer/yes/accountDetails";
import Kyc from "./existingCustomer/no/kyc";
import httpClient from "../../utils/config/core/httpClient";
import baseurl from "../../utils/config/url/base";
import Remittance from "./existingCustomer/no/remittence";
import "./onboarding.scss";
import { ErrorMessage } from "../../utils/enum/messageChoices";
import { OnboardingStatus, ResponseStatus, Status } from "../../utils/enum/choices";
import Breadcrumbs from "../common/heading/breadcrumb";
const OnboardingTabs = () => {
    const navigate = useNavigate();
    const loginData = localStorage.getItem("login_detail") as any;
    const [tabValue, setTabValue] = useState("partyDetails");
    const [existingPartyValue, setExistingPartyValue] = useState("")
    const [AccountInputValue, setAccountInputValue] = useState("")
    const [CustomerIdInputValue, setCustomerIdInputValue] = useState("")
    const [AccCurrencyvalue, setAccCurrencyvalue] = useState("")

    const [kycData, setKycData] = useState([]);
    const [NonMandatoryKycData, setNonMandatoryKycData] = useState([]);
    const [KycConfig, setkycConfig] = useState([]);
    const [countryId, setCountryId] = useState("");
    const [mandatoryFileList, setMandatoryFileList] = useState([])
    const [NonMandatoryFileLists, setNonMandatoryFileList] = useState([])
    const [TotalMandatoryDocLists, setTotalMandatoryDocLists] = useState([])

    const [accountCurrencyValue, setAccountCurrencyValue] = useState("")
    const [accountNumberValue, setAccountNumberValue] = useState("")
    const [accountNameValue, setAccountNameValue] = useState("")
    const [accountWithBankValue, setAccountWithBankValue] = useState("")
    const [bicCodeValue, setBicCodeValue] = useState("")
    const [ifscCodeValue, setIfscCodeValue] = useState("")
    const [ibanValue, setIbanValue] = useState("")
    const [commentsValue, setCommentsValue] = useState("");
    const [partyData, setPartyData] = useState({} as any);
    const [ExistingCustomerData, setExistingCustomerData] = useState("" as any)
    const [remittanceValues, setRemittanceValues] = useState([]);
    const [accountFieldsError, setAccountFieldsError] = useState(false);
    const [CountryList, SetCountryList] = useState("")

    useEffect(() => {

        httpClient
            .getInstance()
            .get(`${baseurl}api-auth/onboarding/remittance-info/`)
            .then((response: any) => {
                setRemittanceValues(response.data.data)
            })
            .catch(() => {
                navigate("/Notfound")
            })

        httpClient
            .getInstance()
            .get(`${baseurl}api/counterparty/onboarding/`)
            .then((resp: any) => {
                SetCountryList(resp.data.data[0].country_code)
                setExistingCustomerData(resp.data.data[0].existing_customer)
            })
            .catch(() => {
                navigate("/Notfound")
            })

    }, [])

    const callback = (key: any) => {
        setTabValue(key);
    };
    const kycConfigValue = (value: any) => {
        setkycConfig(value)
    }
    const countryvalue = (value: any) => {
        setCountryId(value)
    }
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)
    const existingParty = (data: any) => {
        setExistingPartyValue(data)
        if (data === "YES") {
            setTabValue("accountDetails")
        } else if (data === "NO") {
            setTabValue("kycInfo")
        }
    }

    const getPartyData = (data: any) => {
        setPartyData(data)
    }
    const onboardingSubmit = () => {
        setDisableSubmitBtn(true)
        const loginlocalItems = localStorage.getItem("login_detail") as any;
        if (partyData) {
            if (partyData.existing_customer === "YES" && partyData.account_number !== null && partyData.customer_id !== null) {
                if (AccountInputValue !== "" && CustomerIdInputValue !== "" && AccCurrencyvalue !== "") {
                    const body = {
                        account_number: AccountInputValue,
                        customer_id: CustomerIdInputValue,
                        account_currency: AccCurrencyvalue
                    }
                    httpClient
                        .getInstance()
                        .put(`${baseurl}api-auth/party/${JSON.parse(loginlocalItems).party_id}/`, body)
                        .then((response: any) => {
                            if (response.data.status === ResponseStatus.SUCCESS) {
                                const body = {
                                    action: "SUBMIT",
                                    type: "accounts.parties",
                                    t_id: JSON.parse(loginlocalItems).party_id
                                }

                                httpClient
                                    .getInstance()
                                    .post(`${baseurl}api/resource/action/status/`, body)
                                    .then((response: any) => {
                                        if (response.data.status === ResponseStatus.SUCCESS) {
                                            message.success(response?.data?.transition_status)
                                            navigate("/Sent")
                                            // const body = {
                                            //     type: "accounts.parties",
                                            //     t_id: JSON.parse(loginlocalItems).party_id,
                                            //     action: "SUBMIT",
                                            //     from_party: response.data.data.from_party,
                                            //     to_party: response.data.data.to_party,
                                            //     party: JSON.parse(loginlocalItems).party_id
                                            // }
                                            // httpClient
                                            //     .getInstance()
                                            //     .post(`${baseurl}finflo/transition/`, body)
                                            //     .then((response: any) => {
                                            //         if (response.data.status) {
                                            //             navigate("/Sent")
                                            //             message.success(response.data.status)
                                            //         } else {
                                            //             message.error(response.data.status)
                                            //         }
                                            //     })
                                            //     .catch(() => {
                                            //         navigate("/Notfound")
                                            //     })

                                        } else {
                                            message.error(response.data.status)
                                        }
                                        setDisableSubmitBtn(false)
                                    })
                                    .catch(() => {
                                        navigate("/Notfound")
                                    })
                            } else {
                                setDisableSubmitBtn(false)
                            }

                        })
                        .catch(() => {
                            navigate("/Notfound")
                        })
                } else {
                    message.error(ErrorMessage.KFAC)
                    setTabValue("accountDetails");
                    setDisableSubmitBtn(false)
                }
            } else if (partyData.existing_customer === "YES"
                && partyData.account_number === null && partyData.customer_id === null
            ) {
                if (AccountInputValue !== "" && CustomerIdInputValue !== "" && AccCurrencyvalue !== "") {
                    const body = {
                        account_number: AccountInputValue,
                        customer_id: CustomerIdInputValue,
                        account_currency: AccCurrencyvalue
                    }
                    httpClient
                        .getInstance()
                        .put(`${baseurl}api-auth/party/${JSON.parse(loginlocalItems).party_id}/`, body)
                        .then((response: any) => {
                            if (response.data.status === ResponseStatus.SUCCESS) {
                                const body = {
                                    action: "SUBMIT",
                                    type: "accounts.parties",
                                    t_id: JSON.parse(loginlocalItems).party_id
                                }

                                httpClient
                                    .getInstance()
                                    .post(`${baseurl}api/resource/action/status/`, body)
                                    .then((response: any) => {
                                        if (response.data.status === ResponseStatus.SUCCESS) {
                                            message.success(response?.data?.transition_status)
                                            setTimeout(() => {
                                                navigate("/Sent")
                                            }, 1000)
                                            // message.success(response.data.data)
                                            // const body = {
                                            //     type: "accounts.parties",
                                            //     t_id: JSON.parse(loginlocalItems).party_id,
                                            //     action: "SUBMIT",
                                            //     from_party: response.data.data.from_party,
                                            //     to_party: response.data.data.to_party,
                                            //     party: JSON.parse(loginlocalItems).party_id
                                            // }
                                            // httpClient
                                            //     .getInstance()
                                            //     .post(`${baseurl}finflo/transition/`, body)
                                            //     .then((response: any) => {
                                            //         if (response.data.status) {
                                            //             setTimeout(() => {
                                            //                 navigate("/Sent")
                                            //             }, 1000)
                                            //             message.success(response.data.status)
                                            //         } else {
                                            //             message.error(response.data.status)
                                            //         }
                                            //     })
                                            //     .catch(() => {
                                            //         navigate("/Notfound")
                                            //     })

                                        } else {
                                            message.error(response.data.status)
                                        }
                                        setDisableSubmitBtn(false)

                                    })
                                    .catch(() => {
                                        navigate("/Notfound")
                                    })
                            } else {
                                setDisableSubmitBtn(false)

                                message.error(response.data.status)

                            }
                        })
                        .catch(() => {
                            navigate("/Notfound")
                        })
                } else {
                    message.error(ErrorMessage.KFAC)
                    setTabValue("accountDetails");
                    setDisableSubmitBtn(false)
                }
            } else {
                if (TotalMandatoryDocLists.length !== mandatoryFileList.length) {
                    message.error(ErrorMessage.PUM)
                    setTabValue("kycInfo");
                    setDisableSubmitBtn(false)
                } else if (countryId === "" || KycConfig.length === 0 || kycData.length === 0) {
                    message.error(ErrorMessage.PUKYC)
                    setTabValue("kycInfo");
                    setDisableSubmitBtn(false)
                } else if (CountryList === "UNITED ARAB EMIRATES") {
                    if (accountWithBankValue === "" || accountNameValue === ""
                        || bicCodeValue === "") {
                        message.error(ErrorMessage.PMF)
                        setTabValue("remittanceInfo");
                        setDisableSubmitBtn(false)
                    } else {
                        httpClient
                            .getInstance()
                            .get(`${baseurl}api-auth/onboarding/kyc-info/`)
                            .then((data: any) => {
                                if (data.data.data.length === 0) {
                                    submitData()
                                } else {
                                    kycUpdate(data.data.data?.[0].id)
                                }
                            })
                    }
                } else if (CountryList === "INDIA") {
                    if (accountWithBankValue === "" || accountNameValue === ""
                        || accountNumberValue === "" || accountCurrencyValue === ""
                        || bicCodeValue === "" || ifscCodeValue === "") {
                        message.error(ErrorMessage.PMF)
                        setTabValue("remittanceInfo");
                        setDisableSubmitBtn(false)
                    } else {
                        httpClient
                            .getInstance()
                            .get(`${baseurl}api-auth/onboarding/kyc-info/`)
                            .then((data: any) => {
                                if (data.data.data.length === 0) {
                                    submitData()
                                } else {
                                    kycUpdate(data.data.data?.[0].id)
                                }
                            })
                    }
                } else if (CountryList === "CHINA" || CountryList === "SINGAPORE" || CountryList === "HONG KONG") {
                    if (accountWithBankValue === "" || accountNameValue === ""
                        || accountNumberValue === "" || accountCurrencyValue === ""
                        || bicCodeValue === "") {
                        message.error(ErrorMessage.PMF)
                        setTabValue("remittanceInfo");
                        setDisableSubmitBtn(false)
                    } else {
                        httpClient
                            .getInstance()
                            .get(`${baseurl}api-auth/onboarding/kyc-info/`)
                            .then((data: any) => {
                                if (data.data.data.length === 0) {
                                    submitData()
                                } else {
                                    kycUpdate(data.data.data?.[0].id)
                                }
                            })
                    }
                } else {
                    setDisableSubmitBtn(false)
                }
            }
        }
    }
    const kycUpdate = (id: any) => {
        const loginlocalItems = localStorage.getItem("login_detail") as any;

        setDisableSubmitBtn(true)

        const KYCbody = {
            client_id: JSON.parse(loginlocalItems).party_id,
            country: countryId,
            kyc_config: KycConfig,
            uploaded_document: {
                mandatory: kycData,
                non_mandatory: NonMandatoryKycData
            }
        }
        const formdata = new FormData();
        httpClient
            .getInstance()
            .put(`${baseurl}api-auth/onboarding/kyc-info/${id}`, KYCbody)
            .then((response) => {
                if (response.data.status === ResponseStatus.SUCCESS) {
                    const loginlocalItems = localStorage.getItem("login_detail") as any;
                    formdata.append(`party`, JSON.parse(loginlocalItems).party_id);

                    mandatoryFileList.map((item: any) => {
                        formdata.append(`files`, item.files);
                        formdata.append(`comments`, item.filename);
                        return null;
                    })
                    NonMandatoryFileLists.map((item: any) => {
                        formdata.append(`files`, item.files);
                        formdata.append(`comments`, item.filename);
                        return null;
                    })
                    httpClient
                        .getInstance()
                        .post(`${baseurl}api/resource/file/`, formdata)
                        .then(() => {
                            httpClient
                                .getInstance()
                                .get(`${baseurl}api-auth/onboarding/remittance-info/`)
                                .then((data: any) => {
                                    if (data.data.data.length === 0) {
                                        const body = {
                                            client_id: JSON.parse(loginlocalItems).party_id,
                                            bank_name: accountWithBankValue,
                                            account_name: accountNameValue,
                                            account_id: accountNumberValue,
                                            bic_code: bicCodeValue,
                                            iban_no: ibanValue,
                                            ifsc_code: ifscCodeValue,
                                            comments: commentsValue,
                                            currency: accountCurrencyValue
                                        }
                                        httpClient
                                            .getInstance()
                                            .post(`${baseurl}api-auth/onboarding/remittance-info/`, body)
                                            .then((response: any) => {
                                                if (response.data.status === ResponseStatus.SUCCESS) {
                                                    message.success(response.data.data)
                                                    httpClient
                                                        .getInstance()
                                                        .get(`${baseurl}api-auth/party/?party_type=bank`)
                                                        .then(() => {
                                                            if (response.data.status === ResponseStatus.SUCCESS) {
                                                                const body = {
                                                                    action: "SUBMIT",
                                                                    type: "accounts.parties",
                                                                    t_id: JSON.parse(loginlocalItems).party_id
                                                                }
                                                                httpClient
                                                                    .getInstance()
                                                                    .post(`${baseurl}api/resource/action/status/`, body)

                                                                    .then((response: any) => {
                                                                        if (response.data.status === ResponseStatus.SUCCESS) {
                                                                            message.success(response?.data?.transition_status)
                                                                            setTimeout(() => {
                                                                                navigate("/Sent")
                                                                            }, 1000)
                                                                            // message.success(response.data.data)
                                                                            // const body = {
                                                                            //     type: "accounts.parties",
                                                                            //     t_id: JSON.parse(loginlocalItems).party_id,
                                                                            //     action: "SUBMIT",
                                                                            //     from_party: response.data.data.from_party,
                                                                            //     to_party: response.data.data.to_party,
                                                                            //     party: JSON.parse(loginlocalItems).party_id
                                                                            // }
                                                                            // httpClient
                                                                            //     .getInstance()
                                                                            //     .post(`${baseurl}finflo/transition/`, body)
                                                                            //     .then((response: any) => {
                                                                            //         if (response.data.status) {

                                                                            //             message.success(response.data.status)
                                                                            //             setTimeout(() => {
                                                                            //                 navigate("/Sent")
                                                                            //             }, 1000)
                                                                            //         } else {
                                                                            //             message.error(response.data.data)
                                                                            //         }
                                                                            //     })
                                                                            //     .catch(() => {
                                                                            //         navigate("/Notfound")
                                                                            //     })

                                                                        } else {
                                                                            setDisableSubmitBtn(false)

                                                                            message.error(response.data.data)

                                                                        }
                                                                    })
                                                                    .catch(() => {
                                                                        navigate("/Notfound")
                                                                    })
                                                            } else {
                                                                message.error(response.data.data)
                                                                setDisableSubmitBtn(false)

                                                            }
                                                        })
                                                        .catch(() => {
                                                            navigate("/Notfound")
                                                        })
                                                } else {
                                                    message.error(ErrorMessage.IRD)
                                                    setDisableSubmitBtn(false)
                                                }
                                            })
                                            .catch(() => {
                                                navigate("/Notfound")
                                            })
                                    } else {
                                        const body = {
                                            client_id: JSON.parse(loginlocalItems).party_id,
                                            bank_name: accountWithBankValue,
                                            account_name: accountNameValue,
                                            account_id: accountNumberValue,
                                            bic_code: bicCodeValue,
                                            iban_no: ibanValue,
                                            ifsc_code: ifscCodeValue,
                                            comments: commentsValue,
                                            currency: accountCurrencyValue
                                        }
                                        httpClient
                                            .getInstance()
                                            .put(`${baseurl}api-auth/onboarding/remittance-info/${data.data.data.id}`, body)
                                            .then((response: any) => {
                                                if (response.data.status === ResponseStatus.SUCCESS) {
                                                    message.success(response.data.data)
                                                    httpClient
                                                        .getInstance()
                                                        .get(`${baseurl}api-auth/party/?party_type=bank`)
                                                        .then(() => {
                                                            if (response.data.status === ResponseStatus.SUCCESS) {
                                                                const body = {
                                                                    action: "SUBMIT",
                                                                    type: "accounts.parties",
                                                                    t_id: JSON.parse(loginlocalItems).party_id
                                                                }

                                                                httpClient
                                                                    .getInstance()
                                                                    .post(`${baseurl}api/resource/action/status/`, body)

                                                                    .then((response: any) => {
                                                                        if (response.data.status === ResponseStatus.SUCCESS) {
                                                                            message.success(response?.data?.transition_status)
                                                                            setTimeout(() => {
                                                                                navigate("/Sent")
                                                                            }, 1000)
                                                                            // message.success(response.data.data)
                                                                            // const body = {
                                                                            //     type: "accounts.parties",
                                                                            //     t_id: JSON.parse(loginlocalItems).party_id,
                                                                            //     action: "SUBMIT",
                                                                            //     from_party: response.data.data.from_party,
                                                                            //     to_party: response.data.data.to_party,
                                                                            //     party: JSON.parse(loginlocalItems).party_id
                                                                            // }
                                                                            // httpClient
                                                                            //     .getInstance()
                                                                            //     .post(`${baseurl}finflo/transition/`, body)
                                                                            //     .then((response: any) => {
                                                                            //         if (response.data.status) {

                                                                            //             message.success(response.data.status)
                                                                            //             setTimeout(() => {
                                                                            //                 navigate("/Sent")
                                                                            //             }, 1000)
                                                                            //         } else {
                                                                            //             message.error(response.data.data)
                                                                            //         }
                                                                            //     })
                                                                            //     .catch(() => {
                                                                            //         navigate("/Notfound")
                                                                            //     })

                                                                        } else {
                                                                            setDisableSubmitBtn(false)
                                                                            message.error(response.data.data)

                                                                        }
                                                                    })
                                                                    .catch(() => {
                                                                        navigate("/Notfound")
                                                                    })
                                                            } else {
                                                                message.error(response.data.data)
                                                                setDisableSubmitBtn(false)

                                                            }
                                                        })
                                                        .catch(() => {
                                                            navigate("/Notfound")
                                                        })
                                                } else {
                                                    message.error(ErrorMessage.IRD)
                                                    setDisableSubmitBtn(false)
                                                }
                                            })
                                            .catch(() => {
                                                navigate("/Notfound")
                                            })
                                    }
                                })


                        })
                        .catch(() => {
                            message.error(ErrorMessage.INFU)
                            setDisableSubmitBtn(false)
                        })
                } else {
                    message.error(ErrorMessage.INKYCD)
                    setDisableSubmitBtn(false)

                }
            }).catch(() => {
                setDisableSubmitBtn(false)
                navigate("/Notfound")
            })
            .catch(() => {
                setDisableSubmitBtn(false)
            })
    }
    const submitData = () => {
        const loginlocalItems = localStorage.getItem("login_detail") as any;

        setDisableSubmitBtn(true)

        const KYCbody = {
            client_id: JSON.parse(loginlocalItems).party_id,
            country: countryId,
            kyc_config: KycConfig,
            uploaded_document: {
                mandatory: kycData,
                non_mandatory: NonMandatoryKycData
            }
        }
        const formdata = new FormData();



        httpClient
            .getInstance()
            .post(`${baseurl}api-auth/onboarding/kyc-info/`, KYCbody)
            .then((response) => {
                if (response.data.status === ResponseStatus.SUCCESS) {
                    const loginlocalItems = localStorage.getItem("login_detail") as any;
                    formdata.append(`party`, JSON.parse(loginlocalItems).party_id);

                    mandatoryFileList.map((item: any) => {
                        formdata.append(`files`, item.files);
                        formdata.append(`comments`, item.filename);
                        return null;
                    })
                    NonMandatoryFileLists.map((item: any) => {
                        formdata.append(`files`, item.files);
                        formdata.append(`comments`, item.filename);
                        return null;
                    })
                    httpClient
                        .getInstance()
                        .post(`${baseurl}api/resource/file/`, formdata)
                        .then(() => {
                            const body = {
                                client_id: JSON.parse(loginlocalItems).party_id,
                                bank_name: accountWithBankValue,
                                account_name: accountNameValue,
                                account_id: accountNumberValue,
                                bic_code: bicCodeValue,
                                iban_no: ibanValue,
                                ifsc_code: ifscCodeValue,
                                comments: commentsValue,
                                currency: accountCurrencyValue
                            }
                            httpClient
                                .getInstance()
                                .post(`${baseurl}api-auth/onboarding/remittance-info/`, body)
                                .then((response: any) => {
                                    if (response.data.status === ResponseStatus.SUCCESS) {
                                        message.success(response.data.status)
                                        httpClient
                                            .getInstance()
                                            .get(`${baseurl}api-auth/party/?party_type=bank`)
                                            .then(() => {
                                                if (response.data.status === ResponseStatus.SUCCESS) {
                                                    const body = {
                                                        action: "SUBMIT",
                                                        type: "accounts.parties",
                                                        t_id: JSON.parse(loginlocalItems).party_id
                                                    }
                                                    httpClient
                                                        .getInstance()
                                                        .post(`${baseurl}api/resource/action/status/`,body)

                                                        .then((response: any) => {
                                                            if (response.data.status === ResponseStatus.SUCCESS) {
                                                                message.success(response?.data?.transition_status)
                                                                localStorage.setItem("onboardingDetail", "true")
                                                                setTimeout(() => {
                                                                    navigate("/sent")
                                                                }, 1000)

                                                                // message.success(response.data.data)
                                                                // const body = {
                                                                //     type: "accounts.parties",
                                                                //     t_id: JSON.parse(loginlocalItems).party_id,
                                                                //     action: "SUBMIT",
                                                                //     from_party: response.data.data.from_party,
                                                                //     to_party: response.data.data.to_party,
                                                                //     party: JSON.parse(loginlocalItems).party_id
                                                                // }
                                                                // httpClient
                                                                //     .getInstance()
                                                                //     .post(`${baseurl}finflo/transition/`, body)
                                                                //     .then((response: any) => {
                                                                //         if (response.data.status) {
                                                                //             localStorage.setItem("onboardingDetail", "true")
                                                                //             message.success(response.data.status)
                                                                //             setTimeout(() => {
                                                                //                 navigate("/sent")
                                                                //             }, 1000)
                                                                //         } else {
                                                                //             message.error(response.data.data)
                                                                //         }
                                                                //     })
                                                                //     .catch(() => {
                                                                //         navigate("/Notfound")
                                                                //     })

                                                            } else {
                                                                setDisableSubmitBtn(false)
                                                                message.error(response.data.data)

                                                            }
                                                        })
                                                        .catch(() => {
                                                            navigate("/Notfound")
                                                        })
                                                } else {
                                                    message.error(response.data.data)
                                                    setDisableSubmitBtn(false)

                                                }
                                            })
                                            .catch(() => {
                                                navigate("/Notfound")
                                            })
                                    } else {
                                        message.error(ErrorMessage.IRD)
                                        setDisableSubmitBtn(false)
                                    }
                                })
                                .catch(() => {
                                    navigate("/Notfound")
                                })
                        })
                        .catch(() => {
                            message.error(ErrorMessage.INFU)
                            setDisableSubmitBtn(false)
                        })
                } else {
                    message.error(ErrorMessage.INKYCD)
                    setDisableSubmitBtn(false)

                }
            }).catch(() => {
                setDisableSubmitBtn(false)
                navigate("/Notfound")
            })
            .catch(() => {
                setDisableSubmitBtn(false)
            })
    }
    const customerDetails = (data: any) => {
        setCustomerIdInputValue(data)
    }
    const accountCurrencyDetails = (data: any) => {
        setAccCurrencyvalue(data);
    }
    const accountDetails = (data: any) => {
        if (data !== "") {
            setAccountInputValue(data)
            setAccountFieldsError(false)
        } else {
            setAccountFieldsError(true)

        }


    }
    const accountCurrency = (data: any) => {
        setAccountCurrencyValue(data)
    }
    const accountNumber = (data: any) => {
        setAccountNumberValue(data)
    }
    const accountName = (data: any) => {
        setAccountNameValue(data)
    }
    const accountWithBank = (data: any) => {
        setAccountWithBankValue(data)
    }
    const bicCode = (data: any) => {
        setBicCodeValue(data)
    }
    const ifscCode = (data: any) => {
        setIfscCodeValue(data)
    }
    const iban = (data: any) => {
        setIbanValue(data)
    }
    const comment = (data: any) => {
        setCommentsValue(data)
    }
    const kycInfosNonMandatory = (datas: any) => {
        setNonMandatoryKycData(datas)
    }
    const kycInfos = (datas: any) => {
        setKycData(datas)
    }
    const mandatoryfilelist = (file: any) => {
        setMandatoryFileList(file)
    }
    const NonMandatoryfileList = (file: any) => {
        setNonMandatoryFileList(file)
    }
    const totalMandatoryDocList = (totalList: any) => {
        setTotalMandatoryDocLists(totalList)
    }
    const items = [
        {
            label: "Party Details",
            key: "partyDetails",
            id: "partyDetails",
            children: <OnboardingParty existingParty={existingParty} partyData={partyData} getPartyData={getPartyData} ExistingCustomerData={ExistingCustomerData} />
        },
        ...(existingPartyValue === "YES") ?

            [{
                label: "Account Details",
                key: "accountDetails",
                id: "accountDetails",
                children: <AccountDetails account={accountDetails} customer={customerDetails} accCurrency={accountCurrencyDetails} partyData={partyData} accountFieldsError={accountFieldsError} />
            }] : [],
        ...(existingPartyValue === "NO") ?

            [{
                label: "KYC Info",
                key: "kycInfo",
                id: "kycInfo",
                children: <Kyc
                    kycInfos={kycInfos}
                    kycConfigValue={kycConfigValue}
                    countryValue={countryvalue}
                    mandatoryfilelist={mandatoryfilelist}
                    nonMandatoryfileList={NonMandatoryfileList}
                    kycInfosNonMandatory={kycInfosNonMandatory}
                    totalMandatoryDocList={totalMandatoryDocList}

                />
            }] : [],
        ...(existingPartyValue === "NO") ?

            [{
                label: "Remittance Info",
                key: "remittanceInfo",
                id: "remittanceInfo",

                children: <Remittance
                    accountCurrency={accountCurrency}
                    accountNumber={accountNumber}
                    accountName={accountName}
                    accountWithBank={accountWithBank}
                    bicCode={bicCode}
                    ifscCode={ifscCode}
                    iban={iban}
                    comment={comment}
                    remittanceValues={remittanceValues}
                />
            }] : [],
    ]
    const buttonStatusData = {}
    return (
        <React.Fragment>
            <div className="fixedContainer">
                <Breadcrumbs
                    Data={"Onboarding"}
                    onClickExit={onboardingSubmit}
                    commentsValue={commentsValue}
                    flag="cponboarding"
                    onClickAction={onboardingSubmit}
                    buttonStatusData={buttonStatusData}

                />
            </div>
            <div className="OnboadingTabsContainer mainContentContainer">
                {existingPartyValue === "YES" || existingPartyValue === "NO" ?
                    <Button
                        onClick={onboardingSubmit}
                        className="onboardingSubmit"
                        disabled={JSON.parse(loginData).onboarding_status === OnboardingStatus.STB && JSON.parse(loginData).status === Status.IN_PROGRESS ? true : disableSubmitBtn}
                        id="onboarding_submit"
                    >Submit</Button>
                    : ""}
                <Tabs id="onboarding" defaultActiveKey="partyDetails" onChange={callback} activeKey={tabValue}
                    items={items}
                />
            </div>
        </React.Fragment>

    )
}
export default OnboardingTabs