import React, { useState, useEffect, useRef } from "react";
import { Layout, message, Spin } from "antd";
import HeaderComponent from "./header";
import SideNavbarRoutes from "./sideNavbarRoutes";
import SideNavbar from "./sideNavbar";
import { useNavigate } from "react-router";
import ChatApp from "../chat/base";
import httpClient from "../../utils/config/core/httpClient";
import baseurl from "../../utils/config/url/base";
import { useDispatch, useSelector } from "react-redux";
import { allInterestType, allInterestRateType, allCurrency, allCountry, socketInfoDetails, pageSizeDetails, socketConnected, chatSocketList, chatSocketConnection } from "../../redux/action"
import { InterestType } from '../api/base';
import { InterestRateType } from '../api/base';
import { Currency } from "../api/base";
import { Country } from "../api/base";
import { PartyType } from "../../utils/enum/choices";
import socketurl from "../../utils/config/url/socket";
import chatsocketurl from "../../utils/config/url/chatSocket";
import { startInactivityTimer, stopInactivityTimer } from '../../utils/page/inactivePage';
import * as pako from 'pako';

const { Content } = Layout;
let mainLayoutStyle = true as any
const LayoutContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [sideBarWidth, setSideBarWidth] = useState("13%");
    const [mainLayoutWidth, setMainLayoutWidth] = useState("87%");
    const [isPaused, setPause] = useState(false);
    const ws = useRef(null) as any;
    const wschat = useRef(null) as any;
    const loginData = localStorage.getItem("login_detail") || "";
    const logindata = loginData ? JSON.parse(loginData) : "";
    const socketConnection = useSelector(socketConnected)
    const [chatListData, setChatListData] = useState({} as any);
    useEffect(() => {
        //startInactivityTimer();
        const innerwidth = window.innerWidth

        if (innerwidth < 1400) {
            dispatch(
                pageSizeDetails(4)
            )
        } else if (innerwidth > 1400 && innerwidth < 1600) {
            dispatch(
                pageSizeDetails(5)
            )
        } else if (innerwidth > 1600 && innerwidth < 1800) {
            dispatch(
                pageSizeDetails(6)
            )
        } else if (innerwidth > 1800 && innerwidth < 2000) {
            dispatch(
                pageSizeDetails(8)
            )
        } else if (innerwidth > 2000) {
            dispatch(
                pageSizeDetails(9)
            )
        } else {
            dispatch(
                pageSizeDetails(4)
            )
        }

    }, [])

    const layoutStyle = (data: any) => {
        mainLayoutStyle = data
        if (data !== true) {
            setSideBarWidth("13%")
            setMainLayoutWidth("87%")
        } else {
            setSideBarWidth("7%")
            setMainLayoutWidth("93%")

        }
    }
    useEffect(() => {
        const pathname = window.location.pathname.split("/")
        ws.current = socketurl;
        const data = {
            "party_id": logindata.party_id,
            "user_id": logindata.user_id,
            "record_type":
            {
                "inbox":
                {
                    type: "ALL",
                    data: false
                },
                "sent":
                {
                    type: "ALL",
                    data: pathname[1] === 'Sent' ? true : false
                },
                "sent_aw": {
                    type: "ALL",
                    data: false
                },
                "enquiry": {
                    "data": false,
                    "filter": {
                        "model_type": null,
                        "program_type": null,
                        "transaction_date": null,
                        "due_date": null,
                        "currency": null,
                        "counterparty": null,
                        "amount": null
                    },
                    "search": null
                },
                "draft": false

            },
            "data_type": pathname[1] === 'Dashboard' ? "DASHBOARD" : "MESSAGE",
            "page_size": 10,
            "page_number": 1

        }
        setInterval(() => {
            if (ws.current.readyState === WebSocket.CLOSED) {
                ws.current.onopen = () => {
                    dispatch(
                        socketConnected('connected')
                    )
                    ws.current.send(JSON.stringify(data));
                    ws.current.onmessage = (e: any) => {
                        const message = JSON.parse(e.data);
                        const compressedDataArray = message
                        const compressedData = new Uint8Array(compressedDataArray);
                        const decompressedData = pako.inflate(compressedData, { to: 'string' });
                        const decompressedMessageData = JSON.parse(decompressedData);
                        console.log("decompressedData", decompressedMessageData);

                        dispatch(
                            socketInfoDetails(decompressedMessageData)
                        )
                    }

                    window.addEventListener('beforeunload', () => {
                        console.log("websocket disconnected  closed")
                        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
                            console.log("websocket disconnected  closed")
                            ws.current.close();
                        }
                    });
                }
            }
        }, 5000);
        if (socketConnection?.payload?.socketConnectData === "connected") {
            ws.current.onerror = function (error: any) {
                console.error('WebSocket error:', error);
            };
            ws.current.send(JSON.stringify(data));
            ws.current.onmessage = (e: any) => {
                const message = JSON.parse(e.data);
                const compressedDataArray = message
                const compressedData = new Uint8Array(compressedDataArray);
                const decompressedData = pako.inflate(compressedData, { to: 'string' });
                const decompressedMessageData = JSON.parse(decompressedData);
                console.log("decompressedData", decompressedMessageData);
                dispatch(
                    socketInfoDetails(decompressedMessageData)
                )
            }
        } else {
            ws.current.onerror = function (error: any) {
                console.error('WebSocket error:', error);
            };
            ws.current.onopen = () => {
                dispatch(
                    socketConnected('connected')
                )
                ws.current.send(JSON.stringify(data));
                ws.current.onmessage = (e: any) => {
                    const message = JSON.parse(e.data);
                    const compressedDataArray = message
                    const compressedData = new Uint8Array(compressedDataArray);
                    const decompressedData = pako.inflate(compressedData, { to: 'string' });
                    const decompressedMessageData = JSON.parse(decompressedData);
                    console.log("decompressedData", decompressedMessageData);
                    dispatch(
                        socketInfoDetails(decompressedMessageData)
                    )
                }

                window.addEventListener('beforeunload', () => {
                    console.log("websocket disconnected  closed")
                    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
                        console.log("websocket disconnected  closed")
                        ws.current.close();
                    }
                });
            }

        }

    }, []);


    useEffect(() => {
        if (!ws.current) return;
        setInterval(() => {
            if (ws.current.readyState === WebSocket.CLOSED) {
                console.log('WebSocket error WebSocket connection is closed');
            }
        }, 5000);
        ws.current.onmessage = (e: any) => {
            setIsLoading(true)
            if (isPaused) return;
            ws.current.onerror = function (error: any) {
                console.error('WebSocket error:', error);
            };
            const message = JSON.parse(e.data);
            const compressedDataArray = message

            const compressedData = new Uint8Array(compressedDataArray);
            const decompressedData = pako.inflate(compressedData, { to: 'string' });
            const decompressedMessageData = JSON.parse(decompressedData);
            console.log("decompressedData", decompressedMessageData);
            dispatch(
                socketInfoDetails(decompressedMessageData)
            )
            setTimeout(() => {
                setIsLoading(false)
            }, 2000)

        };
    }, [isPaused]);

    useEffect(() => {
        wschat.current = chatsocketurl;
        const data = {
            "type": "CHAT_LIST",
            "email": logindata.email

        }

        if (socketConnection?.payload?.socketConnectData === "connected") {

            wschat.current.send(JSON.stringify(data));
            wschat.current.onmessage = (e: any) => {

                const message = JSON.parse(e.data);
                setChatListData(message)
                dispatch(chatSocketList(message));
                dispatch(chatSocketConnection("connected"));
            }

        } else {
            wschat.current.onopen = () => {
                dispatch(
                    socketConnected('connected')
                )
                wschat.current.send(JSON.stringify(data));
                wschat.current.onmessage = (e: any) => {

                    const message = JSON.parse(e.data);
                    setChatListData(message)
                    dispatch(chatSocketList(message));
                    dispatch(chatSocketConnection("connected"));

                }

                window.addEventListener('beforeunload', () => {
                    console.log("websocket disconnected  closed")
                    if (wschat.current && wschat.current.readyState === WebSocket.OPEN) {
                        console.log("websocket disconnected  closed")
                        wschat.current.close();
                    }
                });
            }

        }

    }, []);
    useEffect(() => {
        if (!wschat.current) return;

        wschat.current.onmessage = (e: any) => {
            setIsLoading(true)
            if (isPaused) return;
            const message = JSON.parse(e.data);

            setChatListData(message)
            dispatch(chatSocketList(message));
            dispatch(chatSocketConnection("connected"));
            setTimeout(() => {
                setIsLoading(false)
            }, 2000)

        };
    }, [isPaused]);

    useEffect(() => {
        getInterestType();
        getInterestRateType();
        getCurrency();
        getCountry();
        const loginData = localStorage.getItem("login_detail") || "";
        const logindata = JSON.parse(loginData)
        if (logindata.is_administrator === true) {
            httpClient
                .getInstance()
                .get(`${baseurl}finflo/action/?party_id=${logindata.party_id}`)
                .then((response: any) => {
                    if ((logindata.party_type === PartyType.NONE && logindata.status === "NEW" && logindata.onboarding_status === "NONE")) {
                        message.error("")
                        navigate("/")
                    } else if ((logindata.party_type === PartyType.NONE && logindata.status === "NEW" && logindata.onboarding_status === "DRAFT") || (logindata.party_type === PartyType.NONE && logindata.status === "IN_PROGRESS" && logindata.onboarding_status === "SENT_TO_COUNTERPARTY")) {
                        navigate("/CounterPartyOnboarding")
                    } else if ((logindata.party_type === PartyType.NONE && logindata.status === "DEACTIVATED" && logindata.onboarding_status === "REJECTED")) {
                        navigate("/Inbox")
                    } else if ((logindata.party_type === PartyType.NONE && logindata.status === "IN_PROGRESS" && logindata.onboarding_status === "SENT_TO_BANK")) {
                        navigate("/Sent")
                    } else if ((response?.data?.data?.length === 0 && logindata.party_type === PartyType.CUSTOMER && logindata.status === "ONBOARDED" && logindata.onboarding_status === "COMPLETED") || (response?.data?.data?.length === 0 && logindata.party_type === PartyType.NON_CUSTOMER && logindata.status === "ONBOARDED" && logindata.onboarding_status === "COMPLETED") || (response?.data?.data?.length === 0 && logindata.party_type === PartyType.BANK)) {
                        navigate("/UserAuthorization")
                    }
                })
        } else {

            if ((logindata.party_type === PartyType.NONE && logindata.status === "NEW" && logindata.onboarding_status === "NONE")) {
                message.error("")
                navigate("/")
            } else if ((logindata.party_type === PartyType.NONE && logindata.status === "NEW" && logindata.onboarding_status === "DRAFT") || (logindata.party_type === PartyType.NONE && logindata.status === "IN_PROGRESS" && logindata.onboarding_status === "SENT_TO_COUNTERPARTY")) {
                navigate("/CounterPartyOnboarding")
            } else if ((logindata.party_type === PartyType.NONE && logindata.status === "DEACTIVATED" && logindata.onboarding_status === "REJECTED")) {
                navigate("/Inbox")
            } else if ((logindata.party_type === PartyType.NONE && logindata.status === "IN_PROGRESS" && logindata.onboarding_status === "SENT_TO_BANK")) {
                navigate("/Sent")
            }
        }

        const innerwidth = window.innerWidth

        if (innerwidth > 600 && innerwidth < 1024) {
            setSideBarWidth("7%")
            setMainLayoutWidth("93%")
        }
    }, [])
    const getInterestType = async () => {
        const data = await
            InterestType()
        dispatch(
            allInterestType({
                allInterestType: data,
            })
        );
    }

    const getInterestRateType = async () => {
        const data = await InterestRateType()
        dispatch(
            allInterestRateType({
                allInterestRateType: data,
            })
        );
    }

    const getCurrency = async () => {
        const data = await Currency()
        dispatch(
            allCurrency({
                allCurrency: data,
            })
        );
    }

    const getCountry = async () => {
        const data = await Country()
        dispatch(
            allCountry({ allCountry: data })
        );
    }

    const getLoading = (data: string) => {
        setIsLoading(true)
    }
    // useEffect(() => {
    //     startInactivityTimer(logoutUser);

    //     return () => {
    //         stopInactivityTimer();
    //     };
    // }, []);

    const logoutUser = () => {
        message.error('Session Expired, try again!', 3)
        setTimeout(() => {
            navigate("/")
            window.location.reload()
            localStorage.clear();
        }, 1000)
    }
    return isLoading ? <Spin /> : (
        <React.Fragment>
            <div className="App" style={{ position: "relative" }}>

                <Layout >
                    <Layout>
                        <div style={{ width: sideBarWidth }}>
                            <SideNavbar LayoutStyle={layoutStyle} />
                        </div>
                        <div style={{ width: mainLayoutWidth }}>
                            <Layout className={"mainLayoutContainer"} >
                                <HeaderComponent loading={getLoading} width={mainLayoutWidth}></HeaderComponent>
                                <Content className="contentLayout">
                                    <SideNavbarRoutes width={mainLayoutWidth} />
                                </Content>
                                <ChatApp />
                            </Layout>
                        </div>
                    </Layout>

                </Layout>


            </div>

        </React.Fragment>
    );
}
export default LayoutContainer;
