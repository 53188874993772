import React, { useState, useEffect } from "react";
import { Row, Col, Card, Input, message, DatePicker, Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { actionStatus } from "../../api/actionStatus";
import httpClient from "../../../utils/config/core/httpClient";
import baseurl from "../../../utils/config/url/base";
import CurrencyField from "../function/currency";
import Breadcrumbs from "../heading/breadcrumb";
import { Action, ResponseStatus } from "../../../utils/enum/choices";
import moment from 'moment';
import images from '../../../assets/images';
import imageBaseurl from "../../../utils/config/url/image";
import { Currency } from "../../api/base";
import NotFound from "../../../utils/page/500";

const FinanceRequestDetail = () => {
  const { state } = useLocation();
  const Navigator = useNavigate();
  const [buttondisable, setButtonDisable] = useState(false);
  const [primaryButtonDisable, setPrimaryButtonDisable] = useState(false)
  const [secondaryButtonDisable, setSecondaryButtonDisable] = useState(false)
  const [thirdButtonDisable, setThirdButtonDisable] = useState(false)
  const [invoice_detail] = useState(state as any);
  const [errorsvalue, setErrorsValue] = useState([] as any)
  const { TextArea } = Input;
  const [commentsValue, setCommentsvalue] = useState(null as any);
  const [financeDate, setFinanceDate] = useState("");
  const [financeDueDate, setFinanceDueDate] = useState("");
  const [currencyData, setCurrencyData] = useState([]);
  const [financeAmount, setFinanceamount] = useState("")
  const [financeAmountErr, setFinanceamountErr] = useState(false as any)
  const [loanAccountNumber, setLoanAccountNumber] = useState("")
  const [fee, setFee] = useState('');
  const { ErrorImage, DatePickerImg } = images;

  const onClickExit = () => {
    Navigator(`/${invoice_detail.fromMenu}`)
  };

  const getCurrency = async () => {
    const data = await Currency();
    setCurrencyData(data);
  }

  const onClickAction = (buttonKey: any) => {
    if (buttonKey === Action.APPROVE && loanAccountNumber === "" && invoice_detail?.data?.interim_state === 'Finance requested') {
      message.error('Loan Account Number is required')
    } else {
      if (invoice_detail?.data?.next_available_transitions === null) {
        financeRequestApi()
      }
      setButtonDisable(true)
      if (buttonKey === Action.RECHECK || buttonKey === Action.RETURN) {
        setSecondaryButtonDisable(true)
      } else if (buttonKey === Action.REJECT) {
        setThirdButtonDisable(true)
      } else {
        setPrimaryButtonDisable(true)
      }
      if ((buttonKey === Action.REJECT && commentsValue === "") || buttonKey === Action.REJECT && commentsValue === null) {
        message.error("Please fill comments")
        setSecondaryButtonDisable(false)
        setThirdButtonDisable(false)
        setPrimaryButtonDisable(false)
        setButtonDisable(false)
      } else {
        if (commentsValue !== null) {
          const commentsBody = {
            t_id: invoice_detail.data.work_model.t_id,
            type: invoice_detail.data.model_type,
            comments: commentsValue
          }
          httpClient
            .getInstance()
            .post(`${baseurl}api/message/comments/`, commentsBody)
            .then(() => {
              actionStatus(invoice_detail, buttonKey, returnRoute, buttonStatus)
            })
            .catch(() => <NotFound />)
        } else {
          actionStatus(invoice_detail, buttonKey, returnRoute, buttonStatus)
        }
      }
    }

  };
  const returnRoute = (data: any) => {
    if (data === true) {
      Navigator(`/${invoice_detail.fromMenu}`)
    }
  }
  useEffect(() => {
    getCurrency();
    setErrorsValue(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.errors)
    setFinanceDate(moment().format("DD-MM-YYYY"));
    setFinanceDueDate(moment().format("DD-MM-YYYY"));

    httpClient
      .getInstance()
      .get(`${baseurl}api/finance-request/${invoice_detail.data.work_model?.t_id}/`)
      .then((resp: any) => {
        if (resp.data.status === ResponseStatus?.SUCCESS) {
          setFinanceamount(resp.data?.data?.finance_amount)
          setFee(resp?.data?.data?.fee);
        }
      })
  }, []);

  const feeCondition: boolean = invoice_detail?.data?.interim_state !== 'Finance requested' && invoice_detail?.data?.record_datas?.values?.[0]?.fields?.fee === '0.00'

  const buttonStatus = (data: any) => {
    setButtonDisable(data)
    setPrimaryButtonDisable(data)
    setSecondaryButtonDisable(data)
    setThirdButtonDisable(data)
  }
  const buttonStatusData = {
    default: buttondisable,
    primary: primaryButtonDisable,
    secondary: secondaryButtonDisable,
    third: thirdButtonDisable

  }

  const disabledDate = (current: any) => {
    return current && current > moment().endOf("day");
  };

  const onChangeDate = (e: any) => {
    setFinanceDate(moment(e?._d).format("DD-MM-YYYY"))
  }

  const onChangeFinanceDueDate = (e: any) => {
    setFinanceDueDate(moment(e?._d).format("DD-MM-YYYY"))
  }

  const handleFeeChange = (event: any) => {
    const inputValue = event.target.value;
    setFee(inputValue);
  };

  const handleFeeonBlur = (event: any) => {
    const inputValue = event.target.value;
    const roundedValue = parseFloat(inputValue).toFixed(2);
    setFee(roundedValue);
  }

  const financeRequestApi = () => {
    const bodyData = {
      finance_amount: parseFloat(financeAmount),
      finance_date: financeDate,
      finance_due_date: financeDueDate,
      loan_acc_number: loanAccountNumber,
      repayment_date: moment().format("DD-MM-YYYY"),
      fee: fee,
    }
    httpClient
      .getInstance()
      .put(`${baseurl}api/finance-request/${invoice_detail.data.work_model?.t_id}/`, bodyData)
      .then((resp: any) => {
        // if(resp.data.status === ResponseStatus.FAILURE) {
        //   message.warning('Loan Account Number is Needed')
        // }
      })
  }

  const onChangeFinanceAmt = (e: any) => {
    if (parseFloat(e.target.value) > parseFloat(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_amount)) {
      setFinanceamountErr(true)
      setButtonDisable(true)
    } else {
      setFinanceamount(e.target.value)
      setFinanceamountErr(false)
      setButtonDisable(false)
    }
  }

  useEffect(() => {
    setFee(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.fee);
    setFinanceDueDate(moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.due_date).format("DD-MM-YYYY"));
  }, [])

  return (
    <React.Fragment>
      <div className="fixedContainer">
        <Breadcrumbs
          Data={invoice_detail}
          onClickExit={onClickExit}
          commentsValue={commentsValue}
          flag="financeRequest"
          onClickAction={onClickAction}
          buttonStatusData={buttonStatusData}
        />
      </div>
      <div className="Card_Main_Container mainContentContainer">
        <Card className="CardContainer">
          <div className="programHeading">Invoice
          </div>
          {errorsvalue !== undefined && errorsvalue !== null && errorsvalue?.length !== 0 ?
            <Row gutter={24} style={{ marginTop: "20px" }}>
              <Col span={2}></Col>
              <Col span={22}>
                <Row gutter={24} style={{ width: "80%", backgroundColor: "#FDF1F1", borderLeft: "5px solid #E43E3E", padding: "10px" }}>
                  <Col span={2} style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <img src={ErrorImage} alt="errorImage" width="35px" />
                  </Col>
                  <Col span={20}>
                    <div style={{ color: "black", fontSize: "16px", fontFamily: "Poppins", fontWeight: 500, paddingBottom: "10px" }}> Automatic Finance Rejection: Please review error details</div>

                    {errorsvalue?.map((item: any) => {
                      return (
                        <div style={{ height: "30px", color: "#485B6C" }}>
                          <div style={{ width: "8px", height: "8px", borderRadius: "25px", backgroundColor: "#EB4F50", display: "inline-block" }}></div> <>{item?.finance_amount}
                            {item?.finance_percent}
                            {item?.system_date}
                            {item?.due_date}
                            {item?.status}</>
                        </div>
                      )
                    })}
                  </Col>
                </Row>
              </Col>
            </Row>
            : ""}
          <div className="SummaryContainer">
            <Row
              className="counterPartyCollapseDetails"
              style={{ marginTop: "3%" }}
            >
              <Col span={2}></Col>
              <Col span={5}><p>Buyer Name</p></Col>
              <Col span={5} className="counterPartyValue">
                <p>  {invoice_detail?.data?.buyer_details?.[0]?.name
                }</p>
              </Col>
              <Col span={1}></Col>
              <Col span={5}><p>Seller Name</p></Col>
              <Col span={5} className="counterPartyValue">
                <p>{invoice_detail?.data?.seller_details?.[0]?.name}</p>
              </Col>
            </Row>

            <Row
              className="counterPartyCollapseDetails"
            >
              <Col span={2}></Col>
              <Col span={5}><p>Invoice No</p></Col>
              <Col span={5} className="counterPartyValue">
                <p> {
                  invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_no
                }</p>
              </Col>
              <Col span={1}></Col>
              <Col span={5}><p>Invoice Date</p></Col>
              <Col span={5} className="counterPartyValue">
                <p>{moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_date).format("DD-MM-YYYY")}</p>
              </Col>
            </Row>

            <Row
              className="counterPartyCollapseDetails"
            >
              <Col span={2}></Col>
              <Col span={5}><p>Invoice Amount</p></Col>
              <Col span={5} className="counterPartyValue">
                <p> <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_amount ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_amount : invoice_detail?.data?.record_datas?.values?.[0]?.fields?.amount} /></p>
              </Col>
              <Col span={1}></Col>
              <Col span={5}><p>Due Date</p></Col>
              <Col span={5} className="counterPartyValue">
                <p>  {
                  moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.due_date).format("DD-MM-YYYY")
                }</p>

              </Col>

              <Col span={1}></Col>
            </Row>

            <Row
              className="counterPartyCollapseDetails"
            >
              <Col span={2}></Col>
              <Col span={5}><p style={invoice_detail?.data?.interim_state === 'Finance requested' && localStorage.getItem('user') === 'Bank' ? { marginTop: 8 } : {}}>Finance Date</p></Col>
              <Col span={5} className="counterPartyValue">
                {
                  (localStorage.getItem("user") === "Bank") && invoice_detail?.data?.next_available_transitions === null ?
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      id="finance_datePicker"
                      disabledDate={disabledDate}
                      suffixIcon={<img src={DatePickerImg} alt="pickericon" />}
                      style={{
                        lineHeight: "2.5",
                        // borderRadius: "8px",
                        fontSize: "13px",
                        padding: "5px 15px",
                        width: "100%",
                        height: "40px",
                      }}
                      placeholder={financeDate ? financeDate : "DD-MM-YYYY"}
                      className={financeDate ? "valueExpiryDate" : ""}
                      onChange={onChangeDate}
                    />
                    :
                    moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_date).format("DD-MM-YYYY")
                }
              </Col>
              <Col span={1}></Col>
              <Col span={5}><p style={invoice_detail?.data?.interim_state === 'Finance requested' && localStorage.getItem('user') === 'Bank' ? { marginTop: 8 } : {}}>Finance Amount</p></Col>
              <Col span={5} className="counterPartyValue" style={{ display: "initial" }}>
                {
                  (localStorage.getItem("user") === "Bank") && invoice_detail?.data?.next_available_transitions === null ?
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "10px" }}>{
                          currencyData?.map((value: any) => {
                            if (invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency === value?.id) {
                              return value?.description
                            }
                          })
                        }
                        </span>
                        <Input
                          defaultValue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_amount}
                          onChange={onChangeFinanceAmt}

                        // onBlur={onBlurFinanceAmount}
                        />
                        {/* {console.log("financeAmountErr",financeAmountErr)} */}
                        {/* {financeAmountErr ? <div className="errorMsg">Finance amount cannot be greater than {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_amount}</div> :""} */}

                      </div>
                    </>
                    :
                    <p> <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_amount} /></p>
                }
                {/* {console.log("financeAmountErr11",financeAmountErr)} */}

                {financeAmountErr ? <div className="errorMsg">Finance amount cannot be greater than {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_amount}</div> : ""}

              </Col>
              <Col span={1}></Col>
            </Row>
            {localStorage.getItem("user") !== "Bank" && invoice_detail?.data?.interim_state === 'Finance requested' ? null : <Row
              className="counterPartyCollapseDetails"
            >
              <Col span={2}></Col>
              <Col span={5}><p style={invoice_detail?.data?.interim_state === 'Finance requested' && localStorage.getItem('user') === 'Bank' ? { marginTop: 8 } : {}}>Finance Due Date</p></Col>
              <Col span={5} className="counterPartyValue">
                {
                  (localStorage.getItem("user") === "Bank") && invoice_detail?.data?.next_available_transitions === null ?
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      id="finance_due_datepicker"
                      //  disabledDate={disabledDate}
                      suffixIcon={<img src={DatePickerImg} alt="pickericon" />}
                      style={{
                        lineHeight: "2.5",
                        // borderRadius: "8px",
                        fontSize: "13px",
                        padding: "5px 15px",
                        width: "100%",
                        height: "40px",
                      }}
                      placeholder={moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.due_date).format('DD-MM-YYYY')}
                      className={financeDueDate ? "valueExpiryDate" : ""}
                      onChange={onChangeFinanceDueDate}
                    />
                    :
                    localStorage.getItem("user") !== "Bank" && invoice_detail?.data?.interim_state === 'Finance requested' ? null :
                      moment(invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_due_date).format('DD-MM-YYYY')
                }
              </Col>
              {feeCondition ? <> <Col span={1}></Col>
                <Col span={5}><p>Loan Account Number</p></Col>
                <Col span={5} className="counterPartyValue" >
                  {invoice_detail?.data?.next_available_transitions === null ?
                    <Input id="loanAccountNumber" onChange={(e: any) => setLoanAccountNumber(e.target.value)} defaultValue={loanAccountNumber} required={true} />
                    : invoice_detail?.data?.record_datas?.values?.[0]?.fields?.loan_acc_number}
                </Col></> : <><Col span={1}></Col>
                <Col span={5}><p style={invoice_detail?.data?.interim_state === 'Finance requested' && localStorage.getItem('user') === 'Bank' ? { marginTop: 8 } : {}}>Fee</p></Col>
                <Col span={5} className="counterPartyValue">
                  {invoice_detail?.data?.next_available_transitions === null ?
                    <>
                      <Select
                        showSearch
                        placeholder="Currency"
                        optionFilterProp="children"
                        // className="dropDownContainer"
                        style={{ width: '30%' }}
                        // onChange={currencyFilter}
                        // value={CurrencyfilteredData}
                        allowClear={true}
                        id="feeCurrency"
                      >
                        {
                          currencyData?.map((item: any, index: number) => {
                            return <Select.Option value={item.description} id={item.id} key={index}>
                              {item.description}
                            </Select.Option>
                          })}
                      </Select>
                      <span style={{ marginLeft: 10 }}><Input id="fee"
                        placeholder="Fee" value={fee} onChange={(e: any) => handleFeeChange(e)} onBlur={handleFeeonBlur} /> </span>
                    </>
                    :
                    <p> <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoice_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.fee} /></p>
                  }
                </Col> </>}
            </Row>}
            {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate !== "0.00" && invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount !== "0.00" ?
              <Row
                className="counterPartyCollapseDetails"

              >
                <Col span={2}></Col>
                <Col span={5}>
                  <p>{invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate !== "0.00" ? "Interest Rate" : ""}</p></Col>
                <Col span={5} className="counterPartyValue">

                  <p> {
                    invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate !== "0.00" ? invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_rate : ""}</p>
                </Col>
                <Col span={1}></Col>
                <Col span={5}><p>{invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount !== "0.00" ? "Interest Amount" : ""}</p></Col>
                <Col span={5} className="counterPartyValue">
                  <p> {invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount !== "0.00" ?
                    <CurrencyField currencyvalue={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.finance_currency} amount={invoice_detail?.data?.record_datas?.values?.[0]?.fields?.interest_amount} />
                    : ""}</p>
                </Col>
                <Col span={1}></Col>

              </Row> : ""}
            {(localStorage.getItem("user") === "Bank") ?
              "" :
              <Row
                className="counterPartyCollapseDetails"
              >
                <Col span={2}> </Col>
                <Col span={5} >
                  <div className="SummaryLabel" style={{ padding: 0 }}><p>Comments From Bank</p></div>
                </Col>
                <Col style={{ padding: 0 }} className="counterPartyValue">
                  <div>
                    <p> {invoice_detail.data.comments !== "None" ? invoice_detail.data.comments : "-"}</p>
                  </div>
                </Col>
              </Row>
            }

            {invoice_detail?.data?.attachments?.file?.length > 0 ?
              <Row gutter={24} className="counterPartyCollapseDetails" >
                <Col span={2}></Col>
                <Col span={4}><p>Attachments</p></Col>
                <Col span={1}></Col>

                {invoice_detail?.data?.attachments?.file?.map((value: any, index: number) => {
                  const splitedValue = value.file_path.split("/")
                  const lastValue = splitedValue.pop()
                  return (
                    <Col span={4} key={lastValue} >
                      <div className="fileAttachmentsText" style={{ width: "max-content" }}>
                        <a href={`${imageBaseurl}${value.file_path}`} target="_blank" id={`invoice_images${index}`}>{lastValue}</a>
                      </div>
                    </Col>
                  )
                })
                }
              </Row> : ""}
            <Row gutter={24} className="counterPartyCollapseDetails">

            </Row>


            {localStorage.getItem("user") !== "Bank" && invoice_detail?.data?.interim_state === 'Finance requested' ? null : invoice_detail?.data?.next_available_transitions === null ?
              <Row
                className="counterPartyCollapseDetails"
              >
                <Col span={2}></Col>
                <Col span={5}><p>Loan Account Number </p></Col>
                <Col span={5} className="counterPartyValue">
                  {invoice_detail?.data?.next_available_transitions === null ?
                    <Input id="loanAccountNumber" onChange={(e: any) => setLoanAccountNumber(e.target.value)} defaultValue={loanAccountNumber} required={true} />
                    : invoice_detail?.data?.loan_acc_number}
                </Col>
                <Col span={12}></Col>
              </Row>
              :
              <Row
                className="counterPartyCollapseDetails"
              >
                <Col span={2}></Col>
                <Col span={5}><p>Comments</p></Col>
                <Col span={5} className="counterPartyValue">
                  {invoice_detail?.data?.comments ? invoice_detail?.data?.comments : "-"}
                </Col>
                {feeCondition ? null : <><Col span={1}></Col>
                  <Col span={5}><p>Loan Account Number</p></Col>
                  <Col span={5} className="counterPartyValue" >
                    {invoice_detail?.data?.next_available_transitions === null ?
                      <Input id="loanAccountNumber" onChange={(e: any) => setLoanAccountNumber(e.target.value)} defaultValue={loanAccountNumber} required={true} />
                      : invoice_detail?.data?.record_datas?.values?.[0]?.fields?.loan_acc_number}
                  </Col>
                </>}
              </Row>
            }

            <Row gutter={24} className="counterPartyCollapseDetails">
              <Col span={2}> </Col>
              <Col span={5} >
                <p>Comments</p>
              </Col>
              <Col span={16} style={{ padding: 0 }}>
                <TextArea style={{ width: "50%", margin: "0px 0px" }} onChange={(e: any) => setCommentsvalue(e.target.value)} id="comments" />
              </Col>
            </Row>
          </div>
        </Card>
      </div >
    </React.Fragment >

  );
};
export default FinanceRequestDetail;
