import httpClient from "../../utils/config/core/httpClient";
import baseurl from "../../utils/config/url/base";
import { transition } from "./finfloTransition";
import { message } from "antd";
import { Action, ResponseStatus } from "../../utils/enum/choices";
import { ErrorMessage } from "../../utils/enum/messageChoices";
export const actionStatus = (info: any, buttonKey: any, returnRoute: any, buttonStatus: any) => {

    // httpClient
    //     .getInstance()
    //     .get(`${baseurl}api/resource/action/status/?action=${buttonKey === Action.DRAFT ? "SUBMIT" : buttonKey}&type=${info?.data?.work_model?.model_type}&t_id=${info?.data?.work_model?.t_id}`)
    //     .then((resp: any) => {
    //         if (resp.data.status === ResponseStatus.SUCCESS) {
    //             transition(info, buttonKey === Action.DRAFT ? "SUBMIT" : buttonKey, resp.data.data.from_party, resp.data.data.to_party, info.fromMenu, returnRoute, buttonStatus)
    //         } else {
    //             message.error(resp.data.data)
    //             buttonStatus(false)
    //         }
    //     }).catch(() => {
    //         message.error(ErrorMessage.INA)
    //         buttonStatus(false)
    //     })
const body = {
    action:buttonKey === Action.DRAFT ? "SUBMIT" : buttonKey,
    type:info?.data?.work_model?.model_type,
    t_id:info?.data?.work_model?.t_id
}
    httpClient
        .getInstance()
        .post(`${baseurl}api/resource/action/status/`,body)
        .then((resp: any) => {
            
            if (resp.data.status === ResponseStatus.SUCCESS) {
                message.success(resp?.data?.transition_status !== null ? resp?.data?.transition_status : resp?.data?.status)
                buttonStatus(false)
                returnRoute(true)
                // transition(info, buttonKey === Action.DRAFT ? "SUBMIT" : buttonKey, resp.data.data.from_party, resp.data.data.to_party, info.fromMenu, returnRoute, buttonStatus)
            } else {
                message.error(resp.data.detail)
                buttonStatus(false)
            }
        }).catch(() => {
            message.error(ErrorMessage.INA)
            buttonStatus(false)
        })

}
