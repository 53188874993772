import { Row, Col, Spin, Card, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import httpClient from "../../../utils/config/core/httpClient";
import baseurl from "../../../utils/config/url/base";
import TableComponent from "../table/Table";
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumbs from "../heading/breadcrumb";
import { useSelector } from "react-redux";
import { pageSizeDetails } from "../../../redux/action";

const HistoryList = () => {
  const Navigator = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, SetIsLoading] = useState(false);
  const [pageNumber, setpageNumber] = useState(1)
  const [totalcount, setTotalCount] = useState(1)
  const { state } = useLocation();
  const program_detail = useState(state as any);
  const pageSizeDatas = useSelector(pageSizeDetails);
  const PageSizeCount = pageSizeDatas?.payload?.pageSizeData

  useEffect(() => {
    if (program_detail?.[0]?.data?.workflowitems) {
      httpClient
        .getInstance()
        .get(`${baseurl}api/message/workflow-history/?wf_item=${program_detail?.[0]?.data?.workflowitems}&page_size=${PageSizeCount}&page_number=${pageNumber}`)
        .then((resp: any) => {
          setDataSource(resp.data.data);
          setTotalCount(resp.data.count)
          SetIsLoading(false);
        });
    } else {
      httpClient
        .getInstance()
        .get(`${baseurl}api/message/workflow-history/?wf_item=${program_detail?.[0]?.data?.id}&page_size=${PageSizeCount}&page_number=${pageNumber}`)
        .then((resp: any) => {
          setDataSource(resp.data.data);
          setTotalCount(resp.data.count)
          SetIsLoading(false);
        });
    }

  }, []);

  const onClickExit = () => {
    Navigator(`/${program_detail?.[0]?.fromMenu}`)
  }
  const onChange = (e: any) => {
    SetIsLoading(true);
    setpageNumber(e)
    if (program_detail?.[0]?.data?.workflowitems) {
      httpClient
        .getInstance()
        .get(`${baseurl}api/message/workflow-history/?wf_item=${program_detail?.[0]?.data?.workflowitems}&page_size=${PageSizeCount}&page_number=${e}`)
        .then((resp: any) => {
          setDataSource(resp.data.data);
          setTotalCount(resp.data.count)
          SetIsLoading(false);
        });
    } else {
      httpClient
        .getInstance()
        .get(`${baseurl}api/message/workflow-history/?wf_item=${program_detail?.[0]?.data?.id}&page_size=${PageSizeCount}&page_number=${e}`)
        .then((resp: any) => {
          setDataSource(resp.data.data);
          setTotalCount(resp.data.count)
          SetIsLoading(false);
        });
    }
  }
  return isLoading ? (
    <Spin />
  ) : (
    <React.Fragment>
      <div className="fixedContainer">
        <Breadcrumbs
          Data={program_detail?.[0]}
          onClickExit={onClickExit}
          commentsValue={""}
          flag="history"
          onClickAction={""}
          buttonStatusData={""}
        />

      </div>
      <div className="allContainer mainContentContainer">

        <Card className="tableContainer"
        >

          <Row className="InvoiceGroupButtons">
            <Col span={3} className="InvoiceSelectedRowLength">
              History
            </Col>
          </Row>
          <TableComponent
            fromPage={"InboxHistory"}
            fromMenu={"InboxHistory"}
            recordType={dataSource}
          />
          {dataSource?.length > 0 ?
            <Pagination
              pageSize={PageSizeCount}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              defaultCurrent={pageNumber}
              current={pageNumber}
              total={totalcount}
              responsive={true}
              onChange={onChange}
            />
            : null}

        </Card>
      </div>
    </React.Fragment>
  );
};
export default HistoryList;
