export const login = (user: any): { type: string, payload: any } => ({
  type: 'login',
  payload: user,
});

export const allInbox = (data: any): { type: string, payload: any } => ({
  type: 'allInbox',
  payload: data,
});

export const allCurrency = (currencyData: any): { type: string, payload: any } => ({

  type: 'allCurrency',
  payload: currencyData,
});
export const basicDetails = (basicDetailsData: any): { type: string, payload: any } => ({
  type: 'basicDetails',
  payload: basicDetailsData,
});
export const inbox_programDetails = (inboxProgramDetail: any): { type: string, payload: any } => ({
  type: 'inbox_programDetails',
  payload: inboxProgramDetail,
});
export const allInterestType = (interestType: any): { type: string, payload: any } => ({
  type: 'allInterestType',
  payload: interestType,
});
export const allInterestRateType = (interestRateType: any): { type: string, payload: any } => ({
  type: 'allInterestRateType',
  payload: interestRateType,
});
export const menuStatus_data = (menuStatus: any): { type: string, payload: any } => ({
  type: 'menuStatus_data',
  payload: menuStatus,
});
export const inboxCount_data = (inboxCount: any): { type: string, payload: any } => ({
  type: 'inboxCount_data',
  payload: inboxCount,
});
export const draftCount_data = (draftCount: any): { type: string, payload: any } => ({
  type: 'draftCount_data',
  payload: draftCount,
});
export const allCountry = (countryData: any): { type: string, payload: any } => ({
  type: 'allCountry',
  payload: countryData,
});
export const program_Basicdetails = (programBasicdetailsData: any): { type: string, payload: any } => ({
  type: 'program_Basicdetails',
  payload: programBasicdetailsData,
});
export const program_counterpartydetails = (programCounterpartydetailsData: any): { type: string, payload: any } => ({
  type: 'program_counterpartydetails',
  payload: programCounterpartydetailsData,
});
export const socketInfoDetails = (socketInfo: any): { type: string, payload: any } => ({
  type: 'socketInfoDetails',
  payload: socketInfo,
});

export const pageSizeDetails = (pageSizeData: any): { type: string, payload: any } => ({
  type: 'pageSizeDetails',
  payload: pageSizeData
})

export const socketConnected = (socketConnectData: any): { type: string, payload: any } => ({
  type: 'socketConnected',
  payload: socketConnectData
})

export const chatSocketList = (chatList: any): { type: string, payload: any } => ({
  type: 'chatSocketList',
  payload: chatList
})
export const chatSocketConnection = (chatConnection: any): { type: string, payload: any } => ({
  type: 'chatSocketConnection',
  payload: chatConnection
})
export const chatConversationConnection = (coversationConnection: any): { type: string, payload: any } => ({
  type: 'chatConversationConnection',
  payload: coversationConnection
})

export const selectedAwaitingItems = (selectedItems: any): { type: string, payload: any } => ({
  type: 'selectedAwaitingItems',
  payload: selectedItems
})

export const selectedNotificationData = (selectedNotification: any): { type: string, payload: any } => ({
  type: 'selectedNotificationData',
  payload: selectedNotification
})




