import { useState, useEffect } from "react";
import { Input, Select, DatePicker, Form, InputNumber } from "antd";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import moment from "moment";
import { UploadImage } from "../../../common/UploadImage/manualInvoiceUpload";
import images from "../../../../assets/images";
import { useNavigate } from "react-router";

const Header_APF_Buyer = [{
  Id: "Seller Id", Name: "Seller Name", InvNo: "Inv No", InvDate: "Inv Date", InvAmt: "Inv Amount", DueDate: "Due Date", FinanceAmount: "Finance Amount", FinanceCurrency: "Financing Currency", SettlementCurrency: "Settlement Currency", autoFinance: "Auto Finance", attached: "Attached"
}]
const ApfBuyerEdit = ({ invoice_detail, getApfBuyerdataSource, getApfBuyercounterPartyList,setFileLists ,commentsData }: any) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const Navigate = useNavigate()

  const { DeleteIcon, DatePickerImg, DropdownIcon } = images
  const [counterpartyId, setCounterpartyId] = useState([] as any);
  const [MaxInvoicePercent, setMaxInvoicePercent] = useState("");
  const [counterPartyList, setCounterPartyList] = useState([] as any);
  const [sellerName, setSellerName] = useState("");

  const [sellerId, setSellerId] = useState([]);
  const [currencyList, SetCurrencyList] = useState([]);
  const [apfBuyerdataSource, setApfBuyerDataSource] = useState([
    {
      SellerID: "" as any,
      SellerName: sellerName as any,
      InvoiceNo: "" as any,
      InvoiceDate: "" as any,
      InvoiceCurrency: "" as any,

      InvoiceAmount: 0 as any,
      DueDate: "" as any,
      FinancingCurrency: "" as any,
      SettlementCurrency: "" as any,
      counter_party: counterpartyId as any,
      FinanceAmount: 0 as any,
      ProgramID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.ProgramID
    }])

  getApfBuyerdataSource(apfBuyerdataSource)
  getApfBuyercounterPartyList(counterPartyList)
 
  const selectedFiles = (value: any) => {
    setFileLists(value)
  }
  useEffect(() => {
    httpClient
      .getInstance()
      .get(`${baseurl}api/pairing/?pg_id=${invoice_detail?.id ? invoice_detail?.id : invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.ProgramID}&data_type=INVOICE`)
      .then((resp: any) => {
        setCounterPartyList(resp.data.data);
        setMaxInvoicePercent(resp.data.data[0].max_invoice_percent)
        const partyName = [] as any;
        resp.data.data.map((items: any) => {
          return partyName.push(items.buyer_name);
        });

        const sellerIds = [] as any;
        resp.data.data.map((items: any) => {
          return sellerIds.push(items.counterparty_uid);
        });
        setSellerId(sellerIds);
  setApfBuyerDataSource([
    {
      SellerID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.SellerID,
      SellerName: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.SellerName,
      InvoiceNo: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceNo,
      InvoiceDate: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceDate,
      InvoiceCurrency: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceCurrency,
      InvoiceAmount: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.InvoiceAmount,
      DueDate: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.DueDate,
      FinanceAmount: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.FinanceAmount,
      FinancingCurrency: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.FinancingCurrency,
      SettlementCurrency: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.FinancingCurrency,
      counter_party: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.counter_party,
      // RepaymentAccount: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.RepaymentAccount,
      // RepaymentCurrency: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.RepaymentCurrency,
      // counterparty_id: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.counterparty_id,
      // RepaymentID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.RepaymentID,
      // auto_finance: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.auto_finance,
      ProgramID: invoice_detail?.data?.record_datas?.values?.[0]?.fields?.invoices?.[0]?.ProgramID
    }])
       
      })
      .catch(() => {
        Navigate("/Notfound")
      })




    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        SetCurrencyList(resp.data.data);
      })
      .catch(() => {
        Navigate("/Notfound")
      })

  }, [])
  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };
  const disabledInvoiceDate = (current: any) => {
    return current && current > moment().endOf("day");
  }
  const onRowDelete = (value: any) => {
    const apfbuyerlist = apfBuyerdataSource
    apfBuyerdataSource.map((data: any, index: any) => {

      if ((value.SellerID === data.SellerID) && (value.InvoiceNo === data.InvoiceNo)) {
        apfbuyerlist.splice(index, 1);
      }
      setApfBuyerDataSource(apfBuyerdataSource)
    })
  }
  const onFinish = (values: any) => {
    console.log("values apf buyer login", values)
  }

  const onFinishFailed = (values: any) => {
    console.log("values apf buyer login failed error message", values)
  }

  return (
    <div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateTrigger="onBlur"
      >
        <div className='containerTable'>
          <div className="OuterDiv">
            {
              Header_APF_Buyer && Header_APF_Buyer.map((item: any, index: any) => {
                return (
                  <div key={index} className='HeadInnerDiv'>
                    <h1 className='head' style={{ width: "11%" }}>{item.Id}</h1>
                    <h1 className='head' style={{ width: "10%" }}>{item.Name}</h1>
                    <h1 className='head' style={{ width: "10%" }}>{item.InvNo}</h1>
                    <h1 className='head' style={{ width: "12%" }}>{item.InvDate}</h1>
                    <h1 className='head' style={{ width: "20%" }}>{item.InvAmt}</h1>
                    <h1 className='head' style={{ width: "10%" }}>{item.DueDate}</h1>
                    <h1 className='head' style={{ width: "19%" }}>{item.FinanceAmount}</h1>
                    <h1 className='head' style={{ width: "6%" }}>{item.attached}</h1>
                  </div>
                )
              })

            }
          </div>
          <div className='OuterDiv'>
            {

              apfBuyerdataSource && apfBuyerdataSource.map((record: any, index: number) => {
                return (

                  <div key={index} className='InnerDiv' >

                    <h5 className='body' style={{ width: "12%", fontSize: "10px" }} id={`invApfBuyer_sellerId${index}`}>

                      {
                        sellerId.length > 1 ?
                          <>
                            {console.log("")}
                            <Select
                              placeholder="Select"
                              showSearch
                              optionFilterProp="children"
                              // defaultValue=""
                              style={{
                                width: 115,
                                color: "#000000",
                                fontSize: "14px"
                              }}
                              suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                              defaultValue={record.SellerID}
                              value={record.SellerID}
                              onSelect={(event: string) => {
                                const arr = [...apfBuyerdataSource];
                                arr[index].SellerID = event;
                                setApfBuyerDataSource(arr);
                                const names: any[] = [];
                                let values: any;
                                counterPartyList.map((data: any) => {
                                  if (event === data.counterparty_uid) {

                                    return (
                                      (values = {
                                        id: data.counterparty_uid,
                                        name: data.counterparty_id,
                                      }),
                                      names.push(values),
                                      setSellerName(data.counterparty_id),
                                      setCounterpartyId(data.counterparty_id),
                                      setMaxInvoicePercent(data.max_invoice_percent),
                                      (arr[index].SellerName = data.counter_party_name),
                                      (arr[index].counter_party = data.counter_party),
                                      setApfBuyerDataSource(arr)
                                    );
                                  }
                                  return true;
                                });

                              }}
                            >
                              {sellerId.map((partyList: any) => {
                                return (
                                  <Option value={partyList} key={partyList} id={`invApfBuyer_${partyList}`}>
                                    {partyList}
                                  </Option>
                                );
                              })}
                            </Select>
                          </>
                          : <div style={{ fontSize: "14px" }}>{sellerId[0]}</div>
                      }
                    </h5>
                    <h5 className='body' style={{ width: "10%" }} id={`invApfBuyer_sellerName${index}`}>
                      <div>{sellerId.length === 1 ? counterPartyList[0]?.counter_party_name : record.SellerName}</div>
                    </h5>
                    <h5 className='body' style={{ width: "10%" }} id="invApfBuyer_InvoiceNumber">
                      <div >
                        <Input
                          id={`invApfBuyer_InvoiceNumber${index}`}
                          className="InputContainer"
                          onChange={(event: any) => {
                            const arr = apfBuyerdataSource;
                            arr[index].InvoiceNo = event.target.value;
                            if (sellerId.length === 1) {
                              arr[index].SellerID = sellerId[0];
                              arr[index].SellerName = counterPartyList[0]?.counter_party_name;
                              arr[index].counter_party = counterPartyList[0]?.counter_party;
                              setMaxInvoicePercent(counterPartyList?.[0]?.max_invoice_percent)

                            }
                            setApfBuyerDataSource(arr);
                          }}
                          defaultValue={record?.InvoiceNo}
                          value={record?.InvoiceNo}
                        />
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "12%" }} id="invApfBuyer_InvoiceDate">

                      <DatePicker
                        id={`invApfBuyer_InvoiceDate${index}`}
                        suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}
                        placeholder={record.InvoiceDate}
                        format="DD-MM-YYYY"
                        onChange={(event: any) => {
                          const arr = apfBuyerdataSource;
                          arr[index].InvoiceDate = moment(event._d).format("DD-MM-YYYY");
                          setApfBuyerDataSource(arr);
                        }}
                        style={{ width: 100 }}
                        className={record.InvoiceDate ? "valueExpiryDate InputContainer" : "InputContainer"}
                        disabledDate={disabledInvoiceDate}
                      />

                    </h5>

                    <h5 className='body' style={{ width: "20%" }} id={`invApfBuyer_Invoice${index}`}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ paddingRight: "10px" }} id={`invApfBuyer_InvoiceCurrency${index}`}>
                          {console.log("")}

                          <Select
                            placeholder="Select"
                            showSearch
                            style={{ width: 100, fontSize: "14px" }}
                            onSelect={(event: string) => {
                              const arr = [...apfBuyerdataSource];
                              arr[index].InvoiceCurrency = event;
                              arr[index].FinancingCurrency = event;
                              setApfBuyerDataSource(arr);

                            }}
                            suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                            defaultValue={record.InvoiceCurrency}
                            value={record.InvoiceCurrency}
                          >
                            {currencyList &&
                              currencyList.map((item: any) => {
                                return (
                                  <Option
                                    value={item.description}
                                    id={item.description}
                                    key={item.description}
                                  >
                                    {item.description}
                                  </Option>
                                );
                              })}
                          </Select>
                        </span>
                        <InputNumber
                          className="InputContainer"
                          disabled={!record.SellerID}
                          id={`invApfBuyer_InvoiceAmount${index}`}
                          onChange={(event: any) => {
                            const arr = [...apfBuyerdataSource];
                            arr[index].InvoiceAmount = parseInt(event);
                            setApfBuyerDataSource(arr);
                            const financeAmt = parseInt(event) * (parseInt(MaxInvoicePercent) / 100)
                            arr[index].FinanceAmount = financeAmt.toFixed(2)
                          }}
                          formatter={(value, e) => {
                            if (value === "" || isNaN(parseFloat(value))) {
                              return "";
                            }
                            return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                              : value
                          }}
                          defaultValue={record.InvoiceAmount}
                        value={record.InvoiceAmount}
                        />
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "10%" }}>
                      <div>
                        <DatePicker
                          id={`invApfBuyer_DueDate${index}`}
                          disabledDate={disabledDate}
                          suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}
                          placeholder={record.DueDate}
                          format="DD-MM-YYYY"
                          onChange={(event: any) => {
                            const arr = apfBuyerdataSource;
                            arr[index].DueDate = moment(event._d).format("DD-MM-YYYY");
                            setApfBuyerDataSource(arr);
                          }}
                          style={{ width: 100 }}
                          className={record.DueDate ? "valueExpiryDate InputContainer" : "InputContainer"}
                        />
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "20%" }} id={`invApfBuyer_Finance${index}`}>
                      <div style={{ display: "flex" }}>
                        <span id={`invApfBuyer_FinanceCurrency${index}`} style={{ marginRight: "10px", border: "1px solid #d9d9d9", width: "50%", height: " 45px", borderRadius: "10px", padding: "9px", backgroundColor: "#FFFFFF", display: "flex", alignItems: "center" }}>
                          {record.InvoiceCurrency}
                        </span>

                        <div style={{ display: "block" }}>
                          <InputNumber
                            className="InputContainer"
                            style={{ width: "80%" }}
                            id={`invApfBuyer_FinanceAmount${index}`}
                            defaultValue={record.FinanceAmount}
                            value={(record.FinanceAmount)}
                            disabled={!record.InvoiceAmount}
                            onChange={(event: any) => {
                              const arr = [...apfBuyerdataSource];
                             arr[index].FinanceAmount = parseInt(event)
                              setApfBuyerDataSource(arr);
                            }}
                            formatter={(value, e) => {
                              if (value === "" || isNaN(parseFloat(value))) {
                                return "";
                              }
                              return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                                : value
                            }}
                          />

                        </div>




                      </div>
                    </h5>

                    <h5 className='body' style={{ width: "6%" }} id={`invApfBuyer_attachments${index}`}>
                      <UploadImage selectedFiles={selectedFiles} attachmentFiles={[]} />
                    </h5>
                    <h5 id={`invApfBuyer_delete${index}`}>
                      <img src={DeleteIcon} alt="deleteIcon"
                        onClick={() => onRowDelete(record)}
                      />
                    </h5>
                  </div>

                )
              })

            }
          </div>


        </div>
        <div className="SummaryContainer" style={{ marginTop: "5%" }}>
          <div className="SummaryLabel">Comments</div>
          <TextArea
            style={{ width: "50%", margin: "0px 10px" }}
            onChange={(e: any) => commentsData(e.target.value)}
            id={`inv_comments`}
          />
        </div>
      </Form>
    </div>
  )
}
export default ApfBuyerEdit