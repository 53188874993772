import httpClient from "../../utils/config/core/httpClient";
import baseurl from "../../utils/config/url/base";
import { message } from "antd";
import { ResponseStatus } from "../../utils/enum/choices";
export const manualActionStatus = async (type: any, id: any, buttonKey: any,returnRoute:any) => {
    // httpClient
    //     .getInstance()
    //     .get(`${baseurl}api/resource/action/status/?action=${buttonKey}&type=${type}&t_id=${id}`)
    //     .then((resp: any) => {
    //         if (resp.data.status === ResponseStatus.SUCCESS) {
    //             const loginlocalItems = localStorage.getItem("login_detail") as any;
    //             const body = {
    //                 type: type,
    //                 t_id: id,
    //                 action: buttonKey,
    //                 from_party: resp.data.data.from_party,
    //                 to_party: resp.data.data.to_party,
    //                 party: JSON.parse(loginlocalItems).party_id
    //             }

    //             httpClient
    //                 .getInstance()
    //                 .post(`${baseurl}finflo/transition/`, body)
    //                 .then((response: any) => {
    //                     if (response.data.status) {
    //                         returnRoute("success",response.data.status)
    //                     } else {
    //                         returnRoute("error",response.data.data)
    //                     }
    //                 })
    //         } else {
    //             message.error(resp.data.data)
    //         }
    //     })

    const body = {
        action:buttonKey,
        type:type,
        t_id:id
    }

    httpClient
        .getInstance()
        .post(`${baseurl}api/resource/action/status/`,body)
        .then((resp: any) => {
            if (resp.data.status === ResponseStatus.SUCCESS) {
                // const loginlocalItems = localStorage.getItem("login_detail") as any;
                console.log("resp?.data",resp?.data)
                // message.success(resp?.data?.transition_status)
                returnRoute("success",resp?.data?.transition_status)
                // const body = {
                //     type: type,
                //     t_id: id,
                //     action: buttonKey,
                //     from_party: resp.data.data.from_party,
                //     to_party: resp.data.data.to_party,
                //     party: JSON.parse(loginlocalItems).party_id
                // }

                // httpClient
                //     .getInstance()
                //     .post(`${baseurl}finflo/transition/`, body)
                //     .then((response: any) => {
                //         if (response.data.status) {
                //             returnRoute("success",response.data.status)
                //         } else {
                //             returnRoute("error",response.data.data)
                //         }
                //     })
            } else {
                message.error(resp.data.data)
            }
        })
}

