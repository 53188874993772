import React, { useState, useEffect } from "react";
import { Button, Input, Card } from "antd";
import BasicDetails from "./basicDetails";
import CounterPartyDetail from "./counterParty";
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumbs from "../../heading/breadcrumb";
const ProgramDetail = () => {
  const { state } = useLocation();
  const [programData] = useState(state as any);
  const [commentsValue, setCommentsvalue] = useState("" as any)
  const [displayComments, setDisplayComments] = useState(false)
  const Navigator = useNavigate();
  const { TextArea } = Input;
  const onClickExit = () => {
    if (programData.fromMenu === "InboxHistory") {
      Navigator(`/Inbox/History`, { state: { workFlowItem: programData.data.workflowitems } })
    } else {
      Navigator(`/${programData.fromMenu}`)
    }
  };

  const displayHistory = () => {
    setDisplayComments(true)
    Navigator(`/${programData.fromMenu}/History`, { state: programData })
  };

  useEffect(() => {
    if (localStorage.getItem("user") === "Bank") {
      setDisplayComments(true);
    }
  }, [])
  const buttonStatusData = {}

  return (
    <React.Fragment>
      <div className="fixedContainer" style={{ position: "unset" }}>
        <Breadcrumbs
          Data={programData}
          onClickExit={onClickExit}
          commentsValue={commentsValue}
          flag="program"
          onClickAction={onClickExit}
          buttonStatusData={buttonStatusData}
        />
      </div>

      <div className="ProgramMainContainer mainContentContainer" style={{ padding: "4vh 24px 24px" }}>

        <div className="Card_Main_Container">
          <Card className="CardContainer" style={{ height: "77vh", overflow: "scroll" }}>
            {programData?.fromMenu !== "InboxHistory" ?
              <Button className="HistoryButtonContainer" onClick={displayHistory}><div style={{ float: "right" }}> History </div></Button>
              : ""}
            <div className="SummaryContainer">
              <BasicDetails flag={programData?.data?.fromMenu} fromMenu={programData?.data?.fromMenu} programData={programData?.data} />
              <CounterPartyDetail flag={programData?.data?.fromMenu} fromMenu={programData?.data?.fromMenu} programData={programData?.data} />
            </div>
            {displayComments ?
              <div className="SummaryContainer">
                <div className="SummaryLabel">Comments</div>
                <TextArea style={{ width: "50%", margin: "0px 10px" }} onChange={(e: any) => setCommentsvalue(e.target.value)} id={`${programData?.fromMenu}_${programData?.recordType}_program_comments`} />
              </div>
              : ""}
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ProgramDetail;
