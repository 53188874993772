import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Select, message, DatePicker, Spin, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  program_Basicdetails, program_counterpartydetails, allCurrency, allInterestType, allInterestRateType, basicDetails
} from "../../../redux/action";
import { UploadImage } from "../../common/UploadImage/Uploadimage";
import { CheckboxField } from "../../common/function/checkbox";
import httpClient from "../../../utils/config/core/httpClient";
import baseurl from "../../../utils/config/url/base";
import images from "../../../assets/images";
import moment from "moment";
import "../manageScf.scss";
import { ErrorMessage, FieldErrorMessages } from "../../../utils/enum/messageChoices";
import { InputPatterns } from "../../../utils/validators/inputPattern";
import { InterestPaidBy, ProgramType, ResponseStatus } from "../../../utils/enum/choices";
import { InterestDeduction, InterestPaidByData } from "../../../utils/validators/dropdown";

interface IProps {
  nextpage: any;
  programData: any;
}

interface BasicDetails {
  id: number;
  max_limit_amount: number;
  limit_currency: string;
  expiry_date: any;
  max_invoice_currency: string;
  max_invoice_amount: number;
  max_invoice_percent: number;
  max_tenor: number;
  grace_period: number;
  interest_type: string;
  interest_rate_type: string;
  margin: number;
  comments: string;
  workflowitems: {
    interim_state: string;
  }
  repayment_account_currency: string;
  attachments: any;
  work_model: {
    t_id: ""
  },
  interest_paid_by: any,
  buyer_details: [
    {
      self_invoice_upload: boolean
    }
  ],
  repayment_account: {
    account_number: "",
    currency: ""
  },
  interest_deduction: "",
  overdue_interest_rate: "",
  buyer_rebate: boolean,
  auto_debit_invoice: boolean,
  insurance_backed: any,
  buyer_rebate_percent: any,
  fee: null | any,
  auto_finance: boolean,
  refund_int_early_repay: boolean
}

const BasicDetailsForm = (props: React.PropsWithChildren<IProps>) => {
  const dispatch = useDispatch();
  const { DropdownIcon, DatePickerImg } = images;
  const program_basicdetail_data = useSelector(program_Basicdetails)
  const Currency_Data = useSelector(allCurrency);
  const InterestType_Data = useSelector(allInterestType);
  const InterestRatetype_Data = useSelector(allInterestRateType);
  const basicDetailsDatacontainer = useSelector(basicDetails);
  console.log("basicDetailsDatacontainer", basicDetailsDatacontainer)
  console.log("basicDetailsDatacontainer", program_basicdetail_data.payload.programBasicdetailsData)
  const basicValues = program_basicdetail_data.payload.programBasicdetailsData
  console.log("basic values program_basicdetail_data", basicValues)
  const [BasicDetailsInfo, setBasicDetailsInfo] = useState<BasicDetails>(
    {} as BasicDetails
  );
  console.log("programprogram basicValues rfprogram", basicValues, props)
  const [fileList, setFileList] = useState([] as any);
  const [interestType, setInterestType] = useState(basicValues?.interest_type ? basicValues?.interest_type : "" as any);
  const [currencyList, SetCurrencyList] = useState([]);
  const [disableButton, setDisableButton] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [maxInvoiceAmtValue, setMaxInvoiceAmtValue] = useState(basicValues ? basicValues?.max_invoice_amount : "" as any)
  const [maxInvoiceAmtError, setMaxInvoiceAmtError] = useState(false)
  const [gracePeriodValue, setGracePeriodValue] = useState(basicValues ? basicValues?.grace_period : "")
  const [gracePeriodError, setgracePeriodError] = useState(false)
  const [maxInvoicePctError, setMaxInvoicePctError] = useState(false)
  const [limitAmount, setLimitAmount] = useState(basicValues
    ? basicValues?.max_limit_amount
    : "" as any)
  const [maxTenorAmount, setMaxTenorAmount] = useState("")
  const disableButtonValue = basicValues ? basicValues?.workflowitems ? basicValues?.workflowitems.interim_state : "" : "";
  const basedOnStatusValue = disableButtonValue === "AWAITING_APPROVAL" ? true : disableButtonValue === "AWAITING_SIGN_A" ? true : disableButtonValue === "AWAITING_SIGN_B" ? true : false
  const [disableButtonBasedStatus, setDisableButtonBasedStatus] = useState(basedOnStatusValue);
  const [maxInvoicePctValue, setMaxInvoicePctValue] = useState("")
  const [interestRateType, setInterestRateType] = useState(null)
  const [PartyAccountDetails, setPartyAccountDetails] = useState([])
  const [PartyAccountCurrencyDetails, setPartyAccountCurrencyDetails] = useState(basicValues ? basicValues?.repayment_account?.currency : "")
  const [uploadBuyer, setUploadBuyer] = useState(basicValues?.buyer_details?.[0]?.self_invoice_upload ? basicValues?.buyer_details?.[0]?.self_invoice_upload : false)
  const [partyAccountId, setPartyAccountId] = useState("");
  const [autoFinanceDebit, setAutoFinanceDebit] = useState(basicValues?.auto_debit_invoice ? basicValues?.auto_debit_invoice : false)
  const [autoFinanceInvoice, setAutoFinanceInvoice] = useState(basicValues ? basicValues?.auto_finance : false)
  const [backedInsurance, setBackedInsurance] = useState(basicValues ? basicValues?.insurance_backed : false)
  const [rebateBuyer, setRebateBuyer] = useState(basicValues?.buyer_rebate)
  const [refundEarlyPay, setRefundEarlyPay] = useState(false)
  const [intDeduction, setIntDeduction] = useState("")
  const [maxInvoiceCurrency, setMaxInvoiceCurrency] = useState(null)
  const [interestPaidBy, setInterestPaidBy] = useState(basicValues?.interest_paid_by)

  const { Option } = Select;
  const { TextArea } = Input;
  const onFinish = (values: any) => {
    setDisableButton(true);
    // let programType = "";
    // if (props?.programData?.record_datas) {
    //   programType = props?.programData?.record_datas.values[0].fields.program_type;
    // } else {
    //   programType = props?.programData?.program;
    // }

    let body;
    if (localStorage.getItem("user") === "Bank") {
      console.log("values", rebateBuyer, "rebateBuyer", values, "1", rebateBuyer ? rebateBuyer !== undefined ? rebateBuyer : false : values.buyer_rebate !== undefined ? values.buyer_rebate : false)
      console.log(rebateBuyer ? rebateBuyer !== undefined ? rebateBuyer : false : false, "values", values, "1", rebateBuyer, "2", values.buyer_rebate)
      console.log("interestType", interestType, values)
      body = {
        program_type:
          props?.programData?.record_datas
            ? props?.programData?.record_datas?.values?.[0]?.fields?.program_type
            : props?.programData?.program_type ? props?.programData?.program_type : props?.programData,
        finance_request_type: "AUTOMATIC",
        limit_currency: values.limit_currency ? values.limit_currency : "",
        max_limit_amount: limitAmount ? limitAmount : parseInt(values.limit_amount),
        expiry_date:
          values?.expiry_date !== undefined ? moment(values?.expiry_date?._d).format("DD-MM-YYYY") : moment(basicValues?.expiry_date).format("DD-MM-YYYY"),
        max_invoice_currency: values.max_invoice_currency ? values.max_invoice_currency : maxInvoiceCurrency,
        max_invoice_amount: maxInvoiceAmtValue ? maxInvoiceAmtValue : parseInt(values.max_invoice_amount),
        max_invoice_percent: maxInvoicePctValue ? maxInvoicePctValue : parseInt(values.max_invoice_percent),
        max_tenor: parseInt(values.max_tenor),
        grace_period: gracePeriodValue ? gracePeriodValue : parseInt(values.grace_period),
        interest_type: interestType === "Fixed" ? 1 : interestType === "FIXED" ? 1 : interestType === "FLOATING" ? 2 : interestType === "Floating" ? 2 : interestType ? interestType : values?.interest_type,
        interest_rate_type: interestType === "Fixed" || interestType === 1 ? null : interestRateType ? interestRateType : values.interest_rate_type === "Euribor" ? 1 : values.interest_rate_type === "Libor" ? 2 : values.interest_rate_type === "Sofr" ? 3 : values.interest_rate_type,
        margin: parseFloat(values.margin),
        comments: values.comments,
        upload_by_buyer: uploadBuyer ? uploadBuyer : values.upload_by_buyer === "" ? false : values.upload_by_buyer,
        interest_paid_by: props?.programData?.record_datas?.values?.[0]?.fields?.program_type === "RF" || props?.programData?.program_type === "RF" || props?.programData === "RF" ? "OWNPARTY" :
          interestPaidBy ? interestPaidBy : values.interest_paid_by,
        repayment_account: partyAccountId ? partyAccountId : basicValues?.repayment_account?.id,
        interest_deduction: interestPaidBy === InterestPaidBy.OWNPARTY || values.interest_deduction === InterestPaidBy.OWNPARTY ? "REPAYMENT" : intDeduction ? intDeduction : values.interest_deduction,
        buyer_rebate: rebateBuyer ? rebateBuyer !== undefined ? rebateBuyer : false : false,
        buyer_rebate_percent: rebateBuyer === false || values.buyer_rebate === false
          ? 0 : values.buyer_rebate_percent ? parseFloat(values.buyer_rebate_percent) : 0,
        overdue_interest_rate: values.overdue_interest_rate,
        refund_int_early_repay: refundEarlyPay,
        auto_debit_invoice: autoFinanceDebit,
        insurance_backed: backedInsurance,
        fee: backedInsurance === false ? null : values.fee !== "" || values.fee !== null ? values.fee ? values.fee : null : null,
        auto_finance: autoFinanceInvoice,
        party: localStorage.getItem("party__id")
      };

    } else {
      console.log("autoFinanceInvoice", autoFinanceInvoice, "refundEarlyPay", refundEarlyPay)
      body = {
        program_type:
          props?.programData?.record_datas
            ? props?.programData?.record_datas?.values?.[0]?.fields?.program_type
            : props?.programData?.program_type ? props?.programData?.program_type : props?.programData,
        finance_request_type: "AUTOMATIC",
        limit_currency: values.limit_currency ? values.limit_currency : "",
        max_limit_amount: limitAmount ? limitAmount : parseInt(values.limit_amount),
        expiry_date:
          values?.expiry_date !== undefined ? moment(values?.expiry_date?._d).format("DD-MM-YYYY") : moment(basicValues?.expiry_date).format("DD-MM-YYYY"),
        max_invoice_currency: values.max_invoice_currency ? values.max_invoice_currency : maxInvoiceCurrency,
        max_invoice_amount: maxInvoiceAmtValue ? maxInvoiceAmtValue : parseInt(values.max_invoice_amount),
        max_invoice_percent: maxInvoicePctValue ? maxInvoicePctValue : parseInt(values.max_invoice_percent),
        max_tenor: parseInt(values.max_tenor),
        grace_period: gracePeriodValue ? gracePeriodValue : parseInt(values.grace_period),
        interest_type: interestType === "Fixed" ? 1 : interestType === "FIXED" ? 1 : interestType === "FLOATING" ? 2 : interestType === "Floating" ? 2 : interestType,
        interest_rate_type: interestType === "Fixed" || interestType === 1 ? null : interestRateType ? interestRateType : values.interest_rate_type === "Euribor" ? 1 : values.interest_rate_type === "Libor" ? 2 : values.interest_rate_type === "Sofr" ? 3 : values.interest_rate_type,
        margin: parseFloat(values.margin),
        comments: values.comments,
        upload_by_buyer: uploadBuyer ? uploadBuyer : values.upload_by_buyer === "" ? false : values.upload_by_buyer,
        interest_paid_by: props?.programData?.record_datas?.values?.[0]?.fields?.program_type === "RF" || props?.programData?.program_type === "RF" || props?.programData === "RF" ? "OWNPARTY" :
          interestPaidBy ? interestPaidBy : values.interest_paid_by,
        repayment_account: partyAccountId ? partyAccountId : basicValues?.repayment_account?.id,
        interest_deduction: props?.programData?.record_datas?.values?.[0]?.fields?.program_type === "RF" || props?.programData?.program_type === "RF" || props?.programData === "RF" ? intDeduction ? intDeduction : values.interest_deduction : interestPaidBy === InterestPaidBy.OWNPARTY || values.interest_deduction === InterestPaidBy.OWNPARTY ? "REPAYMENT" : intDeduction ? intDeduction : values.interest_deduction,
        buyer_rebate: rebateBuyer ? rebateBuyer : false,
        buyer_rebate_percent:
          rebateBuyer === false ? null : values.buyer_rebate_percent ? parseFloat(values.buyer_rebate_percent) : 0,
        overdue_interest_rate: values.overdue_interest_rate,
        refund_int_early_repay: refundEarlyPay,
        auto_debit_invoice: autoFinanceDebit,
        insurance_backed: backedInsurance,
        fee: backedInsurance === false ? null : values.fee !== "" || values.fee !== null ? values.fee ? values.fee : null : null,
        auto_finance: autoFinanceInvoice,
        party: localStorage.getItem("party_id")
      };
      console.log("body", body)

    }
    console.log("basic values", basicValues, basicValues && basicValues?.id)
    console.log("basic values", props)
    if (basicValues && basicValues?.work_model || props?.programData?.work_model) {
      console.log("if program", body)
      const id = basicValues?.work_model?.t_id || props?.programData?.work_model?.t_id
      httpClient
        .getInstance()
        .put(`${baseurl}api/program/${id}/`, body)
        .then((resp: any) => {
          console.log("programprogram resp", resp, resp.data.data)
          if (resp.data.status !== ResponseStatus.FAILURE) {
            setDisableButton(false)
            message.success(ErrorMessage.SB);
            localStorage.setItem("program_id", resp.data.data.id);
            localStorage.setItem("program_t_id", resp.data.data.id)
            setBasicDetailsInfo(resp.data.data);
            console.log("getProgramDetails 2")

            getProgramDetails(resp.data.data)
            dispatch(
              program_counterpartydetails(null)
            )
            props.nextpage("2");
            if (fileList.length > 0) {
              const formdata = new FormData();
              fileList.map((item: any) => {
                formdata.append(`files`, item[0]);
                formdata.append(`comments`, "programfile");
              });
              formdata.append("program", resp.data.data.id.toString());

              httpClient
                .getInstance()
                .post(`${baseurl}api/resource/file/`, formdata)
            }
          } else {
            message.error(resp.data.data[0]);
            setDisableButton(false)
          }
        })
        .catch(() => {
          setDisableButton(false)
        })
    } else if (basicValues && basicValues?.id) {
      //bank login - modify
      console.log("if else program", body)
      httpClient
        .getInstance()
        .put(`${baseurl}api/program/${basicValues.id}/`, body)
        .then((resp: any) => {
          if (resp.data.status !== ResponseStatus.FAILURE) {
            setDisableButton(false)
            message.success(ErrorMessage.SB);
            localStorage.setItem("program_id", resp.data.data.id);
            localStorage.setItem("program_t_id", resp.data.data.id)
            setBasicDetailsInfo(resp.data.data);
            console.log("getProgramDetails 3")

            getProgramDetails(resp.data.data)
            dispatch(
              program_counterpartydetails(null)
            )
            props.nextpage("2");
            if (fileList.length > 0) {
              const formdata = new FormData();
              fileList.map((item: any) => {
                formdata.append(`files`, item[0]);
                formdata.append(`comments`, "programfile");
              });
              formdata.append("program", resp.data.data.id.toString());

              httpClient
                .getInstance()
                .post(`${baseurl}api/resource/file/`, formdata)
            }
          } else {
            message.error(resp.data.data);
            setDisableButton(false)
          }
        })
        .catch(() => {
          setDisableButton(false)
        })
    }
    else {
      console.log("else program", body, "tt", interestPaidBy, "4", values.interest_deduction, "3", "REPAYMENT", intDeduction, "3", values.interest_deduction)
      httpClient
        .getInstance()
        .post(`${baseurl}api/program/`, body)
        .then((resp: any) => {

          if (resp.data.status !== ResponseStatus.FAILURE) {
            setDisableButton(false)
            message.success(ErrorMessage.SB);
            localStorage.setItem("program_id", resp.data.data.id);
            localStorage.setItem("program_t_id", resp.data.data.id)
            dispatch(
              program_counterpartydetails(null)
            )
            setBasicDetailsInfo(resp.data.data);
            console.log("getProgramDetails 4")

            getProgramDetails(resp.data.data)
            props.nextpage("2");
            if (fileList.length > 0) {
              const formdata = new FormData();
              fileList.map((item: any) => {
                formdata.append(`files`, item[0]);
                formdata.append(`comments`, "programfile");
              });
              formdata.append("program", resp.data.data.id.toString());

              httpClient
                .getInstance()
                .post(`${baseurl}api/resource/file/`, formdata)
            }
          } else {
            message.error(ErrorMessage.PFCD);
            setDisableButton(false)
          }
        }).catch(() => {
          setDisableButton(false)
        })
    }
  };

  const onFinishFailed = () => {
    message.error(ErrorMessage.PFD);
    setDisableButton(false)
  };

  useEffect(() => {
    console.log("effect")
    if (localStorage.getItem("user") === "Bank") {
      httpClient
        .getInstance()
        .get(`${baseurl}api-auth/party/party-accounts/?party_id=${localStorage.getItem("party__id")}`)
        .then((resp: any) => {
          setPartyAccountDetails(resp.data.data)
        })
      console.log("basicValues repay acc basicvalues data", basicValues)
    } else {
      httpClient
        .getInstance()
        .get(`${baseurl}api-auth/party/party-accounts/`)
        .then((resp: any) => {
          setPartyAccountDetails(resp.data.data)
        })
    }


    const disableButtonValue = BasicDetailsInfo ? BasicDetailsInfo.workflowitems ? BasicDetailsInfo.workflowitems.interim_state : "" : "";
    const basedOnStatusValue = disableButtonValue === "AWAITING_APPROVAL" ? true : disableButtonValue === "AWAITING_SIGN_A" ? true : disableButtonValue === "AWAITING_SIGN_B" ? true : false
    setDisableButtonBasedStatus(basedOnStatusValue);
    setRebateBuyer(basicValues?.buyer_rebate)
    setBackedInsurance(basicValues?.insurance_backed)
    setInterestPaidBy(basicValues?.interest_paid_by)
    setRefundEarlyPay(basicValues?.refund_int_early_repay)
    setAutoFinanceInvoice(basicValues?.auto_finance)
    setPartyAccountCurrencyDetails(basicValues?.repayment_account?.currency)


    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        SetCurrencyList(resp.data.data);
      });
    console.log("props props", props)
    console.log("getProgramDetails 1")
    getProgramDetails(props)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)

  }, []);

  const getProgramDetails = async (respData: any) => {

    setIsLoading(true)
    console.log("entered if programprogram respData", respData)
    console.log("respData", respData?.program_type)
    const programType = respData?.programData?.record_datas?.values?.[0]?.fields.program_type || respData?.programData || respData.program_type
    console.log("respData programType programType", programType)
    console.log("entered if else")
    if (localStorage.getItem("user") !== "Bank") {
      if (programType) {

        await httpClient
          .getInstance()
          .get(`${baseurl}api/program/?program_type=${programType}`)
          .then((resp: any) => {
            if (resp.data.status === ResponseStatus.SUCCESS) {
              console.log("5")


              if (resp?.data?.data?.length > 0) {
                dispatch(program_Basicdetails(resp.data.data?.[0]))

                if (resp.data.data) {
                  setUploadBuyer(resp.data.data?.buyer_details?.[0]?.self_invoice_upload)
                  setInterestType(resp.data.data?.[0]?.interest_type)
                  setInterestPaidBy(resp.data.data?.[0]?.interest_paid_by)
                  setRebateBuyer(resp.data.data?.[0]?.buyer_rebate)
                  setBackedInsurance(resp.data.data?.[0]?.insurance_backed)
                  setBasicDetailsInfo(resp.data.data)
                  setAutoFinanceInvoice(resp.data.data?.[0]?.auto_finance)
                  setRefundEarlyPay(resp.data.data?.[0]?.refund_int_early_repay)
                }

                httpClient
                  .getInstance()
                  .get(`${baseurl}api-auth/party/party-accounts/`)
                  .then((resps: any) => {
                    resps.data.data &&
                      resps.data.data.map((item: any) => {
                        if (resp.data.data?.repayment_account?.account_number === item?.account_number) {
                          setPartyAccountId(item.id)
                        }

                      })
                  })

                setTimeout(() => {
                  setIsLoading(false)
                  console.log("enter1")
                }, 5000)


              } else {
                dispatch(program_Basicdetails(null))
                setTimeout(() => {
                  setIsLoading(false)
                  console.log("enter2")

                }, 5000)


              }

            } else {
              message.error(resp.data.status)
              setIsLoading(false)
            }

          })
      }
      setTimeout(() => {
        setIsLoading(false)
        console.log("enter2")

      }, 5000)

    } else {
      console.log("basicvalues details", respData, "basic", basicValues, props)
      const id = props?.programData ? props?.programData?.work_model?.t_id : respData?.id
      await httpClient
        .getInstance()
        .get(`${baseurl}api/program/${id}/`)
        .then((resp: any) => {
          console.log("entered if", resp.data.data)
          if (resp.data.status === ResponseStatus.SUCCESS) {
            console.log("5")

            console.log("programprogram get resp", resp.data.data)
            console.log("3")

            dispatch(program_Basicdetails(resp.data.data))

            if (resp.data.data) {
              setUploadBuyer(resp.data.data?.buyer_details?.[0]?.self_invoice_upload)
              setInterestType(resp.data.data?.interest_type)
              setInterestPaidBy(resp.data.data?.interest_paid_by)
              setRebateBuyer(resp.data.data?.buyer_rebate)
              setBackedInsurance(resp.data.data?.insurance_backed)
              setBasicDetailsInfo(resp.data.data)
              setAutoFinanceInvoice(resp.data.data?.auto_finance)
              setRefundEarlyPay(resp.data.data?.refund_int_early_repay)
              setPartyAccountCurrencyDetails(resp.data.data?.repayment_account?.currency)
            }

            httpClient
              .getInstance()
              .get(`${baseurl}api-auth/party/party-accounts/?party_id=${localStorage.getItem("party__id")}`)
              .then((resps: any) => {
                resps.data.data &&
                  resps.data.data.map((item: any) => {
                    if (resp.data.data?.repayment_account?.account_number === item?.account_number) {
                      setPartyAccountId(item.id)
                    }

                  })
              })

            setTimeout(() => {
              setIsLoading(false)
            }, 5000)
          }

          setTimeout(() => {
            setIsLoading(false)
            console.log("enter2")

          }, 5000)
        })

    }
  }
  
  const limitCurrencyData = (e: any) => {
    console.log("err0r e", e)
    console.log("err0r e", e?.target?.value)
    // if (e?.target?.value === "" || e?.target?.value === null) {
    //   setCurrencyError(true)

    // } else {
    //   setCurrencyError(false)
    // }

    // console.log("err0r e", e)
  }
  const maxInvCurrencyData = (e: any) => {
    setMaxInvoiceCurrency(e)
  }
  const intTypeData = (e: any) => {
    setInterestType(e)
  }
  const intPaidByData = (e: any) => {
    setInterestPaidBy(e)
  }
  const onClickInterestRateType = (e: any) => {
    setInterestRateType(e)
  }
  const onClickInterestDeduction = (e: any) => {
    setIntDeduction(e)

  }

  const disabledDate = (current: any) => {
    return current && current < moment().endOf("day");
  };
  const selectedFiles = (value: any) => {
    setFileList(value)
  }
  const exceptThisSymbols = ["e", "E", "+", "-"];

  return isLoading ? <Spin /> : (

    <div className="col_Desc approvedPayableFinacing">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateTrigger="onBlur"
        layout="vertical"
      >
        <Button className="actionButtonSaveContainer" htmlType="submit" disabled={disableButton} loading={disableButton} id="program_save" style={{ position: "fixed", zIndex: 99999 }}>
          Save</Button>
        <Button className="nextButtonContainer" htmlType="submit"
          disabled={disableButton}
          loading={disableButton} id="program_next">Next</Button>

        <Row gutter={24}>
          <Col span={16}>
            <Row>
              <Col span={12}>
                <div className="labelContainer">Limit Amount<span className="mandatoryField">*</span></div>
                <Row>
                  <Col span={7}>
                    <Form.Item
                      label=""
                      name="limit_currency"
                      className="FormContainer"
                      key="limit_currency"
                      initialValue={basicValues?.limit_currency}
                      rules={[
                        {
                          required: true,
                          message: FieldErrorMessages.LIMITCURR,
                        },
                      ]}
                    >
                      <Select
                        id={"program_limit_currency"}
                        showSearch={true}
                        placeholder="Select"
                        optionFilterProp="children"
                        defaultValue={basicValues?.limit_currency}
                        onChange={limitCurrencyData}
                        style={{ width: "100" }}
                        disabled={disableButtonBasedStatus}
                        suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                      >
                        {Currency_Data.payload.currencyData.allCurrency?.map((item: any) => {
                          return (
                            <Option value={item.id} key={item.description}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>


                    </Form.Item>
                  </Col>
                  <Col span={17} style={{ padding: "0 5px" }}>
                    <Form.Item
                      label=""
                      name="limit_amount"
                      className="FormContainer"
                      id="limitAmount"
                      key="limit_amount"
                      initialValue={basicValues ? basicValues?.max_limit_amount : ""}
                      rules={[
                        {
                          pattern: InputPatterns.INVOICEAMT,
                          required: true,
                          message: FieldErrorMessages.lIMITAMT,
                        },
                      ]}
                    >
                      <InputNumber
                        id="program_limit_amount"
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        formatter={(value, e) => {
                          console.log("e limit", e)
                          if (value === "" || isNaN(parseFloat(value))) {
                            return "";
                          }
                          return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                            : value
                        }}
                        min={0}
                        key="limitAmount"
                        onChange={(e) => {
                          console.log("event", e)
                          setLimitAmount(e)
                          localStorage.setItem("limitamt", e)

                          if (parseFloat(e) < parseFloat(maxInvoiceAmtValue)) {
                            setMaxInvoiceAmtError(true)
                          } else {
                            setMaxInvoiceAmtError(false)
                          }
                        }}
                        defaultValue={basicValues?.max_limit_amount}
                        placeholder="Enter your limit"
                        disabled={disableButtonBasedStatus}
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      />
                    </Form.Item>
                    {console.log("limit", limitAmount)}
                  </Col>
                </Row>
              </Col>
              <Col span={12} >
                <Form.Item name="expiry_date"
                  label={<>Expiry Date <span className="mandatoryField"> *</span> </>}

                  className="FormContainer"
                  rules={[
                    {
                      required: basicValues ? basicValues?.expiry_date ? false : true : true,
                      message: FieldErrorMessages.EXPIRYDTE,
                    },
                  ]}
                >
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    id="program_datePicker"
                    disabledDate={disabledDate}
                    suffixIcon={<img src={DatePickerImg} alt="pickericon" />}
                    placeholder={basicValues ? moment(basicValues?.expiry_date).format("DD-MM-YYYY") : "DD-MM-YYYY"}
                    className={basicValues?.expiry_date ? "valueExpiryDate" : ""}
                    style={{
                      lineHeight: "2.5",
                      borderRadius: "8px",
                      fontSize: "13px",
                      padding: "5px 15px",
                      width: "100%",
                      height: "40px",
                    }}
                    disabled={disableButtonBasedStatus}
                  />

                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <div className="labelContainer">Max Invoice Amount<span className="mandatoryField">*</span></div>
                <Row >
                  <Col span={7}>
                    <Form.Item
                      label=""
                      name="max_invoice_currency"
                      className="FormContainer"
                      key="max_invoice_currency"
                      initialValue={
                        basicValues
                          ? basicValues?.max_invoice_currency
                          : maxInvoiceCurrency ? maxInvoiceCurrency : ""
                      }
                      rules={[
                        {
                          required: false,
                          message: FieldErrorMessages.INVCURR,
                        },
                      ]}
                    >
                      {console.log("")}
                      <Select
                        id={"program_invoice_currency"}
                        showSearch={true}
                        placeholder="Select"
                        optionFilterProp="children"
                        defaultValue={basicValues?.max_invoice_currency}
                        onChange={maxInvCurrencyData}
                        style={{ width: "" }}
                        disabled={disableButtonBasedStatus}
                        suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                      >
                        {Currency_Data.payload.currencyData.allCurrency?.map((item: any) => {
                          return (
                            <Option value={item.id} key={item.description}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={17} style={{ padding: "0 5px" }}>
                    <Form.Item
                      label=""
                      name="max_invoice_amount"
                      className="FormContainer"
                      id="MaxInvcAmount"
                      key={
                        basicValues ? basicValues?.max_invoice_amount : ""
                      }
                      initialValue={
                        basicValues ? basicValues?.max_invoice_amount : ""
                      }
                      rules={[
                        {
                          pattern: InputPatterns.INVOICEAMT,
                          required: maxInvoiceAmtError ? false : true,
                          message: FieldErrorMessages.INVAMT,
                        },
                      ]}
                    >
                      <InputNumber
                        id="program_invoice_amount"
                        placeholder="Enter your max invoice amount"
                        onChange={(e) => {
                          setMaxInvoiceAmtValue(e)
                          if (parseFloat(e) > parseFloat(limitAmount)) {
                            setMaxInvoiceAmtError(true)
                          } else {
                            setMaxInvoiceAmtError(false)
                          }
                        }}
                        formatter={(value, data) => {
                          if (value === "" || isNaN(parseFloat(value))) {
                            return "";
                          }
                          if (parseFloat(value) > parseFloat(limitAmount)) {
                            setMaxInvoiceAmtError(true)
                          } else {
                            setMaxInvoiceAmtError(false)
                          }
                          return data?.userTyping === false ? Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                            : value
                        }}

                        defaultValue={maxInvoiceAmtValue}
                        disabled={disableButtonBasedStatus}
                        onFocus={(e: any) => {
                          console.log("max", maxInvoiceCurrency)

                          e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })
                        }}
                      />
                    </Form.Item>
                    <span className="errorMessage" style={maxInvoiceAmtError ? { display: "flex" } : { display: "none" }}>{FieldErrorMessages.INVAMTE}</span>

                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={24} className="maxInvoicePCTColumn colContainer">
                  <Col span={12} style={{ padding: "0 10px" }}>
                    <Form.Item
                      label={<>Max Inv. PCT<span className="mandatoryField">*</span></>}
                      name="max_invoice_percent"
                      className="FormContainer"
                      rules={[
                        {
                          required: true,
                          message: FieldErrorMessages.INVPCT,
                        },
                      ]}
                      key={
                        basicValues
                          ? basicValues?.max_invoice_percent
                          : ""
                      }
                      initialValue={
                        basicValues
                          ? basicValues?.max_invoice_percent
                          : ""
                      }
                    >

                      <Input
                        id="program_invoice_pct"
                        placeholder="Enter your max invoice pct"
                        type="number"
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        suffix="%"
                        onChange={(e) => {
                          console.log("success e.target.value", e.target.value)
                          setMaxInvoicePctValue(e.target.value)
                          const pattern = InputPatterns.INVOICEPCT
                          const isSuccess = pattern.test(e.target.value)
                          console.log("success e.target.value", e.target.value)
                          console.log("success", isSuccess)
                          if (isSuccess) {
                            setMaxInvoicePctError(false)
                          } else {
                            setMaxInvoicePctError(true)
                          }
                        }}
                        disabled={disableButtonBasedStatus}
                        value={maxInvoicePctValue}
                      />

                    </Form.Item>
                    <span className="errorMessage" style={maxInvoicePctError ? { display: "flex" } : { display: "none" }}>{FieldErrorMessages.INVPCTE}</span>

                  </Col>
                  <Col span={12} style={{ padding: "0 10px" }}>
                    <Form.Item
                      label={<>Max Tenor<span className="mandatoryField">*</span></>}
                      name="max_tenor"
                      className="FormContainer"
                      rules={[
                        {
                          pattern: InputPatterns.MAXTENOR,
                          required: true,
                          message: FieldErrorMessages.MAXTENOR,
                        },
                      ]}
                      key={
                        basicValues
                          ? basicValues?.max_tenor
                          : ""
                      }
                      initialValue={
                        basicValues
                          ? basicValues?.max_tenor
                          : ""
                      }
                    >
                      <Input
                        id="program_max_tenor"
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        placeholder="Enter your max tenor"
                        type="number"
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        onChange={(e) => (
                          setMaxTenorAmount(e.target.value)

                        )}
                        disabled={disableButtonBasedStatus}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={<>Grace Period<span className="mandatoryField">*</span></>}
                  name="grace_period"
                  className="FormContainer"
                  rules={[
                    {
                      required: true,
                      message: FieldErrorMessages.GRACEPERIOD,
                    },
                  ]}
                  key={basicValues ? basicValues?.grace_period : ""}
                  initialValue={
                    basicValues ? basicValues?.grace_period : ""
                  }
                >
                  <Input
                    id="program_grace_period"
                    type="number"
                    placeholder="Enter your grace period"
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    onChange={(e) => {
                      setGracePeriodValue(e.target.value)
                      const pattern = InputPatterns.GRACEPERIOD
                      const isSuccess = pattern.test(e.target.value)
                      if (isSuccess) {
                        setgracePeriodError(false)
                      } else {
                        setgracePeriodError(true)
                      }
                      if (parseFloat(e.target.value) > parseFloat(maxTenorAmount)) {
                        setgracePeriodError(true)

                      } else {
                        setgracePeriodError(false)
                      }
                    }}
                    defaultValue={gracePeriodValue}
                    disabled={disableButtonBasedStatus}
                  />

                </Form.Item>
                <span className="errorMessage" style={gracePeriodError ? { display: "flex" } : { display: "none" }}>{FieldErrorMessages.GRACEPERIODE}</span>

              </Col>
              <Col span={12}>
                <Form.Item
                  label={<>Interest Type<span className="mandatoryField">*</span></>}
                  name="interest_type"
                  className="FormContainer"
                  rules={[
                    {
                      required: false,
                      message: FieldErrorMessages.INTTYPE,
                    },
                  ]}
                  key={basicValues ? basicValues?.interest_type : ""}
                  initialValue={
                    basicValues ? basicValues?.interest_type : interestType ? interestType : ""
                  }
                >
                  {console.log("")}
                  <Select
                    id={"program_interest_type"}
                    showSearch={false}
                    placeholder="Select"
                    optionFilterProp="children"
                    defaultValue={basicValues?.interest_type}
                    onChange={intTypeData}
                    style={{ width: "" }}
                    disabled={disableButtonBasedStatus}
                    suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                  >
                    {InterestType_Data.payload.interestType.allInterestType?.map((item: any) => {
                      return (
                        <Option value={item.id} key={item.description}>
                          {item.description}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={(interestType === "FLOATING" || (interestType === "Floating") || interestType === 2) ? <>Interest Rate Type<span className="mandatoryField">*</span></> : "Interest Rate Type"}
                  name="interest_rate_type"
                  className="FormContainer"
                  rules={[
                    {
                      required: interestType === "FIXED" || interestType === "Fixed" || interestType === 1 ? false : ((interestType === "FLOATING" || (interestType === "Floating") || interestType === 2) && interestRateType !== null) ? false : false,
                      message: FieldErrorMessages.INTRATETYPE,
                    },
                  ]}
                  key={
                    basicValues
                      ? (basicValues?.interest_type === "FIXED" || basicValues?.interest_type === "Fixed")
                        ? ""
                        : basicValues?.interest_rate_type
                      : ""
                  }
                  initialValue={
                    interestType === "Fixed" || interestType === "FIXED" || interestType === 1 ? "" :
                      basicValues
                        ? (basicValues?.interest_type === "FIXED" || basicValues?.interest_type === "Fixed")
                          ? null
                          : basicValues?.interest_rate_type
                        : interestRateType ? interestRateType : ""
                  }
                >
                  {console.log("")}
                  {interestType === "FIXED" || interestType === "Fixed" || interestType === 1
                    ? (
                      <div className="inputDiv"
                      ></div>
                    ) : (
                      <Select
                        id={"program_interest_rate_type"}
                        showSearch={false}
                        placeholder="Select"
                        optionFilterProp="children"
                        defaultValue={BasicDetailsInfo
                          ? (basicValues?.interest_type === "FIXED" || basicValues?.interest_type === "Fixed" || basicValues?.interest_type === 1)
                            ? ""
                            : basicValues?.interest_rate_type
                          : ""}
                        onChange={onClickInterestRateType}
                        style={{ width: "" }}
                        disabled={disableButtonBasedStatus ? disableButtonBasedStatus : interestType === "FIXED" ? true : false}
                        suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                      >
                        {InterestRatetype_Data.payload.interestRateType.allInterestRateType?.map((item: any) => {
                          return (
                            <Option value={item.id} key={item.description}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<>Fixed Rate/ Margin<span className="mandatoryField">*</span></>}
                  name="margin"
                  className="FormContainer"
                  rules={[
                    {
                      pattern: InputPatterns.MARGIN,
                      required: true,
                      message: FieldErrorMessages.MARGIN,
                    },
                  ]}
                  key={basicValues ? basicValues?.margin : ""}
                  initialValue={basicValues ? basicValues?.margin : ""}
                >
                  <Input
                    id="program_margin"
                    type="number"
                    placeholder="Please input your fixed rate/margin!"
                    disabled={disableButtonBasedStatus}
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                  />
                </Form.Item>
              </Col>
            </Row>
            {console.log("programprogram props", props, basicValues)}
            {basicValues?.program_type === ProgramType.APF || props?.programData === ProgramType.APF || props?.programData?.program === ProgramType.APF || props?.programData?.program_type === ProgramType.APF || props?.programData?.record_datas?.values?.[0]?.fields?.program_type === ProgramType.APF ?
              <>
                {console.log("programprogram props1", props)}
                {console.log("programprogram props1", basicValues?.program_type === ProgramType.APF, "1", props?.programData === ProgramType.APF, "2", props?.programData?.program === ProgramType.APF, "3", props?.programData?.program_type === ProgramType.APF, "4", props?.programData?.record_datas?.values?.[0]?.fields?.program_type)}
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label={<>Interest Paid By<span className="mandatoryField">*</span></>}
                      name="interest_paid_by"
                      className="FormContainer"
                      key={basicValues ? basicValues?.interest_paid_by : ""}
                      initialValue={basicValues ? basicValues?.interest_paid_by : interestPaidBy ? interestPaidBy : ""}
                      rules={[
                        {
                          required: interestPaidBy ? false : true,
                          message: FieldErrorMessages.INTPAIDBY,
                        },
                      ]}
                    >
                      {console.log("")}
                      <Select
                        id={"program_interest_paid_by"}
                        showSearch={false}
                        placeholder="Select"
                        optionFilterProp="children"
                        defaultValue={basicValues?.interest_paid_by}
                        onChange={intPaidByData}
                        style={{ width: "" }}
                        disabled={disableButtonBasedStatus}
                        suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                      >
                        {InterestPaidByData?.map((item: any) => {
                          return (
                            <Option value={item.id} key={item.description}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Overdue Interest Rate"
                      name="overdue_interest_rate"
                      className="FormContainer"
                      rules={[
                        {
                          pattern: InputPatterns.OVERDUEINTRATE,
                          required: false,
                          message: FieldErrorMessages.OVERDUEINT,
                        },
                      ]}
                      key={basicValues ? basicValues?.overdue_interest_rate : ""}
                      initialValue={basicValues ? basicValues?.overdue_interest_rate : ""}
                    >

                      <Input
                        id="program_overdue_interest_rate"
                        type="number"
                        defaultValue={basicValues ? basicValues?.overdue_interest_rate : ""}
                        placeholder="Please input your overdue interest rate!"
                        disabled={disableButtonBasedStatus}
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      />

                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      label={<>Interest Deduction Stage<span className="mandatoryField">*</span></>}
                      name="interest_deduction"
                      className="FormContainer"
                      rules={[
                        {
                          required: interestPaidBy === InterestPaidBy.OWNPARTY || intDeduction === InterestPaidBy.OWNPARTY ? false : intDeduction !== "null" ? false : false,
                          message: FieldErrorMessages.INTDEDUCTION,
                        },
                      ]}
                      key={basicValues ? basicValues?.interest_deduction : ""}
                      initialValue={basicValues ? basicValues?.interest_paid_by === InterestPaidBy.OWNPARTY ? "REPAYMENT" : basicValues?.interest_deduction : ""}

                    >
                      {console.log("")}
                      {
                        interestPaidBy === InterestPaidBy.OWNPARTY || intDeduction === InterestPaidBy.OWNPARTY ?
                          <div className="inputDiv" id={"program_interest_deduction_stage"}>Repayment</div>
                          :
                          <Select
                            id={"program_interest_deduction_stage"}
                            showSearch={false}
                            placeholder="Select"
                            optionFilterProp="children"
                            defaultValue={basicValues?.interest_deduction}
                            onChange={onClickInterestDeduction}
                            style={{ width: "" }}
                            disabled={disableButtonBasedStatus}
                            suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                          >
                            {InterestDeduction?.map((item: any) => {
                              return (
                                <Option value={item.id} key={item.description}>
                                  {item.description}
                                </Option>
                              );
                            })}
                          </Select>
                      }

                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <div className="labelContainer">Repayment Account<span className="mandatoryField">*</span></div>

                    <Row>
                      <Col span={7}>
                        <Form.Item
                          label=""
                          name="repayment_account_currency"
                          className="FormContainer"
                          style={{ backgroundColor: "#FAFAFA", height: "43px" }}
                          key={
                            basicValues ? basicValues?.repayment_account?.currency : ""
                          }
                          initialValue={
                            basicValues ? basicValues?.repayment_account?.currency : ""
                          }
                        >
                          <div style={{
                            height: "45px", padding: "5px 15px", lineHeight: "2.5",
                            borderRadius: "8px",
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            border: "1px solid #d9d9d9",
                            backgroundColor: "#FFFFFF"
                          }} id="repayment_currency"
                          >
                            {currencyList &&
                              currencyList.map((item: any) => {
                                console.log("enetered if PartyAccountCurrencyDetails", PartyAccountCurrencyDetails)
                                if (PartyAccountCurrencyDetails !== undefined && PartyAccountCurrencyDetails === item.id) {
                                  console.log(`enetered if`, item.description)
                                  return (
                                    <div>{item.description}</div>

                                  )
                                } else if (basicValues?.repayment_account?.currency === item.id) {
                                  return (
                                    <div>{item.description}</div>

                                  )
                                }
                              }
                              )}
                          </div>

                        </Form.Item>
                      </Col>
                      <Col span={17}
                        style={{ padding: "0 5px" }}
                      >
                        <Form.Item
                          label=""
                          name="repayment_account"
                          className="FormContainer"
                          id="repayment_account"
                          key={
                            basicValues
                              ? basicValues?.repayment_account?.account_number
                              : ""
                          }
                          initialValue={
                            basicValues
                              ? basicValues?.repayment_account?.account_number
                              : ""
                          }
                          rules={[
                            {
                              required: false,
                              message: FieldErrorMessages.REPAYMENTACC,
                            },
                          ]}
                        >
                          <Select
                            id="repayment_account"
                            showSearch
                            placeholder="Select"
                            optionFilterProp="children"
                            style={{
                              width: "",
                            }}
                            disabled={disableButtonBasedStatus}
                            suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                            defaultValue={
                              basicValues
                                ? basicValues?.repayment_account?.account_number : ""}

                            onChange={
                              (e) => {

                                PartyAccountDetails &&
                                  PartyAccountDetails.map((item: any) => {
                                    if (e === item.account_number) {
                                      setPartyAccountId(item.id)
                                      setPartyAccountCurrencyDetails(item.currency)
                                    }

                                  })
                              }
                            }

                          >
                            {console.log("")}
                            {PartyAccountDetails &&
                              PartyAccountDetails.map((item: any,index:number) => {
                                return (
                                  <Option value={item.account_number} key={item.account_number} id={`repaymentaccountValue${index}`}>
                                    <div style={{ display: "flex" }}>
                                      <span style={{ paddingRight: "10px" }}>
                                        {currencyList &&
                                          currencyList.map((items: any) => {
                                            if (item.currency === items.id) {
                                              console.log("entered else if 2 repay acc")
                                              return (
                                                `${items.description}`
                                              )
                                            }
                                          })}</span>  {" "}
                                      {item.account_number}
                                    </div>
                                  </Option>
                                );
                              })}
                          </Select>

                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label=" "
                      name="upload_by_buyer"
                      className="FormContainer"
                      rules={[
                        {
                          required: false,
                          message: FieldErrorMessages.UPLOADBUYER,
                        },
                      ]}
                      initialValue={
                        basicValues ?
                          basicValues?.buyer_details?.[0]?.self_invoice_upload
                          : uploadBuyer
                      }
                    >
                      <div className="checkboxClass">Allow Invoice Upload By Buyer <CheckboxField id="program_upload_by_buyer" onchange={(e: any) => setUploadBuyer(e)} defaultChecked={
                        basicValues ?
                          basicValues?.buyer_details?.[0]?.self_invoice_upload
                          : uploadBuyer
                      } value={basicValues ?
                        basicValues?.buyer_details?.[0]?.self_invoice_upload
                        : uploadBuyer} />

                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label=" "
                      name="auto_debit_invoice"
                      className="FormContainer"
                      rules={[
                        {
                          required: false,
                          message: FieldErrorMessages.AUTODEBITINV,
                        },
                      ]}
                      initialValue={basicValues ? basicValues?.auto_debit_invoice : false}
                    >
                      <div className="checkboxClass">Auto Debit for Invoices not financed
                        <CheckboxField id="program_autoDebit_for_invoices" onchange={(e: any) => setAutoFinanceDebit(e)} defaultChecked={basicValues ? basicValues?.auto_debit_invoice : autoFinanceDebit} value={basicValues?.auto_debit_invoice ? basicValues?.auto_debit_invoice : autoFinanceDebit} />

                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                {interestPaidBy === InterestPaidBy.OWNPARTY || intDeduction === InterestPaidBy.OWNPARTY ?
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label=" "
                        name="insurance_backed"
                        className="FormContainer"
                        rules={[
                          {
                            required: false,
                            message: FieldErrorMessages.INSBACKED,
                          },
                        ]}
                        key={basicValues ? basicValues?.insurance_backed : ""}
                        initialValue={basicValues ? basicValues?.insurance_backed : ""}
                      >
                        <div className="checkboxClass">Backed by Insurance
                          <CheckboxField
                            id="program_backed_by_insurance"
                            onchange={(e: any) => setBackedInsurance(e)}
                            defaultChecked={basicValues ? basicValues?.insurance_backed : backedInsurance}
                            value={basicValues ? basicValues?.insurance_backed : backedInsurance}
                          />

                        </div>
                      </Form.Item>
                    </Col>
                    {console.log("basicValues fee", basicValues)}
                    <Col span={12}>
                      <Form.Item
                        label={basicValues?.insurance_backed === true || backedInsurance === true ? <>Fee<span className="mandatoryField">*</span></> : "Fee"}
                        name="fee"
                        className="FormContainer"
                        rules={[
                          {
                            required: basicValues?.insurance_backed === true || backedInsurance === true ? true : false,
                            message: FieldErrorMessages.FEE,
                          },
                        ]}
                        key={basicValues ? basicValues?.fee : ""}
                        initialValue={basicValues ? backedInsurance === false ? "" : basicValues?.fee : ""}
                      >
                        {console.log("backed ", backedInsurance === true ? false : backedInsurance === false ? true : backedInsurance === undefined ? true
                          : disableButtonBasedStatus)}
                        <Input
                          id="program_fee"
                          placeholder="Please input your Fee!"
                          disabled={backedInsurance === true ? false : backedInsurance === false ? true : backedInsurance === undefined ? true
                            : disableButtonBasedStatus}
                          defaultValue={basicValues ? backedInsurance === false ? "" : basicValues?.fee : ""}

                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  :
                  <>
                    <Row>

                      <Col span={12}>
                        <Form.Item
                          label=" "
                          name="buyer_rebate"
                          className="FormContainer"
                          rules={[
                            {
                              required: false,
                              message: FieldErrorMessages.BUYERREBATE,
                            },
                          ]}
                          initialValue={rebateBuyer}
                        > <div className="checkboxClass">Allow Rebate for Buyer
                            <CheckboxField id="program_rebate_for_buyer" onchange={(e: any) =>
                              setRebateBuyer(e)
                            } defaultChecked={rebateBuyer} value={rebateBuyer} />

                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label=" "
                          name="insurance_backed"
                          className="FormContainer"
                          rules={[
                            {
                              required: false,
                              message: FieldErrorMessages.INSBACKED,
                            },
                          ]}
                          key={basicValues ? basicValues?.insurance_backed : ""}
                          initialValue={basicValues ? basicValues?.insurance_backed : ""}
                        >
                          <div className="checkboxClass">Backed by Insurance
                            <CheckboxField
                              id="program_backed_by_insurance"
                              onchange={(e: any) => setBackedInsurance(e)}
                              defaultChecked={basicValues ? basicValues?.insurance_backed : backedInsurance}
                              value={basicValues ? basicValues?.insurance_backed : backedInsurance}
                            />

                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        {rebateBuyer ? <Form.Item
                          label={<>Buyer Rebate PCT<span className="mandatoryField">*</span></>}
                          name="buyer_rebate_percent"
                          className="FormContainer"
                          rules={ [
                             {
                              required: rebateBuyer,
                              message:  FieldErrorMessages.BUYERREBATEPCT,
                            }, 
                            {
                              pattern: InputPatterns.INVOICEPCT,
                              message: 'Percent must be between 0 - 100'
                            }
                          ]} 
                          key={basicValues ? basicValues?.buyer_rebate_percent : ""}
                          initialValue={rebateBuyer ? rebateBuyer === false ? "" : basicValues?.buyer_rebate_percent : 0}
                        >
                          <Input
                            id="program_rebate_percent"
                            type="number"
                            suffix="%"
                            placeholder="Please input your Buyer Rebate PCT!"
                            disabled={rebateBuyer ? false : rebateBuyer === false ? true
                              : disableButtonBasedStatus}
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                          />
                        </Form.Item> : <Form.Item
                          label={"Buyer Rebate PCT"}
                          name="buyer_rebate_percent_disabled"
                          className="FormContainer" 
                          key={basicValues ? basicValues?.buyer_rebate_percent : ""}
                          initialValue={rebateBuyer ? rebateBuyer === false ? "" : basicValues?.buyer_rebate_percent : 0}
                        >
                          <Input
                            id="program_rebate_percent_disabled"
                            type="number"
                            suffix="%"
                            placeholder="Please input your Buyer Rebate PCT!"
                            disabled={ true || disableButtonBasedStatus}
                            // onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                          />
                        </Form.Item>
                        }
        
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={basicValues?.insurance_backed === true || backedInsurance === true ? <>Fee<span className="mandatoryField">*</span></> : "Fee"}
                          name="fee"
                          className="FormContainer"
                          rules={[
                            {
                              required: basicValues?.insurance_backed === true || backedInsurance === true ? true : false,
                              message: FieldErrorMessages.FEE,
                            },
                          ]}
                          key={basicValues ? basicValues?.fee : ""}
                          initialValue={basicValues ? backedInsurance === false ? "" : basicValues?.fee : ""}
                        >
                          <Input
                            id="program_fee"
                            placeholder="Please input your Fee!"
                            disabled={backedInsurance === true ? false : backedInsurance === false ? true : backedInsurance === undefined ? true
                              : disableButtonBasedStatus}
                            defaultValue={basicValues ? backedInsurance === false ? "" : basicValues?.fee : ""}

                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                }
              </>

              :
              <>
                <Row>
                  <Col span={12}>
                    <div className="labelContainer">Repayment Account<span className="mandatoryField">*</span></div>

                    <Row>
                      <Col span={7}>
                        <Form.Item
                          label=""
                          name="repayment_account_currency"
                          className="FormContainer"
                          style={{ backgroundColor: "#FAFAFA" }}
                          key={
                            basicValues ? basicValues?.repayment_account?.currency : ""
                          }
                          initialValue={
                            basicValues ? basicValues?.repayment_account?.currency : ""
                          }
                        >
                          <div style={{
                            height: "45px", padding: "5px 15px", lineHeight: "2.5",
                            borderRadius: "8px",
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            border: "1px solid #d9d9d9",
                            backgroundColor: "#FFFFFF"
                          }} id="repayment_currency">
                            {currencyList &&
                              currencyList.map((item: any) => {
                                if (PartyAccountCurrencyDetails !== undefined && PartyAccountCurrencyDetails === item.id) {
                                  return (
                                    <div>{item.description}</div>

                                  )
                                }
                              })}
                          </div>

                        </Form.Item>
                      </Col>
                      <Col span={17}
                        style={{ padding: "0 5px" }}
                        className="repaymentAccountContainer"
                      >
                        <Form.Item
                          label=""
                          name="repayment_account"
                          className="FormContainer"
                          id="repayment_account"
                          key={
                            basicValues
                              ? basicValues?.repayment_account?.account_number
                              : ""
                          }
                          initialValue={
                            basicValues
                              ? basicValues?.repayment_account?.account_number
                              : ""
                          }
                          rules={[
                            {
                              required: false,
                              message: FieldErrorMessages.REPAYMENTACC,
                            },
                          ]}
                        >
                          {console.log("")}
                          <Select
                            id="repayment_account"
                            showSearch
                            placeholder="Select"
                            optionFilterProp="children"
                            style={{ width: "100" }}
                            disabled={disableButtonBasedStatus}
                            suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                            defaultValue={
                              basicValues
                                ? basicValues?.repayment_account?.account_number : ""}
                            onChange={
                              (e) => {
                                PartyAccountDetails &&
                                  PartyAccountDetails.map((item: any) => {
                                    if (e === item.account_number) {
                                      setPartyAccountId(item.id)
                                      setPartyAccountCurrencyDetails(item.currency)
                                    }

                                  })
                              }
                            }

                          >
                            {console.log("")}
                            {PartyAccountDetails &&
                              PartyAccountDetails.map((item: any, index: number) => {
                                { console.log("item", item) }

                                return (
                                  <Option value={item.account_number} key={item.account_number} id={`repaymentaccountValue${index}`}>
                                    <div style={{ display: "flex" }}>
                                      <span style={{ paddingRight: "10px" }} key={index}>
                                        {currencyList &&
                                          currencyList.map((items: any) => {
                                            if (item.currency === items.id) {
                                              return (
                                                `${items.description}`
                                              )
                                            }
                                          })}</span>  {" "}
                                      {item.account_number}
                                    </div>
                                  </Option>
                                );
                              })}
                          </Select>

                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Overdue Interest Rate"
                      name="overdue_interest_rate"
                      className="FormContainer"
                      rules={[
                        {
                          pattern: InputPatterns.OVERDUEINTRATE,
                          required: false,
                          message: "Please input your Overdue Interest Rate!",
                        },
                      ]}
                      key={basicValues ? basicValues?.overdue_interest_rate : ""}
                      initialValue={basicValues ? basicValues?.overdue_interest_rate : ""}

                    >
                      <Input
                        id="program_overdue_interest_rate"
                        type="number"
                        placeholder="Please input your overdue interest rate!"
                        disabled={disableButtonBasedStatus}
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Interest Deduction Stage"
                      name="interest_deduction"
                      className="FormContainer"
                      rules={[
                        {
                          required: false,
                          message: FieldErrorMessages.INTDEDUCTION,
                        },
                      ]}
                      key={basicValues ? basicValues?.interest_deduction : ""}
                      initialValue={basicValues ? basicValues?.interest_deduction : ""}

                    >
                      {console.log("")}
                      <Select
                        id={"program_interest_deduction_stage"}
                        showSearch={false}
                        placeholder="Select"
                        optionFilterProp="children"
                        defaultValue={basicValues?.interest_deduction}
                        onChange={onClickInterestDeduction}
                        style={{ width: "" }}
                        disabled={disableButtonBasedStatus}
                        suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                      >
                        {InterestDeduction?.map((item: any) => {
                          return (
                            <Option value={item.id} key={item.description}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label=" "
                      name="auto_finance"
                      className="FormContainer"
                      rules={[
                        {
                          required: false,
                          message: FieldErrorMessages.AUTOFINANCEINV,
                        },
                      ]}
                      key={"auto_finance"}
                      initialValue={basicValues ? basicValues?.auto_finance : ""}
                    >
                      {console.log("basicValues", basicValues)}
                      <div className="checkboxClass">Auto Finance Invoices
                        <CheckboxField
                          id="program_autoDebit_for_invoices"
                          onchange={(e: any) => setAutoFinanceInvoice(e)}
                          defaultChecked={basicValues ? basicValues?.auto_finance : autoFinanceInvoice}
                          value={basicValues ? basicValues?.auto_finance : autoFinanceInvoice}
                        />

                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label=" "
                      name="insurance_backed"
                      className="FormContainer"
                      rules={[
                        {
                          required: false,
                          message: FieldErrorMessages.INSBACKED,
                        },
                      ]}
                      key={"backedByInsurance"}
                      initialValue={basicValues ? basicValues?.insurance_backed : backedInsurance}

                    >
                      <div className="checkboxClass">Backed by Insurance  <CheckboxField
                        id="program_backed_by_insurance"
                        onchange={(e: any) => setBackedInsurance(e)}
                        defaultChecked={basicValues ? basicValues?.insurance_backed : backedInsurance}
                        value={basicValues ? basicValues?.insurance_backed : backedInsurance}
                      />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label=" "
                      name="refund_int_early_repay"
                      className="FormContainer"
                      rules={[
                        {
                          required: false,
                          message: FieldErrorMessages.REFUNDINT
                        },
                      ]}
                      key={"refund_int_early_repay"}
                      initialValue={basicValues ? basicValues?.refund_int_early_repay : refundEarlyPay}

                    >
                      <div id="program_refund_int_earlyPay" className="checkboxClass" >Refund Interest during Early Repayment <CheckboxField
                        onchange={(e: any) => setRefundEarlyPay(e)}
                        defaultChecked={basicValues ? basicValues?.refund_int_early_repay : refundEarlyPay}
                        value={basicValues ? basicValues?.refund_int_early_repay : refundEarlyPay}
                      />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label={backedInsurance === true
                        ? <>Fee<span className="mandatoryField">*</span></> : "Fee"}

                      name="fee"
                      className="FormContainer"
                      rules={[
                        {
                          required: backedInsurance === true
                            ? true : false,
                          message: FieldErrorMessages.FEE,
                        },
                      ]}
                      key={basicValues ? basicValues?.fee : ""}
                      initialValue={basicValues ? backedInsurance === false ? "" : basicValues?.fee : ""}
                    >
                      <Input
                        id="program_fee"
                        placeholder="Please input your Fee!"
                        disabled={backedInsurance === true ? false : backedInsurance === false ? true : backedInsurance === undefined ? true
                          : disableButtonBasedStatus}
                        defaultValue={basicValues ? backedInsurance === false ? "" : basicValues?.fee : ""}

                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            }

            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Comment Section"
                  name="comments"
                  className="commentField FormContainer"
                  rules={[
                    {
                      required: false,
                      message: FieldErrorMessages.COMMENT,
                    },
                  ]}
                  key={basicValues ? basicValues.comments : ""}
                  initialValue={
                    basicValues ? basicValues.comments : ""
                  }
                >
                  <TextArea
                    id="program_comments"
                    placeholder="Please input your comment!"
                    disabled={disableButtonBasedStatus}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Form.Item
              label={"Upload Document / Attached file "}
              className="FormContainer"
            >
              <div className="UploadCardContainer" id="program_upload_image">
                <UploadImage selectedFiles={selectedFiles}
                  attachmentFiles={
                    basicValues ? basicValues?.attachments ? basicValues?.attachments?.file : [] :
                      []
                  }
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>

  );
};
export default BasicDetailsForm;
