import { useState, useEffect } from "react";
import { Input, Select, DatePicker, Checkbox, InputNumber,Row,Col } from "antd";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import moment from "moment";
import { UploadImage } from "../../../common/UploadImage/manualInvoiceUpload";
import images from "../../../../assets/images";
import { useNavigate } from "react-router";
import { ResponseStatus } from "../../../../utils/enum/choices";

const Header_RF_Seller = [{
  Name: "Buyer Name", InvNo: "Inv No", InvDate: "Inv Date", InvAmt: "Inv Amount", DueDate: "Due Date", FinanceAmount: "Finance Amount", RepaymentCurrency: "repaymentCurrency", RepaymentAccount: "repayment Account", AutoFinance: "Auto Finance",
  attached: "Attached"
}]
const RfBuyer = ({ invoice_detail, getRfBuyerdataSource, getrfBuyercounterPartyList, FinanceAmountErr, setFileLists,commentsData }: any) => {
  const Navigate = useNavigate()

  const { TextArea } = Input;
  const { Option } = Select;
  const { DeleteIcon, DatePickerImg, DropdownIcon,ErrorImage } = images
  const [buyerId, setBuyerId] = useState("");
  const [counterpartyId, setCounterpartyId] = useState([] as any);
  const [MaxInvoicePercent, setMaxInvoicePercent] = useState("");
  const [counterPartyList, setCounterPartyList] = useState([] as any);
  const [buyerName, setBuyerName] = useState([] as any);
  const [currencyList, SetCurrencyList] = useState([]);
  const [PartyAccountDetails, setPartyAccountDetails] = useState([] as any);
  const [FinanceAmtError, setFinanceAmtError] = useState("")
  const [FinanceAmountError, setFinanceAmountError] = useState(false)
  const [FinanceAmountErrorMessage, setFinanceAmountErrorMessage] = useState("")

  const [rfBuyerdataSource, setrfBuyerDataSource] = useState([
    {
      BuyerID: "" as any,
      BuyerName: buyerName as any,
      InvoiceNo: "" as any,
      InvoiceDate: "" as any,
      InvoiceCurrency: "" as any,
      InvoiceAmount: "" as any,
      DueDate: "" as any,
      FinanceAmount: 0 as any,
      FinancingCurrency: "" as any,
      RepaymentAccount: "" as any,
      RepaymentCurrency: 0 as any,
      counterparty_id: counterpartyId as any,
      RepaymentID: 0 as any,
      auto_finance: "" as any,
      ProgramID: invoice_detail?.id,
      error:"" as any
    }])
const [invoiceNoError,setInvoiceNoError] = useState("")
  getRfBuyerdataSource(rfBuyerdataSource)
  getrfBuyercounterPartyList(counterPartyList)
  const selectedFiles = (value: any) => {
    setFileLists(value)
  }
  const getPartyAccounts = () => {
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/party/party-accounts/`)
      .then((resp: any) => {
        setPartyAccountDetails(resp.data.data)
      })
      .catch(() => {
        Navigate("/Notfound")
      })
  }
  useEffect(() => {
    getPartyAccounts();
    httpClient
      .getInstance()
      .get(`${baseurl}api/pairing/?pg_id=${invoice_detail.id}&data_type=INVOICE`)
      .then((resp: any) => {
        if (invoice_detail.party_details?.RF_seller === true) {
          setCounterPartyList(resp.data.data);
          const partyName = [] as any;
          resp.data.data.map((items: any) => {
            return partyName.push(items.counter_party_name);
          });
          setBuyerName(partyName);

          setCounterPartyList(resp.data.data);
          const sellerIds = [] as any;
          resp.data.data.map((items: any) => {
            return sellerIds.push(items.counterparty_uid);
          });
        }
      })
      .catch(() => {
        Navigate("/Notfound")
      })



    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        SetCurrencyList(resp.data.data);
      })
      .catch(() => {
        Navigate("/Notfound")
      })

  }, [])
  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };
  const disabledInvoiceDate = (current: any) => {
    return current && current > moment().endOf("day");
  }
  const rfBuyerAppendData = () => {
    const newInput = {
      BuyerID: buyerId,
      BuyerName: "",
      InvoiceNo: "",
      InvoiceDate: "",
      InvoiceAmount: 0,
      InvoiceCurrency: "",
      DueDate: 0,
      FinancingCurrency: 0,
      RepaymentAccount: "" as any,
      RepaymentCurrency: "" as any,
      counterparty_id: counterpartyId,
      FinanceAmount: 0,
      RepaymentID: 0 as any,
      ProgramID: invoice_detail?.id
    } as any;
    setrfBuyerDataSource([...rfBuyerdataSource, newInput]);

  }
  const onRowDelete = (value: any) => {
    console.log("value", value)
  }
  // const invoiceNumberApiCall = (data:any) => {
  //   console.log("any",data,"invoice_detail",invoice_detail)
  //   let hasError = false;

  //   rfBuyerdataSource?.map((item:any)=>{
  //     console.log("item",item,data,"data")
  //     if(rfBuyerdataSource.length === 2 && (item?.InvoiceNo ===data?.InvoiceNo)){
  //       console.log("same invoice")
  //       setInvoiceNoError("InvoiceNo. already exixts")
  //       hasError = true; 
  //     }else{
  //       console.log("diff invoice")
      //   httpClient
      // .getInstance()
      // .get(`${baseurl}api/invoice/upload/?invoice_no=${data?.InvoiceNo}&party=${data?.BuyerName}&program_type=${invoice_detail?.program_type}`)
      // .then((resp:any)=>{
      //   console.log(resp,"respp")
      //   if(resp?.data?.status === ResponseStatus?.FAILURE){
      //     setInvoiceNoError(resp?.data?.data)
      //     hasError = true; 
      //   }
      // })

  //     }
  //   })
  //   if (!hasError) {
  //     setInvoiceNoError('');
  //   }

  // }

  const invoiceNumberApiCall = (data: any) => {
    
    const apiEndpoint = `${baseurl}api/invoice/upload/?invoice_no=${data?.InvoiceNo}&party=${data?.BuyerName}&program_type=${invoice_detail?.program_type}`;
    const body = {
      counter_party:data?.BuyerName,
      program_type:invoice_detail?.program_type,
    invoice_no:data?.InvoiceNo
    }
    httpClient
      .getInstance()
      .post(`${baseurl}api/invoice/upload/check/`,body)
      .then((resp: any) => {
        if (resp?.data?.status === ResponseStatus?.FAILURE) {
          // Update error state for the specific row
          setrfBuyerDataSource((prevData) =>
            prevData.map((item) =>
              item.InvoiceNo === data.InvoiceNo ? { ...item, error: resp?.data?.data } : item
            )
          );
          console.log("error3")
        } else {
          console.log("error4")
          // Clear the error state for the specific row if the API call is successful
          setrfBuyerDataSource((prevData) =>
            prevData.map((item) =>
              item.InvoiceNo === data.InvoiceNo ? { ...item, error: '' } : item
            )
          );
        }
      });
  };


  // const invoiceNumberApiCall = (data: any) => {
  //   const targetRowId = data?.id; // Replace 'id' with the actual property name for row identifier
  
  //   console.log("any", data, "invoice_detail", invoice_detail);
  
  //   rfBuyerdataSource?.forEach((item: any) => {
  //     console.log("item", item, data, "data",targetRowId);
  
  //     const isTargetRow = item.id === targetRowId; // Replace 'id' with the actual property name for row identifier
  // console.log("isTargetRow",isTargetRow)
  //     if (isTargetRow) {
  //       // Perform API call only for the target row
  //       httpClient
  //         .getInstance()
  //         .get(
  //           `${baseurl}api/invoice/upload/?invoice_no=${data?.InvoiceNo}&party=${data?.BuyerName}&program_type=${invoice_detail?.program_type}`
  //         )
  //         .then((resp: any) => {
  //           console.log(resp, "respp");
  //           if (resp?.data?.status === ResponseStatus?.FAILURE) {
  //             setInvoiceNoError(resp?.data?.data);
  //           } else {
  //             // Clear the error message if the API call is successful
  //             setInvoiceNoError('');
  //           }
  //         });
  //     }
  //   });
  // };

  // useEffect(() => {
  //   TotalFinanceAmountCalculation()
  //   PartyFinanceAmountCalculation()
  // }, [rfBuyerdataSource])

  const totalFinanceAmount = rfBuyerdataSource.reduce((accumulator, data) => {
    // Use the unary plus (+) to convert string to number if FinanceAmount is in string format
    const financeAmount = +data?.FinanceAmount || 0;

    // Add the financeAmount to the accumulator
    return accumulator + financeAmount;
  }, 0);

  const TotalFinanceAmountCalculation = () => {
    // apfBuyerdataSource.map((data: any) => {
    // console.log("finance amt total",data?.FinanceAmount,"totalFinanceAmount",totalFinanceAmount,totalFinanceAmount.toFixed(2))
    // })
    httpClient
      .getInstance()
      .get(`${baseurl}api/program/${invoice_detail?.program_id}/?finance_amount=${totalFinanceAmount.toFixed(2)}`)
      .then((resp: any) => {
        console.log("resp", resp)
        if (resp.data.status === ResponseStatus?.FAILURE) {
          setFinanceAmtError(resp.data.data)
        } else {
          setFinanceAmtError("")
        }
      })
  }

  const counterPartyTotals: Record<string, number> = {};
  let finamt: any = {};
  counterPartyList.forEach((counterPartyItem: any) => {
    // Initialize total amount for the counterparty
    console.log("counterPartyItem", counterPartyItem)
    console.log("counterPartyItem", counterPartyItem?.id)
    counterPartyTotals[counterPartyItem?.counterparty_uid] = 0;
    finamt = 0;

    rfBuyerdataSource.forEach((data: any) => {
      if (counterPartyItem?.counterparty_uid === data?.SellerID) {
        // Use the unary plus (+) to convert string to number if FinanceAmount is in string format
        const financeAmount = +data?.FinanceAmount || 0;

        // Add financeAmount to the total for the counterparty
        counterPartyTotals[counterPartyItem?.counterparty_uid] += financeAmount;
        finamt += financeAmount;
        console.log("Counterparty Totals: 22", counterPartyTotals, "finamt", finamt, "counterPartyTotals", counterPartyTotals?.cp1s);

        // httpClient
        // .getInstance()
        // .get(`${baseurl}api/pairing/${counterPartyItem?.id}/?finance_amount=${totalFinanceAmount.toFixed(2)}`)
        // .then((resp:any)=>{
        //   console.log("resp",resp)
        //   if(resp.data.status === ResponseStatus?.FAILURE){
        //     setFinanceAmountError(resp.data.data)
        //   }else{
        //     setFinanceAmountError("")
        //   }
        // })


      }
    });
    console.log("Counterparty Totals: 11", counterPartyTotals, "finamt", finamt);


  });
  console.log("Counterparty Totals:", counterPartyTotals, "finamt", finamt);

  const transformedData = Object.entries(counterPartyTotals).map(([key, value]) => ({
    key: key,
    value: value
  }));

  const PartyFinanceAmountCalculation = () => {
    console.log("invoice_detail party", invoice_detail, "apfBuyerdataSource", rfBuyerdataSource, "counterPartyList", counterPartyList)


    counterPartyList.map((item: any) => {
      rfBuyerdataSource.map((data: any) => {
        if (item?.counterparty_uid === data?.SellerID) {
          console.log("invoice_detail party map", data?.FinanceAmount)
        }

      })
    })
    counterPartyList.map((partyitem: any) => {
      rfBuyerdataSource.map((data: any) => {
console.log("partyitem",partyitem)
        console.log("data",data?.SellerID === counterPartyTotals[0])

        transformedData.map((item:any)=>{
          console.log("counterPartyTotals",data?.SellerID === item.key)
          console.log("data",data)
              if(data?.SellerID === item.key && partyitem.counterparty_uid === item.key){
                console.log("if value",item.value)
                httpClient
                .getInstance()
                .get(`${baseurl}api/pairing/${partyitem?.id}/?finance_amount=${item.value.toFixed(2)}`)
                .then((resp: any) => {
                  console.log("resp", resp)
                  if (resp.data.status === ResponseStatus?.FAILURE) {
                    setFinanceAmtError(resp.data.data)
                  } else {
                    setFinanceAmtError("")
                  }
                })
              }
            })
       

      })
    })

    
    TotalFinanceAmountCalculation()
  }


  return (
    <div>
      {
          FinanceAmtError ?

            <Row gutter={24} style={{ width: "80%", backgroundColor: "#FDF1F1", borderLeft: "5px solid #E43E3E", padding: "10px", margin: "0" }}>
              <Col span={2} style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                <img src={ErrorImage} alt="errorImage" width="35px" />
              </Col>
              <Col span={20}>
                {/* <div style={{ color: "black", fontSize: "16px", fontFamily: "Poppins", fontWeight: 500, paddingBottom: "10px" }}> Automatic Finance Rejection: Please review error details</div> */}

                {FinanceAmtError}
              </Col>
            </Row>
            : ""}

      <div className='containerTable'>
        <div className="OuterDiv">
          {
            Header_RF_Seller && Header_RF_Seller.map((item: any, index: any) => {
              return (
                <div key={index} className='HeadInnerDiv'>
                  <h1 className='head' style={{ width: "12%" }}>{item.Name}</h1>
                  <h1 className='head' style={{ width: "10%" }}>{item.InvNo}</h1>
                  <h1 className='head' style={{ width: "12%" }}>{item.InvDate}</h1>
                  <h1 className='head' style={{ width: "20%" }}>{item.InvAmt}</h1>
                  <h1 className='head' style={{ width: "12%" }}>{item.DueDate}</h1>
                  <h1 className='head' style={{ width: "20%" }}>{item.FinanceAmount}</h1>
                  <h1 className='head' style={{ width: "4%" }}>{item.AutoFinance}</h1>
                  <h1 className='head' style={{ width: "6%" }}>{item.attached}</h1>
                </div>
              )
            })

          }
        </div>
        <div className='OuterDiv'>
{console.log("rfBuyerdataSource",rfBuyerdataSource)}
          {
            rfBuyerdataSource && rfBuyerdataSource.map((record: any, index: number) => {
              return (
                <div key={index} className='InnerDiv' >
                  <h5 className='body' style={{ width: "12%", fontSize: "10px", margin: "8px 2px" }}>
                    {buyerName.length !== 1 ?
                      <Select
                        optionFilterProp="children"
                        defaultValue=""
                        style={{
                          width: "100%", padding: "4px"
                        }}
                        suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}

                        onSelect={(event: string) => {
                          const array = [...rfBuyerdataSource];
                          array[index].BuyerName = event;
                          setrfBuyerDataSource(array);
                          const names: any[] = [];
                          let values: any;
                          counterPartyList.map((data: any) => {
                            const array = rfBuyerdataSource;
                            if (event === data.counter_party_name) {
                              return (
                                (values = {
                                  id: data.id,
                                  name: data.counter_party,
                                }),
                                names.push(values),
                                setBuyerId(data.id),
                                setCounterpartyId(data.counter_party),
                                setMaxInvoicePercent(data.max_invoice_percent),
                                (array[index].BuyerID = data.counterparty_uid),
                                (array[index].counterparty_id = data.counter_party),
                                setrfBuyerDataSource(array)
                              );
                            }
                            return true;
                          });
                        }}
                      >
                        {buyerName.map((partyList: any, index: any) => {
                          return (
                            <Option value={partyList} key={partyList} id={`invRfBuyer_partyName${index}`}>
                              {partyList}
                            </Option>
                          );
                        })}
                      </Select>
                      : <div id={`invRfBuyer_partyName${index}`}>
                        {counterPartyList[0].counter_party_name}</div>}
                  </h5>

                  <h5 className='body' style={{ width: "10%", margin: "8px 2px" }}>
                    <div >
                      <Input
                        className="InputContainer"
                        onChange={(event: any) => {
                          const array = rfBuyerdataSource;
                          array[index].InvoiceNo = event.target.value;
                          if (buyerName.length === 1) {
                            setBuyerId(counterPartyList?.[0]?.id)
                            setCounterpartyId(counterPartyList?.[0]?.counter_party)
                            setMaxInvoicePercent(counterPartyList?.[0]?.max_invoice_percent)
                            array[index].BuyerID = counterPartyList?.[0]?.counterparty_uid
                            array[index].BuyerName = counterPartyList?.[0]?.counter_party_name
                            array[index].counterparty_id = counterPartyList?.[0]?.counter_party
                          }
                          console.log("error01",index > 0,"1",index > 0 && array[index - 1].InvoiceNo,"4",event.target.value)

                          if (index > 0 && array[index - 1].InvoiceNo === event.target.value) {
                            // Set an error for the current row
                            console.log('Setting error:', event.target.value);
                            array[index].error = 'InvoiceNo. already exists';
                          } else {
                            // Clear the error for the current row
                            console.log('Clearing error:', event.target.value);
                            array[index].error = '';
                          }

                          // if (index > 0 && array[index - 1].InvoiceNo === event.target.value) {
                          //   // Set an error for the current row
                          //   setrfBuyerDataSource(array, () => {
                          //     console.log('Setting error:', event.target.value);
                          //     array[index].error = 'InvoiceNo. already exists';
                          //   });
                          // } else {
                          //   // Clear the error for the current row
                          //   setrfBuyerDataSource(array, () => {
                          //     console.log('Clearing error:', event.target.value);
                          //     array[index].error = '';
                          //   });
                          // }
                          

                          // if (index > 0 && array[index - 1].InvoiceNo !== event.target.value) {
                          //   // Set an error for the current row
                          //   array[index].error = '';

                          //   console.log("error1")
                          // } else {
                          //   // Clear the error for the current row
                          //   array[index].error = 'InvoiceNo. already exists';

                          //   console.log("error2")
                          // }

                          setrfBuyerDataSource(array);
                          // invoiceNumberApiCall(record);
                        }}
                        onBlur={()=> invoiceNumberApiCall(record)}
                        id={`invRfBuyer_InvoiceNumber${index}`}
                        style={{ padding: "4px" }}
                      />

                    </div>
                    {console.log("error record",record.error)}
                    {record.error && <div className="errorMsg" style={{ color: 'red',fontSize:"10px" }}>{record.error}</div>}
                    {/* {invoiceNoError && <div style={{ color: 'red' }}>{invoiceNoError}</div>} */}
                    {/* <div className="errorMsg">{invoiceNoError}</div> */}
                  </h5>
                  <h5 className='body' style={{ width: "12%", margin: "8px 2px" }}>

                    <DatePicker
                      format="DD-MM-YYYY"
                      suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}

                      onChange={(event: any) => {
                        const array = rfBuyerdataSource;
                        array[index].InvoiceDate = moment(event._d).format("DD-MM-YYYY");
                        setrfBuyerDataSource(array);
                      }}
                      style={{ padding: "4px" }}
                      className="InputContainer"
                      disabledDate={disabledInvoiceDate}
                      id={`invRfBuyer_InvoiceDate${index}`}
                    />

                  </h5>

                  <h5 className='body' style={{ width: "20%", margin: "8px 2px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                      >
                        <Select
                          showSearch
                          style={{ width: 64, padding: "4px" }}
                          onSelect={(event: string) => {
                            const array = [...rfBuyerdataSource];
                            array[index].InvoiceCurrency = event;
                            array[index].FinancingCurrency = event;
                            setrfBuyerDataSource(array);
                          }}
                          suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                          id={`invRfBuyer_InvoiceCurrency${index}`}
                        >
                          {currencyList &&
                            currencyList.map((item: any) => {
                              return (
                                <Option
                                  value={item.description}
                                  id={item.description}
                                  key={item.description}
                                >
                                  {item.description}
                                </Option>
                              );
                            })}
                        </Select>
                      </span>

                      <InputNumber
                        className="InputContainer"
                        disabled={!record.BuyerName}
                        onChange={(event: any) => {
                          const array = [...rfBuyerdataSource];
                          array[index].InvoiceAmount = parseInt(event);
                          array[index].FinanceAmount = (parseInt(event) * (parseInt(MaxInvoicePercent) / 100)).toFixed(2)
                          setrfBuyerDataSource(array)
                        }}
                        formatter={(value, e) => {
                          if (value === "" || isNaN(parseFloat(value))) {
                            return "";
                          }
                          return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                            : value
                        }}
                        onBlur={PartyFinanceAmountCalculation}
                        id={`invRfBuyer_InvoiceAmount${index}`}
                        style={{ padding: "5px 4px" }}
                      />
                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "12%", margin: "8px 2px" }}>
                    <div>
                      <DatePicker
                        disabledDate={disabledDate}
                        format="DD-MM-YYYY"
                        suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}

                        onChange={(event: any) => {
                          const array = rfBuyerdataSource;
                          array[index].DueDate = moment(event._d).format("DD-MM-YYYY");
                          array[index].RepaymentAccount = PartyAccountDetails?.[0]?.account_number;
                          PartyAccountDetails &&
                            PartyAccountDetails.map((item: any) => {
                              return PartyAccountDetails?.[0]?.account_number === item.account_number ?
                                currencyList &&
                                currencyList.map((currency: any) => {
                                  return currency.id === item.currency ?
                                    (array[index].RepaymentCurrency = currency.description,
                                      array[index].RepaymentID = item.id) : null
                                }) : null
                            })

                          setrfBuyerDataSource(array);
                        }}
                        style={{ padding: "4px" }}
                        className="InputContainer"
                        id={`invRfBuyer_DueDate${index}`}
                      />
                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "20%", margin: "8px 2px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span id={`invRfBuyer_FinanceCurrency${index}`} style={{
                        marginRight: "3px", border: "1px solid #d9d9d9", width: "28%", height: " 45px", borderRadius: "10px",
                        padding: "9px",
                        backgroundColor: "#FFFFFF"
                      }}>
                        {record.InvoiceCurrency}

                      </span>
                      <div style={{ display: "block" }}>
                        <InputNumber
                          className="InputContainer"
                          style={{ width: "100%", padding: "5px 4px" }}
                          id={`invRfBuyer_FinanceAmount${index}`}
                          defaultValue={record.FinanceAmount}
                          value={record.FinanceAmount}
                          disabled={!record.InvoiceAmount}
                          onChange={(event: any) => {
                            const arr = [...rfBuyerdataSource];

                            const data = (arr[index].InvoiceAmount * (parseInt(MaxInvoicePercent) / 100)).toFixed(2)
                            if (event > data) {
                              setFinanceAmountError(true)
                              setFinanceAmountErrorMessage(data)
                              FinanceAmountErr(true)
                            } else {
                              setFinanceAmountError(false)
                              setFinanceAmountErrorMessage('')
                              FinanceAmountErr(false)
                            }

                            arr[index].FinanceAmount = parseInt(event)
                            setrfBuyerDataSource(arr);
                          }}
                          formatter={(value, e) => {
                            if (value === "" || isNaN(parseFloat(value))) {
                              return "";
                            }
                            return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                              : value
                          }}
                          onBlur={PartyFinanceAmountCalculation}
                        />
                      </div>
                    </div>
                    {FinanceAmountError ? <span style={{ color: "red" }}>Finance Amount should not be greater than {FinanceAmountErrorMessage}</span> : null}

                  </h5>
                  <h5 className='body' style={{ width: "4%", margin: "8px 2px" }}>
                    <div>
                      <Checkbox onChange={(event) => {
                        const array = rfBuyerdataSource;
                        array[index].auto_finance = event.target.checked;
                        setrfBuyerDataSource(array);
                      }
                      } id={`invRfBuyer_AutoFinance${index}`}
                        defaultChecked={localStorage.getItem("autofinance") === "true" ? true : false}
                      />
                    </div>
                  </h5>
                  <h5 className='body' style={{ width: "6%", margin: "8px 2px" }} id={`invRfBuyer_attachments${index}`}>
                    <UploadImage selectedFiles={selectedFiles} attachmentFiles={[]} />
                  </h5>
                  <h5 >
                    <img src={DeleteIcon} alt="deleteIcon" id="invRfBuyer_delete"
                      onClick={() => onRowDelete(record)}
                    />
                  </h5>
                </div>
              )
            })
          }
        </div>
      </div>
      <div
        onClick={rfBuyerAppendData}
        style={{
          position: "absolute",
          right: "5%",
          padding: "0px 8px",
          backgroundColor: "#FFB800",
          color: "#FFFFFF",
          marginTop: "10px"
        }} id="addInvoice"
      >
        +
      </div>
      <div className="SummaryContainer" style={{ marginTop: "5%" }}>
        <div className="SummaryLabel">Comments</div>
        <TextArea
          style={{ width: "50%", margin: "0px 10px" }}
          onChange={(e: any) => commentsData(e.target.value)} id="comments"
        />
      </div>

    </div>
  )
}
export default RfBuyer