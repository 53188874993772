import React, { useState } from "react";
import { Tabs, Spin, message, Card } from "antd";
import Complete from "./completeFile";
import InComplete from "./incompleteFiles";
import { useNavigate, useLocation } from "react-router";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import { transition } from "../../../api/finfloTransition";
import { Action, ResponseStatus, TransactionType } from "../../../../utils/enum/choices";
import Breadcrumbs from "../../../common/heading/breadcrumb";
const UploadTab = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [upload_detail] = useState(state as any);
    const [tabValue, setTabValue] = useState("1");
    const [IncompleteDatas, setIncompleteData] = useState([]);
    const [completeDatas, setcompleteData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [showCreateInvoiceBtn, SetShowCreateInvocieBtn] = useState(false);
    const [CompletedInvoiceDatas, setCompletedInvoiceDatas] = useState([]);
    const [InCompletedInvoiceDatas, setInCompletedInvoiceDatas] = useState([]);
    const[buttondisable,setButtonDisable] = useState(false)
    const callback = (key: any) => {
        setTabValue(key);
    };

    const IncompleteInvoice = () => {
        httpClient
            .getInstance()
            .get(`${baseurl}api/invoice/upload/?type=NO`)
            .then((resp: any) => {
                if(resp.data.status === ResponseStatus.SUCCESS){
                    setInCompletedInvoiceDatas(resp.data.data)
                    
                    if (resp.data.data.length === 0) {
                        SetShowCreateInvocieBtn(true)
                        setTabValue("2");
                        CompleteInvoice()
                    }
                    setIsLoading(false)
                }else{
                    setIsLoading(false)
                }
                
            })
            .catch(() => {
                navigate("/Notfound")
            })
    }
    const CompleteInvoice = () => {
        httpClient
            .getInstance()
            .get(`${baseurl}api/invoice/upload/?type=YES`)
            .then((resp: any) => {
                setIsLoading(false)
                if(resp.data.status === ResponseStatus.SUCCESS){
                    if (resp.data.data.length === 0) {
                        SetShowCreateInvocieBtn(false)
                        setTabValue("1");
                    } else {
                        IncompleteInvoice();
                        setCompletedInvoiceDatas(resp.data.data)
                    }
                    setIsLoading(false)
                }
            })
            .catch(() => {
                navigate("/Notfound")
            })
    }
    const UploadInvoice = () => {
        setButtonDisable(true)
        setIsLoading(true)
        if (IncompleteDatas.length > 0) {
            const promises = IncompleteDatas.map((item: any) => {
                let body = {} as any
                if (upload_detail?.party_details?.APF_buyer === true) {
                    body = {
                        invoices:[
                            {
                                "SellerID": item.invoices[0].SellerID,
                                "SellerName": item.invoices[0].SellerName.toUpperCase(),
                                "InvoiceNo": item.invoices[0].InvoiceNo,
                                "InvoiceDate": item.invoices[0].InvoiceDate,
                                "InvoiceAmount": item.invoices[0].InvoiceAmount,
                                "DueDate": item.invoices[0].DueDate,
                                "FinancingCurrency": item.invoices[0].FinancingCurrency,
                                "SettlementCurrency": item.invoices[0].SettlementCurrency,
                                "InvoiceCurrency": item.invoices[0].InvoiceCurrency,
                                "FinanceAmount" : item.invoices[0].FinanceAmount,
                                "auto_finance":item.invoices[0].auto_finance,
                                ProgramID: item.invoices[0].program_id
                            }
                        ]
                    }
                }else{
                    body = {
                        invoices: [{
                            BuyerID: item.invoices[0].BuyerID,
                            BuyerName: item.invoices[0].BuyerName,
                            InvoiceNo: item.invoices[0].InvoiceNo,
                            InvoiceDate: item.invoices[0].InvoiceDate,
                            InvoiceCurrency: item.invoices[0].InvoiceCurrency,
                            InvoiceAmount: item.invoices[0].InvoiceAmount,
                            DueDate: item.invoices[0].DueDate,
                            FinancingCurrency: item.invoices[0].FinancingCurrency,
                            SettlementCurrency: item.invoices[0].SettlementCurrency,
                            ProgramID: item.invoices[0].ProgramID ? item.invoices[0].ProgramID : item.invoices[0].program_id,
                        }]
                    }
                }
                 
                return httpClient
                    .getInstance()
                    .put(`${baseurl}api/invoice/upload/${item.id}/`, body)
                    .catch(() => {
                        navigate("/Notfound")
                    })
            })
            Promise.all(promises)
        .then(() => {
            setIsLoading(false);
            setButtonDisable(false);
            message.success("Invoice(s) Uploaded Successfully");
        });
        } else {
            navigate("/new")
        }
    }

    const InCompleteData = (data: any) => {
        setIncompleteData(data)
        if (data.length === 0) {
            SetShowCreateInvocieBtn(true)
            setTabValue("2");
        }
        setIncompleteData(data)
    }
    const completedData = (data: any) => {
        setcompleteData(data)
    }

    const handleTransition = async (item:any) => {
        try {
            const body ={
                action:Action.SUBMIT,
                type:TransactionType.TINVOICEUPLOADS,
                t_id:item.id
            }
            const response = await httpClient.getInstance().post(`${baseurl}api/resource/action/status/`,body);
            if (response.data.status === ResponseStatus.SUCCESS) {
                // message.success(response?.data?.transition_status)
                buttonStatus(false)
                // returnRoute(true)
                navigate("/New");
                setButtonDisable(false)
                // await transition(response.data.data, "Submit", response.data.data.from_party, response.data.data.to_party, "New", returnRoute, buttonStatus);
            } else {
                message.error(response.data.data);
                buttonStatus(false)
            }
        } catch (error) {
            navigate("/Notfound");
        }
        // try {
        //     const response = await httpClient.getInstance().get(`${baseurl}api/resource/action/status/?action=${Action.SUBMIT}&type=${TransactionType.TINVOICEUPLOADS}&t_id=${item.id}`);
    
        //     if (response.data.status === ResponseStatus.SUCCESS) {
        //         await transition(response.data.data, "Submit", response.data.data.from_party, response.data.data.to_party, "New", returnRoute, buttonStatus);
        //     } else {
        //         message.error(response.data.data);
        //     }
        // } catch (error) {
        //     navigate("/Notfound");
        // }
    };

    const CreateInvoice = () => {
        setButtonDisable(true)
        completeDatas && Promise.all(completeDatas.map(handleTransition))
    .then(() => {
        message.success("Transition(s) completed successfully");
    })
    .catch(() => {
        message.error("An error occurred during transition");
    });
        // completeDatas && completeDatas.map((item: any) => {
        //     return httpClient
        //         .getInstance()
        //         .get(`${baseurl}api/resource/action/status/?action=${Action.SUBMIT}&type=${TransactionType.TINVOICEUPLOADS}&t_id=${item.id}`)
        //         .then((response: any) => {
        //             if (response.data.status === ResponseStatus.SUCCESS) {
        //                 transition(response.data.data, "Submit", response.data.data.from_party, response.data.data.to_party, "New", returnRoute,buttonStatus)
        //                 // transition(item, "Submit", JSON.parse(loginlocalItems).party_id, JSON.parse(loginlocalItems).party_id, "New", returnRoute)

        //             } else {
        //                 message.error(response.data.data)
        //             }
        //         })
        //         .catch(() => {
        //             navigate("/Notfound")
        //         })
        // })
    }
    const returnRoute = (data: any) => {
        setButtonDisable(false)
        if (data === true) {
            navigate("/New");
        }
    }
    const onClickExit = () => {
        navigate("/New");
    };
    const getTabValue = (value: string) => {
        setTabValue(value);
    }
    const data = {
        fromMenu: "Invoice",
        data: {
            type: "bulk upload"
        },
        recordType: ""
    }
    const items =[
       {
        label:"Incomplete",
        key:"1",
        children: <InComplete
        incompleteData={InCompleteData}
        upload_detail={upload_detail}
        InCompletedInvoiceDatas={InCompletedInvoiceDatas}
        tabValue={getTabValue}
    />
       },
       {
        label:"Complete",
        key:"2",
        children: <Complete upload_detail={upload_detail} CompletedInvoiceDatas={CompletedInvoiceDatas} completedData={completedData} />
       }

    ]
    const buttonStatus = (data:any) => {
        console.log("program button",data)
       
    }
    const buttonStatusData = {
        default: buttondisable,
      }
    return isLoading ? <Spin /> : (
        <React.Fragment>
            <div className="fixedContainer">
                <Breadcrumbs
                    Data={data}
                    onClickExit={onClickExit}
                    commentsValue={showCreateInvoiceBtn}
                    flag="bulk upload"
                    onClickAction={showCreateInvoiceBtn === true ? CreateInvoice : UploadInvoice}
                    buttonStatusData={buttonStatusData}

                />
            </div>
            <div className="approvedFinanceContainer mainContentContainer">

                <div className="Card_Main_Container ">
                    <Card className="CardContainer">
                        <h1>Upload File</h1>
                        <Tabs style={{padding:"0px"}} defaultActiveKey={tabValue} onChange={callback} activeKey={tabValue} items={items} />
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default UploadTab;