import { useState, useEffect, useRef } from "react";
import Images from "../../assets/images";
import { Link } from "react-router-dom";
import { Layout, Menu, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { menuStatus_data, socketInfoDetails } from "../../redux/action";
import { MenuStatus } from "../api/base";
import httpClient from "../../utils/config/core/httpClient";
import baseurl from "../../utils/config/url/base";
import { PartyType, Status, OnboardingStatus } from "../../utils/enum/choices";
import * as pako from 'pako';

const SideNavbar = ({ LayoutStyle }: any) => {
  const { Sider } = Layout;
  const dispatch = useDispatch();
  const ws = useRef(null) as any;

  const socketInfoDetailsData = useSelector(socketInfoDetails);
  const MenuStatusData = socketInfoDetailsData?.payload?.socketInfo
  const [meustatusData, setMenuStatusData] = useState('');
  const [repaymentData, setRepaymentData] = useState([]);
  const [isPaused, setPause] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const { managescfIcon, manageUserIcon, newIcon, InboxIcon, draftIcon, sentIcon,
    enquiryIcon, settingIcon, krediq, SelectedManageUsers, SelectedManageScf, SelectedDraft, SelectedInbox,
    SelectedEnquiry, SelectedNew, SelectedSent, SelectedSetting, SelectedSentforSign, SentforSign, DashboardIcon, SelectedDashboard,
    krediqLogo, SelectedRepayment, Repayment, SelectedInvoice, Invoice, SelectedDate, DateIcon
  } = Images;

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const handleMenuOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const [collapsed, setCollapsed] = useState(false);
  const [adjustWidth, setAdjustWidth] = useState(false);
  const [disableMenu, setDisabledMenu] = useState(localStorage.getItem("enableMenu") !== null ? localStorage.getItem("enableMenu") === "true" ? true : false : false)
  const loginData = localStorage.getItem("login_detail") || "";
  const logindata = JSON.parse(loginData)
  const { SubMenu } = Menu;
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    setAdjustWidth(!adjustWidth)
    LayoutStyle(!adjustWidth)
  };

  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e: any) => {
      setIsLoading(true)
      if (isPaused) return;
      const message = JSON.parse(e.data);

      const compressedDataArray = message

      const compressedData = new Uint8Array(compressedDataArray);
      const decompressedData = pako.inflate(compressedData, { to: 'string' });
      const decompressedMessageData = JSON.parse(decompressedData);

      dispatch(
        socketInfoDetails(decompressedMessageData)
      )
      setTimeout(() => {
        setIsLoading(false)
      }, 2000)

    };
  }, [isPaused]);

  const pathname = window.location.pathname
  const SideMenuBar =
    [
      {
        key: "0",
        icon: pathname === "/Dashboard" || pathname === "/ProgramDetailView" ? <img src={SelectedDashboard} alt="dashboard" /> : <img src={DashboardIcon} alt="dashboard" />,
        route: "/Dashboard",
        disabledMenu: disableMenu === true ? true : (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.STB) || JSON.parse(loginData).status === Status.NEW
          ? true : false,
        className: pathname === "/Dashboard" || pathname === "/ProgramDetailView" ? "activeMenu" : "",
        id: "Dashboard",
        label: "Dashboard"
      },
      {
        key: "1",
        label: "Manage SCF",
        route: localStorage.getItem("user") === "Bank" ? "/ManageScfNew" : "/ManageScf",
        disabledMenu: disableMenu === true ? true : (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.STB) || localStorage.getItem("user") === "NonCustomer" ? true : JSON.parse(loginData).status === Status.NEW
          ? true : false,
        className: pathname === "/ManageScf" || pathname === "/ApprovedPayableFinancing" || pathname === "/ReceivableFinancing" || pathname === "/Inbox/ProgramModify" || pathname === "/ManageScfNew" ? "activeMenu" : "",
        icon: pathname === "/ManageScf" || pathname === "/ApprovedPayableFinancing" || pathname === "/ReceivableFinancing" || pathname === "/Inbox/ProgramModify" ? <img src={SelectedManageScf} /> : <img src={managescfIcon} />,
        id: "Manage SCF"
      },
      ...(JSON.parse(loginData).is_supervisor === false) ?
        [{
          key: "2",
          label: "Manage Users",
          route: "/ManageUsers",
          disabledMenu: disableMenu === true ? true : (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.STB) || (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || JSON.parse(loginData).status === Status.NEW
            ? true : false,
          className: pathname === "/ManageUsers" ? "activeMenu" : "",
          icon: pathname === "/ManageUsers" ? <img src={SelectedManageUsers} /> : <img src={manageUserIcon} />,
          id: "Manage Users"
        }] : [],

      {
        key: "3",
        label: "New",
        route: "/New",
        disabledMenu: disableMenu === true ? true : (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.STB) ||
          (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || JSON.parse(loginData).status === Status.NEW
          ? true : (MenuStatusData && MenuStatusData?.status_data?.[0]?.menu_status === "DISABLED") ? true : false,
        // className: pathname === "/New" || pathname === "/Repayment" || pathname === "/RepaymentDetail" || pathname === "/RepaymentCustomer" || pathname === "/Manual" || pathname === "/Repayment" || pathname === "/Invoice" || pathname === "/NewAccount" || pathname === "/Upload" || pathname === "/UploadDetail" ? "activeMenu" : "",
        icon: pathname === "/New" || pathname === "/Repayment" || pathname === "/RepaymentDetail" || pathname === "/RepaymentCustomer" || pathname === "/Manual" || pathname === "/Repayment" || pathname === "/Invoice" || pathname === "/NewAccount" || pathname === "/Upload" || pathname === "/UploadDetail" ? <img src={SelectedNew} /> : <img src={newIcon} />,
        id: "New",
        subMenu: [
          // ...(localStorage.getItem("user") !== "Bank")?
          // // (MenuStatusData && MenuStatusData?.status_data?.[0]?.menu_status !== "REPAYMENT") || 
          //   [
          {
            key: "3.1",
            label: "Invoice",
            route: localStorage.getItem("user") === "Bank" ? "/InvoiceCustomer" : "/New",
            disabledMenu: (MenuStatusData && MenuStatusData?.status_data?.[0]?.menu_status === "DISABLED") ? true :
              (logindata.party_type === PartyType.NONE && logindata.status === Status.NEW && logindata.onboarding_status === OnboardingStatus.DRAFT) || (logindata.party_type === PartyType.NONE && logindata.status === Status.IN_PROGRESS && logindata.onboarding_status === OnboardingStatus.STC) ? true :
                (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || JSON.parse(loginData).status === Status.NEW
                  ? true : false,
            className: pathname === "/New" ? "activeMenu" : "",
            icon: pathname === "/New" ? <img src={SelectedInvoice} /> : <img src={Invoice} />,
            id: "Invoice"

          },
          // ] 
          //   : [],
          ...(localStorage.getItem("user") !== "Bank" && repaymentData?.length !== 0) || ((localStorage.getItem("user") === "Bank")) ?
            [{
              key: "3.2",
              label: "Repayment",
              route: localStorage.getItem("user") === "Bank" ? "/RepaymentCustomer" : "/Repayment",
              disabledMenu:
                (logindata.party_type === PartyType.NONE && logindata.status === Status.NEW && logindata.onboarding_status === OnboardingStatus.DRAFT) || (logindata.party_type === PartyType.NONE && logindata.status === Status.IN_PROGRESS && logindata.onboarding_status === OnboardingStatus.STC) ? true :
                  (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || JSON.parse(loginData).status === Status.NEW
                    ? true : false,
              className: pathname === "/Repayment" ? "activeMenu" : "",
              icon: pathname === "/Repayment" ? <img src={SelectedRepayment} /> : <img src={Repayment} />,
              id: "Repayment"
            }] : [],

        ]
      },
      ...(MenuStatusData && MenuStatusData?.status_data?.[0]?.pending_invoice !== false)
        && (meustatusData !== "DISABLED") ? [
        {
          key: "4",
          label: "Pending Invoice",
          route: "/PendingInvoices",
          disabledMenu: disableMenu === true ? true : (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.STB) || JSON.parse(loginData).onboarding_status === OnboardingStatus.STC && JSON.parse(loginData).status === Status.IN_PROGRESS ? true : JSON.parse(loginData).status === Status.NEW
            ? true : false,
          className: pathname === "/PendingInvoices" || pathname === "PendingInvoiceDetail" ? "activeMenu" : "",
          icon: pathname === "/PendingInvoices" || pathname === "PendingInvoiceDetail" ? <img src={SelectedInvoice} /> : <img src={Invoice} />,
          id: "Pending Invoice",
          submenu: []
        }
      ] : [],
      {
        key: "5",
        label: <> Inbox   <span style={{ float: "right", paddingRight: "10px" }}>{MenuStatusData?.message_data?.inbox?.unread_message > 0 ? MenuStatusData?.message_data?.inbox?.unread_message : ""}</span></>,
        route: "/Inbox",
        disabledMenu:
          disableMenu === true ? true : (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.STB) || JSON.parse(loginData).onboarding_status === OnboardingStatus.STC && JSON.parse(loginData).status === Status.IN_PROGRESS ? true : JSON.parse(loginData).status === Status.NEW
            ? true : false,
        className: pathname === "/Inbox" || pathname === "/inbox" || pathname === "/Inbox/ProgramDetail" || pathname === "/inbox/ProgramDetail" || pathname === "/inbox/InvoiceDetail" || pathname === "/Inbox/UploadDetail" || pathname === "/Inbox/FinanceRequestDetail" || pathname === "/Inbox/InvoiceDetail" || pathname === "/inbox/CounterpartyDetail" || pathname === "/Inbox/History" ? "activeMenu" : "",
        icon: pathname === "/Inbox" || pathname === "/inbox" || pathname === "/Inbox/ProgramDetail" || pathname === "/inbox/ProgramDetail" || pathname === "/inbox/InvoiceDetail" || pathname === "/Inbox/UploadDetail" || pathname === "/Inbox/FinanceRequestDetail" || pathname === "/Inbox/InvoiceDetail" || pathname === "/inbox/CounterpartyDetail" || pathname === "/Inbox/History" ? <img src={SelectedInbox} /> : <img src={InboxIcon} />,
        id: "Inbox"
      },
      {
        key: "6",
        label: <> Draft   <span style={{ float: "right", paddingRight: "10px" }}>{MenuStatusData?.message_data?.draft?.unread_message > 0 ? MenuStatusData?.message_data?.draft?.unread_message : ""}</span></>,
        route: "/Draft",
        disabledMenu: disableMenu === true ? true : (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.STB) || (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || JSON.parse(loginData).status === Status.NEW
          ? true : false,
        className: pathname === "/Draft" || pathname === "/draft" || pathname === "/Draft/UploadDetail" || pathname === "/Draft/InvoiceDetail" || pathname === "/draft/FinanceRequestDetail" ? "activeMenu" : "",
        icon: pathname === "/Draft" || pathname === "/draft" || pathname === "/Draft/UploadDetail" || pathname === "/Draft/InvoiceDetail" || pathname === "/draft/FinanceRequestDetail" ? <img src={SelectedDraft} /> : <img src={draftIcon} />,
        id: "Draft"
      },
      {
        key: "7",
        label: "Sent",
        route: "/Sent",
        disabledMenu:
          localStorage.getItem("onboardingDetail") === "true" ? false :
            disableMenu === true ? true : JSON.parse(loginData).onboarding_status === OnboardingStatus.STC && JSON.parse(loginData).status === Status.IN_PROGRESS ? true : (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || JSON.parse(loginData).status === Status.NEW ? true :
              (logindata.party_type === PartyType.NONE && logindata.status === Status.IN_PROGRESS && logindata.onboarding_status === OnboardingStatus.STB)
                ? false : false,
        className: pathname === "/Sent" || pathname === "/sent" || pathname === "/Sent/InvoiceDetail" || pathname === "/sent/ProgramDetail" || pathname === "/sent/CounterpartyDetail" || pathname === "/sent/FinanceRepaymentDetail" || pathname === "/Sent/FinanceRequestDetail" ? "activeMenu" : "",
        icon: pathname === "/Sent" || pathname === "/sent" || pathname === "/Sent/InvoiceDetail" || pathname === "/sent/ProgramDetail" || pathname === "/sent/CounterpartyDetail" || pathname === "/sent/FinanceRepaymentDetail" || pathname === "/Sent/FinanceRequestDetail" ? <img src={SelectedSent} /> : <img src={sentIcon} />,
        id: "Sent"
      },
      {
        key: "8",
        label: "Sent for Sign",
        route: "/SubmittedForSign",
        disabledMenu: disableMenu === true ? true : (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.STB) || (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || JSON.parse(loginData).status === Status.NEW
          ? true : false,
        className: pathname === "/SubmittedForSign" || pathname === "/sent_awap/InvoiceDetail" || pathname === "/sent_awap" || pathname === "/sent_awap/ProgramDetail" || pathname === "/sent_awap/FinanceRequestDetail" ? "activeMenu" : "",
        icon: pathname === "/SubmittedForSign" || pathname === "/sent_awap/InvoiceDetail" || pathname === "/sent_awap" || pathname === "/sent_awap/ProgramDetail" || pathname === "/sent_awap/FinanceRequestDetail" ? <img src={SelectedSentforSign} /> : <img src={SentforSign} />,
        id: "Sent for Sign"
      },
      {
        key: "9",
        label: "Enquiry",
        route: "/Enquiry",
        disabledMenu: disableMenu === true ? true : (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.STB) || (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || JSON.parse(loginData).status === Status.NEW
          ? true : false,
        className: pathname === "/Enquiry" ? "activeMenu" : "",
        icon: pathname === "/Enquiry" ? <img src={SelectedEnquiry} /> : <img src={enquiryIcon} />,
        id: "Enquiry"
      },
      ...(JSON.parse(loginData).is_supervisor === true || JSON.parse(loginData).is_administrator === true) ?
        [
          {
            key: "10",
            label: "Settings",
            route: "/Settings",
            disabledMenu:
              (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.STB) || (logindata.party_type === PartyType.NONE && logindata.status === Status.NEW && logindata.onboarding_status === OnboardingStatus.DRAFT) || (logindata.party_type === PartyType.NONE && logindata.status === Status.IN_PROGRESS && logindata.onboarding_status === OnboardingStatus.STC) ? true :
                (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || JSON.parse(loginData).status === Status.NEW
                  ? true : false,
            className: pathname === "/Settings" ? "activeMenu" : "",
            icon: pathname === "/Settings" || pathname === "/UserAuthorization" ? <img src={SelectedSetting} /> : <img src={settingIcon} />,
            id: "Settings",
            subMenu:
              [
                ...(JSON.parse(loginData).is_supervisor === false) ?

                  [{
                    key: "10.1",
                    label: "User Auth",
                    route: "/UserAuthorization",
                    disabledMenu:
                      (logindata.party_type === PartyType.NONE && logindata.status === Status.NEW && logindata.onboarding_status === OnboardingStatus.DRAFT) || (logindata.party_type === PartyType.NONE && logindata.status === Status.IN_PROGRESS && logindata.onboarding_status === OnboardingStatus.STC) ? true :
                        (JSON.parse(loginData).party_type === PartyType.NONE && JSON.parse(loginData).onboarding_status === OnboardingStatus.REJECTED) || JSON.parse(loginData).status === Status.NEW
                          ? true : false,
                    className: pathname === "/UserAuthorization" ? "activeMenu" : "",
                    icon: pathname === "/UserAuthorization" ? <img src={SelectedManageUsers} /> : <img src={manageUserIcon} />,
                    id: "UserAuth"
                  }] : []],
          }] : [],


    ]

  const getMenuStatus = async () => {
    const data = await MenuStatus()
    setMenuStatusData(data?.menu_status?.status);
    dispatch(
      menuStatus_data(data?.menu_status?.status)
    )
  }
  useEffect(() => {
    getActionStatus();
    getRepaymentDetails()
    const innerwidth = window.innerWidth
    if (innerwidth > 600 && innerwidth < 1024) {
      setCollapsed(true)
    }
  }, [])
  const getRepaymentDetails = () => {

    if (localStorage.getItem("user") !== "Bank") {
      httpClient
        .getInstance()
        .get(`${baseurl}api/finance-request/`)
        .then((resp: any) => {
          setRepaymentData(resp.data.data)

        })
    }

  }
  const getActionStatus = () => {
    if (logindata.is_administrator === true) {
      httpClient
        .getInstance()
        .get(`${baseurl}finflo/action/?party_id=${logindata.party_id}`)
        .then((response: any) => {
          if (response?.data?.data?.length === 0) {
            setDisabledMenu(true)
          } else {
            setDisabledMenu(false)
          }
        })
    } else {
      setDisabledMenu(false)
    }

  }

  return isLoading ? <Spin /> : (
    <Sider
      width={collapsed === true ? "7%" : "13%"}
      className="site-layout-background"
    >
      {collapsed ?
        <img
          src={krediq}
          alt="krediqLogo"
          className={"finfloLogo"}
          onClick={toggleCollapsed}
        />
        :
        <img
          src={krediqLogo}
          alt="krediqLogo"
          className={"finfloLogoText"}
          onClick={toggleCollapsed}
        />}
      <Menu
        mode="inline"
        className={adjustWidth !== false ? "adjustwidth" : "layoutContainer"}
        id="menuList"
        openKeys={openKeys}
        onOpenChange={handleMenuOpenChange}
      >
        {SideMenuBar &&
          SideMenuBar.map((data: any) => {
            return (
              data?.subMenu ?
                <SubMenu key={data?.key} title={collapsed ? "" : data?.label}
                  icon={data?.icon}
                  disabled={data.disabledMenu}
                  className={(meustatusData === "DISABLED" && data.label === "New") ? "disabledMenuStatus "
                    : data.className ? data.className
                      : ""}
                  data-id={data.id}
                >
                  {
                    data.subMenu?.map((sub: any) => {
                      return (
                        <Menu.Item key={sub?.key} id={sub?.id} disabled={data.disabledMenu} style={{ paddingLeft: "25px" }}
                          className={(localStorage.getItem("user") === "NonCustomer" && data.label === "Manage SCF") || (meustatusData === "DISABLED" && data.label === "New") ? "disabledMenuStatus"
                            : data.className ? data.className
                              : ""}
                          icon={sub.icon}
                        >
                          <Link to={sub?.route} className={"sideBarText"}>
                            {collapsed ? "" : sub?.label}
                          </Link>
                        </Menu.Item>
                      )
                    })
                  }


                </SubMenu>
                :

                <Menu.Item
                  id={data.id}
                  className={(localStorage.getItem("user") === "NonCustomer" && data.menuItemText === "Manage SCF") || (meustatusData === "DISABLED" && data.menuItemText === "New") ? "disabledMenuStatus"
                    : data.className ? data.className
                      : ""}
                  key={data.key}
                  icon={data.icon}
                  style={{ paddingLeft: "15px" }}
                  disabled={data.disabledMenu}>
                  {meustatusData === "DISABLED" && data.label === "New" ? "" :
                    <Link to={data.route} className={"sideBarText"}>
                      {collapsed ? "" : data.label}
                    </Link>
                  }
                </Menu.Item>
            );
          })}
      </Menu>

    </Sider>
  );
};
export default SideNavbar;
