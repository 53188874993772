const initialState = {
    user: null,
    data: null,
    currencyData: null,
    basicDetailsData: null,
    inboxProgramDetail: null,
    interestType: null,
    interestRateType: null,
    menuStatus: null,
    inboxCount: null,
    draftCount: null,
    countryData: null,
    programBasicdetailsData: null,
    programCounterpartydetailsData: null,
    socketInfo: null,
    pageSizeData: null,
    socketConnectData: null,
    chatList: null,
    chatConnection: "disconnected",
    coversationConnection: null,
    selectedItems: null,
    selectedNotification: []
};

const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'login':
            return {
                ...state,
                user: action.payload,
            };
        case 'allInbox':
            return {
                ...state,
                data: action.payload,
            };
        case 'allCurrency':
            return {
                ...state,
                currencyData: action.payload,
            };
        case 'basicDetails':
            return {
                ...state,
                basicDetailsData: action.payload,
            };
        case 'inbox_programDetails':
            return {
                ...state,
                inboxProgramDetail: action.payload,
            };
        case 'allInterestType':
            return {
                ...state,
                interestType: action.payload,
            };
        case 'allInterestRateType':
            return {
                ...state,
                interestRateType: action.payload,
            };
        case 'menuStatus_data':
            return {
                ...state,
                menuStatus: action.payload,
            };
        case 'inboxCount_data':
            return {
                ...state,
                inboxCount: action.payload,
            };
        case 'draftCount_data':
            return {
                ...state,
                draftCount: action.payload,
            };
        case 'allCountry':
            return {
                ...state,
                countryData: action.payload,
            };
        case 'program_Basicdetails':
            return {
                ...state,
                programBasicdetailsData: action.payload,
            }
        case 'program_counterpartydetails':
            return {
                ...state,
                programCounterpartydetailsData: action.payload,
            };
        case 'socketInfoDetails':
            return {
                ...state,
                socketInfo: action.payload,
            };
        case 'pageSizeDetails':
            return {
                ...state,
                pageSizeData: action.payload,
            };
        case 'socketConnected':
            return {
                ...state,
                socketConnectData: action.payload,
            };
        case 'chatSocketList':
            return {
                ...state,
                chatList: action.payload,
            };
        case 'chatSocketConnection':
            return {
                ...state,
                chatConnection: action.payload,
            };
        case 'chatConversationConnection':
            return {
                ...state,
                coversationConnection: action.payload,
            };
        case 'selectedAwaitingItems':
            return {
                ...state,
                selectedItems: action.payload,
            };

        case 'selectedNotificationData':
            return {
                ...state,
                selectedNotification: action.payload,
            };


        default:
            return state;
    }

};

export default userReducer;
