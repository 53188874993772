import { allInterestType } from "../../../redux/action";
import { useSelector } from "react-redux";

export const InterestTypeField = ({ interestType }: any) => {

    const interest_Type_data = useSelector(allInterestType) as any;
    return (
        interest_Type_data?.payload?.interestType?.allInterestType.map((item: any) => {
            if (item.id === interestType) {
                return item.description

            } else if (item.description === interestType) {
                return item.description

            }
        })
    )

}