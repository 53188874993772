import React from 'react';
import { Form, message, AutoComplete, Card } from "antd";
import "./new.scss";
import { useNavigate } from 'react-router-dom';
import httpClient from "../../utils/config/core/httpClient";
import baseurl from "../../utils/config/url/base";
import { useDispatch } from "react-redux";
import { basicDetails } from "../../redux/action";
import { FieldErrorMessages } from "../../utils/enum/messageChoices";
import Breadcrumbs from '../common/heading/breadcrumb';
import { ResponseStatus } from '../../utils/enum/choices';
const CreateNewAccount = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [customerId, setCustomerId] = React.useState("")
  const [customerIdList, setCustomerIdList] = React.useState([]);

  const onFinish = () => {
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/party/check/?data=${customerId}`)
      .then((resp: any) => {
        if (resp.data.status === ResponseStatus.FAILURE) {
          message.error(resp.data.data)
        } else {
          history("/ManageScf");
          localStorage.setItem("party_name", resp.data.data?.[0]?.party__name)
          dispatch(basicDetails(resp.data.data))
        }
      });

  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo)
  };
  const Data = {
    fromMenu: "Create New Account",
    data: {
      type: ""
    },
    recordType: ""
  }

  const onChange = (e: any) => {
    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/party/?data=${e}`)
      .then((resp) => {
        setCustomerIdList(resp.data.data)
      })
  }
  const onSelect = (selectedText: string) => {
    const splitSelectedText = selectedText.split(" - ");
    setCustomerId(splitSelectedText[0].trim());
  };

  return (
    <React.Fragment>
      <div className="fixedContainer">
        <Breadcrumbs
          Data={Data}
          onClickExit={""}
          commentsValue={""}
          flag="ManageSCF_Bank"
          onClickAction={onFinish}
          buttonStatusData={""}
        />
      </div>
      <div className="manageScfContainer mainContentContainer" style={{ marginTop: "12px", border: "none" }}>
        <Card>
          <h4>{'Create New Account'}</h4>
          <>
          <div className="createNewAccount loginContainer">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={"Customer ID"}
                name={"account_number"}
                className="FormContainer"
                key={"account_number"}
                rules={[
                  {
                    required: false,
                    message: FieldErrorMessages.ACCCUS,
                  },
                ]}
              >
                <AutoComplete
                  dataSource={
                    customerIdList &&
                    customerIdList
                      .filter(
                        (value: any) =>
                          value.customer_id !== null && value.name !== null
                      )
                      .map((value: any) => (
                        <AutoComplete.Option
                          key={value.customer_id}
                          value={value.customer_id}
                        >
                          {value.customer_id} - {value.name}
                        </AutoComplete.Option>
                      ))
                  }
                  style={{ width: "100%", top: "unset", bottom: "unset" }}
                  onSelect={onSelect}
                  onSearch={onChange}
                  onChange={onChange}
                  placeholder="Enter customerId"
                  defaultValue={customerId}
                  value={customerId}
                  id={"accountNumber"}
                />
              </Form.Item>
            </Form>
            </div>
          </>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default CreateNewAccount;
