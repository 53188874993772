import React, { useState } from "react";
import { Card, message } from "antd";
import "../../new.scss";
import { useNavigate, useLocation } from "react-router-dom";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import ApfSeller from "./apfSeller";
import ApfBuyer from "./apfBuyer";
import RfBuyer from "./rfBuyer";
import RfBuyerEdit from "./rfBuyerEdit";
import ApfBuyerEdit from "./apfBuyerEdit";
import ApfSellerEdit from "./apfSellerEdit";
import { ProgramType, ResponseStatus,TransactionType } from "../../../../utils/enum/choices";
import Breadcrumbs from "../../../common/heading/breadcrumb";
import { manualActionStatus } from "../../../api/actionStatusUpload";
const Manual = () => {
  const { state } = useLocation();

  const [invoice_detail] = useState(state as any);
  const [counterPartyList, setCounterPartyList] = useState([]);
  const [fileList, setFileList] = useState([] as any);
  const [apfSellerdataSource, setApfSellerDataSource] = useState([]);
  const [apfBuyerdataSource, setApfBuyerDataSource] = useState([])
  const [rfBuyerdataSource, setrfBuyerDataSource] = useState([])
  const [commentsValue, setCommentsvalue] = useState(null as any);
  const [buttondisable, setButtonDisable] = useState(false);
  const [primaryButtonDisable, setPrimaryButtonDisable] = useState(false)
  const [secondaryButtonDisable, setSecondaryButtonDisable] = useState(false)
  const [FinanceAmtError, setFinanceAmtError] = useState(false)
  const history = useNavigate();

  const onClickExit = () => {
    history("/New");
  };

  const postManualFile = (id: any) => {
    const formdata = new FormData();
    fileList.map((item: any) => {
      formdata.append(`files`, item[0]);
      formdata.append(`comments`, "manualfile");
    });
    formdata.append("invoice_upload", id.toString());

    httpClient
      .getInstance()
      .post(`${baseurl}api/resource/file/`, formdata)
      .catch(() => {
        history("/Notfound")
      })
  }
  const putManualFile = (id: any) => {
    const formdata = new FormData();
    fileList.map((item: any) => {
      formdata.append(`files`, item[0]);
      formdata.append(`comments`, "manualfile");
    });
    formdata.append("invoice_upload", id.toString());

    httpClient
      .getInstance()
      .put(`${baseurl}api/resource/file/${invoice_detail?.data?.attachments?.file?.[0]?.id}/`, formdata)
      .catch(() => {
        history("/Notfound")
      })
  }

  const putInvoiceUpload = (bodyData: any, buttonKey: any) => {
    httpClient
      .getInstance()
      .put(`${baseurl}api/invoice/upload/${invoice_detail.data?.work_model?.t_id}/`, bodyData)
      .then((resp: any) => {
        if (resp.data.status === ResponseStatus.SUCCESS) {
          if (fileList.length > 0) {
            if (invoice_detail?.data?.attachments?.file.length === 0) {
              postManualFile(resp.data.data.id)
            } else {
              putManualFile(resp.data.data.id)
            }

          }
          if (buttonKey === "SUBMIT") {
            manualActionStatus(TransactionType.TINVOICEUPLOADS, resp.data.data.id, buttonKey,returnRoute)
            history(`/new`)
          } else {
            localStorage.setItem("invoice_t_id", resp.data.data.id)
            history(`/new`)
          }
        }
      })
      .catch(() => {
        history("/Notfound")
      })
  }
let routeCalled = false;

const returnRoute = (status: any, messageValue: any) => {
  if (!routeCalled) {
    routeCalled = true; 
    if(routeCalled === true){
      if (status === "success") {
        message.success(messageValue);
      } else if(status === "error"){
        message.error(messageValue);
      }
    }
  }
};
  const postInvoiceUpload = (bodyData: any, buttonKey: any) => {
    httpClient
      .getInstance()
      .post(`${baseurl}api/invoice/upload/`, bodyData)
      .then((resp: any) => {
        if (resp.data.status === ResponseStatus.SUCCESS) {
          if (fileList.length > 0) {
            postManualFile(resp.data.data.id)
          }
          if (buttonKey === "SUBMIT") {
            manualActionStatus(TransactionType.TINVOICEUPLOADS, resp.data.data.id, buttonKey,returnRoute)
            history(`/new`)
          } else {
            localStorage.setItem("invoice_t_id", resp.data.data.id)
            history(`/new`)
          }
        }
      })
      .catch(() => {
        history("/Notfound")
      })
  }
  const onClickSubmit = (buttonkey: string) => {
    const formdata = new FormData();
    formdata.append("comments", commentsValue);
    if (invoice_detail?.fromMenu === "draft") {

      if (invoice_detail.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "APF_SELLER") {
        let body = {} as any
        apfSellerdataSource.map((datas: any) => {
          counterPartyList.map((values: any) => {
            return values.counterparty_id === datas.counter_party_name ?
              body = {
                program_type: "APF",
                invoices: [datas],
                comments: commentsValue,
                counter_party: values.counter_party,
                anchor_party: values.anchor_party,
                is_manual: true,
                uploaded_by: null
              } : null
          })
          putInvoiceUpload(body, buttonkey)
        })
      } else if (invoice_detail.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "APF_BUYER") {
        let body = {} as any
        apfBuyerdataSource.map((datas: any) => {
          counterPartyList.map((values: any) => {
            if (values.counter_party === datas.counter_party) {
              body = {
                program_type: "APF",
                invoices: [datas],
                comments: commentsValue,
                counter_party: values.counter_party,
                anchor_party: values.anchor_party,
                is_manual: true,
                uploaded_by: null
              };
            }
          })
          putInvoiceUpload(body, buttonkey)
        })
      } else if (invoice_detail.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "RF_SELLER") {
        if (buttonkey === "delete") {
          httpClient
            .getInstance()
            .delete(`${baseurl}api/invoice/upload/${invoice_detail?.data?.work_model?.t_id}/`)
            .then((resp: any) => {
              if (resp.data.status === ResponseStatus.SUCCESS) {
                history("/New")
                message.success(resp.data.data)
              }

            })
        } else {
          if (FinanceAmtError === true) {
            message.error("Please check the finance amount")
          } else {
            let body = {} as any
            rfBuyerdataSource.map((datas: any) => {
              if (datas.BuyerName === "" || datas?.InvoiceDate === "" || datas.InvoiceNo === "" || datas.InvoiceAmount === "" || datas.DueDate === "" || datas.InvoiceCurrency === "" || datas?.FinancingCurrency === "" || datas?.FinanceAmount === "" || (String(datas?.FinanceAmount) === "NaN") || datas?.RepaymentAccount === "") {
                message.error("Please fill all the fields")
              } else {
                counterPartyList.map((values: any) => {
                  if (values.counter_party === datas.counterparty_id) {
                    body = {
                      program_type: "RF",
                      invoices: [datas],
                      comments: commentsValue,
                      counter_party: values.counter_party,
                      anchor_party: values.anchor_party,
                      is_manual: true,
                      uploaded_by: null
                    };
                  }
                })
                putInvoiceUpload(body, buttonkey);
              }

            })
          }
        }


      }

    } else {
      if (invoice_detail.party_details?.APF_seller === true) {
        let body = {} as any
        apfSellerdataSource.map((datas: any) => {
          counterPartyList.map((values: any) => {
            return values.counterparty_id === datas.counter_party_name ?
              body = {
                program_type: "APF",
                invoices: [datas],
                comments: commentsValue,
                counter_party: values.counter_party,
                anchor_party: values.anchor_party,
                is_manual: true,
                uploaded_by: null
              } : null
          })
          postInvoiceUpload(body, buttonkey)
        })
      } else if (invoice_detail.party_details?.APF_buyer === true) {
        let body = {} as any
        apfBuyerdataSource.map((datas: any) => {
          counterPartyList.map((values: any) => {
            if (values.counter_party === datas.counter_party) {
              body = {
                program_type: "APF",
                invoices: [datas],
                comments: commentsValue,
                counter_party: values.counter_party,
                anchor_party: values.anchor_party,
                is_manual: true,
                uploaded_by: null
              };
            }
          })
          postInvoiceUpload(body, buttonkey)
        })
      } else if (invoice_detail.party_details?.RF_seller === true) {
        let body = {} as any
        rfBuyerdataSource.map((datas: any) => {
          if (datas.BuyerName === "" || datas?.InvoiceDate === "" || datas.InvoiceNo === "" || datas.InvoiceAmount === "" || datas.DueDate === "" || datas.InvoiceCurrency === "" || datas?.FinancingCurrency === "" || datas?.FinanceAmount === "" || (String(datas?.FinanceAmount) === "NaN") || datas?.RepaymentAccount === "") {
            message.error("Please fill all the fields")
          } else if (FinanceAmtError === true) {
            message.error("Please check the finance amount")
          } else {
            counterPartyList.map((values: any) => {
              if (values.counter_party === datas.counterparty_id) {
                body = {
                  program_type: "RF",
                  invoices: [datas],
                  comments: commentsValue,
                  counter_party: values.counter_party,
                  anchor_party: values.anchor_party,
                  is_manual: true,
                  uploaded_by: null
                };
              }
            })
            postInvoiceUpload(body, buttonkey);
          }

        })
      }
    }

  };

  const getApfSellerdataSource = (data: any) => {
    setApfSellerDataSource(data)
  }
  const getApfBuyerdataSource = (data: any) => {
    setApfBuyerDataSource(data)
  }
  const getApfBuyercounterPartyList = (data: any) => {
    setCounterPartyList(data)
  }
  const getApfSellercounterPartyList = (data: any) => {
    setCounterPartyList(data)
  }
  const getrfBuyercounterPartyList = (data: any) => {
    setCounterPartyList(data)
  }
  const getRfBuyerdataSource = (data: any) => {
    setrfBuyerDataSource(data)
  }
  const FinanceAmountError = (data: any) => {

    setFinanceAmtError(data)
  }
  const setFileLists = (data: any) => {
    setFileList(data)
  }

  const data = {
    fromMenu: "Invoice",
    data: {
      type: "manual"
    },
    recordType: ""
  }
  const buttonStatusData = {
    default: buttondisable,
    primary: primaryButtonDisable,
    secondary: secondaryButtonDisable,

  }
  const commentsData =(value:any)=>{
    setCommentsvalue(value)
  }
  const TotalFinanceAmountError = (data:string) => {
    if(data !== ""){
      setButtonDisable(true)
      setPrimaryButtonDisable(true)
      setSecondaryButtonDisable(true)
    }
   
  }
  return (
    <React.Fragment>
      <div className="fixedContainer">
        <Breadcrumbs
          Data={data}
          onClickExit={onClickExit}
          commentsValue={commentsValue}
          flag="manual"
          onClickAction={onClickSubmit}
          buttonStatusData={buttonStatusData}
        />
      </div>
      <div className="ManualContainer mainContentContainer">

        <div className="Card_Main_Container new_card_main_container">
          <Card className="CardContainer">
            <h1>Manual</h1>

            {
              invoice_detail?.fromMenu === "draft" ?
                invoice_detail.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "APF_SELLER" ?
                  <ApfSellerEdit invoice_detail={invoice_detail} getApfSellerdataSource={getApfSellerdataSource} getApfSellercounterPartyList={getApfSellercounterPartyList} setFileLists={setFileLists} commentsData={commentsData} />
                  : invoice_detail.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "APF_BUYER" ?
                    <ApfBuyerEdit invoice_detail={invoice_detail} getApfBuyerdataSource={getApfBuyerdataSource} getApfBuyercounterPartyList={getApfBuyercounterPartyList} setFileLists={setFileLists} commentsData={commentsData} />
                    : invoice_detail.data?.record_datas?.values?.[0]?.fields?.uploaded_by === "RF_SELLER" ?
                      <RfBuyerEdit invoice_detail={invoice_detail} getRfBuyerdataSource={getRfBuyerdataSource} getrfBuyercounterPartyList={getrfBuyercounterPartyList} FinanceAmountErr={FinanceAmountError} setFileLists={setFileLists} commentsData={commentsData} />
                      : "No records"

                :
                invoice_detail.party_details?.APF_seller === true && invoice_detail.program_type === ProgramType.APF ?
                  <ApfSeller invoice_detail={invoice_detail} getApfSellerdataSource={getApfSellerdataSource} getApfSellercounterPartyList={getApfSellercounterPartyList} setFileLists={setFileLists} commentsData={commentsData} />
                  : invoice_detail.party_details?.APF_buyer === true && invoice_detail.program_type === ProgramType.APF ?
                    <ApfBuyer invoice_detail={invoice_detail} getApfBuyerdataSource={getApfBuyerdataSource} getApfBuyercounterPartyList={getApfBuyercounterPartyList} setFileLists={setFileLists} commentsData={commentsData} TotalFinanceAmountError={TotalFinanceAmountError}/>
                    : invoice_detail.party_details?.RF_seller === true && invoice_detail.program_type === ProgramType.RF ?
                      <RfBuyer invoice_detail={invoice_detail} getRfBuyerdataSource={getRfBuyerdataSource} getrfBuyercounterPartyList={getrfBuyercounterPartyList} FinanceAmountErr={FinanceAmountError} setFileLists={setFileLists} commentsData={commentsData}/>
                      : "No records"
            }
          </Card>
        </div>
      </div>
    </React.Fragment>

  );
};
export default Manual;
