import React, { useState, useEffect } from "react";
import { Radio, Spin } from "antd";
import moment from "moment";
import { socketInfoDetails } from "../../../redux/action";
import { useSelector } from "react-redux";
const LimitUtilization = ({ limitUtilizationData }: any) => {
  const [value, setValue] = React.useState("High");
  const [loading, setLoading] = useState(true)
  const [dataSource, setDataSource] = useState(limitUtilizationData ? limitUtilizationData?.High : [] as any);
  const socketData = useSelector(socketInfoDetails)
  const Data = limitUtilizationData ? limitUtilizationData : socketData?.payload?.socketInfo?.dashboard_data?.limit_utilization
  useEffect(() => {
    setDataSource(Data?.High)
    setTimeout(() => {
      setLoading(false)

    }, 1000)
  }, [])
  const onChange = (e: any) => {
    setValue(e.target.value);
    if (e.target.value === "Low") {
      setDataSource(Data?.Low)
    } else if (e.target.value === "Rare") {
      setDataSource(Data?.Rare)
    } else if (e.target.value === "Nil") {
      setDataSource(Data?.Nil !== "None" ? Data?.Nil : [])
    } else if (e.target.value === "High") {
      setDataSource(Data?.High)
    }

  };
  
  return (
    <div style={{ padding: "24px" }}>
      <h2>Limit Utilization</h2>
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={"High"}>High Utilization</Radio>
        <Radio value={"Low"}>Low Utilization</Radio>
        <Radio value={"Rare"}>Rare Utilization</Radio>
        <Radio value={"Nil"}>Nil</Radio>
      </Radio.Group>

      <div className='containerTable'>
        <div className="OuterDiv" style={{ width: value !== "Rare" ? "100%" : "100%" }}>
          <div className='HeadInnerDiv'>
            <h1 className='head' style={{ width: value !== "Rare" ? "40%" : "30%" }}>Buyer-Seller</h1>
            <h1 className='head' style={{ width: value !== "Rare" ? "40%" : "25%" }}>Limit (INR)</h1>
            <h1 className='head' style={{ width: value !== "Rare" ? "20%" : "20%" }}> Utilization %
            </h1>
            {value !== "Rare" ? "" : <h1 className='head' style={{ width: value !== "Rare" ? "14%" : "25%" }}>Last Utilization Date</h1>}
          </div>
        </div>
        {loading ? <Spin /> :
          <div className='OuterDiv'
            style={{
              overflow: "scroll", height: "19vh",
              paddingBottom: "23px", justifyContent: "unset"
            }}
          >
            {dataSource?.length !== 0 ? dataSource ?
              dataSource?.map((record: any, index: number) => {
                return (
                  <div key={index}
                    className={'InnerDiv'}
                    style={{ cursor: "default", width: value !== "Rare" ? "100%" : "unset" }}
                  >
                    <h5 className='body' style={{
                      width: value !== "Rare" ? "40%" : "30%"
                    }}>  {record?.anchor_party__name} - {record?.counter_party__name}  </h5>
                    <h5 className='body' style={{ width: value !== "Rare" ? "40%" : "25%" }}>  {record?.max_limit_amount}  </h5>
                    <h5 className='body' style={{ width: value !== "Rare" ? "20%" : "20%" }}> {record?.utilization_percent}
                    </h5>
                    {value !== "Rare" ? "" : <h5 className='body' style={{
                      width: value !== "Rare" ? "20%" : "25%"
                    }}>  {record?.last_utilization_date !== null ? moment(record.last_utilization_date).format("DD-MM-YYYY") : "-"}  </h5>}
                  </div>
                )
              })
              :
              Data?.length > 0 ?
                Data?.High?.map((record: any, index: number) => {
                  return (
                    <div key={index}
                      className={'InnerDiv'}
                      style={{ cursor: "default", width: value !== "Rare" ? "100%" : "unset" }}
                    >
                      <h5 className='body' style={{ whiteSpace: "nowrap", width: value !== "Rare" ? "40%" : "unset" }}>  {record?.anchor_party__name} - {record?.counterparty_id__name}  </h5>
                      <h5 className='body' style={{ width: value !== "Rare" ? "40%" : "unset" }}>  {record?.max_limit_amount}  </h5>
                      <h5 className='body' style={{ width: value !== "Rare" ? "20%" : "unset" }}> {record?.utilization_percent}
                      </h5>
                      {value !== "Rare" ? "" : <h5 className='body' style={{ whiteSpace: "nowrap", width: value !== "Rare" ? "14%" : "unset" }}>  {record?.last_utilization_date !== null ? moment(record.last_utilization_date).format("DD-MM-YYYY") : "-"}  </h5>}
                    </div>
                  )
                })
                : <div className="noDataText">No data</div>
              :
              <div className="noDataText">No data</div>

            }

          </div>
        }
      </div>

    </div>
  );
};

export default LimitUtilization;
