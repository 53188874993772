import ButtonContainer from "../detail/program/buttonContainer";
import InvoiceButtonContainer from "../detail/invoice/buttonContainer";
import images from "../../../assets/images";
import { Action, InterimState, OnboardingStatus, Status } from "../../../utils/enum/choices";
import { Button } from "antd";

interface Iprops {
  Data: any,
  onClickExit: any,
  commentsValue: any
  flag: any
  onClickAction: any
  buttonStatusData: any
}
const Breadcrumbs = ({ Data, onClickExit, commentsValue, flag, onClickAction, buttonStatusData }: Iprops) => {
  const { BreadcrumbArrow, LeftArrow } = images;
  const loginData = localStorage.getItem("login_detail") as any;

  return (
    <div className="breadcrumbMainContainer">
      {flag === "cponboarding"
        ?
        <>
          <div className="BreadcrumbHeadingTxt">
            {Data}

          </div>
        </>
        :
        <>
          {Data?.fromMenu === "Settings" ?
            <div className="BreadcrumbHeadingTxt">
              {Data?.fromMenu}
            </div>
            :
            <div className="BreadcrumbHeadingTxt">
              <span><img src={LeftArrow} alt="LeftArrow" onClick={onClickExit} className="breadcrumbBackArrow" /></span>
              {Data?.fromMenu === "sent_awap" ? "Sent for Sign" : Data?.fromMenu}

            </div>
          }
          <div>
            <div
              className="breadcrumbContainer"
            >
              {flag === "repaymentcustomer" || flag === 'ManageSCF_Bank' ? "" : <>
                <span onClick={onClickExit}>{Data?.fromMenu === "sent_awap" ? "Sent for Sign" : Data?.fromMenu}</span>
                {Data?.data?.type === "" ? "" : <img src={BreadcrumbArrow} alt="BreadcrumbArrow" />}

                {Data?.recordType !== "" ?
                  <>
                    <span onClick={onClickExit}>{Data?.recordType}</span>
                    {Data?.data?.type === "" ? "" : <img src={BreadcrumbArrow} alt="BreadcrumbArrow" />}
                  </> : ""}
                <span>{flag === "manual" || flag === "BulkUpload" || flag === "bulk upload" ? Data?.data?.type : Data?.data?.type === "Financerequest" ? "Invoice Detail" : Data?.data?.type === "Repayment List" ? "Repayment List" : Data?.data?.type === "bulk upload Edit" ? "bulkupload" : Data?.data?.type === "" ? "" : Data?.data?.type + " detail"}</span>
                {flag === "history" ?
                  <>
                    <img src={BreadcrumbArrow} alt="BreadcrumbArrow" />
                    <span onClick={onClickExit}>{"History"}</span>

                  </>
                  : ""
                }
              </>
              }
              {Data?.fromMenu === "sent" || Data?.fromMenu === "sent_awap" ?"" :
              flag === "program" ?
                <ButtonContainer Data={Data} commentsValue={commentsValue} />
                : flag === "financeRequest" ?
                  <div className="Button_Container">
                    {
                      Data.fromMenu === "sent" || Data.fromMenu === "sent_awap" ? "" :
                        Data.fromMenu === "draft" ?
                          <Button className="SaveButtonContainer"
                            onClick={() => onClickAction(Data.data.action)}
                            loading={buttonStatusData?.primary}
                            disabled={buttonStatusData?.default}
                            id={"financeRequest_release"}>
                            Release
                          </Button>
                          :
                          Data.data.next_available_transitions && Data.data.next_available_transitions.values.length > 0 ?
                            <>
                              <Button
                                className="SaveButtonContainer"
                                loading={buttonStatusData?.secondary}
                                disabled={buttonStatusData?.default}
                                onClick={() => onClickAction(Action.RETURN)}
                                id={"financeRequest_return"}
                              >
                                Return
                              </Button>
                              <Button
                                className="SaveButtonContainer"
                                loading={buttonStatusData?.primary}
                                disabled={buttonStatusData?.default}
                                onClick={() => onClickAction(Data.data.action)}
                                id={"financeRequest_release"}
                              >
                                Release
                              </Button>

                            </>
                            :
                            Data.data.interim_state === InterimState.REJECTEDBYB || Data.data.interim_state === InterimState.SETTLED || Data.data.interim_state === InterimState.FINANCED ? "" :
                              <>

                                <Button
                                  className="ExitButtonContainer"
                                  loading={buttonStatusData?.third}
                                  disabled={buttonStatusData?.default}
                                  onClick={() => onClickAction(Action.REJECT)}
                                  id={"financeRequest_reject"}
                                >
                                  Reject
                                </Button>
                                {Data?.data?.record_datas?.values?.[0]?.fields?.errors?.length === 0 ? "" :
                                  <Button
                                    className="ExitButtonContainer"
                                    loading={buttonStatusData?.secondary}
                                    disabled={buttonStatusData?.default}
                                    onClick={() => onClickAction(Action.RECHECK)}
                                    id={"financeRequest_recheck"}
                                  >
                                    Recheck
                                  </Button>}
                                <Button
                                  className="SaveButtonContainer"
                                  style={{ width: "145px" }}
                                  loading={buttonStatusData?.primary}
                                  disabled={buttonStatusData?.default}
                                  onClick={() => onClickAction(Action.APPROVE)}
                                  id={"financeRequest_approve"}
                                >
                                  Approve
                                </Button>

                              </>
                    }
                  </div>

                  : flag === "ManageSCF_Bank" ?
                    <div className="Button_Container">
                      <Button className="SaveButtonContainer" onClick={onClickAction}>Submit</Button>

                    </div>
                    : flag === "onboarding" ?
                      <div className="Button_Container">

                        {Data.fromMenu === "sent" || Data.fromMenu === "sent_awap" || Data.data.interim_state === InterimState.COMPLETED || Data.data.interim_state === InterimState.REJECTED ? "" : Data.fromMenu === "inbox" && Data.data.interim_state === InterimState.RETURNED
                          || (Data?.data && Data?.data?.next_available_transitions && Data?.data?.next_available_transitions?.values?.length > 0)

                          ?
                          <>
                            <Button
                              className="ExitButtonContainer"
                              onClick={() => onClickAction(Action.RETURN)}
                              loading={buttonStatusData?.secondary}
                              disabled={buttonStatusData?.default}
                              id="onboarding_return">
                              Return
                            </Button>
                            <Button
                              className="SaveButtonContainer"
                              onClick={() => onClickAction(Data.data.action)}
                              loading={buttonStatusData?.primary}
                              disabled={buttonStatusData?.default}
                              id="onboarding_release">
                              Release
                            </Button>

                          </> :
                          Data.fromMenu === "sent" || Data.fromMenu === "sent_awap" || Data.data.interim_state === InterimState.COMPLETED || Data.data.interim_state === InterimState.REJECTED || Data.data.interim_state === InterimState.APPROVED ? "" :
                            <>
                              <Button
                                className="ExitButtonContainer"
                                onClick={() => onClickAction(Action.REJECT)}
                                id="onboarding_reject"
                                loading={buttonStatusData?.secondary}
                                disabled={buttonStatusData?.default}
                              >
                                Reject
                              </Button>
                              <Button
                                className="SaveButtonContainer"
                                htmlType="submit"
                                onClick={() => onClickAction(Action.APPROVE)}
                                id="onboarding_approve"
                                loading={buttonStatusData?.primary}
                                disabled={buttonStatusData?.default}
                              >
                                Approve
                              </Button>
                            </>
                        }
                      </div>
                      : flag === "invoice" ?
                        <InvoiceButtonContainer Data={Data} commentsValue={commentsValue} />

                        : flag === "repayment" ?
                        Data?.fromMenu === "sent" || Data?.fromMenu === "sent_awap" ? "" :
                          <div className="Button_Container">
                            {Data.data.next_available_transitions && Data.data.next_available_transitions.values.length > 0 ?
                              <>
                                <Button
                                  className="SaveButtonContainer"
                                  loading={buttonStatusData?.secondary}
                                  disabled={buttonStatusData?.default}
                                  onClick={() => onClickAction(Action.RETURN)}
                                  id={"repayment_return"}
                                >
                                  Return
                                </Button>
                                <Button
                                  className="SaveButtonContainer"
                                  loading={buttonStatusData?.primary}
                                  disabled={buttonStatusData?.default}
                                  onClick={() => onClickAction(Data.data.action)}
                                  id={"repayment_release"}
                                >
                                  Release
                                </Button>
                              </>
                              : ""
                            }
                          </div>
                          : flag === "Current Date" ?
                            <div className="Button_Container">

                              <Button
                                className="SaveButtonContainer"
                                loading={buttonStatusData?.default}
                                disabled={buttonStatusData?.default}
                                onClick={() => onClickAction(Data.data.action)}
                                id={"currentDate_submit"}
                              >
                                Submit
                              </Button>


                            </div>
                            : flag === "Repayment" ?
                              <div className="Button_Container">

                                <Button
                                  className="SaveButtonContainer"
                                  loading={buttonStatusData?.default}
                                  disabled={buttonStatusData?.default}
                                  onClick={() => onClickAction(Data.data.action)}
                                  id={"repayment_release"}
                                >
                                  Submit
                                </Button>


                              </div>
                              : flag === "repaymentcustomer" ?
                                <div className="Button_Container">
                                  <Button
                                    className="SaveButtonContainer"
                                    onClick={onClickAction}
                                    id={"pendingInvoice_submit"}
                                    loading={buttonStatusData?.default}
                                    disabled={buttonStatusData?.default}
                                  >
                                    Submit
                                  </Button>
                                </div>
                                : flag === "pendingInvoice" ?
                                  <div className="Button_Container">
                                    <Button
                                      className="SaveButtonContainer"
                                      onClick={onClickAction}
                                      id={"pendingInvoice_submit"}
                                      loading={buttonStatusData?.primary}
                                      disabled={buttonStatusData?.default}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                  : flag === "bulkUploadEdit" ?
                                    <div className="Button_Container">
                                      <Button className="ExitButtonContainer" onClick={() => onClickAction("delete")} id="inv_deleteBtn"> Delete </Button>
                                      <Button className="SaveButtonContainer" onClick={() => onClickAction("SUBMIT")} id="inv_submitBtn"> Submit </Button>
                                    </div>
                                    : flag === "manual" ?
                                      window.location.pathname === "/Draft/Manual" ?
                                        <div className="Button_Container">
                                          <Button className="ExitButtonContainer" onClick={() => onClickAction("delete")} id="invManual_deleteBtn"> Delete </Button>
                                          <Button className="SaveButtonContainer" onClick={() => onClickAction("SUBMIT")} id="invManual_submitBtn"> Submit </Button>
                                        </div> :
                                        <div className="Button_Container">
                                          <Button className="ExitButtonContainer" onClick={() => onClickAction("save")} id="invManual_saveBtn"> Save </Button>
                                          <Button className="SaveButtonContainer" onClick={() => onClickAction("SUBMIT")} id="invManual_submitBtn"> Submit </Button>
                                        </div>
                                      : flag === "cponboarding" ?
                                        <Button
                                          onClick={onClickAction}
                                          className="onboardingSubmit"
                                          disabled={JSON.parse(loginData).onboarding_status === OnboardingStatus.STB && JSON.parse(loginData).status === Status.IN_PROGRESS ? true : false}
                                          id="onboarding_submit"
                                        >Submit</Button>
                                        : flag === "BulkUpload" ?
                                          <div className="Button_Container">
                                            <Button className="SaveButtonContainer" onClick={onClickAction}
                                              loading={buttonStatusData?.uploadBtnLoader}
                                            >
                                              Upload
                                            </Button>

                                          </div>
                                          : flag === "bulk upload" ?
                                            <div className="Button_Container">

                                              {commentsValue === true ?
                                                <Button className="SaveButtonContainer" onClick={onClickAction} loading={buttonStatusData?.default}>Create Invoice</Button>
                                                :
                                                <Button className="SaveButtonContainer" onClick={onClickAction} loading={buttonStatusData?.default}>Upload</Button>
                                              }
                                            </div>

                                            : flag === "Enquiry" ?
                                              <div className="Button_Container">
                                                <Button className="SaveButtonContainer" onClick={onClickExit}>Back</Button>

                                              </div>

                                              : ""}

            </div>
          </div>
        </>}
    </div>
  )
}
export default Breadcrumbs