import { Row, Col, Input, Card } from "antd";
import moment from "moment";
import images from "../../assets/images"
import sendIcon from "../../assets/images/send.png";
import { useEffect, useState, useRef } from "react";
import { chatSocketList } from "../../redux/action"
import { useDispatch, useSelector } from "react-redux";
import chatsocketurl from "../../utils/config/url/chatSocket";

const Conversation = ({ configId, subjectsData, selectedUsers, selectedMainUsers, selectDataMembers, closeConversation }: any) => {

  const [messageValue, setMessageValue] = useState("");
  const updatedMsg = useRef([])

  const loginData = localStorage.getItem("login_detail") || "";
  const logindata = JSON.parse(loginData);

  const loginUser = localStorage.getItem("login_email") || ""
  const { BackArrow_White } = images;
  const [selectedDatasMsgs, setSelectedDatasMsgs] = useState([]);
  const [page, setPage] = useState(1);
  const wschat = useRef(null) as any;
  const chatListData = useSelector(chatSocketList);
  const scrollto = useRef(null) as any;
  console.log("chatSocketList", chatListData)
  const dispatch = useDispatch();
  const messages = () => {


    wschat.current = chatsocketurl;

    console.log("max max body", wschat.current, wschat)
    const data = {
      "type": "RECEIVE",
      "config_id": configId,
      "members": selectDataMembers,
      "page": page ? page : 1,
      email_id: logindata?.email
    }
    console.log("max max body", data)

    wschat.current.send(JSON.stringify(data));
    wschat.current.onmessage = (e: any) => {
      console.log("return max max enetred onmessage", e.data)
      const message = JSON.parse(e.data);
      console.log("enetred onmessage e msg message", message, message?.data?.[0])
      setSelectedDatasMsgs(message?.data?.[0]?.message)


    };

  }
  useEffect(() => {
    console.log("useefect")
    wschat.current = chatsocketurl;

    const data = {
      "type": "RECEIVE",
      "config_id": configId,
      "members": selectDataMembers,
      "page": page ? page : 1,
      email_id: logindata?.email
    }

    wschat.current.send(JSON.stringify(data));
    wschat.current.onmessage = (e: any) => {
      console.log("return max max enetred onmessage", e.data)
      const message = JSON.parse(e.data);
      console.log("enetred onmessage e msg message", message, message?.data?.[0])
      setSelectedDatasMsgs(message?.data?.[0]?.message)

    };

  }, [])

  const messageSending = () => {
    console.log("DomainData send from conver")

    console.log("selectedUsers", selectedUsers, "selectDataMembers", selectDataMembers)
    const ws = chatsocketurl
    const data = {

      "type": "SEND",
      "config_id": configId,
      "members": selectDataMembers,
      "subject": "",
      "party": [selectedMainUsers, logindata.party],
      "message": [
        {
          "text": updatedMsg.current,
          "sender": loginUser,
          "is_read": [logindata?.email]
        }
      ]
    }
    console.log("DomainData send from conver", data)

    console.log("max xx body", data)
    ws.send(JSON.stringify(data));
    ws.onmessage = (e: any) => {
      console.log("return max max enetred onmessage", e.data)
      const message = JSON.parse(e.data);

      console.log("enetred onmessage e msg message", message, message?.data?.[0])
      setSelectedDatasMsgs(message?.message_data?.[0]?.message)
      dispatch(chatSocketList(message));
    }
    setMessageValue("")


  }
  const onchangeMessage = (e: any) => {
    setMessageValue(e.target.value)
    updatedMsg.current = e.target.value
  }
  { console.log("return 123") }
  const onclickMore = () => {
    console.log("onclickMore", page)
    setPage(page + 1)

    messages()

  }

  useEffect(() => {
    if (scrollto.current) {
      scrollto.current.scrollTop = scrollto.current.scrollHeight;
    }
  }, [selectedDatasMsgs]);
  return (
    <>
      {console.log("return")}
      <div className="chat-window-wrapper msg" >
        <div style={{ position: "fixed", backgroundColor: "white", zIndex: 1, height: "12vh" }}>
          <div onClick={() => closeConversation(false)} className="BackBtn"><img src={BackArrow_White} alt="Back" />Back</div>
          <Row gutter={24}>
            <Col span={12}>
              <div>party</div>
              <Input readOnly defaultValue={selectedMainUsers} />
            </Col>
            <Col span={12}>
              <div>Members</div>
              <Input readOnly defaultValue={selectDataMembers} />
            </Col>
          </Row>
        </div>

        {subjectsData !== undefined ? subjectsData.length > 0 ?
          <Card style={{ padding: "4px", marginTop: "15px", marginBottom: "15px" }}>
            {subjectsData?.map((item: any) => {
              return (
                <div>
                  {item.label} <span> - </span> <span>{item.xpath}</span>

                </div>
              )
            })}
          </Card>
          : null : null
        }
        <div style={{ float: "right", fontWeight: 700, marginTop: "13vh", backgroundColor: "white", cursor: "pointer" }} onClick={onclickMore}>More</div>
        <div
          style={{
            marginTop: "12vh",
            height: '250px',
            overflowY: 'scroll',
            padding: '10px',
            flexDirection: "column",
            display: "flex",
            transition: "all 0.5s linear",
            scrollBehavior: "smooth"

          }}
          ref={scrollto}
        >
          {console.log("selectedDatasMsgs", selectedDatasMsgs)}
          {selectedDatasMsgs ? selectedDatasMsgs?.map((playerChat: any, index: any) => {
            return (
              <div className={"chatwrapperplayer"} id="scrollContainer" key={index}>
                <div className={loginUser === playerChat.sender ? "chatselfplayer" : "chatoppplayer"}  >

                  <div
                    className={loginUser === playerChat.sender ? "chat-player-selfword" : "chat-player-oppword"}
                  >
                    <p style={{ fontSize: "12px" }}>
                      {playerChat.sender}
                      <p style={{ fontSize: "16px" }}
                      >

                        {playerChat.text}
                      </p>
                    </p>
                  </div>

                  <p style={{ fontSize: "10px" }}>{moment(playerChat.time).format("lll")}
                  </p>
                </div>
              </div>
            )
          })

            :
            null}

        </div>
      </div>
      <div className={"input"}>
        {console.log("text send")}
        <Input type="text" className={"msg_send"} id="msg_send" placeholder="New Message" onChange={onchangeMessage} value={messageValue} />
        <div className={"send"} id="reply"
          onClick={messageSending}
        >
          <img src={sendIcon} alt="sendIcon" />
        </div>
      </div>
    </>
  )
}
export default Conversation;
