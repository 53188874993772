export const ErrorMessage = {
    "FCA": "File couldn't be attached, Please exit and try again.",
    "PAF": "Please attach the file.",
    "KFAC": "Kindly fill account Number and Customer Id",
    "PUM": "Please Upload all Mandatory Documents",
    "PUKYC": "Please update the KYC mandatory Documents",
    "PMF": "Please fill the Mandatory fields",
    "IRD": "Invalid Remittance Details",
    "INFU": "Invalid File Uploads",
    "INKYCD": "Invalid KYC Documents",
    "PUD": "Please Upload the Document",
    "PFD": "Please fill all the details",
    "PFCD": "Please fill the correct details",
    "PSIRT": "Please select Interest Rate Type",
    "LIS": "Logged in successfully",
    "SB": "Saved basicdetails",
    "SC": "Saved counterparty",
    "UDS": "User deleted successfully",
    "TCS": "Transaction authorization created successfully",
    "TUS": "Transaction authorization UPDATED successfully",
    "UTCS": "User Transaction authorization created successfully",
    "UTUS": "User Transaction authorization updated successfully",
    "UPNE": "user with this phone number already exists",
    "INTRANS": "Invalid Transition",
    "PCR": "Please choose Repayment option",
    "PFOAMT": "Please fill the other amount field",
    "PFC": "Please fill comments",
    "OPB": "Onboarding is on process by Bank.",
    "PUDE": "Please check the user details, already exists",
    "UNF": "User not found",
    "INA": "Invalid Action",
    "DUNA": "Duplicate file upload is not allowed"
}



export const FieldErrorMessages = {
    //PROGRAM
    "LIMITCURR": "Input your Limit Currency",
    "lIMITAMT": "Input your Limit amount",
    "EXPIRYDTE": "Input your Expirydate",
    "INVAMT": "Input your Max Inv. amt!",
    "INVCURR": "Input your Inv Currency",
    "INVAMTE": "Max Invoice Amount cannot be greater than Limit Amount",
    "INVPCT": "Input your Max inv Percent!",
    "INVPCTE": "Max Invoice PCT cannot be greater than 100",
    "MAXTENOR": "Input your max tenor!",
    "GRACEPERIOD": "Input grace period!",
    "GRACEPERIODE": "Grace Period cannot be greater than Max Tenor",
    "INTTYPE": "Input your interest type!",
    "INTRATETYPE": "Input your interest rate type!",
    "MARGIN": "Input your margin!",
    "INTPAIDBY": "Input your Interest paid by!",
    "OVERDUEINT": "Input your Overdue Interest Rate!",
    "INTDEDUCTION": "Input your Interest deduction stage!",
    "REPAYMENTACC": "Invalid Repayment Account!",
    "UPLOADBUYER": "Input your upload by buyer!",
    "AUTODEBITINV": "Input your AutoDebit for invoices!",
    "BUYERREBATE": "Input your rebate for buyer!",
    "INSBACKED": "Input your Backed by insurance!",
    "BUYERREBATEPCT": "Input your buyer rebate PCT!",
    "FEE": "Input your fee",
    "AUTOFINANCEINV": "Input your auto finance invoices!",
    "REFUNDINT": "Input your refund interest!",
    "COMMENT": "Input your comment!",

    "LIMITAMTE": "Cannot be greater than ",
    "INVOICEAMTE": "Cannot be greater than ",
    "TENORE": "Cannot be greater than ",
    "PCTE": "Cannot be greater than ",
    "GRACEE": "Cannot be greater than ",

    //PARTY
    "PARTYID": "Please input your counter party id!",
    "PARTYNAME": "Please input your counter party name!",
    "PARTYEMAIL": "Please input your counter party email!",
    "PARTYPHONENO": "Please input your counter party mobile!",
    "PHONENOE": "user with this phone number already exists",
    "EMAILE": "user with this email id already exists",
    "ADDRESS": "Please input your address!",
    "CITY": "Please input your City!",
    "COUNTRY": "Please input your country!",
    "LIMIT": "Please input your limit",
    "EXPIRYDATE": "Invalid Expiry Date",
    "INVALIDINVAMT": "Invalid Max Invoice Amount",
    "INVALIDINVPCT": "Invalid Max Invoice PCT",
    "INVALIDMAXTENOR": "Invalid Max Tenor",
    "INVALIDGRACEPERIOD": "Invalid Grace Period",
    "INVALIDMARGIN": "Invalid Margin",
    "CPUID": "Counter party Id should not contain underscores",

    //ACCOUNT
    "ACCNO": "Please input your account number!",
    "CUSID": "Please input your customerId!",
    "ACCCUS": "Please input your account number / customerId!",

    "EMAIL": "Please input your email!",
    "MOBILENO": "Please input your mobile!",

    //KYC
    "DOCNAME": "Please input your DocumentName!",
    "DOCUPLOADED": "Document already uploaded",
    "DOCINPUT": "Please input your DocumentInputField!",
    "DOCUPLOAD": "Please Upload your Document!",


    //REMITTENCE
    "ACCNUM": "Please enter valid account Number",
    "ACCNAME": "Please enter valid Account name",
    "BANKACC": "Please enter valid Account with bank",
    "BIC": "Please enter valid Bic Code",
    "INVALIDBIC": "Invalid BIC code. BIC should either be 8 or 11 characters!",
    "IFSC": "Please enter valid ifscCode",
    "INVALIDIFSC": "Invalid IFSC code!",
    "IBAN": "Please enter valid IbanCode",
    "COMMENTS": "Please enter valid comments",

    //LOGIN
    "OTP": "Please input your OTP!"

}


