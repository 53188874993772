import { useState, useEffect } from "react";
import { Input, Select, DatePicker, Form, InputNumber, Row, Col } from "antd";
import httpClient from "../../../../utils/config/core/httpClient";
import baseurl from "../../../../utils/config/url/base";
import moment from "moment";
import { UploadImage } from "../../../common/UploadImage/manualInvoiceUpload";
import images from "../../../../assets/images";
import { useNavigate } from "react-router";
import { ResponseStatus } from "../../../../utils/enum/choices";

const Header_APF_Buyer = [{
  Id: "Seller Id", Name: "Seller Name", InvNo: "Inv No", InvDate: "Inv Date", InvAmt: "Inv Amount", DueDate: "Due Date", FinanceAmount: "Finance Amount", FinanceCurrency: "Financing Currency", SettlementCurrency: "Settlement Currency", autoFinance: "Auto Finance", attached: "Attached"
}]
const ApfSeller = ({ invoice_detail, getApfBuyerdataSource, getApfBuyercounterPartyList, setFileLists, commentsData }: any) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const Navigate = useNavigate()

  const { DeleteIcon, DatePickerImg, DropdownIcon, ErrorImage } = images
  const [counterpartyId, setCounterpartyId] = useState([] as any);
  const [MaxInvoicePercent, setMaxInvoicePercent] = useState("");
  const [counterPartyList, setCounterPartyList] = useState([] as any);
  const [sellerName, setSellerName] = useState("");

  const [sellerId, setSellerId] = useState([]);
  const [currencyList, SetCurrencyList] = useState([]);
  const [apfBuyerdataSource, setApfBuyerDataSource] = useState([
    {
      SellerID: "" as any,
      SellerName: sellerName as any,
      InvoiceNo: "" as any,
      InvoiceDate: "" as any,
      InvoiceCurrency: "" as any,

      InvoiceAmount: 0 as any,
      DueDate: "" as any,
      FinancingCurrency: "" as any,
      SettlementCurrency: "" as any,
      counter_party: counterpartyId as any,
      FinanceAmount: 0 as any,
      ProgramID: invoice_detail?.id,
      error: "" as any
    }])
  const [FinanceAmountError, setFinanceAmountError] = useState("")

  getApfBuyerdataSource(apfBuyerdataSource)
  getApfBuyercounterPartyList(counterPartyList)
  const apfBuyerAppendData = () => {
    const newInput = {
      SellerID: "",
      SellerName: "",
      InvoiceNo: "",
      InvoiceDate: "",
      InvoiceAmount: 0,
      InvoiceCurrency: "",
      DueDate: "",
      FinancingCurrency: 0,
      SettlementCurrency: 0,
      counterparty_id: counterpartyId,
      FinanceAmount: 0,
      ProgramID: invoice_detail?.id
    } as any;
    setApfBuyerDataSource([...apfBuyerdataSource, newInput]);

  }
  const selectedFiles = (value: any) => {
    setFileLists(value)
  }
  useEffect(() => {
    httpClient
      .getInstance()
      .get(`${baseurl}api/pairing/?pg_id=${invoice_detail?.id}&data_type=INVOICE`)
      .then((resp: any) => {
        setCounterPartyList(resp.data.data);
        setMaxInvoicePercent(resp.data.data[0].max_invoice_percent)
        const partyName = [] as any;
        resp.data.data.map((items: any) => {
          return partyName.push(items.buyer_name);
        });

        const sellerIds = [] as any;
        resp.data.data.map((items: any) => {
          return sellerIds.push(items.counterparty_uid);
        });
        setSellerId(sellerIds);
      })
      .catch(() => {
        Navigate("/Notfound")
      })




    httpClient
      .getInstance()
      .get(`${baseurl}api-auth/currency/`)
      .then((resp: any) => {
        SetCurrencyList(resp.data.data);
      })
      .catch(() => {
        Navigate("/Notfound")
      })

  }, [])
  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };
  const disabledInvoiceDate = (current: any) => {
    return current && current > moment().endOf("day");
  }
  const onRowDelete = (value: any) => {
    const apfbuyerlist = apfBuyerdataSource
    apfBuyerdataSource.map((data: any, index: any) => {

      if ((value.SellerID === data.SellerID) && (value.InvoiceNo === data.InvoiceNo)) {
        apfbuyerlist.splice(index, 1);
      }
      setApfBuyerDataSource(apfBuyerdataSource)
    })
  }
  const onFinish = (values: any) => {
    console.log("values apf buyer login", values)
  }

  const onFinishFailed = (values: any) => {
    console.log("values apf buyer login failed error message", values)
  }
  // useEffect(() => {
  //   TotalFinanceAmountCalculation()
  //   // PartyFinanceAmountCalculation()
  // }, [apfBuyerdataSource])


  const totalFinanceAmount = apfBuyerdataSource.reduce((accumulator, data) => {
    // Use the unary plus (+) to convert string to number if FinanceAmount is in string format
    const financeAmount = +data?.FinanceAmount || 0;

    // Add the financeAmount to the accumulator
    return accumulator + financeAmount;
  }, 0);

  const TotalFinanceAmountCalculation = () => {
    // apfBuyerdataSource.map((data: any) => {
    // console.log("finance amt total",data?.FinanceAmount,"totalFinanceAmount",totalFinanceAmount,totalFinanceAmount.toFixed(2))
    // })
    httpClient
      .getInstance()
      .get(`${baseurl}api/program/${invoice_detail?.program_id}/?finance_amount=${totalFinanceAmount.toFixed(2)}`)
      .then((resp: any) => {
        console.log("resp", resp)
        if (resp.data.status === ResponseStatus?.FAILURE) {
          setFinanceAmountError(resp.data.data)
        } else {
          setFinanceAmountError("")
        }
      })
  }

  const counterPartyTotals: Record<string, number> = {};
  let finamt: any = {};
  counterPartyList.forEach((counterPartyItem: any) => {
    // Initialize total amount for the counterparty
    console.log("counterPartyItem", counterPartyItem)
    console.log("counterPartyItem", counterPartyItem?.id)
    counterPartyTotals[counterPartyItem?.counterparty_uid] = 0;
    finamt = 0;

    apfBuyerdataSource.forEach((data: any) => {
      if (counterPartyItem?.counterparty_uid === data?.SellerID) {
        // Use the unary plus (+) to convert string to number if FinanceAmount is in string format
        const financeAmount = +data?.FinanceAmount || 0;

        // Add financeAmount to the total for the counterparty
        counterPartyTotals[counterPartyItem?.counterparty_uid] += financeAmount;
        finamt += financeAmount;
        console.log("Counterparty Totals: 22", counterPartyTotals, "finamt", finamt, "counterPartyTotals", counterPartyTotals?.cp1s);

        // httpClient
        // .getInstance()
        // .get(`${baseurl}api/pairing/${counterPartyItem?.id}/?finance_amount=${totalFinanceAmount.toFixed(2)}`)
        // .then((resp:any)=>{
        //   console.log("resp",resp)
        //   if(resp.data.status === ResponseStatus?.FAILURE){
        //     setFinanceAmountError(resp.data.data)
        //   }else{
        //     setFinanceAmountError("")
        //   }
        // })


      }
    });
    console.log("Counterparty Totals: 11", counterPartyTotals, "finamt", finamt);


  });
  console.log("Counterparty Totals:", counterPartyTotals, "finamt", finamt);

  const transformedData = Object.entries(counterPartyTotals).map(([key, value]) => ({
    key: key,
    value: value
  }));
  
  console.log("Transformed Data:", transformedData);


  const PartyFinanceAmountCalculation = () => {
    console.log("invoice_detail party", invoice_detail, "apfBuyerdataSource", apfBuyerdataSource, "counterPartyList", counterPartyList)

console.log("counterPartyTotals",counterPartyTotals,counterPartyList)

//  apfBuyerdataSource.map((data: any) => {
 
// })

    counterPartyList.map((partyitem: any) => {
      apfBuyerdataSource.map((data: any) => {
console.log("partyitem",partyitem)
        console.log("data",data?.SellerID === counterPartyTotals[0])

        transformedData.map((item:any)=>{
          console.log("counterPartyTotals",data?.SellerID === item.key)
          console.log("data",data)
              if(data?.SellerID === item.key && partyitem.counterparty_uid === item.key){
                console.log("if value",item.value)
                httpClient
                .getInstance()
                .get(`${baseurl}api/pairing/${partyitem?.id}/?finance_amount=${item.value.toFixed(2)}`)
                .then((resp: any) => {
                  console.log("resp", resp)
                  if (resp.data.status === ResponseStatus?.FAILURE) {
                    setFinanceAmountError(resp.data.data)
                  } else {
                    setFinanceAmountError("")
                  }
                })
              }
            })

        // const amt = []
        // if (item?.counterparty_uid === data?.SellerID) {
        //   console.log("invoice_detail party map", data?.FinanceAmount)
        //   amt.push(data?.FinanceAmount)
        // }
        // console.log("amt",amt)
       

      })
    })

    
    TotalFinanceAmountCalculation()
  }

  // const invoiceNumberApiCall = (data:any) => {
  //   console.log("any",data,"invoice_detail",invoice_detail)

  //   httpClient
  //     .getInstance()
  //     .get(`${baseurl}api/invoice/upload/?invoice_no=${data?.InvoiceNo}&party=${data?.SellerName}&program_type=${invoice_detail?.program_type}`)
  //     .then((resp:any)=>{
  //       console.log(resp,"respp")
  //     })

  // }
  const invoiceNumberApiCall = (data: any) => {
console.log("data",data)
    const apiEndpoint = `${baseurl}api/invoice/upload/?invoice_no=${data?.InvoiceNo}&party=${data?.SellerName}&program_type=${invoice_detail?.program_type}`;
const body = {
  counter_party:data?.SellerName,
  program_type:invoice_detail?.program_type,
invoice_no:data?.InvoiceNo
}
    httpClient
      .getInstance()
      .post(`${baseurl}api/invoice/upload/check/`,body)
      .then((resp: any) => {
        if (resp?.data?.status === ResponseStatus?.FAILURE) {
          // Update error state for the specific row
          setApfBuyerDataSource((prevData) =>
            prevData.map((item) =>
              item.InvoiceNo === data.InvoiceNo ? { ...item, error: resp?.data?.data } : item
            )
          );
          console.log("error3")
        } else {
          console.log("error4")
          // Clear the error state for the specific row if the API call is successful
          setApfBuyerDataSource((prevData) =>
            prevData.map((item) =>
              item.InvoiceNo === data.InvoiceNo ? { ...item, error: '' } : item
            )
          );
        }
      });
  };


  // const invoiceNumberApiCall = (data: any, checkDuplicate = false) => {
  //   const apiEndpoint = `${baseurl}api/invoice/upload/?invoice_no=${data?.InvoiceNo}&party=${data?.SellerName}&program_type=${invoice_detail?.program_type}`;

  //   httpClient
  //     .getInstance()
  //     .get(apiEndpoint)
  //     .then((resp: any) => {
  //       if (resp?.data?.status === ResponseStatus?.FAILURE) {
  //         // Update error state for the specific row only if checking for duplicate
  //         if (checkDuplicate) {
  //           setApfBuyerDataSource((prevData) =>
  //             prevData.map((item) =>
  //               item.InvoiceNo === data.InvoiceNo ? { ...item, error: resp?.data?.data } : item
  //             )
  //           );
  //           console.log("Setting error:", resp?.data?.data);
  //         }
  //       } else {
  //         // Clear the error state for the specific row if the API call is successful
  //         setApfBuyerDataSource((prevData) =>
  //           prevData.map((item) =>
  //             item.InvoiceNo === data.InvoiceNo ? { ...item, error: '' } : item
  //           )
  //         );
  //         console.log('Clearing error for InvoiceNo:', data.InvoiceNo);
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.error('Error during API call:', error);
  //       // Handle any error, and maybe set an error state if needed
  //     });
  // };

  const handleInvoiceNumberChange = (event: any, index: number) => {
    const arr = [...apfBuyerdataSource];
    const newValue = event.target.value;

    // Check for duplicate invoice number
    const isDuplicate = arr.slice(0, index).some((item) => item.InvoiceNo === newValue);

    // Update the current row's invoice number
    arr[index].InvoiceNo = newValue;
    console.log("isDuplicate", isDuplicate)
    if (isDuplicate) {
      // Set an error for the current row
      arr[index].error = 'InvoiceNo. already exists';
    } else {
      // Clear the error for the current row
      arr[index].error = '';
    }

    setApfBuyerDataSource(arr);

    // Perform API call only if not checking for duplicates
    // if (!isDuplicate) {
    //   invoiceNumberApiCall(arr[index]);
    // }
  };

  // const handleBlur = (record: any) => {
  //   invoiceNumberApiCall(record, false);
  // };

  return (
    <div>
      {console.log("apf buyer", invoice_detail, invoice_detail?.program_id, "apfBuyerdataSource", apfBuyerdataSource, "FinanceAmountError", FinanceAmountError)}
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateTrigger="onBlur"
      >{
          FinanceAmountError ?

            <Row gutter={24} style={{ width: "80%", backgroundColor: "#FDF1F1", borderLeft: "5px solid #E43E3E", padding: "10px", margin: "0" }}>
              <Col span={2} style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                <img src={ErrorImage} alt="errorImage" width="35px" />
              </Col>
              <Col span={20}>
                {/* <div style={{ color: "black", fontSize: "16px", fontFamily: "Poppins", fontWeight: 500, paddingBottom: "10px" }}> Automatic Finance Rejection: Please review error details</div> */}

                {FinanceAmountError}
              </Col>
            </Row>
            : ""}
        {/* <div style={{ height: "30px", color: "#485B6C" }}>
                          <div style={{ width: "8px", height: "8px", borderRadius: "25px", backgroundColor: "#EB4F50", display: "inline-block" }}></div>
                           <>{FinanceAmountError}</>
                        </div> */}
        <div className='containerTable'>
          <div className="OuterDiv">
            {
              Header_APF_Buyer && Header_APF_Buyer.map((item: any, index: any) => {
                return (
                  <div key={index} className='HeadInnerDiv'>
                    <h1 className='head' style={{ width: "11%" }}>{item.Id}</h1>
                    <h1 className='head' style={{ width: "10%" }}>{item.Name}</h1>
                    <h1 className='head' style={{ width: "10%" }}>{item.InvNo}</h1>
                    <h1 className='head' style={{ width: "12%" }}>{item.InvDate}</h1>
                    <h1 className='head' style={{ width: "20%" }}>{item.InvAmt}</h1>
                    <h1 className='head' style={{ width: "10%" }}>{item.DueDate}</h1>
                    <h1 className='head' style={{ width: "19%" }}>{item.FinanceAmount}</h1>
                    <h1 className='head' style={{ width: "6%" }}>{item.attached}</h1>
                  </div>
                )
              })

            }
          </div>
          <div className='OuterDiv'>
            {

              apfBuyerdataSource && apfBuyerdataSource.map((record: any, index: number) => {
                return (

                  <div key={index} className='InnerDiv' >

                    <h5 className='body' style={{ width: "12%", fontSize: "10px" }} id={`invApfBuyer_sellerId${index}`}>

                      {
                        sellerId.length > 1 ?
                          <>
                            {console.log("")}
                            <Select
                              placeholder="Select"
                              showSearch
                              optionFilterProp="children"
                              defaultValue=""
                              style={{
                                width: 115,
                                color: "#000000",
                                fontSize: "14px"
                              }}
                              suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}
                              onSelect={(event: string) => {
                                const arr = [...apfBuyerdataSource];
                                arr[index].SellerID = event;
                                setApfBuyerDataSource(arr);
                                const names: any[] = [];
                                let values: any;
                                counterPartyList.map((data: any) => {
                                  if (event === data.counterparty_uid) {

                                    return (
                                      (values = {
                                        id: data.counterparty_uid,
                                        name: data.counterparty_id,
                                      }),
                                      names.push(values),
                                      setSellerName(data.counterparty_id),
                                      setCounterpartyId(data.counterparty_id),
                                      setMaxInvoicePercent(data.max_invoice_percent),
                                      (arr[index].SellerName = data.counter_party_name),
                                      (arr[index].counter_party = data.counter_party),
                                      setApfBuyerDataSource(arr)
                                    );
                                  }
                                  return true;
                                });

                              }}
                            >
                              {sellerId.map((partyList: any) => {
                                return (
                                  <Option value={partyList} key={partyList} id={`invApfBuyer_${partyList}`}>
                                    {partyList}
                                  </Option>
                                );
                              })}
                            </Select>
                          </>
                          : <div style={{ fontSize: "14px" }}>{sellerId[0]}</div>
                      }
                    </h5>
                    <h5 className='body' style={{ width: "10%" }} id={`invApfBuyer_sellerName${index}`}>
                      <div>{sellerId.length === 1 ? counterPartyList[0]?.counter_party_name : record.SellerName}</div>
                    </h5>
                    <h5 className='body' style={{ width: "10%" }} id="invApfBuyer_InvoiceNumber">
                      <div >
                      <Input
                          id={`invApfBuyer_InvoiceNumber${index}`}
                          className="InputContainer"
                          onChange={(event: any) => {
                            const arr = apfBuyerdataSource;
                            arr[index].InvoiceNo = event.target.value;
                            if (sellerId.length === 1) {
                              arr[index].SellerID = sellerId[0];
                              arr[index].SellerName = counterPartyList[0]?.counter_party_name;
                              arr[index].counter_party = counterPartyList[0]?.counter_party;
                              setMaxInvoicePercent(counterPartyList?.[0]?.max_invoice_percent)

                            }
                            console.log("data error",index > 0 && arr[index - 1].InvoiceNo === event.target.value,"1",index > 0 && arr[index - 1].InvoiceNo ,"2", event.target.value)
                            if (index > 0 && arr[index - 1].InvoiceNo === event.target.value) {
                              // Set an error for the current row
                              console.log('Setting error:', event.target.value);
                              arr[index].error = 'InvoiceNo. already exists';
                            } else {
                              // Clear the error for the current row
                              console.log('Clearing error:', event.target.value);
                              arr[index].error = '';
                            }

                            setApfBuyerDataSource(arr);
                          }}
                          // onChange={(event: any) => {
                          //   const arr = apfBuyerdataSource;
                          
                          //   // Check if the current invoice number is duplicated
                          //   const isDuplicate = arr.filter((item, i) => i !== index && item.InvoiceNo === event.target.value).length > 0;
                          
                          //   // Rest of your code remains the same
                          
                          //   if (isDuplicate) {
                          //     // Set an error for the current row
                          //     console.log('Setting error:', event.target.value);
                          //     arr[index].error = 'InvoiceNo. already exists';
                          //   } else {
                          //     // Clear the error for the current row
                          //     console.log('Clearing error:', event.target.value);
                          //     arr[index].error = '';
                          //   }
                          
                          //   setApfBuyerDataSource(arr);
                          // }}

                        onBlur={()=> invoiceNumberApiCall(record)}

                        />
                      </div>
{/* {console.log("apfBuyerdataSource",apfBuyerdataSource)} */}

                    {console.log("error record",record.error)}

                    {record.error && <div className="errorMsg" style={{ color: 'red',fontSize:"10px" }}>{record.error}</div>}
                      {/* <Input
                        id={`invApfBuyer_InvoiceNumber${index}`}
                        className="InputContainer"
                        onChange={(event: any) => {
                          const arr = apfBuyerdataSource;
                          // Check for duplicate invoice numbers
                          const isDuplicate = arr.filter((item, i) => i !== index && item.InvoiceNo === event.target.value).length > 0;

                          if (isDuplicate) {
                            // Set an error for the current row
                            console.log('Setting error:', event.target.value);
                            arr[index].error = 'InvoiceNo. already exists';
                          } else {
                            // Clear the error for the current row
                            console.log('Clearing error:', event.target.value);
                            arr[index].error = '';
                          }

                          // Update the state
                          setApfBuyerDataSource(arr);
                        }}
                        onBlur={() =>invoiceNumberApiCall(record)}
                      />
                      {record.error && <div className="errorMsg" style={{ color: 'red', fontSize: "10px" }}>{record.error}</div>} */}
                    </h5>
                    <h5 className='body' style={{ width: "12%" }} id="invApfBuyer_InvoiceDate">

                      <DatePicker
                        id={`invApfBuyer_InvoiceDate${index}`}
                        suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}
                        format="DD-MM-YYYY"
                        onChange={(event: any) => {
                          const arr = apfBuyerdataSource;
                          arr[index].InvoiceDate = moment(event._d).format("DD-MM-YYYY");
                          setApfBuyerDataSource(arr);
                        }}
                        style={{ width: 100 }}
                        className="InputContainer"
                        disabledDate={disabledInvoiceDate}
                      />

                    </h5>

                    <h5 className='body' style={{ width: "20%" }} id={`invApfBuyer_Invoice${index}`}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ paddingRight: "10px" }} id={`invApfBuyer_InvoiceCurrency${index}`}>
                          {console.log("")}

                          <Select
                            placeholder="Select"
                            showSearch
                            style={{ width: 100, fontSize: "14px" }}
                            onSelect={(event: string) => {
                              const arr = [...apfBuyerdataSource];
                              arr[index].InvoiceCurrency = event;
                              arr[index].FinancingCurrency = event;
                              setApfBuyerDataSource(arr);

                            }}
                            suffixIcon={<img src={DropdownIcon} alt="DropdownIcon" />}>
                            {currencyList &&
                              currencyList.map((item: any) => {
                                return (
                                  <Option
                                    value={item.description}
                                    id={item.description}
                                    key={item.description}
                                  >
                                    {item.description}
                                  </Option>
                                );
                              })}
                          </Select>
                        </span>
                        <InputNumber
                          className="InputContainer"
                          disabled={!record.SellerID}
                          id={`invApfBuyer_InvoiceAmount${index}`}
                          onChange={(event: any) => {
                            const arr = [...apfBuyerdataSource];
                            arr[index].InvoiceAmount = parseInt(event);
                            setApfBuyerDataSource(arr);
                            const financeAmt = parseInt(event) * (parseInt(MaxInvoicePercent) / 100)
                            arr[index].FinanceAmount = financeAmt.toFixed(2)
                          }}
                          formatter={(value, e) => {
                            if (value === "" || isNaN(parseFloat(value))) {
                              return "";
                            }
                            return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                              : value
                          }}
                          onBlur={PartyFinanceAmountCalculation}
                        />
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "10%" }}>
                      <div>
                        <DatePicker
                          id={`invApfBuyer_DueDate${index}`}
                          disabledDate={disabledDate}
                          suffixIcon={<img src={DatePickerImg} alt="pickericon" style={{ width: "17px", height: "17px" }} />}
                          format="DD-MM-YYYY"
                          onChange={(event: any) => {
                            const arr = apfBuyerdataSource;
                            arr[index].DueDate = moment(event._d).format("DD-MM-YYYY");
                            setApfBuyerDataSource(arr);
                          }}
                          style={{ width: 100 }}
                          className="InputContainer"
                        />
                      </div>
                    </h5>
                    <h5 className='body' style={{ width: "20%" }} id={`invApfBuyer_Finance${index}`}>
                      <div style={{ display: "flex" }}>
                        <span id={`invApfBuyer_FinanceCurrency${index}`} style={{ marginRight: "10px", border: "1px solid #d9d9d9", width: "50%", height: " 45px", borderRadius: "10px", padding: "9px", backgroundColor: "#FFFFFF", display: "flex", alignItems: "center" }}>
                          {record.InvoiceCurrency}
                        </span>

                        <div style={{ display: "block" }}>
                          <InputNumber
                            className="InputContainer"
                            style={{ width: "80%" }}
                            id={`invApfBuyer_FinanceAmount${index}`}
                            defaultValue={record.FinanceAmount}
                            value={(record.FinanceAmount)}
                            disabled={!record.InvoiceAmount}
                            onChange={(event: any) => {
                              const arr = [...apfBuyerdataSource];
                              arr[index].FinanceAmount = parseInt(event)
                              setApfBuyerDataSource(arr);
                            }}
                            formatter={(value, e) => {
                              if (value === "" || isNaN(parseFloat(value))) {
                                return "";
                              }
                              return e?.userTyping === false ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                                : value
                            }}
                          onBlur={PartyFinanceAmountCalculation}

                          />

                        </div>




                      </div>
                    </h5>

                    <h5 className='body' style={{ width: "6%" }} id={`invApfBuyer_attachments${index}`}>
                      <UploadImage selectedFiles={selectedFiles} attachmentFiles={[]} />
                    </h5>
                    <h5 id={`invApfBuyer_delete${index}`}>
                      <img src={DeleteIcon} alt="deleteIcon"
                        onClick={() => onRowDelete(record)}
                      />
                    </h5>
                  </div>

                )
              })

            }
          </div>


        </div>
        <div
          onClick={apfBuyerAppendData}
          style={{
            position: "absolute",
            right: "5%",
            padding: "0px 8px",
            backgroundColor: "#FFB800",
            color: "#FFFFFF",
            marginTop: "10px"
          }}
          id="addInvoice"
        >
          +
        </div>
        <div className="SummaryContainer" style={{ marginTop: "5%" }}>
          <div className="SummaryLabel">Comments</div>
          <TextArea
            style={{ width: "50%", margin: "0px 10px" }}
            onChange={(e: any) => commentsData(e.target.value)}
            id={`inv_comments`}
          />
        </div>
      </Form>
    </div>
  )
}
export default ApfSeller